import { actionCreators, actionTypes } from '@app/common/auth/auth.reducer';
import { actionTypes as sessionActionTypes } from '@app/common/session/session.reducer';
import { AnyAction, Middleware } from 'redux';
import { PERSIST, REHYDRATE } from 'redux-persist';
import { LOCATION_CHANGE, CALL_HISTORY_METHOD } from 'connected-react-router';
import * as cognito from '../../lib/cognito';
import UserSession from '@app/common/user-session';
import { State } from '../root-reducer';
import { add } from 'date-fns';

const IGNORE_LIST = [
  PERSIST,
  REHYDRATE,
  LOCATION_CHANGE,
  CALL_HISTORY_METHOD,
  actionTypes.TOKEN_REFRESH_REQUEST,
  actionTypes.TOKEN_REFRESH_SUCCESS,
  actionTypes.TOKEN_REFRESH_FAILURE,
];

export const jwt: Middleware = ({ dispatch, getState }) => {
  let tokenRefreshing = false;
  let actionQueue: AnyAction[] = [];

  return (next) => (action) => {
    if (!IGNORE_LIST.includes(action.type)) {
      const session = (getState() as State).common?.auth?.session;
      const currentTime = add(new Date(), { seconds: 15 });
      const expireTime = new Date(session?.expiration as number);

      if (!session?.token || currentTime < expireTime) {
        return next(action);
      } else {
        if (!tokenRefreshing) {
          tokenRefreshing = true;
          dispatch(actionCreators.tokenRefreshRequest());
        }
        actionQueue.push(action);
      }
    } else {
      if (action.type === REHYDRATE && action.payload?.session?.userId) {
        cognito.createCognitoUser(action.payload.session.userId);
        // Only refresh these session params if not in a partner space
        if (!UserSession.getParam('isImpersonating')) {
          UserSession.setParams({
            token: action.payload.session.token,
            role: action.payload.session.role,
            userId: action.payload.session.userId,
            partnerName: action.payload.session.partnerName,
            partnerId: action.payload.session.partnerId,
          });
        }
      } else if (action.type === actionTypes.TOKEN_REFRESH_SUCCESS) {
        tokenRefreshing = false;
        actionQueue.forEach(dispatch);
        actionQueue = [];
      }
      action.type !== actionTypes.TOKEN_REFRESH_REQUEST && next(action);
    }
  };
};
