import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@patternfly/react-core';
import { DownloadImage } from '@app/components/DownloadImage';
import { sortable, cellWidth } from '@patternfly/react-table';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { PageSection, Title, Button, ModalVariant, Modal } from "@patternfly/react-core";
import { BullhornIcon } from '@patternfly/react-icons';
import { useSiteContext } from '../../contexts/site.context';
import { controllerSelectors, serverSelectors, siteSelectors } from '@app/common/configuration/configuration.selectors';
import { useSearch } from '@app/lib/use-search';
import { push } from 'connected-react-router';
import { ErrorView } from '../error/error';
import { actionCreators } from '@app/common/configuration/configuration.reducer';

export const SiteList: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isFailed } = useSiteContext();

  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, actionId: '' });
  const [page, setPage] = React.useState(1);
  
  const sites = useSearch(useSelector(siteSelectors.selectAll));
  const controllerById = useSelector(controllerSelectors.selectEntities);
  const serverById = useSelector(serverSelectors.selectEntities);

  const getColumns = () => {
    return [
      { title: 'Address', transforms: [sortable] }, 
      { title: 'City' }, 
      { title: 'Company' }, 
      { title: 'Controller', transforms: [sortable] }, 
      { title: 'WAN', transforms: [sortable] },
      { title: 'Site ID' }, 
      { title: 'Image' },
      { title: '' },
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  }

  const editSite = React.useCallback((_, __, data) => {
    const siteId = data.cells[7].id;
    dispatch(push(`/site/edit/${siteId}`));
  }, [dispatch]);

  const deleteSite = React.useCallback((_, __, data) => {
    const siteId = data.cells[7].id;
    setConfirmOpen({ isOpen: true, actionId: siteId });
  }, [confirmOpen]);

  const deleteRow = React.useCallback(() => {
    dispatch(actionCreators.siteDeleteRequest(confirmOpen.actionId));
    setConfirmOpen({ isOpen: false, actionId: '' }); 
  }, [dispatch, confirmOpen]);

  const handleConfirmToggle = React.useCallback(() => {
    setConfirmOpen({ isOpen: false, actionId: '' });
  }, [setConfirmOpen]);

  const siteFromItem = React.useCallback((item) => {
    const siteServerId = item.Tunnels?.[0]?.serverId ?? "N/A";
    const siteServer = siteServerId !== "N/A" ? serverById[siteServerId] : "N/A";
    const controller = controllerById[(siteServer as any)?.gatewayId] || { name: "N/A "};
    return {
      cells: [
        { title: <Link to={{ pathname: "/site/" + item.id }}>{item.address1}</Link>},
        { title: item.city },
        { title: item.ClientCompany.name },
        { title: controller.name },
        { title: item.Wan ? <Link to={{ pathname: "/wan/" + item.Wan.id }}>{item.Wan.name}</Link> : ""},
        { title: item.id.replaceAll('-', '') },
        { title: <DownloadImage imageType="site" imageId={item.id} />},
        { id: item.id }
      ]
    }
  }, [sites, serverById, controllerById]);

  if (isFailed) {
    return <ErrorView title="Error" message="Failed to load Sites" />
  }

  if (isLoading && !isSuccess) {
    return <Spinner size="lg" />;
  }

  return (
    <React.Fragment>
    <Helmet>
      <title>Sites</title>
    </Helmet>
    <PageSection>
      <div><Title headingLevel="h1" size="xl">Sites</Title><br /></div>
      
      {sites.length === 0 ? <div>No Sites found!</div> :
        <div>
          <PatternflyTable
            columns={getColumns}
            editRow={editSite}
            deleteRow={deleteSite}
            data={sites.map(siteFromItem)}
            page={page}
          />
        </div>
      }

      <Modal
        title="Confirm Action"
        titleIconVariant={BullhornIcon}
        variant={ModalVariant.small}
        isOpen={confirmOpen.isOpen}
        onClose={handleConfirmToggle}
        actions={[
          <Button key="confirm" variant="primary" onClick={deleteRow}>Yes</Button>,
          <Button key="cancel" variant="link" onClick={handleConfirmToggle}>Cancel</Button>
        ]}
      >
        <React.Fragment>
          <p>Are you sure you want to delete this site?</p> 
          <b>{confirmOpen.actionId}</b>
        </React.Fragment>
      </Modal>
    </PageSection>
  </React.Fragment>
  )
}
