import gql from "graphql-tag";

export const GET_PUBLIC_IPS = gql`
  query AllPublicIps {
    PublicIp {
      id
      address
      ip4Cidr
      isV6
      serverId
    }
  }
`;

export const CREATE_PUBLIC_IP = gql`
  mutation CreatePublicIp($input: [PublicIp_insert_input!]!) {
    insert_PublicIp(objects: $input) {
      returning {
        id
      }
    }
  }
`;
