import * as React from 'react';
import { Button, Card, CardBody, Modal, ModalVariant, Checkbox } from '@patternfly/react-core';
import UserSession from '@app/common/user-session';
import { viewerSubmitPaths } from '@app/common/constants';
import BullhornIcon from '@patternfly/react-icons/dist/js/icons/bullhorn-icon';
import './Saver.css';

interface SaverProps {
  isLoading: boolean,
  message: string,
  submitButtonText: string,
  submitButtonisDisabled: boolean,
  confirmationMessage: string,
  showConfirmationMessage: boolean,
  cancelButtonText: string,
  showCancel: boolean,
  isConfirmOpen: boolean,
  forceAcceptTerms: boolean,
  termsChecked: boolean,
  submit: () => void,
  cancel: () => void,
}

class Saver extends React.Component<Partial<SaverProps>, Partial<SaverProps>>  {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      message: "",
      submitButtonText: "",
      submitButtonisDisabled: false,
      confirmationMessage: "",
      showConfirmationMessage: true,
      cancelButtonText: "Cancel",
      showCancel: true,
      isConfirmOpen: false,
      forceAcceptTerms: false,
      termsChecked: false
    }
  }

  componentDidMount() {
    this.setState(() => ({
      isLoading: this.props.isLoading,
      message: this.props.message,
      showCancel: this.props.showCancel,
      showConfirmationMessage: this.props.showConfirmationMessage === undefined ? true : this.props.showConfirmationMessage,
      confirmationMessage: this.props.confirmationMessage || "Are you sure you want to save this resource?", 
      submitButtonText: this.props.submitButtonText,
      cancelButtonText: this.props.cancelButtonText || "Cancel",
      submitButtonisDisabled: this.props.submitButtonisDisabled,
      forceAcceptTerms: this.props.forceAcceptTerms || false
    }));
  }

  handleCheckboxChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleConfirmToggle = () => {
    this.setState(({ isConfirmOpen }) => ({
      isConfirmOpen: !isConfirmOpen
    }));
  };

  handleSubmit = async () => {
    this.setState(() => ({
      isLoading: true,
      isConfirmOpen: false
    }));
    await this.props.submit();
    (document.getElementById('submit-btn') as HTMLButtonElement).blur();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var isLoading = this.state.isLoading;
    var message = this.state.message;
    var submitButtonisDisabled = this.state.submitButtonisDisabled;

    if (nextProps.isLoading !== isLoading) {
      isLoading = nextProps.isLoading;
    }
    if (nextProps.message !== message) {
      message = nextProps.message;
    }
    if (nextProps.submitButtonisDisabled !== submitButtonisDisabled) {
      submitButtonisDisabled = nextProps.submitButtonisDisabled;
    }

    this.setState(() => ({
      isLoading: isLoading,
      message: message,
      submitButtonisDisabled: submitButtonisDisabled
    }));
  }

  render() {    
    const { isLoading, message, submitButtonText, cancelButtonText, showConfirmationMessage, confirmationMessage, showCancel, isConfirmOpen, submitButtonisDisabled, forceAcceptTerms, termsChecked } = this.state;
    const termsLink = process.env.REACT_APP_SITE_TERMS_LINK;
    const confirmButtonDisabled = forceAcceptTerms ? (termsChecked ? false : true) : false;
    const isViewer = UserSession.getParam('role')?.toLowerCase() === 'viewer';
    const currentPath = window.location.pathname + window.location.search;
    const isViewerSubmitPath = viewerSubmitPaths().includes(currentPath) ? true : false;
    let submitDisabled = (submitButtonisDisabled || isViewer) && !isViewerSubmitPath;

    if (isViewerSubmitPath) {
      submitDisabled = submitButtonisDisabled;
    }

    return (
      <div>
        <table className="buttons-table">
          <tbody>
            <tr height="60">
              <td className="buttons-td">
                <Button id="submit-btn" isLoading={isLoading} isDisabled={submitDisabled} variant="primary" className="button-confirm" onClick={showConfirmationMessage ? this.handleConfirmToggle : this.props.submit}>
                  {submitButtonText} 
                </Button>
                <Modal
                  title="Confirm Action"
                  titleIconVariant={BullhornIcon}
                  variant={ModalVariant.small}
                  isOpen={isConfirmOpen}
                  onClose={this.handleConfirmToggle}
                  actions={[
                    <Button key="confirm" variant="primary" className="button-confirm" isDisabled={confirmButtonDisabled} onClick={this.handleSubmit} autoFocus>Yes</Button>,
                    <Button key="cancel" variant="primary" className="button-confirm" onClick={this.handleConfirmToggle}>Cancel</Button>
                  ]}
                >
                  {forceAcceptTerms ? 
                    <div>
                      <Checkbox 
                        label="Accept the Terms and Conditions" aria-label="Accept the Terms and Conditions" 
                        name="termsChecked" id="termsChecked" key="termsChecked"
                        isChecked={termsChecked} onChange={this.handleCheckboxChange}
                      /> 
                      <br /> 
                      <p>Terms and Conditions can be read <a href={termsLink} target="_blank">here</a>.</p>
                    </div>
                  : 
                    <div>{confirmationMessage}</div>
                  }
                </Modal>
              </td>
              {showCancel ? 
                <td className="buttons-td">
                  <Button variant="primary" className="button-confirm" onClick={this.props.cancel}>
                    {cancelButtonText}
                  </Button>
                </td>
              : <td></td>}
              <td className="buttons-td-message">
                {message ? 
                  <Card className="response-message-box" isCompact>
                    <CardBody>
                      {message}
                    </CardBody>
                  </Card>
                : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export { Saver };