import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import Site from '@app/api/site/site.actions';
import { Spinner } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import { truncateString } from '@app/utils/utils';
import { sortBy } from '@app/lib/functions';
import { PageSection, Title, Button, Modal, ModalVariant } from "@patternfly/react-core";
import { BullhornIcon } from '@patternfly/react-icons';
import '../../app.css';

export const ACLProfileList: React.FunctionComponent = ({ props }) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [aclProfiles, setAclProfiles] = React.useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isErrorOpen, setIsErrorOpen] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [deleteError, setDeleteError] = React.useState("");

  React.useEffect(() => {
    (async function() {
      try {
        const acls = await ACLProfile.getAll();
        const sites = await Site.getAll();
        const sorted = sortBy(acls, 'name');
        let aclProfileRows = [];

        if (sorted) {
          aclProfileRows = sorted.map(item => {
            return aclProfileFromItem(sorted, item, sites);
          });
        }

        setAclProfiles(aclProfileRows);
        setLoading(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const getColumns = () => {
    return [
      { title: 'Name', transforms: [sortable] }, 
      { title: 'Description' }, 
      { title: 'Sites', transforms: [sortable] },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' }},
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  }

  const getColumnIndex = (columnName) => {
    const columns = getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  const editACLProfile = (event, rowId, rowData) => {
    const aclProfileId = rowData['id']['title'];
    history.push("/aclProfile/edit/" + aclProfileId);
  }

  const deleteACLProfile = async (event, rowId, rowData) => {
    const aclProfileId = rowData['id']['title'];
    setIsConfirmOpen(!isConfirmOpen);
    setActionId(aclProfileId);
  }

  const deleteRow = async () => {
    const aclProfile = await ACLProfile.get(actionId);

    if (aclProfile.assignments?.length > 0) {
      setDeleteError("This ACL Profile has Sites or WANs assigned. You must unassign this from all Sites and WANs before it can be deleted.")
      setIsConfirmOpen(false);
      setIsErrorOpen(true);
    }
    else {
      const result = await ACLProfile.delete(actionId);

      if (result?.data?.request_id) {
        const aclProfileIdIndex = getColumnIndex('ID');
        const updated = aclProfiles.filter(a => a.cells[aclProfileIdIndex].title !== actionId);
  
        setAclProfiles(updated);
        setLoading(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
        setActionId("");
        setPage(1);
      }
      else {
        setDeleteError("There was an error deleting the ACL Profile, or you are not permitted to perform this action")
        setIsConfirmOpen(false);
        setIsErrorOpen(true);
      }
    }
  }

  const handleConfirmToggle = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };

  const handleErrorToggle = () => {
    setIsErrorOpen(!isErrorOpen);
  };

  const aclProfileFromItem = (acls, item, allSites) => {
    let sitesCount = 0;  

    acls.map(acl => {
      if (acl.id === item.id && acl.assignments) {
        let sites = acl.assignments.filter(a => a.resource_type === 'site');
        let wans = acl.assignments.filter(a => a.resource_type === 'wan');

        if (sites?.length > 0) {
          sitesCount += sites.length;
        }
        if (wans?.length > 0) {
          wans.map(wan => {
            const wanSites = allSites.filter(site => site.wanId === wan.resource_id);
            const siteCount = wanSites ? wanSites.length : 0;
            sitesCount += siteCount;
          });
        }
      }
    });

    return {
      cells: [
        { title: <Link to={{ pathname: "/aclprofile/" + item.id }}>{item.name}</Link> },
        { title: truncateString(item.description, 50) },
        { title: sitesCount },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
      ]
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>ACL Profiles</title>
      </Helmet>
      <PageSection>
        <Title headingLevel="h1" size="xl">ACL Profiles</Title><br />

        {loading ? 
          <Spinner size="lg" />
        : aclProfiles.length === 0 ? <div>No ACL Profiles found!</div> :
          <div>
            <PatternflyTable
              columns={getColumns}
              editRow={editACLProfile}
              deleteRow={deleteACLProfile}
              data={aclProfiles}
              page={page}
            />
          </div>
        }

        <Modal
          title="Confirm Action"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isConfirmOpen}
          onClose={handleConfirmToggle}
          actions={[
            <Button key="confirm" variant="primary" onClick={deleteRow}>Yes</Button>,
            <Button key="cancel" variant="link" onClick={handleConfirmToggle}>Cancel</Button>
          ]}
        >
          <p>Are you sure you want to delete this ACL Profile?</p>
          <p><b>{actionId}</b></p>
        </Modal>

        <Modal
          title="Error"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isErrorOpen}
          onClose={handleErrorToggle}
          actions={[
            <Button key="cancel" variant="primary" onClick={handleErrorToggle}>OK</Button>
          ]}
        >
          {deleteError}
        </Modal>
      </PageSection>
    </React.Fragment>
  );
}
