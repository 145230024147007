import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Page } from '@patternfly/react-core';
import { userIsAuthenticatedRedir } from './auth.component';
import { withSplashScreen } from './splash.screen';
import { Header } from './header.component';
import { Sidebar } from './sidebar.component';

const AppLayout = withRouter(withSplashScreen(userIsAuthenticatedRedir(({ children }) => {
  return (
    <Page
      isManagedSidebar
      mainContainerId="primary-app-container"
      header={<Header />}
      sidebar={<Sidebar />}
    >
      {children}
    </Page>
  );
})));

export { AppLayout };