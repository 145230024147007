// @ts-nocheck
import * as React from 'react';
import { DEFAULT_MIN_SERVER_PORT, DEFAULT_MAX_SERVER_PORT } from '@app/lib/constants';
import Partner from '@app/api/partner/partner.actions';
import Site from '@app/api/site/site.actions';
import SiteApi from '@app/api/site-api/site.actions';
import Controller from '@app/api/controller/controller.actions';
import ClientCompany from '@app/api/client-company/client-company.actions';
import Server from '@app/api/gateway/gateway.actions';
import Wan from '@app/api/wan/wan.actions';
import { wifiChannelOptions } from '@app/common/constants';
import { InfoSection } from '@app/components/InfoSection';
import { IconHeading } from '@app/components/IconHeading';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import License from '@app/api/license/license.actions';
import UserSession from '@app/common/user-session';
import { truncateString } from '@app/utils/utils';
import { getAlgos, getLbAlgoIdFromName, getLbAlgoNameFromId } from './TunnelHelper';
import getAllGatewayIds from '@app/pages/wan/WanHelper';
import { Link } from 'react-router-dom';
import { getCountryOptions, getRegionOptions, getIndustryOptions, getLinkInterfaceOptions } from '@app/common/world';
import { ServiceCatalogIcon } from '@patternfly/react-icons';
import throughputFromLinks from './SiteHelper';
import '../../app.css';

import {
  textInputRequiredOnly,
  textInputValidate,
  isValidNumber,
  isValidIP,
  isValidEmail,
  isValidSubnet,
  ipInSubnet,
  isValidPhone,
  isValidPercent,
  isWithinRange,
  isLengthWithinRange,
  isUUID,
  isValidUrl,
  isValidVlan,
  isValidIpNotRequired,
} from '@app/lib/validator';

import { MAX_LINKS_ALLOWED } from '@app/lib/constants';

import { sortBy, nextIp, lastIp, isUsableAddress } from '@app/lib/functions';

import { Saver } from '@app/components/Saver';
import { Spacer } from '@app/components/Spacer';
import { IconHeading } from '@app/components/IconHeading';
import { Spinner } from '@patternfly/react-core';
import { TimePicker } from '@app/components/TimePicker';
import ContactInfo from './components/ContactInfo';
import SiteInfo from './components/SiteInfo';
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  BullhornIcon,
  CatalogIcon,
  EnterpriseIcon,
  ExclamationCircleIcon,
  KeyIcon,
  NetworkIcon,
  PlusIcon,
  ServiceCatalogIcon,
  TrashIcon,
  TopologyIcon,
  PficonNetworkRangeIcon,
  UserIcon,
  WrenchIcon,
} from '@patternfly/react-icons';
import {
  PageSection,
  Title,
  Button,
  Card,
  CardBody,
  Checkbox,
  Chip,
  ChipGroup,
  DatePicker,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  Select,
  SelectOption,
  SelectVariant,
  TextArea,
  TextInput,
  Tabs, 
  Tab, 
  TabTitleIcon, 
  TabTitleText, 
} from '@patternfly/react-core';

const LanSettings = React.lazy(() => import('./elements/lan-settings/lan-settings.form'));
const WanSettings = React.lazy(() => import('./elements/wan-settings/wan-settings.form'));
const TunnelSettings = React.lazy(() => import('./elements/tunnel-settings/tunnel-settings.form'));

import { FormGroupSpacer } from '../../components/forms/pf/form-group-spacer.component';

import './Site.css';

enum TabIndex {
  // OSPF,
  BGP
}

const TitleMap = {
  // [TabIndex.OSPF]: 'OSPF',
  [TabIndex.BGP]: 'BGP'
};
// TODO: create state type
class SiteForm extends React.Component<any, any> {
  constructor(props) {
    super(props);

    // TODO: restructure route url, have id and action params?
    const id = this.props.computedMatch.params.id;

    const countries = getCountryOptions();
    const industries = getIndustryOptions();

    const mode = id ? 'edit' : 'new';

    this.state = {
      id: id,
      countries: countries,
      regions: [],
      industries: industries,
      newCompany: '',
      isNewCompanyOpen: false,
      isCountryOpen: false,
      isRegionOpen: false,
      isLbAlgoOpen: false,
      isLicenseOpen: false,
      lbAlgo: 'HybridLink',
      licenseId: '',
      licenseList: [],
      flowlet: false,
      clientCompanyId: '',
      isServerless: false,
      activeTabIndex: TabIndex.BGP,
      bgp_configuration_rows: 10,
      // ospf_configuration_rows: 10,
      wanId: '',
      wanName: '',
      siteInfo: { address1: '', address2: '', postalCode: '', country: '', state: '', city: '' },
      siteContact: { firstName: '', lastName: '', email: '', phone: '', ext: '', emailAlert: false },
      // TODO: We need to ensure that the primary tunnel is at index 0 since the rest of orchestrator
      // is expecting that. This should be refactored
      siteTunnels: [
        {
          serverId: '',
          isPrimary: true,
          autoMTU: true,
          mtu: '1452',
          lossTolerance: '25',
          portRangeStart: DEFAULT_MIN_SERVER_PORT,
          portRangeEnd: DEFAULT_MAX_SERVER_PORT,
          shouldReorderBuffer: true,
          reorderBufferTime: 5,
          isClearTextData: true,
          isQOSEnabled: false
        },
        {
          serverId: '',
          isPrimary: false,
          autoMTU: true,
          mtu: '1452',
          lossTolerance: '25',
          portRangeStart: DEFAULT_MIN_SERVER_PORT,
          portRangeEnd: DEFAULT_MAX_SERVER_PORT,
          shouldReorderBuffer: true,
          reorderBufferTime: 5,
          isClearTextData: true
          // bd: missing qos value
        },
      ],
      hasActiveRoutes: false,
      newRouteIndex: 0,
      newQuickLinkIndex: 0,
      controllers: [],
      activeController: {},
      activeServer: {},
      standbyController: {},
      standbyServer: {},
      standbyControllers: [],
      lans: [],
      wans: [],
      routes: [],
      links: [],
      quickLinks: [],
      linkTypes: [],
      carriers: [],
      businessContinuityLinks: [],
      isActiveControllerOpen: false,
      isActiveServerOpen: false,
      isStandbyControllerOpen: false,
      isStandbyServerOpen: false,
      isInterfaceErrorOpen: false,
      isLinkInterfaceErrorOpen: false,
      isLanInterfaceErrorOpen: false,
      isWanOpen: false,
      lossManual: false,
      failoverEnabled: false,
      portRangeCustom: false,
      isBusinessContinuityOpen: false,
      isACLProfileOpen: false,
      aclProfiles: [],
      aclProfileOptions: [],
      linkViewClass: 'link-container',
      linkViewLabel: 'Show More',
      businessContinuity: 'eth1',
      selectedInterface: '',
      throughput: '',
      clientCompanies: [],
      clientCompany: {},
      emailAlertTest: '',
      emailAlerts: [],
      alertErrorState: {},
      message: '',
      messageType: '',
      sortLinks: true,
      dataLoading: id ? true : false,
      mode: mode,
    };
  }

  componentDidMount = () => {
    try {
      this.loadSite();
    }
    catch (error) {
      this.setState(() => ({
        message: 'There was an error loading the site',
      }));
    }
  };

  loadSite = async () => {
    const partnerId = UserSession.getPartnerId();
    const controllers = await Controller.getAll();
    const clientCompanies = await ClientCompany.getAll();
    const sites = await Site.getAll();
    const linkTypes = await Partner.getLinkTypes();
    const carriers = await Partner.getCarriers();
    const wans = await Wan.getAll();
    const servers = await Server.getAll();
    const wanGateways = getAllGatewayIds(wans);
    const serverlessController = controllers.find((c) => c.name === 'Serverless');
    const notServerless = controllers.filter((c) => c.name !== 'Serverless');
    const products = await License.getProducts(partnerId);
    const licenseList = await License.getUnassigned(partnerId, this.state.id);
    const license = licenseList.find((l) => l.assigned_to === this.state.id);
    const product = license ? products.find(product => product.id === license.type) : '';
    const licenseId = license && product ? license.id + ' - ' + product.name : '';
    const allAclProfiles = await ACLProfile.getAll();
    let aclProfileOptions = [];

    if (allAclProfiles) {
      aclProfileOptions = allAclProfiles.map(({ id, inbound_rules, outbound_rules, ...profiles }) => profiles);
      aclProfileOptions = aclProfileOptions.map((profile, index) => {
        return {
          value: profile.name,
          description: truncateString(profile.description, 80),
          disabled: false,
          key: index
        }
      });
    }

    if (this.state.mode === 'new') {
      this.setState(() => ({
        controllers: notServerless,
        wans: wans,
        aclProfileOptions: [...aclProfileOptions],
        serverlessController: serverlessController,
        clientCompanies: clientCompanies,
        linkTypes: linkTypes,
        carriers: carriers,
        products: products,
        licenseList: licenseList,
        wanEnabled: false,
        wanName: '',
      }));
      this.addLink();
      this.addLan(true);
    }
    else if (this.state.mode === 'edit') {
      const site = sites.find((site) => site.id === this.state.id);
      const lans = await Site.getSiteLansBySiteId(this.state.id);
      const routingConfig = await SiteApi.getRouting(this.state.id);

      if (site === undefined) {
        this.props.history.push('/notfound');
      }
      const clientCompany = clientCompanies.find((c) => c.id === site.clientCompanyId);
      let siteContact = site.SiteContacts[0];
      siteContact.phone = siteContact.phone || "";
      siteContact.ext = siteContact.ext || "";
      siteContact.label = siteContact.label || "";
      const siteRoutes = site.SiteRoutes;
      const tunnels = site.Tunnels;
      const activeTunnel = tunnels[0];
      const standbyTunnel = tunnels[1];

      // Active Controller
      let activeController = controllers.find((c) => c.id === activeTunnel?.Server.gatewayId);
      const activeServers = activeController.Servers.filter((s) => {
        return (s.gatewayId === activeController?.id && s.Tunnels?.length === 0 && !wanGateways.includes(s.id)) || s.id === activeTunnel?.Server.id;
      });
      const activeServer = servers.find((server) => server.id === activeTunnel?.serverId);
      activeController.Servers = [...activeServers];

      // Standby Controller
      let standbyController = standbyTunnel ? controllers.find((c) => c.id === standbyTunnel.Server.gatewayId) : {};
      const standbyServers = standbyTunnel ? standbyController.Servers.filter((s) => {
        return (s.gatewayId === standbyController?.id && s.Tunnels?.length === 0 && !wanGateways.includes(s.id)) || s.id === standbyTunnel?.Server.id;
      }) : [];
      const standbyServer = standbyTunnel ? servers.find((server) => server.id === standbyTunnel.serverId) : '';
      standbyController.Servers = standbyTunnel ? [...standbyServers] : [];
      const standbyControllers = notServerless.filter((c) => {
        return c.id !== activeTunnel.Server.gatewayId;
      });

      const lossTolerance = activeTunnel.lossTolerance || '25';

      // this isnt used?
      const mtu = activeTunnel.mtu || '1452';

      let links = activeTunnel.Links;

      const businessContinuityLink = links.find((link) => link.isPassthrough === true);

      const businessContinuityLinks = links.filter((link) => {
        return link.isEnabled === true;
      });

      // TODO: why not write more efficient gql query, get wan single query
      const siteWan = wans.find((w) => w.id === site.wanId);

      const wanEnabled = site.wanId && site.wanId !== '' ? true : false;

      let siteTunnels = [activeTunnel];

      if (standbyTunnel) {
        siteTunnels[1] = standbyTunnel;
      }

      // These Link props are for UI related stuff only, and not present in the database
      for (var i = 0; i < links.length; i++) {
        let dateStr = '';
        let timeStr = '';

        if (links[i].dataLimitReset) {
          let dateParts = links[i].dataLimitReset.split('T');
          dateStr = dateParts[0];
          let timePart = dateParts[1].split('+');
          let timeParts = timePart[0].split(':');
          let hour = timeParts[0].startsWith('0') ? timeParts[0].substr(timeParts[0].length - 1) : timeParts[0];
          let minute = timeParts[1];
          let ampm = parseInt(hour) >= 12 ? 'pm' : 'am';
          hour = ampm === 'pm' ? parseInt(hour) - 12 : hour;
          timeStr = hour + ':' + minute + ' ' + ampm;
        }

        links[i].cardClass = this.getCardClass('links', links[i].isEnabled);
        links[i].typeSelectOpen = false;
        links[i].interfaceNameSelectOpen = false;
        links[i].carrierNameSelectOpen = false;
        links[i].dataLimitResetTimeSelectOpen = false;
        links[i].addressTypeSelectOpen = false;
        links[i].dataLimitResetDate = dateStr;
        links[i].dataLimitResetTime = timeStr;
        links[i].dataLimitEnabled = links[i].dataLimit > 0;

        const addressParts = links[i].staticIp?.split('/');

        if (addressParts?.length == 2) {
          links[i].tunnelLinkIp4 = addressParts?.[0];
          links[i].linkNetmask = `/${addressParts?.[1]}`;
        } else {
          links[i].tunnelLinkIp4 = '';
          links[i].linkNetmask = '';
        }

        links[i].staticGatewayIp = links[i].staticGatewayIp ?? '';
        links[i].addressType = links[i].staticGatewayIp ? 'Static' : 'Dynamic';

        const dnsAddresses = links[i].staticDnsIp?.split(',');
        links[i].dnsAddresses = Array.isArray(dnsAddresses) ? dnsAddresses : [dnsAddresses];
        const embedded = links[i].modemAPN?.split(':');
        if (embedded?.length > 1) {
          links[i].modemIndex = embedded[0];
          links[i].modemAPN = embedded[1];
        }
        else {
          links[i].modemIndex = 0;
          links[i].modemAPN = embedded ? embedded[0] : '';
        }
      }

      // Set defaults for LANs
      const dmzLans = lans.filter(lan => lan.isDMZ === true);
      const dmzCount = dmzLans ? dmzLans.length : 0;

      for (var i = 0; i < lans.length; i++) {
        let lan = lans[i];
        lan.interfaceName = lan.interfaceName.split(',');
        lan.dhcpDefaultLease = !lan.dhcpDefaultLease || lan.dhcpDefaultLease === '' ? '3600' : lan.dhcpDefaultLease;
        lan.dhcpMaxLease = !lan.dhcpMaxLease || lan.dhcpMaxLease === '' ? '7200' : lan.dhcpMaxLease;
        lan.vlanId = lan.vlanId || '';
        lan.isDMZDisabled = dmzCount >= 1 ? !lan.isDMZ : false;
      }

      let aclProfiles = await ACLProfile.getAssignedACLs(site.id);

      if (site.wanId) {
        aclProfiles = aclProfiles.concat(await ACLProfile.getAssignedACLs(site.wanId));
        site.isServerless = false;
      }

      aclProfiles = aclProfiles.map(acl => { return acl.name });

      // let ospfConfig = "";
      let bgpConfig = "";
      // const ospf = routingConfig?.ospf_configuration;
      const bgp = routingConfig?.routing_configuration?.bgp_configuration;
      
      // if (ospf) {
      //   ospfConfig = ospf.join('\n');
      // }
      if (bgp) {
        bgpConfig = bgp.join('\n');
      }  
      this.setState(() => ({
        originalData: {
          isServerless: site.isServerless,
          activeTunnel: JSON.parse(JSON.stringify(activeTunnel)),
          standbyTunnel: standbyTunnel !== undefined ? JSON.parse(JSON.stringify(standbyTunnel)) : undefined,
          links: JSON.parse(JSON.stringify(links)),
          lans: JSON.parse(JSON.stringify(lans)),
          wanId: site.wanId,
        },
        siteInfo: {
          address1: site.address1 || '',
          address2: site.address2 || '',
          postalCode: site.postalCode || '',
          country: site.country || '',
          state: site.state || '',
          city: site.city || '',
        },
        siteTunnels: siteTunnels.map((tunnel) => ({ ...tunnel })),
        regions: site.country ? getRegionOptions(site.country) : [],
        siteContact: { ...siteContact },
        isServerless: site.isServerless,
        lbAlgo: getLbAlgoNameFromId(activeTunnel.lbAlgo),
        flowlet: activeTunnel.lbAlgo === "FastPath" ? false : activeTunnel.flowlet,
        wanId: site.wanId,
        wanName: site.wanId ? siteWan.name : '',
        wanEnabled: wanEnabled,
        lans: [...lans],
        wans: wans,
        hasActiveRoutes:
          siteRoutes.filter((route) => {
            return route.isActive === true && route.crudState !== 'delete';
          }).length > 0,
        aclProfiles: [...aclProfiles],
        aclProfileOptions: [...aclProfileOptions],
        products: products,
        licenseList: licenseList,
        licenseId: licenseId,
        controllers: [...notServerless],
        activeController: site.isServerless ? {} : { ...activeController },
        activeServer: site.isServerless ? {} : { ...activeServer } || '',
        loadedActiveServer: site.isServerless ? {} : { ...activeServer } || '',
        standbyControllers: [...standbyControllers],
        standbyController: { ...standbyController } || '',
        loadedStandbyController: { ...standbyController } || '',
        standbyServer: site.isServerless ? {} : { ...standbyServer } || '',
        loadedStandbyServer: site.isServerless ? {} : { ...standbyServer } || '',
        serverlessController: { ...serverlessController },
        routes: [...siteRoutes],
        failoverEnabled: tunnels.length >= 2,
        lossManual: lossTolerance !== '25',
        mtuManual: !siteTunnels[0].autoMTU,
        portRangeCustom:
          activeTunnel.portRangeStart !== DEFAULT_MIN_SERVER_PORT ||
          activeTunnel.portRangeEnd !== DEFAULT_MAX_SERVER_PORT,
        businessContinuity: businessContinuityLink ? 'eth' + businessContinuityLink.interfaceName : 'eth1',
        linkTypes: linkTypes,
        carriers: carriers,
        links: links.map((link) => {
          return { ...link, dataLimit: link.dataLimit > 0 ? link.dataLimit / 1000 / 1000 : '' };
        }),
        businessContinuityLinks: businessContinuityLinks,
        quickLinks: [...clientCompany.Quicklinks],
        isCompanyOpen: false,
        isIndustryOpen: false,
        clientCompanies: clientCompanies,
        clientCompanyId: site.clientCompanyId,
        clientCompany: { ...clientCompany },
        activeTabIndex: TabIndex.BGP,
        //ospf_configuration_rows: ospf?.length > 10 ? ospf.length : 10,
        bgp_configuration_rows: bgp?.length > 10 ? bgp.length : 10,
        routingConfig: {
          // ospf_enabled: controller.routing_configuration?.ospf_enabled,
          // ospf_configuration: ospfConfig,
          bgp_enabled: routingConfig?.routing_configuration?.bgp_enabled,
          bgp_configuration: bgpConfig,
        },
        dataLoading: false,
      }));
    }
  };

  onACLProfileSelect = (event, selection) => {
    const { aclProfiles } = this.state;

    if (aclProfiles.includes(selection)) {
      this.setState(prev => ({
        aclProfiles: prev.aclProfiles.filter(item => item !== selection)
      }));
    }
    else {
      this.setState(prev => ({
        aclProfiles: [...prev.aclProfiles, selection]
      }));
    }
  };

  clearACLProfileSelection = () => {
    const { wanName } = this.state;

    if (wanName === "") {
      this.setState({
        aclProfiles: [],
        isACLProfileOpen: false
      });
    }
  };

  chipGroupComponent = () => {
    const { aclProfiles, wanName } = this.state;

    return (
      <ChipGroup>
        {(aclProfiles || []).map((currentChip, index) => (
          <Chip
            isReadOnly={wanName !== "" ? true : false}
            key={currentChip}
            onClick={event => this.onACLProfileSelect(event, currentChip)}
          >
            {currentChip}
          </Chip>
        ))}
      </ChipGroup>
    );
  };

  onSelectToggle = (isOpen, isOpenKey) => {
    this.setState(() => ({
      [isOpenKey]: isOpen,
    }));
  };

  onSelectACLToggle = (isOpen) => {
    const { wanName } = this.state;

    if (wanName === "") {
      this.setState(() => ({
        isACLProfileOpen: isOpen,
      }));
    }
  };

  onWanSelectClick = async (event, selection, isPlaceholder) => {
    const { id, wans, wanName, siteTunnels, isServerless } = this.state;
    let wanGateways = getAllGatewayIds(wans);
    const controllers = await Controller.getAll();
    const notServerless = controllers.filter((c) => c.name !== 'Serverless');
    const servers = await Server.getAll();
    const wan = wans.find((wan) => wan.name === selection);
    const standbyControllers = notServerless.filter((c) => {
      return c.id !== wan.activeGatewayId;
    });
    const activeController = notServerless.find((c) => c.id === wan.activeGatewayId);
    const activeServer = servers.find((s) => s.id === wan.activeServerId);
    const activeServers = activeController.Servers.filter(s => {
      return s.gatewayId === activeController?.id && !wanGateways.includes(s.id);
    });
    activeController.Servers = [...activeServers];
    const standbyController = wan.standbyGatewayId ? notServerless.find((c) => c.id === wan.standbyGatewayId) : {};
    const standbyServer = wan.standbyServerId ? servers.find((s) => s.id === wan.standbyServerId) : {};

    const standbyServers = wan.standbyServerId ? standbyController.Servers.filter(s => {
      return s.gatewayId === standbyController?.id && !wanGateways.includes(s.id);
    }) : [];
    standbyController.Servers = [...standbyServers];

    let serverless = isServerless;
    let aclProfiles = await ACLProfile.getAssignedACLs(id);

    if (selection !== "") {
      aclProfiles = aclProfiles.concat(await ACLProfile.getAssignedACLs(wan.id));
      serverless = false;
    }

    aclProfiles = aclProfiles.map(acl => { return acl.name });

    siteTunnels[0].isClearTextData = !wan.isEncrypted;

    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState((prevState) => ({
        wanName: selection,
        aclProfiles: aclProfiles,
        isServerless: serverless,
        activeController: { ...activeController } || '',
        activeServer: { ...activeServer } || '',
        standbyController: { ...standbyController } || '',
        standbyServer: { ...standbyServer } || '',
        standbyControllers: [...standbyControllers],
        failoverEnabled: wan.standbyGatewayId ? true : false,
        siteTunnels: [...siteTunnels],
        isWanOpen: false,
      }));
    }
  };

  onSelectClick = (event, selection, isPlaceholder, stateVar, isOpenKey) => {
    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState(() => ({
        [stateVar]: selection,
        [isOpenKey]: false,
      }));
    }
  };

  onLinkSelectToggle = (isOpen, prop, linkId) => {
    const { links } = this.state;
    const changedSelect = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(changedSelect.id);
    const selectOpenProp = prop + 'SelectOpen';
    changedSelect[selectOpenProp] = isOpen;
    links[index] = changedSelect;

    this.setState(() => ({
      links: links,
    }));
  };

  onLinkSelectClick = (event, selection, isPlaceholder, prop, linkId) => {
    const { links } = this.state;
    const clickedSelect = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(clickedSelect.id);
    const selectOpenProp = prop + 'SelectOpen';
    clickedSelect[selectOpenProp] = false;
    clickedSelect[prop] = selection;
    links[index] = clickedSelect;

    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState(() => ({
        links: [...links],
      }));
    }
  };

  onLinkInterfaceSelectClick = (event, selection, isPlaceholder, linkId, prevInterface) => {
    const { links, businessContinuity, lans } = this.state;
    let bcLink = businessContinuity;
    let interfaceName = selection.replace('eth', '');
    const linkInUse = links.find((link) => link.interfaceName === interfaceName && link?.crudState !== 'delete');
    const lanInUse = lans.find((lan) => lan.interfaceName.includes(selection) && lan?.crudState !== 'delete');

    if (linkInUse || lanInUse) {
      this.setState(() => ({
        isInterfaceErrorOpen: true,
        selectedInterface: selection,
      }));
      return;
    }

    const clickedSelect = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(clickedSelect.id);
    const businessContinuityLinks = links.filter((link) => {
      return link.isEnabled === true && link.crudState !== 'delete';
    });
    clickedSelect['interfaceNameSelectOpen'] = false;
    clickedSelect['interfaceName'] = interfaceName;
    links[index] = clickedSelect;

    if (prevInterface === businessContinuity) {
      bcLink = selection;
    }

    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState(() => ({
        sortLinks: false,
        links: [...links],
        businessContinuity: bcLink,
        businessContinuityLinks: businessContinuityLinks,
      }));
    }
  };

  onSelectCompanyClick = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      // Do wat?
    } else {
      ClientCompany.getAll()
        .then((clientCompanies) => {
          const clientCompany = clientCompanies.find((c) => c.name === selection);

          this.setState(() => ({
            clientCompanyId: clientCompany.id,
            clientCompany: { ...clientCompany },
            clientCompanies: clientCompanies,
            isCompanyOpen: false,
          }));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  onActiveControllerSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController('activeController', 'isActiveControllerOpen');
    }
    else {
      const { controllers, wans, siteTunnels, loadedActiveServer, standbyController } = this.state;
      const active = controllers.find((c) => c.name === selection);
      const wanGateways = getAllGatewayIds(wans);
      const activeServers = active.Servers.filter(s => {
        return (s.gatewayId === active?.id && s.Tunnels?.length === 0 && !wanGateways.includes(s.id)) || s.id === loadedActiveServer?.id;
      });
      const standbyControllers = controllers.filter((c) => {
        return c.name !== selection;
      });

      this.setState((prevState) => ({
        activeController: {
          ...prevState.activeController,
          name: selection,
          Servers: [...activeServers],
        },
        activeServer: {},
        standbyControllers: [...standbyControllers],
        standbyController: selection === standbyController.name ? {} : { ...prevState.standbyController },
        standbyServer: selection === standbyController.name ? {} : { ...prevState.standbyServer },
        isActiveControllerOpen: false,
      }));
    }
  };

  onActiveServerSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController('activeServer', 'isActiveServerOpen');
    }
    else {
      this.setState((prevState) => ({
        activeServer: {
          ...prevState.activeServer,
          ip4Address: selection,
        },
        isActiveServerOpen: false,
      }));
    }
  };

  onStandbyControllerSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController('standbyController', 'isStandbyControllerOpen');
    }
    else {
      const { controllers, wans, siteTunnels, loadedStandbyServer, activeController } = this.state;
      let wanGateways = getAllGatewayIds(wans);
      const standbyController = controllers.find((c) => c.name === selection);
      const standbyServers = standbyController.Servers.filter(s => {
        return (s.gatewayId === standbyController?.id && s.Tunnels?.length === 0 && !wanGateways.includes(s.id)) || s.id === loadedStandbyServer?.id;
      });
      const standbyControllers = controllers.filter((c) => {
        return c.name !== selection && c.name !== activeController.name;
      });

      this.setState((prevState) => ({
        standbyServer: {},
        standbyControllers: [...standbyControllers],
        standbyController: {
          ...prevState.standbyController,
          name: selection,
          Servers: [...standbyServers],
        },
        isStandbyControllerOpen: false,
      }));
    }
  };

  onStandbyServerSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController('standbyServer', 'isStandbyServerOpen');
    }
    else {
      this.setState((prevState) => ({
        standbyServer: {
          ...prevState.standbyServer,
          ip4Address: selection,
        },
        isStandbyServerOpen: false,
      }));
    }
  };

  clearActiveController = (stateObject, isOpenStateVar) => {
    this.setState({
      [stateObject]: null,
      [isOpenStateVar]: false,
    });
  };

  getQuickLinks = (quickLinks) => {
    if (quickLinks === undefined) {
      return 'No Quick Links defined';
    }

    const allQuickLinks = quickLinks.filter((quickLink) => {
      return quickLink.crudState !== 'delete';
    });

    return allQuickLinks.map((quickLink, index) => {
      const quickLinkId = 'link-' + quickLink.id;
      const quickDescId = 'description-' + quickLink.id;
      const linkValidated = quickLink.link ? 'default' : 'error';
      const descValidated = 'default';
      var linkErrorText = ' Required field';
      var descErrorText = ' Required field';

      if (quickLink.link && !isValidUrl(quickLink.link)) {
        linkErrorText = ' Invalid link format';
        linkValidated = 'error';
      }
      if (quickLink.link && !isLengthWithinRange(quickLink.link, 1, 255)) {
        linkErrorText = ' Max 255 characters allowed';
        linkValidated = 'error';
      }
      if (quickLink.description && !isLengthWithinRange(quickLink.description, 1, 255)) {
        descErrorText = ' Max 255 characters allowed';
        descValidated = 'error';
      }

      return (
        <div className="quick-link" key={quickLink.id}>
          <FormGroup
            label="Quick Link:"
            isRequired
            fieldId={quickLinkId}
            validated={linkValidated}
            helperTextInvalid={linkErrorText}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
          >
            <TextInput
              className="form-input"
              isRequired
              type="text"
              id={quickLinkId}
              name={quickLinkId}
              validated={linkValidated}
              value={quickLink.link}
              onChange={(e) => this.handleQuickLinkChange('link', quickLinkId, e)}
            />
          </FormGroup>

          <FormGroupSpacer>
            <FormGroup
              label="Description:"
              fieldId={quickDescId}
              validated={descValidated}
              helperTextInvalid={descErrorText}
              helperTextInvalidIcon={<ExclamationCircleIcon />}
            >
              <TextInput
                className="form-input"
                type="text"
                id={quickDescId}
                name={quickDescId}
                validated={descValidated}
                value={quickLink.description}
                onChange={(e) => this.handleQuickLinkChange('description', quickDescId, e)}
              />
            </FormGroup>
          </FormGroupSpacer>

          <div onClick={(e) => this.deleteQuickLink(e, quickLink.id)}>
            <TrashIcon className="pointer" />
          </div>
        </div>
      );
    });
  };

  getActiveControllerOptions = () => {
    return this.state.controllers.map((controller) => {
      return <SelectOption key={controller.id} id={controller.id} value={controller.name} />;
    });
  };

  getActiveServerOptions = () => {
    const { activeController } = this.state;
    if (!activeController.Servers) {
      return [];
    }
    return activeController.Servers.filter((s) => s).map((server) => {
      return <SelectOption key={server.id} id={server.id} value={server.ip4Address} />;
    });
  };

  getStandbyControllerOptions = () => {
    const { activeController, standbyControllers, failoverEnabled } = this.state;

    if (activeController && failoverEnabled) {
      return standbyControllers.map((controller) => {
        return <SelectOption key={controller.id} id={controller.id} value={controller.name} />;
      });
    }
  };

  getStandbyServerOptions = () => {
    const { standbyController, failoverEnabled } = this.state;

    if (failoverEnabled) {
      if (!standbyController.Servers) {
        return [];
      }
      return standbyController.Servers.map((server) => {
        return <SelectOption key={server.id} id={server.id} value={server.ip4Address} />;
      });
    }
  };

  getBusinessContinuityOptions = (enabledLinks) => {
    return sortBy(enabledLinks, 'interfaceName').map((link) => {
      return <SelectOption key={link.id} id={link.id} value={`eth${link.interfaceName}`} />;
    });
  };

  handleQuickLinkChange = (prop, name, value) => {
    const { quickLinks } = this.state;
    const changedLink = quickLinks.filter((quickLink) => {
      return prop + '-' + quickLink.id === name;
    });
    const index = quickLinks
      .map((quickLink) => {
        return quickLink.id;
      })
      .indexOf(changedLink[0].id);
    changedLink[0][prop] = value;
    quickLinks[index] = changedLink[0];

    this.setState(() => ({
      quickLinks: quickLinks,
    }));
  };

  handleLinkIpChange = (linkId, value) => { };

  addQuickLink = () => {
    const { quickLinks, newQuickLinkIndex } = this.state;
    const newIndex = newQuickLinkIndex + 1;
    const emptyQuickLink = {
      id: 'quick-link-' + newIndex,
      link: '',
      description: '',
      crudState: 'create',
    };
    this.setState(() => ({
      quickLinks: [emptyQuickLink, ...this.state.quickLinks],
      newQuickLinkIndex: newIndex,
    }));
  };

  deleteQuickLink = (event, id) => {
    let { quickLinks } = this.state;
    const deletedQuickLink = quickLinks.filter((quickLink) => {
      return quickLink.id === id;
    });
    const index = quickLinks
      .map((quickLink) => {
        return quickLink.id;
      })
      .indexOf(id);

    // If the quickLink was never in the database, completely remove it from state, otherwise
    // flag it as "delete"
    if (id.startsWith('quick-link')) {
      quickLinks = quickLinks.filter((quickLink) => {
        return quickLink.id !== id;
      });
    } else {
      deletedQuickLink[0].crudState = 'delete';
      quickLinks[index] = deletedQuickLink[0];
    }

    this.setState(() => ({
      quickLinks: [...quickLinks],
    }));
  };

  getClientCompanyOptions = () => {
    return this.state.clientCompanies.map((clientCompany) => {
      return <SelectOption key={clientCompany.id} id={clientCompany.id} value={clientCompany.name} />;
    });
  };

  getLicenseOptions = () => {
    const { licenseList, products } = this.state;

    return licenseList.map((license) => {
      const product = products.find(product => product.id === license.type);
      return <SelectOption key={license.id} id={license.id} value={license.id + ' - ' + product.name} />;
    });
  };

  getNetmaskOptions = () => {
    const netmaskOptions = new Array(23);
    for (let i = 31, j = 0; i > 7; i--, j++) {
      var mask = '/' + i.toString();
      netmaskOptions[j] = <SelectOption key={mask} id={mask} value={mask} />;
    }
    return netmaskOptions;
  };

  getWifiChannelOptions = () => {
    const wifiOptions = wifiChannelOptions();
    return wifiOptions.map((channel) => {
      return <SelectOption key={channel} id={channel} value={channel} />;
    });
  }

  getCardClass = (section, enabled) => {
    return enabled ? section + '-form-row card-enabled' : section + '-form-row card-disabled';
  };

  getInversedCardClass = (section, enabled) => {
    return enabled ? section + '-form-row card-disabled' : section + '-form-row card-enabled';
  };

  getRoute = (route) => {
    const { hasActiveRoutes } = this.state;
    const routerIpId = 'routerIP-' + route.id;
    const subnetId = 'subnet-' + route.id;
    const subnetIsValid = isValidSubnet(route.subnet);
    const routerIpIsValid = isValidIP(route.routerIP);
    let subnetErrorState = textInputValidate(route.subnet, hasActiveRoutes, subnetIsValid, 'Invalid Subnet');
    let routerIpErrorState = textInputValidate(route.routerIP, hasActiveRoutes, routerIpIsValid, 'Invalid IP Address');

    // if (routerIpErrorState.validated !== 'error') {
    //   let lanSubnet = lanConfig.lanSegmentIp4 + lanConfig.lanCidrIp4;
    //   const ipIsInSubnet = ipInSubnet(route.routerIP, lanSubnet);
    //   routerIpErrorState = textInputValidate(route.routerIP, hasActiveRoutes, ipIsInSubnet, 'IP not in LAN subnet');
    // }

    let deleteIcon = (
      <div>
        <TrashIcon />
      </div>
    );

    if (hasActiveRoutes) {
      deleteIcon = (
        <div onClick={(e) => this.deleteRoute(e, route.id)}>
          <TrashIcon className="pointer" />
        </div>
      );
    }

    return (
      <tr key={route.id}>
        <td width="50%">
          <FormGroup
            label=""
            isRequired={hasActiveRoutes}
            fieldId={subnetId}
            helperTextInvalid={subnetErrorState.errorText}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={subnetErrorState.validated}
          >
            <TextInput
              className="form-input"
              isDisabled={!hasActiveRoutes}
              validated={subnetErrorState.validated}
              isRequired={hasActiveRoutes}
              type="text"
              id={subnetId}
              name={subnetId}
              placeholder="192.168.10.0/24"
              value={route.subnet}
              onChange={(e) => this.handleRouteChange('subnet', subnetId, e)}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup
            label=""
            isRequired={hasActiveRoutes}
            fieldId={subnetId}
            helperTextInvalid={routerIpErrorState.errorText}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={routerIpErrorState.validated}
          >
            <TextInput
              className="form-input"
              isDisabled={!hasActiveRoutes}
              validated={routerIpErrorState.validated}
              isRequired={hasActiveRoutes}
              type="text"
              id={routerIpId}
              name={routerIpId}
              placeholder="192.168.10.2"
              value={route.routerIP}
              onChange={(e) => this.handleRouteChange('routerIP', routerIpId, e)}
            />
          </FormGroup>
        </td>
        <td width="7px;"></td>
        <td>{deleteIcon}</td>
      </tr>
    );
  };

  getRoutes = (routes) => {
    const allRoutes = routes.filter((route) => {
      return route.crudState !== 'delete';
    });

    if (allRoutes.length === 0) {
      return 'No routes defined';
    }

    const activeRoutes = allRoutes.map((route) => {
      return this.getRoute(route);
    });

    const routesTable = (
      <table>
        <tbody>
          <tr>
            <td width="50%">Public Subnet</td>
            <td width="40%">Router IP</td>
            <td></td>
          </tr>
          {activeRoutes}
        </tbody>
      </table>
    );
    return routesTable;
  };

  deleteRoute = (event, id) => {
    let { routes } = this.state;
    const deletedRoute = routes.filter((route) => {
      return route.id === id;
    });
    const index = routes
      .map((route) => {
        return route.id;
      })
      .indexOf(id);

    // If the route was never in the database, completely remove it from state, otherwise
    // flag it as "delete"
    if (id.startsWith('route')) {
      routes = routes.filter((route) => {
        return route.id !== id;
      });
    } else {
      deletedRoute[0].crudState = 'delete';
      routes[index] = deletedRoute[0];
    }

    this.setState(() => ({
      routes: routes,
    }));
  };

  addRoute = () => {
    const { routes, newRouteIndex } = this.state;
    const newIndex = newRouteIndex + 1;
    const emptyRoute = {
      id: 'route-' + newIndex,
      isActive: true,
      isInbound: true,
      metric: 0,
      routerIP: '',
      routerInterface: '',
      siteId: this.state.id,
      subnet: '',
    };
    this.setState(() => ({
      routes: [...this.state.routes, emptyRoute],
      newRouteIndex: newIndex,
    }));
  };

  getLinkTypeOptions = () => {
    const { linkTypes } = this.state;
    const linkTypeNames = new Set(linkTypes.map((linkType) => linkType.name));
    const activeLinkTypes = linkTypes.map((linkType) => {
      if (linkTypeNames.has(linkType.name)) {
        return linkType;
      }
    });
    return activeLinkTypes.map((linkType) => {
      return <SelectOption key={linkType.id} id={linkType.id} value={linkType.name} />;
    });
  };

  getCarrierOptions = () => {
    return this.state.carriers.map((carrier) => {
      return <SelectOption key={carrier.id} id={carrier.id} value={carrier.name} />;
    });
  };

  getWanOptions = () => {
    return this.state.wans.map((wan) => {
      return <SelectOption key={wan.id} id={wan.id} value={wan.name} />;
    });
  };

  getLbAlgoOptions = () => {
    const algos = getAlgos();
    return algos.map((algo) => {
      return <SelectOption key={algo.id} id={algo.id} value={algo.name} />;
    });
  };

  getLbAlgoDescription = (lbAlgo) => {
    if (lbAlgo === "HybridLink")
      return "Maximizes potential of disparate links considering speed and latency, i.e. Fiber, 5G";
    if (lbAlgo === "HybridLink-CA")
      return "Maximizes potential of disparate links accounting for packet loss";
    if (lbAlgo === "Velocity")
      return "Ideal for optimizing links with similar latencies, equal-weighted links based on similar speeds. Ideal for high network utilization.";
    if (lbAlgo === "FastPath")
      return "Prioritizes links based off speed. Optimizes bonding of links with different speeds and similar latencies, i.e. fibre + DSL";

    return "";
  }

  handleRouteChange = (prop, name, value) => {
    const { routes } = this.state;
    const changedRoute = routes.filter((route) => {
      return prop + '-' + route.id === name;
    });
    const index = routes
      .map((route) => {
        return route.id;
      })
      .indexOf(changedRoute[0].id);
    changedRoute[0][prop] = value;
    routes[index] = changedRoute[0];
    this.setState(() => ({
      routes: routes,
    }));
  };

  handleChange = (name, value) => {
    this.setState(() => ({
      [name]: value,
    }));
  };

  handleNewCompanyChange = (value) => {
    this.setState(() => ({
      newCompany: value,
      quickLinks: [],
    }));
  };

  handleTunnelChange = (name, value) => {
    const { siteTunnels } = this.state;
    siteTunnels[0][name] = value;
    if (siteTunnels[1]) {
      siteTunnels[1][name] = value;
    }
    this.setState(() => ({
      siteTunnels: siteTunnels,
    }));
  };

  handleObjectChange = (key, name, value) => {
    const obj = this.state[key];
    obj[name] = value;
    this.setState(() => ({
      [key]: obj,
    }));
  };

  handleEmailChange = (value) => {
    const { siteContact, alertErrorState } = this.state;
    siteContact.email = value;

    if (isValidEmail(siteContact.email)) {
      alertErrorState = { validated: 'default', errorText: '' };
    } else {
      alertErrorState = { validated: 'error', errorText: 'Must provide valid email for alerts' };
      siteContact.emailAlert = false;
    }

    this.setState(() => ({
      siteContact: { ...siteContact },
      alertErrorState: { ...alertErrorState },
    }));
  };

  handleEmailAlertChange = (checked, event) => {
    const { siteContact } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    siteContact.emailAlert = value;

    let alertErrorState = { validated: 'default', errorText: '' };

    if ((siteContact.email === '' || !isValidEmail(siteContact.email)) && siteContact.emailAlert) {
      siteContact.emailAlert = false;
      alertErrorState = { validated: 'error', errorText: 'Must provide valid email for alerts' };
    }

    this.setState({
      siteContact: { ...siteContact },
      alertErrorState: alertErrorState,
    });
  };

  onObjectToggle = (isOpen, isOpenStateVar) => {
    this.setState(() => ({
      [isOpenStateVar]: isOpen,
    }));
  };

  onObjectSelectClick = (event, selection, isPlaceholder, stateObj, prop, isOpenKey) => {
    const obj = this.state[stateObj];
    obj[prop] = selection;
    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState(() => ({
        [stateObj]: obj,
        [isOpenKey]: false,
      }));
    }
  };

  onCompanySelect = (event, selection, isPlaceholder, isOpenKey) => {
    const { clientCompanies, clientCompany } = this.state;
    const clientCo = clientCompanies.find((c) => c.name === selection);

    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState(() => ({
        clientCompany: { ...clientCo },
        clientCompanyId: clientCo.id,
        quickLinks: clientCo.Quicklinks,
        [isOpenKey]: false,
      }));
    }
  };

  onLicenseSelect = (event, selection, isPlaceholder, isOpenKey) => {
    const licenseItems = selection.split(' - ');
    const selectedLicenseId = licenseItems[0];
    const selectedProduct = licenseItems[1];
    const license = this.state.licenseList.find((l) => l.id === selectedLicenseId);

    if (isPlaceholder) {
      // Do wat?
    }
    else {
      this.setState(() => ({
        licenseId: license.id + ' - ' + selectedProduct,
        [isOpenKey]: false,
      }));
    }
  };

  onLinkAddressTypeSelect = (event, selection, isPlaceholder, link) => {
    this.onLinkSelectToggle(event, 'addressType', link.id);
    if (!isPlaceholder) {
    }
  };

  handleCheckboxChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleServerlessChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const tunnels = this.state.siteTunnels;

    if (tunnels[1]) {
      tunnels[1].crudState = 'delete';
    }

    this.setState(() => ({
      siteTunnels: [...tunnels],
      activeController: {},
      activeServer: {},
      standbyController: {},
      standbyServer: {},
      failoverEnabled: false,
      isServerless: value,
    }));
  };

  handleLinkCheckboxChange = (checked, event, prop, linkId) => {
    const { links } = this.state;
    const clickedCheckbox = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(clickedCheckbox.id);
    clickedCheckbox[prop] = event.target.checked;
    if (prop === 'dataLimitEnabled') {
      clickedCheckbox.dataLimit = 0;
    }
    links[index] = clickedCheckbox;

    this.setState(() => ({
      links: links,
    }));
  };

  handleLinkChange = (prop, value, linkId) => {
    const { links } = this.state;
    const changedLink = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(changedLink.id);
    changedLink[prop] = value;
    links[index] = changedLink;

    this.setState(() => ({
      links: links,
    }));
  };

  handleLinkViewChange = (event) => {
    const { linkViewLabel } = this.state;
    var linkViewClass = linkViewLabel === 'Show More' ? '' : 'link-container';
    var linkViewLabel = linkViewLabel === 'Show More' ? 'Show Less' : 'Show More';

    this.setState({
      linkViewClass: linkViewClass,
      linkViewLabel: linkViewLabel,
    });
  };

  handleEnableFailoverChange = (checked, event) => {
    const failoverEnabled = event.target.checked;
    const { siteTunnels, controllers, activeController } = this.state;
    const standbyControllers = controllers.filter((c) => {
      return c.name !== activeController.name;
    });

    siteTunnels[1] = siteTunnels[1] === undefined ? { isPrimary: false, siteId: id } : siteTunnels[1];

    if (!failoverEnabled) {
      if (siteTunnels[1].hasOwnProperty('id')) {
        siteTunnels[1].crudState = 'delete';
      } else {
        delete siteTunnels[1];
      }
    } else {
      if (siteTunnels[1]) {
        delete siteTunnels[1].crudState;
      }
    }

    this.setState(() => ({
      siteTunnels: [...siteTunnels],
      failoverEnabled: failoverEnabled,
      standbyControllers: standbyControllers,
      standbyController: {},
      standbyServer: {},
    }));
  };

  handleTunnelInverseEnabled = (key, checked) => {
    const { siteTunnels } = this.state;
    siteTunnels[0][key] = !checked;
    if (siteTunnels[1]) {
      siteTunnels[1][key] = !checked;
    }

    this.setState({
      siteTunnels: siteTunnels,
    });
  };

  handleEnabled = (key, checked) => {
    this.setState({
      [key]: checked,
    });
  };

  handleWanEnabled = (checked) => {
    this.setState({
      wanName: checked ? this.state.wanName : '',
      wanEnabled: checked
    });

    if (!checked) {
      this.setState({
        activeServer: {},
        standbyServer: {}
      });
    }
  };

  handleTunnelEnabled = (key, checked) => {
    const { siteTunnels } = this.state;
    siteTunnels[0][key] = checked;
    this.setState({
      siteTunnels: siteTunnels,
    });
  };

  handleTunnelEnabledWithDefault = (checked, defaultValue, tunnelProp, enabledProp) => {
    const { siteTunnels } = this.state;
    siteTunnels[0][tunnelProp] = checked ? siteTunnels[0][tunnelProp] : defaultValue;
    siteTunnels[0].autoMTU = !checked;
    if (siteTunnels[1]) {
      siteTunnels[1][tunnelProp] = checked ? siteTunnels[0][tunnelProp] : defaultValue;
      siteTunnels[1].autoMTU = !checked;
    }
    this.setState({
      [enabledProp]: checked,
      siteTunnels: siteTunnels,
    });
  };

  handleTunnelPortRange = (checked, enabledProp) => {
    const { siteTunnels } = this.state;
    siteTunnels[0].portRangeStart = checked ? siteTunnels[0].portRangeStart : DEFAULT_MIN_SERVER_PORT;
    siteTunnels[0].portRangeEnd = checked ? siteTunnels[0].portRangeEnd : DEFAULT_MAX_SERVER_PORT;
    this.setState({
      [enabledProp]: checked,
      siteTunnels: siteTunnels,
    });
  };

  handleReorderBufferEnabled = (checked) => {
    const { siteTunnels } = this.state;
    siteTunnels[0].shouldReorderBuffer = checked;
    siteTunnels[0].reorderBufferTime = checked ? siteTunnels[0].reorderBufferTime : 20;
    this.setState({
      siteTunnels: siteTunnels,
    });
  };

  handleObjectEnabled = (stateObj, key, checked) => {
    const obj = this.state[stateObj];
    obj[key] = checked;
    this.setState({
      [stateObj]: obj,
    });
  };

  handleLinkEnabled = (linkId, checked) => {
    const { links, businessContinuity } = this.state;
    const enabledLink = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(enabledLink.id);
    const linkInterface = 'eth' + enabledLink.interfaceName;
    enabledLink.isEnabled = checked;
    enabledLink.cardClass = this.getCardClass('links', checked);
    links[index] = enabledLink;

    const businessContinuityLinks = links.filter((link) => {
      return link.isEnabled === true && link.state !== 'delete';
    });

    this.setState({
      links: links,
      businessContinuity: businessContinuity === linkInterface ? '' : businessContinuity,
      businessContinuityLinks: businessContinuityLinks,
    });
  };

  handleLanIpChange = (lanId, value) => {
    const { lans } = this.state;
    const changedLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(changedLan.id);

    changedLan.lanSegmentIp4 = value;

    let ipParts = value.split('.');
    let firstThreeOctets = ipParts.slice(0, 3);

    if (ipParts.length >= 3 && firstThreeOctets.length >= 3) {
      firstThreeOctets = ipParts[0] + '.' + ipParts[1] + '.' + ipParts[2];

      if (changedLan.isDhcpRequired) {
        const dhcpStartParts = changedLan.dhcpRangeStart.split('.');
        const dhcpEndParts = changedLan.dhcpRangeEnd.split('.');
        const dhcpStartLastOctet = dhcpStartParts[dhcpStartParts.length - 1];
        const dhcpEndLastOctet = dhcpEndParts[dhcpEndParts.length - 1];
        changedLan.dhcpRangeStart = firstThreeOctets + '.' + dhcpStartLastOctet;
        changedLan.dhcpRangeEnd = firstThreeOctets + '.' + dhcpEndLastOctet;
      }
      if (changedLan.isDMZ) {
        const dmzParts = changedLan.ip4DMZ.split('.');
        const dmzLastOctet = dmzParts[dmzParts.length - 1];
        changedLan.ip4DMZ = firstThreeOctets + '.' + dmzLastOctet;
      }
    }

    lans[index] = { ...changedLan };

    this.setState(() => ({
      lans: [...lans]
    }));
  };

  handleLanCheckboxChange = (lanId, lanProp, checked, event) => {
    const { lans } = this.state;
    const foundLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(foundLan.id);
    foundLan[lanProp] = checked;
    lans[index] = { ...foundLan };

    if (checked) {
      for (let i = 0; i < lans.length; i++) {
        if (lans[i].id !== lanId) {
          lans[i].isDMZ = false;
          lans[i].isDMZDisabled = true;
        }
      }
    }
    else {
      for (let i = 0; i < lans.length; i++) {
        if (lans[i].id !== lanId) {
          lans[i].isDMZDisabled = false;
        }
      }
    }

    this.setState({
      lans: [...lans]
    });
  };

  handleLanEnabled = (lanId, checked, subProp) => {
    const { lans } = this.state;
    const enabledLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(enabledLan.id);
    enabledLan[subProp] = !checked;
    lans[index] = { ...enabledLan };

    this.setState({
      lans: [...lans]
    });
  };

  handleLanObjectChange = (lanId, name, value) => {
    const { lans } = this.state;
    const foundLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(foundLan.id);
    foundLan[name] = value;
    lans[index] = { ...foundLan };

    this.setState({
      lans: [...lans]
    });
  };

  onLanSelectClick = (event, selection, isPlaceholder, lanId, prop, isOpenKey) => {
    const { lans } = this.state;
    const foundLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(foundLan.id);
    foundLan[prop] = selection;
    foundLan[isOpenKey] = false;
    lans[index] = { ...foundLan };

    this.setState({
      lans: [...lans]
    });
  };

  handleWifiRadioChange = (_, event, lanId) => {
    const { lans } = this.state;
    const { value } = event.currentTarget;
    const foundLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(foundLan.id);

    foundLan.apMode = value.split('-')[0];
    lans[index] = { ...foundLan };

    this.setState(() => ({
      lans: [...lans]
    }));
  };

  onLanInterfaceSelectClick = (event, selection, isPlaceholder, lanId) => {
    const { lans, links } = this.state;
    const tempLanInterfaces = [];
    const foundLan = lans.find((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(foundLan.id);
    const interfaceName = selection.replace('eth', '');
    const lanInterfaces = lans.map((lan) => {
      if (lan.id !== lanId) {
      	lan.interfaceName.map((i) => tempLanInterfaces.push(parseInt(i.replace('eth', ''))));
      }
    });
    const linkInUse = links.find((link) => link.interfaceName === interfaceName && link?.crudState !== 'delete');
    const lanInUse = tempLanInterfaces.includes(parseInt(interfaceName));

    if (linkInUse) {
      this.setState(() => ({
        isLinkInterfaceErrorOpen: true,
        selectedInterface: selection,
      }));
      return;
    }

    if (lanInUse && foundLan?.vlanId === '') {
      this.setState(() => ({
        isLanInterfaceErrorOpen: true,
        selectedInterface: selection,
      }));
      return;
    }

    if (foundLan.interfaceName.includes(selection)) {
      foundLan.interfaceName = foundLan.interfaceName.filter(i => i !== selection);
    } 
    else {
      foundLan.interfaceName = [ ...foundLan.interfaceName, selection ];
    }
    lans[index] = { ...foundLan };

    if (isPlaceholder) {
      // Do wat?
    } else {
      this.setState(() => ({
        lans: [...lans],
        isLanInterfaceSelectOpen: false,
      }));
    }
  };

  handleNewCompanyToggle = () => {
    this.setState(({ isNewCompanyOpen }) => ({
      isNewCompanyOpen: !isNewCompanyOpen,
    }));
  };

  handleInterfaceErrorToggle = () => {
    this.setState(({ isInterfaceErrorOpen }) => ({
      isInterfaceErrorOpen: !isInterfaceErrorOpen,
    }));
  };

  handleLinkInterfaceErrorToggle = () => {
    this.setState(({ isLinkInterfaceErrorOpen }) => ({
      isLinkInterfaceErrorOpen: !isLinkInterfaceErrorOpen,
    }));
  };

  handleLanInterfaceErrorToggle = () => {
    this.setState(({ isLanInterfaceErrorOpen }) => ({
      isLanInterfaceErrorOpen: !isLanInterfaceErrorOpen,
    }));
  };

  addCompany = () => {
    this.setState(() => ({
      isNewCompanyOpen: true,
    }));
  };

  createCompany = () => {
    const clientCompany = {
      name: this.state.newCompany,
      Quicklinks: [],
    };

    ClientCompany.create(clientCompany)
      .then((clientCompany) => {
        ClientCompany.getAll()
          .then((clientCompanies) => {
            this.setState(() => ({
              clientCompany: { ...clientCompany, name: this.state.newCompany },
              clientCompanyId: clientCompany.id,
              clientCompanies: clientCompanies,
              isNewCompanyOpen: false,
            }));
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onCountryToggle = (isOpen) => {
    this.setState(() => ({
      isCountryOpen: isOpen,
    }));
  };

  onRegionToggle = (isOpen) => {
    this.setState(() => ({
      isRegionOpen: isOpen,
    }));
  };

  onCountrySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearCountry();
    } else {
      const regions = getRegionOptions(selection);
      const { siteInfo } = this.state;
      siteInfo.country = selection;
      siteInfo.state = null;
      this.setState(() => ({
        siteInfo: siteInfo,
        regions: regions,
        isCountryOpen: false,
      }));
    }
  };

  onRegionSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearRegion();
    } else {
      const { siteInfo } = this.state;
      siteInfo.state = selection;
      this.setState(() => ({
        siteInfo: siteInfo,
        //state: selection,
        isRegionOpen: false,
      }));
    }
  };

  clearCountry = () => {
    this.setState({
      country: null,
      isCountryOpen: false,
    });
  };

  clearRegion = () => {
    this.setState({
      state: null,
      isRegionOpen: false,
    });
  };

  handleEmailAlert = (value) => {
    this.setState(() => ({
      emailAlertTest: value,
    }));
  };

  onDateChange = (dateStr, dateObj, linkId) => {
    const { links } = this.state;
    const link = links.find((link) => {
      return link.id === linkId;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(link.id);
    link.dataLimitResetDate = dateStr;
    links[index] = link;

    this.setState({
      links: links,
    });
  };

  onDateClick = (event) => {
    event.preventDefault();
  };

  addLan = (isFirst) => {
    const { lans, links } = this.state;
    const tempLanInterfaces = [];
    const linkInterfaces = links.map((link) => parseInt(link.interfaceName.replace('eth', '')));
    const lanInterfaces = lans.map((lan) => {
      lan.interfaceName.map((i) => tempLanInterfaces.push(parseInt(i.replace('eth', ''))));
    });
    const interfaceNums = [...linkInterfaces, ...tempLanInterfaces];
    const dmzLans = lans.filter(lan => lan.isDMZ === true);
    const dmzCount = dmzLans ? dmzLans.length : 0;
    let nextInterface = 1;

    if (isFirst === true) {
      nextInterface = 0;
    }
    else {
      // Determine the next interface number that hasn't been used(starting with lowest)
      for (var i = 1; i <= MAX_LINKS_ALLOWED; i++) {
        if (!interfaceNums.includes(i)) {
          nextInterface = i;
          break;
        }
      }
    }

    const emptyLan = {
      id: 'lan-' + lans.length + 1,
      lanCidrIp4: '',
      lanSegmentIp4: '',
      dnsServer1: '1.1.1.1',
      dnsServer2: '8.8.8.8',
      isDMZ: false,
      ip4DMZ: '',
      isAP: false,
      apMode: 'mode_24',
      apSSID: '',
      apPassword: '',
      interfaceName: [ 'eth' + nextInterface.toString() ],
      apChannel: 0,
      ap5SSID: '',
      ap5Password: '',
      ap5Channel: 0,
      isDhcpRequired: false,
      dhcpRangeStart: '',
      dhcpRangeEnd: '',
      dhcpDefaultLease: '3600',
      dhcpMaxLease: '7200',
      vlanId: '',
      isDMZDisabled: dmzCount >= 1 ? true : false
    }

    this.setState(() => ({
      lans: [emptyLan, ...this.state.lans]
    }));
  }

  deleteLan = (event, lanId) => {
    let { lans } = this.state;
    const deletedLan = lans.filter((lan) => { return lan.id === lanId });
    const index = lans.map((lan) => { return lan.id }).indexOf(lanId);

    if (lans.length === 1) {
      return;
    }

    // If the LAN was never in the database, remove it from state, otherwise
    // flag it as "delete"
    if (lanId.startsWith('lan')) {
      lans = lans.filter((lan) => { return lan.id !== lanId });
    }
    else {
      deletedLan[0].crudState = 'delete';
      lans[index] = deletedLan[0];
    }

    this.setState(() => ({
      lans: [...lans]
    }));
  };

  addLink = () => {
    const { links, siteTunnels, lans } = this.state;
    const tempLanInterfaces = [];
    const linkInterfaces = links.map((link) => parseInt(link.interfaceName.replace('eth', '')));
    const lanInterfaces = lans.map((lan) => {
      lan.interfaceName.map((i) => tempLanInterfaces.push(parseInt(i.replace('eth', ''))));
    });
    const interfaceNums = [...linkInterfaces, ...tempLanInterfaces];
    let linkPort = siteTunnels[0].portRangeStart;
    let nextInterface = 1;

    // Determine the next interface number that hasn't been used(starting with lowest)
    for (var i = 1; i <= MAX_LINKS_ALLOWED; i++) {
      if (!interfaceNums.includes(i)) {
        nextInterface = i;
        break;
      }
    }

    const emptyLink = {
      id: 'link-' + links.length + 1,
      cardClass: this.getCardClass('links', true),
      typeSelectOpen: false,
      interfaceNameSelectOpen: false,
      carrierNameSelectOpen: false,
      carrierName: '',
      cpeBindIp: '',
      dataLimit: 0,
      dataLimitEnabled: false,
      dataLimitUsed: 0,
      dataLimitReset: '',
      dataLimitResetDate: '',
      dataLimitResetTime: '',
      downloadThroughput: '10',
      downloadThroughputCap: 0,
      interfaceName: nextInterface.toString(),
      isCapEnabled: false,
      isEnabled: true,
      isEncrypted: false,
      isMetered: false,
      isPassthrough: false,
      isStandby: false,
      isDHCP: true,
      staticIp: '',
      isWWAN: false,
      isPPPoE: false,
      pppoeUsername: '',
      pppoePassword: '',
      modemAPN: '',
      modemIndex: 0,
      name: '',
      notes: '',
      serverBindIp: '',
      state: '',
      type: '',
      uploadThroughput: '10',
      uploadThroughputCap: 0,
      serverPort: linkPort,
      dnsAddresses: [],
    };

    this.setState(() => ({
      sortLinks: false,
      links: [...this.state.links, emptyLink],
      businessContinuityLinks: [...this.state.businessContinuityLinks, emptyLink],
    }));
  };

  deleteLink = (event, id) => {
    let { links, businessContinuity } = this.state;
    const deletedLink = links.filter((link) => {
      return link.id === id;
    });
    const index = links
      .map((link) => {
        return link.id;
      })
      .indexOf(id);
    const deletedInterface = 'eth' + deletedLink[0].interfaceName;

    // If the link was never in the database, completely remove it from state, otherwise
    // flag it as "delete"
    if (id.startsWith('link')) {
      links = links.filter((link) => {
        return link.id !== id;
      });
    } else {
      deletedLink[0].crudState = 'delete';
      links[index] = deletedLink[0];
    }

    const businessContinuityLinks = links.filter((link) => {
      return link.isEnabled === true && link.crudState !== 'delete';
    });

    this.setState(() => ({
      links: links,
      sortLinks: false,
      businessContinuity: businessContinuity === deletedInterface ? '' : businessContinuity,
      businessContinuityLinks: businessContinuityLinks,
    }));
  };

  getLink = (link, index) => {
    const { linkViewClass } = this.state;
    const activeLinkTypes = this.getLinkTypeOptions();
    const activeCarriers = this.getCarrierOptions();
    const linkInterfaces = getLinkInterfaceOptions();
    const downloadIsValid = isValidNumber(link.downloadThroughput);
    const uploadIsValid = isValidNumber(link.uploadThroughput);
    const dataLimitIsValid = link.dataLimitEnabled
      ? isValidNumber(link.dataLimit) && link.dataLimit != '0' && parseFloat(link.dataLimit) > 0
      : true;
    const downloadErrorState = textInputValidate(
      link.downloadThroughput,
      link.isEnabled,
      downloadIsValid,
      'Numeric only'
    );
    const uploadErrorState = textInputValidate(link.uploadThroughput, link.isEnabled, uploadIsValid, 'Numeric only');
    const dataLimitErrorState = link.dataLimitEnabled
      ? textInputValidate(link.dataLimit, link.dataLimitEnabled, dataLimitIsValid, 'Must be a positive number')
      : { validated: 'default', errorText: '' };
    const notesErrorState = isLengthWithinRange(link.notes, 1, 255)
      ? { validated: 'default', errorText: '' }
      : { validated: 'error', errorText: 'Max 255 characters allowed' };

    const linkIp4IsValid = link.linkNetmask
      ? isUsableAddress(link.tunnelLinkIp4, link.linkNetmask)
      : isValidIP(link.tunnelLinkIp4);
    const linkIp4ErrorState = textInputValidate(link.tunnelLinkIp4, true, linkIp4IsValid, 'Invalid IP Address');

    let linkGatewayIpIsValid = isValidIpNotRequired(link.staticGatewayIp);
    let linkGatewayIpErrorState = { validated: 'default' };

    // Ensure that Gateway IP is required and valid if address type is Static
    if (link.addressType === 'Static') {
      linkGatewayIpIsValid = isValidIP(link.staticGatewayIp);

      if (!link.staticGatewayIp || link.staticGatewayIp === "") {
        linkGatewayIpErrorState = { validated: 'error', errorText: 'Required field' };
      }
      else if (!linkGatewayIpIsValid) {
        linkGatewayIpErrorState = { validated: 'error', errorText: 'Invalid IP Address' };
      }
    }
    else {
      linkGatewayIpErrorState = linkGatewayIpIsValid ? { validated: 'default' } : { validated: 'error', errorText: 'Invalid IP Address' }
    }

    const linkDnsPrimaryIsValid = isValidIpNotRequired(link.dnsAddresses?.[0]);
    const linkDnsPrimaryErrorState = linkDnsPrimaryIsValid
      ? { validated: 'default' }
      : { validated: 'error', errorText: 'Invalid IP Address' };

    const linkDnsSecondaryIsValid = isValidIpNotRequired(link.dnsAddresses?.[1]);
    const linkDnsSecondaryErrorState = linkDnsSecondaryIsValid
      ? { validated: 'default' }
      : { validated: 'error', errorText: 'Invalid IP Address' };

    const modemIndexIsValid = isValidNumber(link.modemIndex);
    const modemIndexErrorState = link.modemIndex === '' 
      ? { validated: 'error', errorText: 'Required field' } 
      : modemIndexIsValid ? { validated: 'default' } : { validated: 'error', errorText: 'Must be a number' };
    return (
      <FlexItem className="flex-item-quarter" key={index}>
        <Card isCompact className={link.cardClass}>
          <CardBody>
            <IconHeading
              icon={<NetworkIcon className="icon-medium" />}
              heading={'eth' + link.interfaceName}
              showEnable={true}
              handleEnable={(e) => this.handleLinkEnabled(link.id, e)}
              enabled={link.isEnabled}
            />

            <div className={linkViewClass}>
              <div className="right">
                <Checkbox
                  label="Standby"
                  aria-label="Standby"
                  name={`standby-${link.id}`}
                  id={`standby-${link.id}`}
                  key={`standby-${link.id}`}
                  isDisabled={!link.isEnabled}
                  onChange={(checked, event) => this.handleLinkCheckboxChange(checked, event, 'isStandby', link.id)}
                  isChecked={link.isStandby}
                />
              </div>
              <FormGroup
                label="Interface"
                isRequired={link.isEnabled}
                fieldId="link-type-toggle"
                helperTextInvalid=" Required field"
                helperTextInvalidIcon={<ExclamationCircleIcon />}
                validated={link.isEnabled ? textInputRequiredOnly(link.interfaceName) : 'default'}
              >
                <Select
                  maxHeight={300}
                  isDisabled={!link.isEnabled}
                  variant={SelectVariant.single}
                  placeholderText="Select ..."
                  aria-label="Select Interface"
                  validated={link.isEnabled ? textInputRequiredOnly(link.interfaceName) : 'default'}
                  onToggle={(e) => this.onLinkSelectToggle(e, 'interfaceName', link.id)}
                  onSelect={(event, selection, isPlaceholder) =>
                    this.onLinkInterfaceSelectClick(
                      event,
                      selection,
                      isPlaceholder,
                      link.id,
                      'eth' + link.interfaceName
                    )
                  }
                  selections={'eth' + link.interfaceName}
                  isOpen={link.interfaceNameSelectOpen}
                >
                  {linkInterfaces}
                </Select>
              </FormGroup>
              <FormGroupSpacer>
                <FormGroup
                  label="Address Type"
                  isRequired={link.isEnabled}
                  fieldId="link-address-type-toggle"
                  helperTextInvalid=" Required field"
                  helperTextInvalidIcon={<ExclamationCircleIcon />}
                  validated={link.isEnabled ? textInputRequiredOnly(link.interfaceName) : 'default'}
                >
                  <Select
                    maxHeight={300}
                    isDisabled={!link.isEnabled}
                    variant={SelectVariant.single}
                    placeholderText="Select ..."
                    aria-label="Select Address Type"
                    validated={link.isEnabled ? 'default' : 'default'}
                    onToggle={(e) => this.onLinkSelectToggle(e, 'addressType', link.id)}
                    onSelect={(event, selection, isPlaceholder) => {
                      const { links } = this.state;
                      const index = links
                        .map((link) => {
                          return link.id;
                        })
                        .indexOf(link.id);

                      link.isDHCP = selection === 'Dynamic';
                      link.addressType = selection;
                      links[index] = link;

                      this.setState({
                        links: links,
                      });

                      this.onLinkSelectToggle(false, 'addressType', link.id);
                    }}
                    selections={link.isDHCP ? 'Dynamic' : 'Static'}
                    isOpen={link.addressTypeSelectOpen}
                  >
                    <SelectOption key={'Dynamic'} id={'Dynamic'} value={'Dynamic'} />
                    <SelectOption key={'Static'} id={'Static'} value={'Static'} />
                  </Select>
                </FormGroup>
              </FormGroupSpacer>
              {link.isDHCP === false && (
                <React.Fragment>
                  <FormGroupSpacer>
                    <FormGroup
                      label="IP Address"
                      isRequired
                      fieldId="tunnelLinkIp4"
                      helperTextInvalid={linkIp4ErrorState.errorText}
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={linkIp4ErrorState.validated}
                    >
                      <TextInput
                        className="form-input"
                        validated={linkIp4ErrorState.validated}
                        isRequired
                        type="text"
                        id="tunnelLinkIp4"
                        name="tunnelLinkIp4"
                        placeholder="192.168.0.1"
                        value={link.tunnelLinkIp4}
                        onChange={(value) => {
                          const { links } = this.state;
                          const index = links
                            .map((link) => {
                              return link.id;
                            })
                            .indexOf(link.id);

                          links[index] = {
                            ...link,
                            tunnelLinkIp4: value,
                          };

                          this.setState({
                            links: links,
                          });
                        }}
                        autoComplete="new-password"
                      />
                    </FormGroup>
                  </FormGroupSpacer>

                  <FormGroupSpacer>
                    <FormGroup
                      label="Netmask"
                      isRequired
                      fieldId="netmask-toggle"
                      helperTextInvalid=" Required field"
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={textInputRequiredOnly(link.linkNetmask)}
                    >
                      <Select
                        maxHeight={300}
                        variant={SelectVariant.single}
                        validated={textInputRequiredOnly(link.linkNetmask)}
                        placeholderText="Select ..."
                        aria-label="Select Netmask"
                        onToggle={(e) => {
                          this.onLinkSelectToggle(e, 'netmask', link.id);
                        }}
                        onSelect={(event, selection, isPlaceholder) => {
                          const { links } = this.state;
                          const index = links
                            .map((link) => {
                              return link.id;
                            })
                            .indexOf(link.id);

                          link.linkNetmask = selection;

                          links[index] = link;

                          this.setState({
                            links: links,
                          });

                          this.onLinkSelectToggle(false, 'netmask', link.id);
                        }}
                        selections={link.linkNetmask}
                        isOpen={link.netmaskSelectOpen}
                        isDisabled={false}
                      >
                        {this.getNetmaskOptions()}
                      </Select>
                    </FormGroup>
                  </FormGroupSpacer>

                  <FormGroupSpacer>
                    <FormGroup
                      label="Gateway"
                      fieldId="gatewayAddress"
                      isRequired={link.addressType === "Static"}
                      helperTextInvalid={linkGatewayIpErrorState.errorText}
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={linkGatewayIpErrorState.validated}
                    >
                      <TextInput
                        className="form-input"
                        validated={linkGatewayIpErrorState.validated}
                        isRequired={link.addressType === "Static"}
                        type="text"
                        id="tunnelLinkIp4"
                        name="tunnelLinkIp4"
                        placeholder="192.168.0.1"
                        value={link.staticGatewayIp}
                        onChange={(value) => {
                          const { links } = this.state;
                          const index = links
                            .map((link) => {
                              return link.id;
                            })
                            .indexOf(link.id);

                          links[index] = {
                            ...link,
                            staticGatewayIp: value,
                          };

                          this.setState({
                            links: links,
                          });
                        }}
                        autoComplete="new-password"
                      />
                    </FormGroup>
                  </FormGroupSpacer>

                  <FormGroupSpacer>
                    <FormGroup
                      label="DNS - Primary"
                      fieldId="dnsPrimary"
                      helperTextInvalid={linkDnsPrimaryErrorState.errorText}
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={linkDnsPrimaryErrorState.validated}
                    >
                      <TextInput
                        className="form-input"
                        validated={linkDnsPrimaryErrorState.validated}
                        type="text"
                        id="tunnelDnsPrimary"
                        name="tunnelDnsPrimary"
                        placeholder="192.168.0.1"
                        value={link.dnsAddresses?.[0]}
                        onChange={(value) => {
                          const { links } = this.state;
                          const index = links
                            .map((link) => {
                              return link.id;
                            })
                            .indexOf(link.id);

                          links[index].dnsAddresses[0] = value;

                          this.setState({
                            links: links,
                          });
                        }}
                        autoComplete="new-password"
                      />
                    </FormGroup>
                  </FormGroupSpacer>

                  <FormGroupSpacer>
                    <FormGroup
                      label="DNS - Secondary"
                      fieldId="dnsSecondary"
                      helperTextInvalid={linkDnsSecondaryErrorState.errorText}
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={linkDnsSecondaryErrorState.validated}
                    >
                      <TextInput
                        className="form-input"
                        validated={linkDnsSecondaryErrorState.validated}
                        type="text"
                        id="tunnelDnsSecondary"
                        name="tunnelDnsSecondary"
                        placeholder="192.168.0.1"
                        value={link.dnsAddresses?.[1]}
                        onChange={(value) => {
                          const { links } = this.state;
                          const index = links
                            .map((link) => {
                              return link.id;
                            })
                            .indexOf(link.id);

                          links[index].dnsAddresses[1] = value;

                          this.setState({
                            links: links,
                          });
                        }}
                        autoComplete="new-password"
                      />
                    </FormGroup>
                  </FormGroupSpacer>
                </React.Fragment>
              )}
              <FormGroupSpacer>
                <FormGroup
                  label="Link Type"
                  isRequired={link.isEnabled}
                  fieldId="link-type-toggle"
                  helperTextInvalid=" Required field"
                  helperTextInvalidIcon={<ExclamationCircleIcon />}
                  validated={link.isEnabled ? textInputRequiredOnly(link.type) : 'default'}
                >
                  <Select
                    maxHeight={300}
                    isDisabled={!link.isEnabled}
                    variant={SelectVariant.single}
                    placeholderText="Select ..."
                    aria-label="Select Link Type"
                    validated={link.isEnabled ? textInputRequiredOnly(link.type) : 'default'}
                    onToggle={(e) => this.onLinkSelectToggle(e, 'type', link.id)}
                    onSelect={(event, selection, isPlaceholder) =>
                      this.onLinkSelectClick(event, selection, isPlaceholder, 'type', link.id)
                    }
                    selections={link.type}
                    isOpen={link.typeSelectOpen}
                  >
                    {activeLinkTypes}
                  </Select>
                </FormGroup>
              </FormGroupSpacer>
              <FormGroupSpacer>
                <FormGroup
                  label="Carrier"
                  isRequired={link.isEnabled}
                  fieldId="carrier-toggle"
                  helperTextInvalid=" Required field"
                  helperTextInvalidIcon={<ExclamationCircleIcon />}
                  validated={link.isEnabled ? textInputRequiredOnly(link.carrierName) : 'default'}
                >
                  <Select
                    maxHeight={300}
                    isDisabled={!link.isEnabled}
                    variant={SelectVariant.single}
                    placeholderText="Select ..."
                    aria-label="Select Carrier"
                    validated={link.isEnabled ? textInputRequiredOnly(link.carrierName) : 'default'}
                    onToggle={(e) => this.onLinkSelectToggle(e, 'carrierName', link.id)}
                    onSelect={(event, selection, isPlaceholder) =>
                      this.onLinkSelectClick(event, selection, isPlaceholder, 'carrierName', link.id)
                    }
                    selections={link.carrierName}
                    isOpen={link.carrierNameSelectOpen}
                  >
                    {activeCarriers}
                  </Select>
                </FormGroup>
              </FormGroupSpacer>
              <Spacer />
              <table>
                <tbody>
                  <tr key="throughput-1">
                    <td className="link-throughput">Expected Throughput</td>
                    <td className="link-throughput right">
                      <Checkbox
                        label="Cap"
                        aria-label="Cap"
                        name={`cap-${link.id}`}
                        id={`cap-${link.id}`}
                        key={`cap-${link.id}`}
                        isDisabled={!link.isEnabled}
                        onChange={(checked, event) =>
                          this.handleLinkCheckboxChange(checked, event, 'isCapEnabled', link.id)
                        }
                        isChecked={link.isCapEnabled}
                      />
                    </td>
                  </tr>
                  <tr key="throughput-2">
                    <td className="link-throughput">
                      <ArrowCircleDownIcon className="icon-small" />
                      &nbsp;&nbsp;
                    </td>
                    <td className="link-throughput" width="70px">
                      Download
                    </td>
                    <td className="link-throughput">
                      <FormGroup
                        label=""
                        isRequired={link.isEnabled}
                        fieldId={`download-${link.id}`}
                        helperTextInvalid={downloadErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={link.isEnabled ? downloadErrorState.validated : 'default'}
                      >
                        <TextInput
                          className="link-throughput-input"
                          validated={link.isEnabled ? downloadErrorState.validated : 'default'}
                          id={`download-${link.id}`}
                          name={`download-${link.id}`}
                          autoComplete="new-password"
                          isDisabled={!link.isEnabled}
                          value={link.downloadThroughput}
                          onChange={(e) => this.handleLinkChange('downloadThroughput', e, link.id)}
                          aria-label="Download Throughput"
                        />
                      </FormGroup>
                    </td>
                    <td className="link-throughput">&nbsp;Mbps</td>
                  </tr>
                  <tr className="link-throughput" key="throughput-3">
                    <td className="link-throughput">
                      <ArrowCircleUpIcon className="icon-small" />
                      &nbsp;&nbsp;
                    </td>
                    <td className="link-throughput" width="70px">
                      Upload
                    </td>
                    <td className="link-throughput">
                      <FormGroup
                        label=""
                        isRequired={link.isEnabled}
                        fieldId={`upload-${link.id}`}
                        helperTextInvalid={uploadErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={link.isEnabled ? uploadErrorState.validated : 'default'}
                      >
                        <TextInput
                          className="link-throughput-input"
                          validated={link.isEnabled ? uploadErrorState.validated : 'default'}
                          id={`upload-${link.id}`}
                          name={`upload-${link.id}`}
                          autoComplete="new-password"
                          isDisabled={!link.isEnabled}
                          value={link.uploadThroughput}
                          onChange={(e) => this.handleLinkChange('uploadThroughput', e, link.id)}
                          aria-label="Upload Throughput"
                        />
                      </FormGroup>
                    </td>
                    <td className="link-throughput">&nbsp;Mbps</td>
                  </tr>
                </tbody>
              </table>
              <Spacer />
              <Checkbox
                label="PPPoE"
                aria-label="PPPoE"
                name={`pppoe-${link.id}`}
                id={`pppoe-${link.id}`}
                key={`pppoe-${link.id}`}
                isDisabled={!link.isEnabled}
                onChange={(checked, event) => this.handleLinkCheckboxChange(checked, event, 'isPPPoE', link.id)}
                isChecked={link.isPPPoE}
              />
              {link.isPPPoE ? (
                <div>
                  PPPoE Username: <br />
                  <FormGroup
                    label=""
                    isRequired={link.isEnabled}
                    fieldId={`pppoe-user-${link.id}`}
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={link.isPPPoE ? textInputRequiredOnly(link.pppoeUsername) : 'default'}
                  >
                    <TextInput
                      validated={link.isEnabled ? textInputRequiredOnly(link.pppoeUsername) : 'default'}
                      id={`pppoe-user-${link.id}`}
                      name={`pppoe-user-${link.id}`}
                      autoComplete="new-password"
                      isDisabled={!link.isEnabled}
                      value={link.pppoeUsername || ''}
                      onChange={(e) => this.handleLinkChange('pppoeUsername', e, link.id)}
                      aria-label="PPPoE Username"
                    />
                  </FormGroup>
                  PPPoE Password: <br />
                  <FormGroup
                    label=""
                    isRequired={link.isEnabled}
                    fieldId={`pppoe-pass-${link.id}`}
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={link.isPPPoE ? textInputRequiredOnly(link.pppoePassword) : 'default'}
                  >
                    <TextInput
                      type="password"
                      validated={link.isPPPoE ? textInputRequiredOnly(link.pppoePassword) : 'default'}
                      id={`pppoe-pass-${link.id}`}
                      name={`pppoe-pass-${link.id}`}
                      autoComplete="new-password"
                      isDisabled={!link.isEnabled}
                      value={link.pppoePassword || ''}
                      onChange={(e) => this.handleLinkChange('pppoePassword', e, link.id)}
                      aria-label="PPPoE Password"
                    />
                  </FormGroup>
                  <Spacer />
                </div>
              ) : (
                <div></div>
              )}
              <Checkbox
                label="Embedded"
                aria-label="Embedded"
                name={`embedded-${link.id}`}
                id={`embedded-${link.id}`}
                key={`embedded-${link.id}`}
                isDisabled={!link.isEnabled}
                onChange={(checked, event) => this.handleLinkCheckboxChange(checked, event, 'isWWAN', link.id)}
                isChecked={link.isWWAN}
              />
              {link.isWWAN ? (
                <div>
                  APN: <br />
                  <FormGroup
                    label=""
                    isRequired={link.isEnabled}
                    fieldId={`embedded-${link.id}`}
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={link.isWWAN ? textInputRequiredOnly(link.modemAPN) : 'default'}
                  >
                    <TextInput
                      validated={link.isWWAN ? textInputRequiredOnly(link.modemAPN) : 'default'}
                      id={`embedded-${link.id}`}
                      name={`embedded-${link.id}`}
                      autoComplete="new-password"
                      isDisabled={!link.isEnabled}
                      value={link.modemAPN || ''}
                      onChange={(e) => this.handleLinkChange('modemAPN', e, link.id)}
                      aria-label="Embedded"
                    />
                  </FormGroup>

                  Modem Index: <br />
                  <FormGroup
                    label=""
                    isRequired={link.isEnabled}
                    fieldId={`modem-index-${link.id}`}
                    helperTextInvalid={modemIndexErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={link.isWWAN ? modemIndexErrorState.validated : 'default'}
                  >
                    <TextInput
                      validated={link.isWWAN ? modemIndexErrorState.validated : 'default'}
                      id={`modem-index-${link.id}`}
                      name={`modem-index-${link.id}`}
                      autoComplete="new-password"
                      isDisabled={!link.isEnabled}
                      value={link.modemIndex}
                      onChange={(e) => this.handleLinkChange('modemIndex', e, link.id)}
                      aria-label="Modem Index"
                    />
                  </FormGroup>
                  <Spacer />
                </div>
              ) : (
                <div></div>
              )}
              <Checkbox
                label="Set Data Limit"
                aria-label="Set Data Limit"
                name={`data-limit-${link.id}`}
                id={`data-limit-${link.id}`}
                key={`data-limit-${link.id}`}
                isDisabled={!link.isEnabled}
                onChange={(checked, event) =>
                  this.handleLinkCheckboxChange(checked, event, 'dataLimitEnabled', link.id)
                }
                isChecked={link.dataLimitEnabled}
              />
              {link.dataLimitEnabled ? (
                <div>
                  Data Limit (GB)
                  <br />
                  <FormGroup
                    label=""
                    isRequired={link.dataLimitEnabled}
                    fieldId={`data-limit-${link.id}`}
                    helperTextInvalid={dataLimitErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={link.dataLimitEnabled ? dataLimitErrorState.validated : 'default'}
                  >
                    <TextInput
                      validated={link.dataLimitEnabled ? dataLimitErrorState.validated : 'default'}
                      id={`data-limit-${link.id}`}
                      name={`data-limit-${link.id}`}
                      autoComplete="new-password"
                      isDisabled={!link.isEnabled}
                      value={link.dataLimit || ''}
                      onChange={(e) => this.handleLinkChange('dataLimit', e, link.id)}
                      aria-label="Data Limit"
                    />
                  </FormGroup>
                  Next Reset Date:
                  <br />
                  {link.isEnabled ? (
                    <div>
                      <DatePicker
                        id={`link-date-${link.id}`}
                        isDisabled={!link.isEnabled}
                        className="link-date"
                        value={link.dataLimitResetDate || ''}
                        onChange={(str, date) => this.onDateChange(str, date, link.id)}
                        onClick={(e) => this.onDateClick(e, link.id)}
                      />
                      <FormGroup
                        label=""
                        fieldId={`data-limit-${link.id}`}
                        helperTextInvalid=" Required field"
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={link.dataLimitResetDate ? textInputRequiredOnly(link.dataLimitResetTime) : 'default'}
                      >
                        <TimePicker
                          id={`link-time-${link.id}`}
                          isEnabled={link.isEnabled}
                          selection={link.dataLimitResetTime || ''}
                          validated={
                            link.dataLimitResetDate ? textInputRequiredOnly(link.dataLimitResetTime) : 'default'
                          }
                          onToggle={(e) => this.onLinkSelectToggle(e, 'dataLimitResetTime', link.id)}
                          onSelect={(event, selection, isPlaceholder) =>
                            this.onLinkSelectClick(event, selection, isPlaceholder, 'dataLimitResetTime', link.id)
                          }
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <DatePicker
                        className="link-date"
                        value={link.dataLimitResetDate || ''}
                        isDisabled={!link.isEnabled}
                      />
                      <TimePicker
                        className="link-date"
                        value={link.dataLimitResetTime || ''}
                        isDisabled={!link.isEnabled}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
              <Spacer />
              Notes:
              <br />
              <FormGroup
                label=""
                fieldId={`link-notes-${link.id}`}
                helperTextInvalid={notesErrorState.errorText}
                helperTextInvalidIcon={<ExclamationCircleIcon />}
                validated={link.notes !== '' ? notesErrorState.validated : 'default'}
              >
                <TextArea
                  rows="3"
                  id={`link-notes-${link.id}`}
                  name={`link-notes-${link.id}`}
                  autoComplete="new-password"
                  isDisabled={!link.isEnabled}
                  validated={link.notes !== '' ? notesErrorState.validated : 'default'}
                  value={link.notes || ''}
                  onChange={(e) => this.handleLinkChange('notes', e, link.id)}
                  aria-label="Link Notes"
                />
              </FormGroup>
            </div>

            <hr className="link-footer" />
            <div align="center" onClick={(e) => this.deleteLink(e, link.id)}>
              <TrashIcon className="pointer" />
            </div>
          </CardBody>
        </Card>
      </FlexItem>
    );
  };

  getLinks = () => {
    const numLinksPerRow = 4;
    const { links, sortLinks } = this.state;
    var flexLinks = [];

    let flexItems = links.filter((link) => {
      return link.crudState === undefined || link.crudState !== 'delete';
    });

    if (sortLinks) {
      flexItems = sortBy(links, 'interfaceName').filter((link) => {
        return link.crudState === undefined || link.crudState !== 'delete';
      });
    }

    flexItems = flexItems.map((link, index) => {
      return this.getLink(link, index);
    });

    for (var i = 0; i < MAX_LINKS_ALLOWED; i = i + numLinksPerRow) {
      var rowItems = flexItems.slice(i, i + numLinksPerRow);
      if (rowItems.length > 0) {
        flexLinks.push(<Flex key={`link-${i}`}>{...rowItems}</Flex>);
      }
    }

    return flexLinks;
  };

  handleCancel = () => {
    if (this.state.id) {
      this.props.history.push('/site/' + this.state.id);
    }
    else {
      this.props.history.push('/sites');
    }
  };

  dataLimitResetFromLink = (link) => {
    const resetDate = link.dataLimitResetDate.toString();

    if (resetDate === '') {
      return '';
    }
    else {
      const timeParts = link.dataLimitResetTime.split(' ');
      const ampm = timeParts[1];
      const hour = timeParts[0].split(':')[0];
      const minutes = timeParts[0].split(':')[1];
      hour = ampm.trim() === 'pm' ? parseInt(hour) + 12 : hour;
      const resetTime = hour.toString() + ':' + minutes + ':00';
      const dataLimitReset = resetDate + 'T' + resetTime + '.000000+00:00';
      return dataLimitReset;
    }
  };

  getSitePayloadFromState = async () => {
    const {
      activeController,
      activeServer,
      businessContinuity,
      clientCompanyId,
      failoverEnabled,
      id: siteId,
      isServerless,
      lbAlgo,
      flowlet,
      links,
      mode,
      routes,
      lans,
      serverlessController,
      siteContact,
      siteInfo,
      siteTunnels,
      standbyController,
      standbyServer,
      wanName,
      wans,
    } = this.state;
    let payload = {};

    const tunnelLinks = {
      data: links.map((link) => {
        const dataLimitReset = this.dataLimitResetFromLink(link);
        const linkData = {
          id: link.id,
          tunnelId: siteTunnels[0].id,
          crudState: link.crudState,
          carrierName: link.carrierName,
          cpeBindIp: link.cpeBindIp,
          dataLimit: link.dataLimit === '' ? 0 : (link.dataLimit * 1000 * 1000).toFixed(2), // GB to KB
          dataLimitUsed: link.dataLimitUsed,
          downloadThroughput: link.downloadThroughput,
          downloadThroughputCap: link.downloadThroughputCap,
          interfaceName: link.interfaceName.toString(),
          isCapEnabled: link.isCapEnabled,
          isEnabled: link.isEnabled,
          isEncrypted: link.isEncrypted,
          isMetered: link.isMetered,
          isStandby: link.isStandby,
          isPassthrough: false,
          isDHCP: link.isDHCP,
          staticIp: link.isDHCP ? '' : `${link.tunnelLinkIp4}${link.linkNetmask}`,
          staticDnsIp: link.isDHCP ? '' : link.dnsAddresses?.join(','),
          staticGatewayIp: link.isDHCP ? '' : link.staticGatewayIp,
          isWWAN: link.isWWAN,
          isPPPoE: link.isPPPoE,
          name: link.name,
          notes: link.notes,
          serverBindIp: link.serverBindIp,
          type: link.type,
          uploadThroughput: link.uploadThroughput,
          uploadThroughputCap: link.uploadThroughputCap,
          serverPort: link.serverPort,
        };

        if (dataLimitReset !== '') {
          linkData.dataLimitReset = dataLimitReset;
        }
        if (link.isWWAN && link.modemAPN !== '') {
          linkData.modemAPN = `${link.modemIndex}:${link.modemAPN}`;
        }
        else {
          linkData.modemAPN = '';
        }
        if (link.isPPPoE && link.pppoeUsername?.length) {
          linkData.pppoeUsername = link.pppoeUsername.trim();
        }
        if (link.isPPPoE && link.pppoePassword?.length) {
          linkData.pppoePassword = link.pppoePassword.trim();
        }
        if ('eth' + link.interfaceName === businessContinuity) {
          linkData.isPassthrough = true;
        }

        return linkData;
      }),
    };

    // Add Links to primary Tunnel[0]
    const algo = getLbAlgoIdFromName(lbAlgo);
    const flowletVal = lbAlgo === "FastPath" ? false : flowlet;
    siteTunnels[0].Links = tunnelLinks;
    siteTunnels[0].siteId = siteId;
    siteTunnels[0].mtu = siteTunnels[0].autoMTU ? '1452' : siteTunnels[0].mtu;
    siteTunnels[0].lbAlgo = algo;
    siteTunnels[0].flowlet = flowletVal;

    if (siteTunnels[1]) {
      siteTunnels[1].mtu = siteTunnels[0].autoMTU ? '1452' : siteTunnels[0].mtu;
      siteTunnels[1].lbAlgo = algo;
      siteTunnels[1].flowlet = flowletVal;
    }

    payload.ipAccessLevel = 'All';
    payload.accessibleIps = '';

    // If a WAN is configured, apply WAN config to Site config
    if (wanName !== '') {
      let siteWan = wans.find((w) => w.name === wanName);
      payload.wanId = siteWan.id;
      siteTunnels[0].serverId = siteWan.activeServerId;
      siteTunnels[0].isClearTextData = !siteWan.isEncrypted;

      if (siteWan.standbyServerId) {
        if (siteTunnels[1] === undefined) {
          siteTunnels[1] = { ...siteTunnels[0] };
          delete siteTunnels[1].id;
        }
        siteTunnels[1].siteId = siteId;
        siteTunnels[1].serverId = siteWan.standbyServerId;
        siteTunnels[1].Links = tunnelLinks;
        siteTunnels[1].isClearTextData = !siteWan.isEncrypted;
      }
      else {
        if (mode === 'new') {
          siteTunnels = [siteTunnels[0]];
        }
        else if (siteTunnels[1] !== undefined) {
          siteTunnels[1].crudState = 'delete';
        }
      }
    }
    else {
      payload.wanId = null;

      if (isServerless) {
        siteTunnels[0].serverId = serverlessController.Servers[0].id;
      }
      else {
        const selectedActiveServer = activeController.Servers.find((s) => s.ip4Address === activeServer.ip4Address);
        siteTunnels[0].serverId = selectedActiveServer.id;
      }

      if (failoverEnabled) {
        const selectedStandbyServer = standbyController.Servers.find((s) => s.ip4Address === standbyServer.ip4Address);
        siteTunnels[1].siteId = siteId;
        siteTunnels[1].serverId = selectedStandbyServer.id;
        siteTunnels[1].Links = tunnelLinks;
      }
      else {
        // We only want to pass one Tunnel in payload on initial create, when failover is not enabled
        if (mode === 'new') {
          siteTunnels = [siteTunnels[0]];
        }
      }
    }

    const siteLans = lans.map((lan) => {
      const siteLan = {
        id: lan.id,
        siteId: siteId,
        crudState: lan.crudState,
        dnsServer1: lan.dnsServer1,
        dnsServer2: lan.dnsServer2,
        isDhcpRequired: lan.isDhcpRequired,
        isDMZ: lan.isDMZ,
        isAP: lan.isAP,
        interfaceName: lan.interfaceName.toString(),
        apMode: lan.apMode,
        apChannel: lan.apChannel,
        ap5Channel: lan.ap5Channel,
        apSSID: lan.apSSID,
        ap5SSID: lan.ap5SSID,
        apCountryCode: lan.apCountryCode,
        lanSegmentIp4: lan.lanSegmentIp4,
        lanCidrIp4: lan.lanCidrIp4,
        dhcpRangeStart: lan.dhcpRangeStart,
        dhcpRangeEnd: lan.dhcpRangeEnd,
        dhcpDefaultLease: lan.dhcpDefaultLease,
        dhcpMaxLease: lan.dhcpMaxLease
      };

      if (lan.vlanId !== '') {
        siteLan.vlanId = parseInt(lan.vlanId)
      }
      if (lan.ip4DMZ !== '') {
        siteLan.ip4DMZ = lan.ip4DMZ;
      }
      if (lan.apPassword !== '') {
        siteLan.apPassword = lan.apPassword;
      }
      if (lan.ap5Password !== '') {
        siteLan.ap5Password = lan.ap5Password;
      }

      return siteLan;
    })

    payload = {
      ...payload,
      address1: siteInfo.address1,
      address2: siteInfo.address2,
      city: siteInfo.city,
      state: siteInfo.state,
      country: siteInfo.country,
      postalCode: siteInfo.postalCode,
      isServerless: isServerless,
      clientCompanyId: clientCompanyId,
      Tunnels: { data: siteTunnels },
      SiteLans: { data: siteLans },
      SiteContacts: {
        data: [
          {
            firstName: siteContact.firstName,
            lastName: siteContact.lastName,
            phone: siteContact.phone,
            ext: siteContact.ext,
            email: siteContact.email,
            emailAlert: siteContact.emailAlert,
          },
        ],
      },
    };

    if (mode === 'edit') {
      payload.SiteContacts.data[0].id = siteContact.id;
    }

    if (routes.length > 0) {
      payload.SiteRoutes = {
        data: routes.map((route) => {
          return {
            id: route.id,
            siteId: id,
            crudState: route.crudState,
            isActive: route.isActive,
            isInbound: route.isInbound,
            metric: route.metric,
            routerIP: route.routerIP,
            routerInterface: route.routerInterface,
            subnet: route.subnet,
          };
        }),
      };
    }

    return payload;
  };

  handleRoutingCheckboxChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(prevState => ({
      routingConfig: {
        ...prevState.routingConfig,
        [name]: value
      },
    }));
  };
  updateSite = async (payload) => {
    const { id, licenseId, wanName, aclProfiles, siteInfo, originalData, clientCompany, clientCompanyId, quickLinks, routingConfig } = this.state;
    const sites = await Site.getAll();
    let otherSites = sites?.filter((s) => s.id !== id);
    let site = otherSites?.find((s) => s.address1 === siteInfo.address1);

    if (site) {
      this.setState(() => ({
        message: 'A Site with the name ' + siteInfo.address1 + ' already exists. Please try another name',
      }));
      return;
    }

    payload.siteId = id;

    try {
      let license_id = licenseId?.split(' - ')[0];
      clientCompany.Quicklinks = quickLinks;

      await ClientCompany.update(clientCompanyId, clientCompany);
      await Site.update(payload, originalData);

      if (isUUID(license_id)) {
        await SiteApi.attachLicense(id, { license_id });
      }

      if (routingConfig?.bgp_configuration) {
        const routing_configuration = {
          // ospf_enabled: routingConfig.ospf_enabled,
          // ospf_configuration: routingConfig.ospf_configuration.split('\n') || [],
          bgp_enabled: routingConfig.bgp_enabled,
          bgp_configuration: routingConfig.bgp_configuration.split('\n') || []
        }
        await SiteApi.addRouting(id, routing_configuration);
      }

      // If Site not in WAN, assign the ACL Profiles
      if (wanName === "") {
        await ACLProfile.setSiteAssignments(aclProfiles, id);
      }

      this.props.history.push('/site/' + id);
    }
    catch (error) {
      console.log(error);
      this.setState(() => ({ message: 'There was an error updating the Site' }));
    }
  };

  createSite = async (payload) => {
    const { aclProfiles, licenseId, clientCompany, clientCompanyId, quickLinks, routingConfig } = this.state;

    const sites = await Site.getAll();
    let site = sites.find((s) => s.address1 === this.state.siteInfo.address1);

    if (site) {
      this.setState(() => ({
        message: 'A Site with the name ' + this.state.siteInfo.address1 + ' already exists. Please try another name',
      }));
      return;
    }

    const updatedClientCompany = {
      ...clientCompany,
      Quicklinks: quickLinks,
    };

    try {
      const site = await Site.create(payload);
      let license_id = licenseId.split(' - ')[0];
      await ClientCompany.update(clientCompanyId, updatedClientCompany);
      await ACLProfile.setSiteAssignments(aclProfiles, site.id);
      await SiteApi.createRegistrations(site.id);

      if (isUUID(license_id)) {
        await SiteApi.attachLicense(site.id, { license_id });
      }

      if (routingConfig?.bgp_configuration) {
        const routing_configuration = {
          // ospf_enabled: routingConfig.ospf_enabled,
          // ospf_configuration: routingConfig.ospf_configuration.split('\n') || [],
          bgp_enabled: routingConfig.bgp_enabled,
          bgp_configuration: routingConfig.bgp_configuration.split('\n') || []
        }
        await SiteApi.addRouting(site.id, routing_configuration);
      }

      this.props.history.push('/site/' + site.id);
    } 
    catch (error) {
      console.log(error);
      this.setState(() => ({ message: 'There was an error creating the Site' }));
    }
  };

  handleSubmit = async (event) => {
    const { id, mode } = this.state;
    const payload = await this.getSitePayloadFromState(id);

    if (mode === 'edit') {
      this.updateSite(payload);
    } else if (mode === 'new') {
      this.createSite(payload);
    }
  };

  render() {
    const {
      licenseId,
      isServerless,
      hasActiveRoutes,
      routes,
      selectedInterface,
      wanId,
      isWanOpen,
      wanName,
      wanEnabled,
      failoverEnabled,
      isActiveControllerOpen,
      isStandbyControllerOpen,
      isActiveServerOpen,
      isStandbyServerOpen,
      lossManual,
      portRangeCustom,
      mtuManual,
      isInterfaceOpen,
      throughput,
      businessContinuity,
      businessContinuityLinks,
      isBusinessContinuityOpen,
      links,
      lans,
      dmzCount,
      clientCompanies,
      clientCompany,
      quickLinks,
      alertErrorState,
      isInterfaceErrorOpen,
      isLinkInterfaceErrorOpen,
      isLanInterfaceErrorOpen,
      isCompanyOpen,
      isIndustryOpen,
      countries,
      regions,
      industries,
      siteTunnels,
      isCountryOpen,
      isRegionOpen,
      isNewCompanyOpen,
      isLbAlgoOpen,
      lbAlgo,
      flowlet,
      siteInfo,
      siteContact,
      activeController,
      activeServer,
      standbyController,
      aclProfiles,
      aclProfileOptions,
      isACLProfileOpen,
      isLicenseOpen,
      standbyServer,
      linkTypes,
      newCompany,
      linkViewClass,
      linkViewLabel,
      activeTabIndex,
      routingConfig,
      bgp_configuration_rows,
      // ospf_configuration_rows,
      dataLoading,
      message,
      messageType,
      mode,
    } = this.state;

    const flexDirection = { default: 'column' };
    const title = mode === 'edit' ? 'Edit ' + siteInfo.address1 : 'Create New Site';
    const siteRoutes = this.getRoutes(routes);
    const quickLinks = this.getQuickLinks(quickLinks);
    const allLinks = this.getLinks();
    const throughputComponent = throughputFromLinks(links);
    const businessContinuityOptions = this.getBusinessContinuityOptions(businessContinuityLinks);
    const activeControllers = this.getActiveControllerOptions();
    const activeServers = this.getActiveServerOptions();
    const standbyControllers = this.getStandbyControllerOptions();
    const standbyServers = this.getStandbyServerOptions();
    const linkInterfaces = getLinkInterfaceOptions();
    const wanOptions = this.getWanOptions();
    const lbAlgoOptions = this.getLbAlgoOptions();

    // Set CSS class for Enabling/Disabling of Card Sections
    const businessContinuityCardClass = this.getCardClass('tunnel-settings', isServerless ? false : true);
    const loadBalancingCardClass = this.getCardClass('tunnel-settings', isServerless ? false : true);
    const lossCardClass = this.getCardClass('tunnel-settings', isServerless ? false : lossManual);
    const reorderCardClass = this.getCardClass('tunnel-settings', isServerless ? false : siteTunnels[0].shouldReorderBuffer);
    const encryptionCardClass = this.getInversedCardClass('tunnel-settings', isServerless ? true : siteTunnels[0].isClearTextData);
    const portRangeCardClass = this.getCardClass('tunnel-settings', isServerless ? false : portRangeCustom);
    const mtuCardClass = this.getCardClass('tunnel-settings', isServerless ? false : mtuManual);
    const routesCardClass = this.getCardClass('lan-settings', hasActiveRoutes);
    const wanCardClass = this.getCardClass('wan', wanEnabled);
    const licenseCardClass = this.getCardClass('license', licenseId !== '');
    const aclProfileCardClass = this.getCardClass('tunnel-settings', aclProfiles.length > 0)

    // WAN validation
    const wanInvalid = wanEnabled === true && wanName === '';

    // LANs validation
    const invalidLans = lans.filter((lan) => {
      const lanSegmentIp4IsValid = isValidIP(lan.lanSegmentIp4) && isUsableAddress(lan.lanSegmentIp4, lan.lanCidrIp4);
      const dnsServer1IsValid = isValidIP(lan.dnsServer1);
      const dnsServer2IsValid = isValidIP(lan.dnsServer2);
      const ip4DMZIsValid = isValidIP(lan.ip4DMZ);
      const apSSIDIsValid = isLengthWithinRange(lan.apSSID, 1, 32);
      const apPasswordIsValid = isLengthWithinRange(lan.apPassword, 8, 63);
      const apChannelIsValid = isValidNumber(lan.apChannel);
      const ap5SSIDIsValid = isLengthWithinRange(lan.ap5SSID, 1, 32);
      const ap5PasswordIsValid = isLengthWithinRange(lan.ap5Password, 8, 63);
      const ap5ChannelIsValid = isValidNumber(lan.ap5Channel);
      const lanSubnet = lan.lanSegmentIp4 + lan.lanCidrIp4;

      if (lan.isDhcpRequired && lanSegmentIp4IsValid) {
        if (lan.dhcpRangeStart === '') {
          lan.dhcpRangeStart = nextIp(lanSubnet, 2);
        }
        if (lan.dhcpRangeEnd === '') {
          lan.dhcpRangeEnd = lastIp(lanSubnet);
        }
      }

      const dhcpStartIsValid = isValidIP(lan.dhcpRangeStart);
      const dhcpEndIsValid = isValidIP(lan.dhcpRangeEnd);
      const dhcpDefaultLeaseIsValid = isValidNumber(lan.dhcpDefaultLease) && isWithinRange(lan.dhcpDefaultLease, 1, 31535999);
      const dhcpMaxLeaseIsValid = isValidNumber(lan.dhcpMaxLease) && isWithinRange(lan.dhcpMaxLease, 1, 31535999);
      const vlanIsValid = lan.vlanId === '' || (lan.vlanId !== '' && isValidVlan(lan.vlanId));

      return (
        !lan.lanCidrIp4 || !lan.lanSegmentIp4 || !vlanIsValid ||
        (lan.lanSegmentIp4 && !lanSegmentIp4IsValid) ||
        !lan.dnsServer1 || (lan.dnsServer1 && !dnsServer1IsValid) ||
        !lan.dnsServer2 || (lan.dnsServer2 && !dnsServer2IsValid) ||
        (lan.isDMZ && !lan.ip4DMZ) || (lan.isDMZ && !ip4DMZIsValid) ||
        lan.isAP && (
          (lan.apMode === 'mode_24' && (!apSSIDIsValid || !apPasswordIsValid || !apChannelIsValid)) ||
          (lan.apMode === 'mode_5' && (!ap5SSIDIsValid || !ap5PasswordIsValid || !ap5ChannelIsValid))
        ) ||
        lan.isDhcpRequired && (
          !lan.dhcpRangeStart ||
          !lan.dhcpRangeEnd ||
          !lan.dhcpDefaultLease ||
          !lan.dhcpMaxLease ||
          !dhcpStartIsValid ||
          !dhcpEndIsValid ||
          !dhcpDefaultLeaseIsValid ||
          !dhcpMaxLeaseIsValid
        )
      );
    });
    const lansInvalid = invalidLans.length > 0 ? true : false;

    // Routed IP's validations
    const invalidRoutes = routes.filter((route) => {
      return !isValidSubnet(route.subnet) || !isValidIP(route.routerIP); //|| !ipInSubnet(route.routerIP, lanSubnet);
    });
    const routesInvalid = hasActiveRoutes && invalidRoutes.length > 0 ? true : false;

    // Controller config validations
    const controllerConfigInvalid =
      !isServerless &&
      (!activeController.name ||
        !activeServer.ip4Address ||
        (failoverEnabled && (!standbyController.name || !standbyServer.ip4Address)));

    // Loss Tolerance validation
    const lossToleranceIsValid = isValidPercent(siteTunnels[0].lossTolerance);
    const lossToleranceErrorState = textInputValidate(
      siteTunnels[0].lossTolerance,
      lossManual,
      lossToleranceIsValid,
      'Must be between 1-100'
    );
    const lossToleranceInvalid = lossManual && (!siteTunnels[0].lossTolerance || !lossToleranceIsValid);

    // Reorder Buffer validation
    const reorderBufferIsValid = isWithinRange(siteTunnels[0].reorderBufferTime, 0, 2000);
    const reorderBufferErrorState = siteTunnels[0].shouldReorderBuffer ? 
      reorderBufferIsValid ? { validated: 'default', errorText: '' } 
        : { validated: 'error', errorText: 'Must be a number between 0-2000' }
        : { validated: 'default', errorText: '' };
    const reorderInvalid =
      siteTunnels[0].shouldReorderBuffer && (!siteTunnels[0].reorderBufferTime || !reorderBufferIsValid);

    // MTU validation
    const mtuIsValid = isWithinRange(siteTunnels[0].mtu, 600, 1452);
    const mtuErrorState = textInputValidate(
      siteTunnels[0].mtu,
      mtuManual,
      mtuIsValid,
      'Must be a number between 600-1452'
    );
    const mtuInvalid = mtuManual && (!siteTunnels[0].mtu || !mtuIsValid);

    // Port Range validations
    const portRangeStartIsValid = isValidNumber(siteTunnels[0].portRangeStart) && siteTunnels[0].portRangeStart !== '';
    const portRangeEndIsValid = isValidNumber(siteTunnels[0].portRangeEnd) && siteTunnels[0].portRangeEnd !== '';
    const portRangeStartErrorState = portRangeStartIsValid
      ? { validated: 'default', errorText: '' }
      : { validated: 'error', errorText: 'Must be a number' };
    const portRangeEndErrorState = portRangeEndIsValid
      ? { validated: 'default', errorText: '' }
      : { validated: 'error', errorText: 'Must be a number' };

    // Links validations
    const invalidLinks = links.filter((link) => {
      return (
        link.isEnabled &&
        (!link.type ||
          !link.carrierName ||
          (link.isPPPoE && (!link.pppoeUsername || !link.pppoePassword)) ||
          (link.isWWAN && (!link.modemAPN || !isValidNumber(link.modemIndex))) ||
          !isValidNumber(link.downloadThroughput) ||
          !isValidNumber(link.uploadThroughput) ||
          (link.dataLimitEnabled &&
            (link.dataLimit == '0' || link.dataLimit === '' || !isValidNumber(link.dataLimit))) ||
          (link.dataLimitResetDate && !link.dataLimitResetTime) ||
          (link.notes !== '' && !isLengthWithinRange(link.notes, 1, 255)) ||
          (!link.isDHCP &&
            (!isValidIP(link.tunnelLinkIp4) ||
              !link.linkNetmask ||
              (link.addressType === 'Static' && !isValidIP(link.staticGatewayIp)) ||
              (link.addressType !== 'Static' && !isValidIpNotRequired(link.staticGatewayIp)) ||
              !(Array.isArray(link.dnsAddresses) ? link.dnsAddresses.every(isValidIpNotRequired) : false))))
      );
    });
    const linksInvalid = invalidLinks.length > 0 ? true : false;

    // Contact info validations
    const emailIsValid = siteContact.email ? isValidEmail(siteContact.email) : true;
    const phoneIsValid = siteContact.phone ? isValidPhone(siteContact.phone) : true;
    const emailErrorState = siteContact.email
      ? isLengthWithinRange(siteContact.email, 1, 255)
        ? textInputValidate(siteContact.email, true, emailIsValid, 'Invalid Email Address')
        : { validated: 'error', errorText: ' Max 255 characters allowed' }
      : { validated: 'default', errorText: '' };
    const phoneErrorState = siteContact.phone
      ? textInputValidate(siteContact.phone, true, phoneIsValid, 'Invalid Phone Number')
      : { validated: 'default', errorText: '' };
    const firstNameErrorState =
      siteContact.firstName !== ''
        ? isLengthWithinRange(siteContact.firstName, 1, 255)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 255 characters allowed' }
        : { validated: 'default', errorText: '' };
    const lastNameErrorState =
      siteContact.lastName !== ''
        ? isLengthWithinRange(siteContact.lastName, 1, 255)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 255 characters allowed' }
        : { validated: 'default', errorText: '' };
    const extErrorState =
      siteContact.ext !== ''
        ? isLengthWithinRange(siteContact.ext, 1, 20)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 20 characters allowed' }
        : { validated: 'default', errorText: '' };

    const contactInfoInvalid =
      !clientCompany.name ||
      !clientCompany.industry ||
      (siteContact.email && !emailIsValid) ||
      (siteContact.phone && !phoneIsValid) ||
      alertErrorState.validated === 'error' ||
      firstNameErrorState.validated === 'error' ||
      lastNameErrorState.validated === 'error' ||
      extErrorState.validated === 'error';

    // Site Location validations
    const address1ErrorState =
      siteInfo.address1 !== ''
        ? isLengthWithinRange(siteInfo.address1, 1, 255)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 255 characters allowed' }
        : { validated: 'error', errorText: ' Required field' };
    const address2ErrorState =
      siteInfo.address2 !== ''
        ? isLengthWithinRange(siteInfo.address2, 1, 255)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 255 characters allowed' }
        : { validated: 'default', errorText: '' };
    const cityErrorState =
      siteInfo.city !== ''
        ? isLengthWithinRange(siteInfo.city, 1, 255)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 255 characters allowed' }
        : { validated: 'default', errorText: '' };
    const postalCodeErrorState =
      siteInfo.postalCode !== ''
        ? isLengthWithinRange(siteInfo.postalCode, 1, 255)
          ? { validated: 'default', errorText: '' }
          : { validated: 'error', errorText: ' Max 255 characters allowed' }
        : { validated: 'default', errorText: '' };
    const siteLocationInvalid =
      address1ErrorState.validated === 'error' ||
      address2ErrorState.validated === 'error' ||
      cityErrorState.validated === 'error' ||
      postalCodeErrorState.validated === 'error';

    // Client Company validations
    const notesErrorState = clientCompany.notes
      ? isLengthWithinRange(clientCompany.notes, 1, 255)
        ? { validated: 'default', errorText: '' }
        : { validated: 'error', errorText: 'Max 255 characters allowed' }
      : { validated: 'default', errorText: '' };
    const clientCompanyInvalid = notesErrorState.validated === 'error';
    const lbAlgoErrorState = { validated: 'default', errorText: '' };
    const aclProfilesHeading = wanName === "" ? "ACL Profiles" : "ACL Profiles (inherited from WAN)";
    // const licenseErrorState = isUUID(licenseId) ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'A valid License must be assigned' }
    // const licenseInvalid = licenseErrorState.validated === 'error';

    const bgpErrorState = routingConfig?.bgp_enabled && routingConfig?.bgp_configuration === "" ? { validated: 'error', errorText: " BGP Configuration must be provided" } : { validated: 'default', errorText: "" };
    //const ospfErrorState = routingConfig?.ospf_enabled && routingConfig?.ospf_configuration === "" ? { validated: 'error', errorText: " OSPF Configuration must be provided" } : { validated: 'default', errorText: "" };
    const formInvalid =
      routesInvalid ||
      lansInvalid ||
      controllerConfigInvalid ||
      lossToleranceInvalid ||
      reorderBufferErrorState.validated === 'error' ||
      mtuInvalid ||
      linksInvalid ||
      contactInfoInvalid ||
      siteLocationInvalid ||
      wanInvalid ||
      bgpErrorState.validated === 'error' ||
      clientCompanyInvalid; // ||
    //licenseInvalid;

    return (
      <React.Suspense fallback={<React.Fragment />}>
        <React.Fragment>
          <PageSection>
            <Title headingLevel="h1" size="xl">
              {title}
            </Title>
            <br />

            <Form>
              <Flex>
                <FlexItem className="flex-item-half">
                  <WanSettings
                    dataLoading={dataLoading}
                    handleEnabled={this.handleWanEnabled.bind(this)}
                    isWanOpen={isWanOpen}
                    onSelect={this.onWanSelectClick.bind(this)}
                    onToggle={this.onObjectToggle.bind(this)}
                    wanCardClass={wanCardClass}
                    wanEnabled={wanEnabled}
                    wanName={wanName}
                    wanOptions={wanOptions}
                  />
                </FlexItem>

                <FlexItem className="flex-item-half">
                  <Card isCompact className={licenseCardClass}>
                    <CardBody>
                      {dataLoading ?
                        <Spinner size="lg" />
                        :
                        <div>
                          <IconHeading
                            icon={<KeyIcon className="icon-medium" />}
                            heading="License Information"
                          />
                          <FormGroup
                            label="License ID"
                            isRequired
                            fieldId="licenseId"
                          // helperTextInvalid={licenseErrorState.errorText}
                          // helperTextInvalidIcon={<ExclamationCircleIcon />}
                          // validated={licenseErrorState.validated}
                          >
                            <Select
                              maxHeight={300}
                              variant={SelectVariant.single}
                              placeholderText="Attach License ..."
                              validated={true}
                              onToggle={(e) => this.onSelectToggle(e, 'isLicenseOpen')}
                              onSelect={(event, selection, isPlaceholder) =>
                                this.onLicenseSelect(event, selection, isPlaceholder, 'isLicenseOpen')
                              }
                              selections={licenseId}
                              isOpen={isLicenseOpen}
                              isDisabled={false}
                            >
                              {this.getLicenseOptions()}
                            </Select>
                          </FormGroup>
                        </div>
                      }
                    </CardBody>
                  </Card>
                </FlexItem>
              </Flex>

              <Flex direction={flexDirection}>
                <Title headingLevel="h3" size="md">
                  LAN Interfaces
                </Title>

                <div className="pointer add-link" onClick={this.addLan}>
                  <PlusIcon /> &nbsp;Add LAN
                </div>

                {dataLoading ? (
                  <Spinner size="lg" />
                ) : (
                  lans.filter(lan => lan.crudState === undefined || lan.crudState !== 'delete').map(lan =>
                    <LanSettings
                      key={lan.id}
                      dataLoading={dataLoading}
                      dmzCount={dmzCount}
                      linkInterfaces={linkInterfaces}
                      lanConfig={lan}
                      wanEnabled={wanEnabled}
                      wanName={wanName}
                      deleteLan={this.deleteLan}
                      netmaskOptions={this.getNetmaskOptions()}
                      wifiChannelOptions={this.getWifiChannelOptions()}
                      handleLanEnabled={this.handleLanEnabled}
                      handleLanIpChange={this.handleLanIpChange}
                      handleCheckboxChange={this.handleLanCheckboxChange}
                      handleChange={this.handleLanObjectChange}
                      handleWifiRadioChange={this.handleWifiRadioChange}
                      onSelectClick={this.onLanSelectClick}
                      onLanInterfaceSelectClick={this.onLanInterfaceSelectClick}
                    />
                  )
                )}

                <Title headingLevel="h3" size="md">
                  Routing
                </Title>

                <Flex>
                  <FlexItem className="flex-item-quarter">
                    <Card isCompact className={routesCardClass}>
                      <CardBody>
                        {dataLoading ? (
                          <Spinner size="lg" />
                        ) : (
                          <div>
                            <IconHeading
                              icon={<TopologyIcon className="icon-medium" />}
                              heading="Routed IP Blocks"
                              showEnable={true}
                              handleEnable={(e) => this.handleEnabled('hasActiveRoutes', e)}
                              enabled={hasActiveRoutes}
                            />
                            Assign Routed IP Addresses
                            <br />
                            <Spacer />
                            {hasActiveRoutes ? (
                              <div className="pointer add-link" onClick={this.addRoute}>
                                <PlusIcon /> &nbsp;Add Route
                              </div>
                            ) : (
                              <div>
                                <PlusIcon /> &nbsp;Add Route
                              </div>
                            )}
                            <Spacer />
                            {siteRoutes}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </FlexItem>

                  <FlexItem className="flex-item-half">
                    <Card isCompact>
                      <CardBody>
                        <IconHeading icon={<TopologyIcon className="icon-medium" />} heading="Advanced" />

                        <Tabs activeKey={activeTabIndex} onSelect={this.handleTabClick} isBox>
                          <Tab
                            eventKey={TabIndex.BGP}
                            title={
                              <React.Fragment>
                                <TabTitleIcon>
                                  <PficonNetworkRangeIcon className="icon-medium" />
                                </TabTitleIcon>{' '}
                                <TabTitleText>{TitleMap[TabIndex.BGP]}</TabTitleText>
                              </React.Fragment>
                            }
                          >
                            <br />
                            <Checkbox
                              label="BGP Enabled"
                              aria-label="BGP Enabled"
                              name="bgp_enabled"
                              id="bgp-enabled"
                              key="bgp-enabled"
                              onChange={this.handleRoutingCheckboxChange}
                              isChecked={routingConfig?.bgp_enabled}
                            />
                            <br />
                            <FormGroup label="" isRequired fieldId="bgp-config-toggle"
                              helperTextInvalid={bgpErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={bgpErrorState.validated}>
                              <TextArea 
                                rows={bgp_configuration_rows} id="bgp_configuration" name="bgp_configuration" autoComplete="new-password" isDisabled={!routingConfig?.bgp_enabled}
                                value={routingConfig?.bgp_configuration} 
                                onChange={(e) => this.handleObjectChange('routingConfig', 'bgp_configuration', e)} 
                                aria-label="BGP Configuration" className="code-config"
                              />
                            </FormGroup>
                          </Tab>
                        </Tabs>
                      </CardBody>
                    </Card>
                  </FlexItem>
                </Flex>

                <Title headingLevel="h3" size="md">
                  Tunnel Settings
                </Title>

                <TunnelSettings
                  activeController={activeController}
                  activeControllers={activeControllers}
                  activeServer={activeServer}
                  activeServers={activeServers}
                  businessContinuity={businessContinuity}
                  businessContinuityOptions={businessContinuityOptions}
                  businessContinuityCardClass={businessContinuityCardClass}
                  dataLoading={dataLoading}
                  encryptionCardClass={encryptionCardClass}
                  failoverEnabled={failoverEnabled}
                  flexDirection={flexDirection}
                  handleChange={this.handleChange.bind(this)}
                  handleCheckboxChange={this.handleCheckboxChange.bind(this)}
                  handleEnabled={this.handleEnabled.bind(this)}
                  handleEnableFailoverChange={this.handleEnableFailoverChange.bind(this)}
                  handleReorderBufferEnabled={this.handleReorderBufferEnabled.bind(this)}
                  handleServerlessChange={this.handleServerlessChange.bind(this)}
                  handleTunnelChange={this.handleTunnelChange.bind(this)}
                  handleTunnelEnabled={this.handleTunnelEnabled.bind(this)}
                  handleTunnelEnabledWithDefault={this.handleTunnelEnabledWithDefault.bind(this)}
                  handleTunnelInverseEnabled={this.handleTunnelInverseEnabled.bind(this)}
                  handleTunnelPortRange={this.handleTunnelPortRange.bind(this)}
                  isActiveControllerOpen={isActiveControllerOpen}
                  isActiveServerOpen={isActiveServerOpen}
                  isBusinessContinuityOpen={isBusinessContinuityOpen}
                  isLbAlgoOpen={isLbAlgoOpen}
                  lbAlgoErrorState={lbAlgoErrorState}
                  lbAlgoOptions={lbAlgoOptions}
                  loadBalancingCardClass={loadBalancingCardClass}
                  isServerless={isServerless}
                  isStandbyControllerOpen={isStandbyControllerOpen}
                  isStandbyServerOpen={isStandbyServerOpen}
                  lbAlgo={lbAlgo}
                  flowlet={flowlet}
                  lossCardClass={lossCardClass}
                  lossManual={lossManual}
                  lossToleranceErrorState={lossToleranceErrorState}
                  mtuCardClass={mtuCardClass}
                  mtuErrorState={mtuErrorState}
                  mtuManual={mtuManual}
                  onActiveControllerSelect={this.onActiveControllerSelect.bind(this)}
                  onActiveServerSelect={this.onActiveServerSelect.bind(this)}
                  onObjectToggle={this.onObjectToggle.bind(this)}
                  onSelectClick={this.onSelectClick.bind(this)}
                  getLbAlgoDescription={this.getLbAlgoDescription}
                  onSelectToggle={this.onSelectToggle.bind(this)}
                  onStandbyControllerSelect={this.onStandbyControllerSelect.bind(this)}
                  onStandbyServerSelect={this.onStandbyServerSelect.bind(this)}
                  portRangeCardClass={portRangeCardClass}
                  portRangeCustom={portRangeCustom}
                  portRangeEndErrorState={portRangeEndErrorState}
                  portRangeStartErrorState={portRangeStartErrorState}
                  reorderBufferErrorState={reorderBufferErrorState}
                  reorderCardClass={reorderCardClass}
                  siteTunnels={siteTunnels}
                  standbyController={standbyController}
                  standbyControllers={standbyControllers}
                  standbyServer={standbyServer}
                  standbyServers={standbyServers}
                  wanEnabled={wanEnabled}
                />

                <Title headingLevel="h3" size="md">
                  Security Settings
                </Title>

                <Flex>
                  <FlexItem className="flex-item-wan">
                    <Card isCompact className={aclProfileCardClass}>
                      <CardBody>
                        {dataLoading ?
                          <Spinner size="lg" />
                          :
                          <div>
                            <IconHeading icon={<ServiceCatalogIcon className="icon-medium" />} heading={aclProfilesHeading} />
                            <div>
                              <span id="multi-typeahead-render-chip-group-props-id-1" hidden>
                                Select ACL Profile(s)
                              </span>
                              <Select
                                maxHeight={300}
                                chipGroupProps={{ numChips: 1, expandedText: 'Hide', collapsedText: 'Show ${remaining}' }}
                                variant={SelectVariant.typeaheadMulti}
                                typeAheadAriaLabel="Select ACL Profile(s)"
                                onToggle={this.onSelectACLToggle}
                                onSelect={this.onACLProfileSelect}
                                onClear={this.clearACLProfileSelection}
                                selections={aclProfiles}
                                isOpen={isACLProfileOpen}
                                aria-labelledby="multi-typeahead-render-chip-group-props-id-1"
                                placeholderText="Select ACL Profile(s)"
                                chipGroupComponent={this.chipGroupComponent()}
                              >
                                {aclProfileOptions.map((option, index) => (
                                  <SelectOption
                                    isDisabled={option.disabled}
                                    key={index}
                                    value={option.value}
                                    {...(option.description && { description: option.description })}
                                  />
                                ))}
                              </Select>
                            </div>
                          </div>
                        }
                      </CardBody>
                    </Card>
                  </FlexItem>
                </Flex>

                <table>
                  <tbody>
                    <tr>
                      <td width="100px">
                        <Title headingLevel="h3" size="md">
                          Links
                        </Title>
                      </td>
                      <td>
                        {allLinks.length > 0 ? (
                          <a
                            className="link-view-toggle"
                            id="linkViewToggle"
                            key="linkViewToggle"
                            onClick={this.handleLinkViewChange}
                          >
                            {linkViewLabel}
                          </a>
                        ) : (
                          <div></div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pointer add-link" onClick={this.addLink}>
                  <PlusIcon /> &nbsp;Add Link
                </div>

                {allLinks}

                <Title headingLevel="h3" size="md">
                  Misc.
                </Title>

                <Flex>
                  <FlexItem className="flex-item-quarter">
                    <Card isCompact className="misc-settings-form-row card-enabled">
                      <CardBody>
                        {dataLoading ? (
                          <Spinner size="lg" />
                        ) : (
                          <div>
                            <IconHeading
                              icon={<WrenchIcon className="icon-medium" />}
                              heading="Configured Throughput"
                            />
                            {links.length > 0 ? throughputComponent : 'There are no active links configured!'}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </FlexItem>
                </Flex>

                <Title headingLevel="h3" size="md">
                  Contact Information
                </Title>

                <Flex>
                  <FlexItem className="flex-item-half card-enabled">
                    <Card isCompact>
                      <CardBody>
                        {dataLoading ? (
                          <Spinner size="lg" />
                        ) : (
                          <div>
                            <IconHeading icon={<EnterpriseIcon className="icon-medium" />} heading="Company Profile" />

                            <div className="pointer right" onClick={this.addCompany}>
                              <PlusIcon /> &nbsp;New Company
                            </div>

                            <FormGroup
                              label="Company"
                              isRequired
                              fieldId="company-toggle"
                              helperTextInvalid=" Required field"
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              validated={textInputRequiredOnly(clientCompany.name)}
                            >
                              <Select
                                maxHeight={300}
                                variant={SelectVariant.single}
                                placeholderText="Select ..."
                                validated={textInputRequiredOnly(clientCompany.name)}
                                onToggle={(e) => this.onSelectToggle(e, 'isCompanyOpen')}
                                onSelect={(event, selection, isPlaceholder) =>
                                  this.onCompanySelect(event, selection, isPlaceholder, 'isCompanyOpen')
                                }
                                selections={clientCompany.name}
                                isOpen={isCompanyOpen}
                                isDisabled={false}
                              >
                                {this.getClientCompanyOptions()}
                              </Select>
                            </FormGroup>

                            <FormGroupSpacer>
                              <FormGroup label="Industry" isRequired fieldId="industry-toggle">
                                <Select
                                  maxHeight={300}
                                  variant={SelectVariant.single}
                                  placeholderText="Select ..."
                                  onToggle={(e) => this.onSelectToggle(e, 'isIndustryOpen')}
                                  onSelect={(event, selection, isPlaceholder) =>
                                    this.onObjectSelectClick(
                                      event,
                                      selection,
                                      isPlaceholder,
                                      'clientCompany',
                                      'industry',
                                      'isIndustryOpen'
                                    )
                                  }
                                  selections={clientCompany.industry}
                                  isOpen={isIndustryOpen}
                                  isDisabled={false}
                                >
                                  {industries}
                                </Select>
                              </FormGroup>
                            </FormGroupSpacer>

                            <br />

                            <IconHeading icon={<CatalogIcon className="icon-medium" />} heading="Company Notes" />
                            <div className="pointer right" onClick={this.addQuickLink}>
                              <PlusIcon /> &nbsp;New Quick Link
                            </div>
                            {quickLinks}
                            <br />

                            <strong>Notes:</strong>
                            <br />
                            <FormGroup
                              label=""
                              fieldId={'clientCompanyNotes'}
                              helperTextInvalid={notesErrorState.errorText}
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              validated={clientCompany.notes !== '' ? notesErrorState.validated : 'default'}
                            >
                              <TextArea
                                rows="3"
                                id="clientCompanyNotes"
                                name="clientCompanyNotes"
                                autoComplete="new-password"
                                value={clientCompany.notes || ''}
                                onChange={(e) => this.handleObjectChange('clientCompany', 'notes', e)}
                                aria-label="Company Notes"
                              />
                            </FormGroup>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </FlexItem>

                  <FlexItem className="flex-item-half card-enabled">
                    <Card isCompact>
                      <CardBody>
                        {dataLoading ? (
                          <Spinner size="lg" />
                        ) : (
                          <div>
                            <SiteInfo
                              site={siteInfo}
                              address1ErrorState={address1ErrorState}
                              address2ErrorState={address2ErrorState}
                              cityErrorState={cityErrorState}
                              postalCodeErrorState={postalCodeErrorState}
                              onRegionSelect={this.onRegionSelect}
                              onRegionToggle={this.onRegionToggle}
                              isRegionOpen={isRegionOpen}
                              isCountryOpen={isCountryOpen}
                              onCountrySelect={this.onCountrySelect}
                              onCountryToggle={this.onCountryToggle}
                              handleChange={this.handleObjectChange}
                              countries={countries}
                              regions={regions}
                            />

                            <IconHeading icon={<UserIcon className="icon-medium" />} heading="Contact Information" />

                            <ContactInfo
                              contact={siteContact}
                              emailErrorState={emailErrorState}
                              phoneErrorState={phoneErrorState}
                              alertErrorState={alertErrorState}
                              extErrorState={extErrorState}
                              firstNameErrorState={firstNameErrorState}
                              lastNameErrorState={lastNameErrorState}
                              handleChange={this.handleObjectChange}
                              handleEmailChange={this.handleEmailChange}
                              handleAlertChange={this.handleEmailAlertChange}
                            />
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </FlexItem>
                </Flex>
              </Flex>
            </Form>

            <Modal
              title="Add Company"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={isNewCompanyOpen}
              onClose={this.handleNewCompanyToggle}
              actions={[
                <Button key="confirm" variant="primary" onClick={this.createCompany}>
                  OK
                </Button>,
                <Button key="cancel" variant="link" onClick={this.handleNewCompanyToggle}>
                  Cancel
                </Button>,
              ]}
            >
              <FormGroup label="Enter the new Company Name:" isRequired fieldId="newCompany">
                <TextInput
                  className="form-input"
                  isRequired
                  type="text"
                  id="newCompany"
                  name="newCompany"
                  placeholder=""
                  value={newCompany}
                  onChange={this.handleNewCompanyChange}
                />
              </FormGroup>
            </Modal>

            <Modal
              title="Interface Error"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={isInterfaceErrorOpen}
              onClose={this.handleInterfaceErrorToggle}
              actions={[
                <Button key="cancel" variant="primary" onClick={this.handleInterfaceErrorToggle}>
                  OK
                </Button>,
              ]}
            >
              Interface {selectedInterface} is already being used by another Link, or by a LAN interface
            </Modal>

            <Modal
              title="Interface Error"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={isLinkInterfaceErrorOpen}
              onClose={this.handleLinkInterfaceErrorToggle}
              actions={[
                <Button key="cancel" variant="primary" onClick={this.handleLinkInterfaceErrorToggle}>
                  OK
                </Button>,
              ]}
            >
              Interface {selectedInterface} is already being used by a Link
            </Modal>

            <Modal
              title="Interface Error"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={isLanInterfaceErrorOpen}
              onClose={this.handleLanInterfaceErrorToggle}
              actions={[
                <Button key="cancel" variant="primary" onClick={this.handleLanInterfaceErrorToggle}>
                  OK
                </Button>,
              ]}
            >
              Interface {selectedInterface} is already being used by another LAN
            </Modal>

            <Saver
              submitButtonText="Save"
              submitButtonisDisabled={formInvalid}
              submit={this.handleSubmit}
              cancel={this.handleCancel}
              showCancel={true}
              forceAcceptTerms={mode === 'new' ? true : false}
              message={message}
              messageType={messageType}
            />
          </PageSection>
        </React.Fragment>
      </React.Suspense>
    );
  }
}

export { SiteForm };
