import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectLogoFileUrl } from '../../common/session/session.selectors';
import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import UserSession from '@app/common/user-session';

export const Logo: React.FC = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(push('/dashboard'));
  let logoUrl = useSelector(selectLogoFileUrl) || EthicaLogo;

  return React.useMemo(
    () => <img src={logoUrl} width="100px" height="42px" onClick={handleClick} alt="Orchestrator Logo" />,
    [logoUrl]
  );
};
