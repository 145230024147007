import * as React from 'react';
import { Helmet } from 'react-helmet';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { PageSection, Title, Tabs, Tab, TabTitleText, TabTitleIcon } from '@patternfly/react-core';
import { KeyIcon, UsersIcon, UserIcon, WrenchIcon, OptimizeIcon, LockIcon, TagIcon } from '@patternfly/react-icons';
import UserSession from '@app/common/user-session';
import Settings from './Settings';
import Profile from './Profile';
import Users from './Users';
import ApiKeys from './ApiKeys';
import PaymentMethods from './PaymentMethods';
import { Preferences } from './preferences';
import ResetPassword from '../auth/ResetPassword';

enum TabIndex {
  PROFILE,
  SETTINGS,
  BRANDING,
  USERS,
  SECURITY,
  PAYMENT_METHODS,
  API_KEYS
}

export const AdminView: React.FC = () => {
  const TitleMap = {
    [TabIndex.PROFILE]: 'Profile',
    [TabIndex.SETTINGS]: 'Settings',
    [TabIndex.BRANDING]: 'Branding',
    [TabIndex.USERS]: 'Users',
    [TabIndex.SECURITY]: 'Security',
    [TabIndex.PAYMENT_METHODS]: 'Payment Methods',
    [TabIndex.API_KEYS]: 'Personal Access Tokens'
  };
  
  const isImpersonating = UserSession.getParam('isImpersonating');
  const role = UserSession.getParam('role').toLowerCase();
  const isManager = role === 'manager' ? true : false;
  const isViewer = role === 'viewer' ? true : false;
  const isEditor = role === 'editor' ? true : false;
  const defaultTab = isImpersonating ? TabIndex.SETTINGS : TabIndex.PROFILE;
  const [activeTabIndex, setActiveTabIndex] = useQueryParam('tab', withDefault(NumberParam, defaultTab));
  const [currentTitle, setCurrentTitle] = React.useState('');

  const handleTabClick = React.useCallback(
    (_, index) => {
      setActiveTabIndex(index);
    },
    [setActiveTabIndex]
  );

  React.useEffect(() => {
    setCurrentTitle(TitleMap[activeTabIndex]);
  }, [activeTabIndex]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <PageSection>
        <Title headingLevel="h1" size="xl">
          Admin
        </Title>
        <br />

        <Tabs activeKey={activeTabIndex} onSelect={handleTabClick} isBox>
          <Tab
            eventKey={TabIndex.PROFILE}
            isHidden={isImpersonating}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <UserIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.PROFILE]}</TabTitleText>
              </React.Fragment>
            }
          >
            <Profile />
          </Tab>
          <Tab
            eventKey={TabIndex.SETTINGS}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <WrenchIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.SETTINGS]}</TabTitleText>
              </React.Fragment>
            }
          >
            <Settings />
          </Tab>
          <Tab
            eventKey={TabIndex.BRANDING}
            isHidden={isImpersonating || isViewer}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <OptimizeIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.BRANDING]}</TabTitleText>
              </React.Fragment>
            }
          >
            <Preferences />
          </Tab>
          <Tab
            eventKey={TabIndex.USERS}
            isHidden={isImpersonating || !isManager}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <UsersIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.USERS]}</TabTitleText>
              </React.Fragment>
            }
          >
            <Users />
          </Tab>
          <Tab
            eventKey={TabIndex.SECURITY}
            isHidden={isImpersonating}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <LockIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.SECURITY]}</TabTitleText>
              </React.Fragment>
            }
          >
            <ResetPassword />
          </Tab>
          <Tab
            eventKey={TabIndex.PAYMENT_METHODS}
            isHidden={isImpersonating || isViewer || isEditor}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <TagIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.PAYMENT_METHODS]}</TabTitleText>
              </React.Fragment>
            }
          >
            <PaymentMethods />
          </Tab>
          <Tab
            eventKey={TabIndex.API_KEYS}
            isHidden={isImpersonating}
            title={
              <React.Fragment>
                <TabTitleIcon>
                  <KeyIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>{TitleMap[TabIndex.API_KEYS]}</TabTitleText>
              </React.Fragment>
            }
          >
            <ApiKeys />
          </Tab>
        </Tabs>
      </PageSection>
    </React.Fragment>
  );
};
