import React from 'react';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { Checkbox, FormGroup, TextInput } from "@patternfly/react-core";
import { isLengthWithinRange } from '@app/lib/validator';
import { FormGroupSpacer } from '../../../components/forms/pf/form-group-spacer.component';

const ContactInfo = ({ 
  contact, emailErrorState, phoneErrorState, alertErrorState, handleChange, handleEmailChange, handleAlertChange, 
  firstNameErrorState, lastNameErrorState, extErrorState
}) => {
  return (     
    <table>
      <tbody> 
        <tr>
          <td width="50%">
            <table> 
              <tbody>
                <tr>
                  <td>
                    <FormGroup label="First Name" fieldId="firstName"
                      helperTextInvalid={firstNameErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.firstName !== "" ? firstNameErrorState.validated : 'default'}>
                      <TextInput className="form-input"
                        isRequired type="text" id="firstName" name="firstName" placeholder=""
                        validated={contact.firstName !== "" ? firstNameErrorState.validated : 'default'}
                        value={contact.firstName} onChange={(e) => handleChange("siteContact", "firstName", e)}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup label="Last Name" fieldId="lastName"
                      helperTextInvalid={lastNameErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.lastName !== "" ? lastNameErrorState.validated : 'default'}>
                      <TextInput className="form-input"
                        isRequired type="text" id="lastName" name="lastName" placeholder=""
                        validated={contact.lastName !== "" ? lastNameErrorState.validated : 'default'}
                        value={contact.lastName} onChange={(e) => handleChange("siteContact", "lastName", e)}
                      />
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <FormGroup label="Email" fieldId="email"
              helperTextInvalid={emailErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.email ? emailErrorState.validated : 'default'}>
              <TextInput className="form-input" validated={contact.email ? emailErrorState.validated : 'default'}
                isRequired type="text" id="email" name="email" placeholder=""
                value={contact.email} onChange={handleEmailChange}
              />
            </FormGroup>
          </td>
        </tr>
        <tr>
          <td width="50%">
            <table> 
              <tbody>
                <tr>
                  <td>
                    <FormGroupSpacer>
                      <FormGroup label="Phone" fieldId="phone"
                        helperTextInvalid={phoneErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.phone ? phoneErrorState.validated : 'default'}>
                        <TextInput className="form-input" validated={contact.phone ? phoneErrorState.validated : 'default'}
                          isRequired type="text" id="phone" name="phone" placeholder=""
                          value={contact.phone} onChange={(e) => handleChange("siteContact", "phone", e)}
                        />
                      </FormGroup>
                    </FormGroupSpacer>
                  </td>
                  <td>
                    <FormGroupSpacer>
                      <FormGroup label="Extension" fieldId="ext"
                        helperTextInvalid={extErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.ext !== "" ? extErrorState.validated : 'default'}>
                        <TextInput className="form-input"
                          isRequired type="text" id="ext" name="ext" placeholder="" validated={contact.ext !== "" ? extErrorState.validated : 'default'}
                          value={contact.ext} onChange={(e) => handleChange("siteContact", "ext", e)}
                        />
                      </FormGroup>
                    </FormGroupSpacer>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width="50%">
            <div className="email-alert">
              <FormGroup fieldId="emailAlert"
                helperTextInvalid={alertErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={alertErrorState.validated}>
                <Checkbox 
                  label="Receive Alerts?" aria-label="Receive Alerts?" name="emailAlert" id="emailAlert" key="emailAlert"
                  isChecked={contact.emailAlert} onChange={handleAlertChange}
                />
              </FormGroup>
            </div>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ContactInfo;