import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNotifier } from '../../lib/use-notifier';
import UserSession from '@app/common/user-session';
import { selectFaviconFileUrl, selectHeaderColor, selectPageTitle } from '../../common/session/session.selectors';

export const Html: React.FC = ({ children }) => {
  useNotifier();
  let pageTitle = useSelector(selectPageTitle);
  let faviconUrl = useSelector(selectFaviconFileUrl);
  let headerColor = useSelector(selectHeaderColor);

  if (UserSession.getParam('isImpersonating')) {
    pageTitle = UserSession.getParam('pageTitle') || pageTitle;
    faviconUrl = UserSession.getParam('faviconFileUrl') || faviconUrl;
    headerColor = UserSession.getParam('headerColor') || headerColor;
  }

  return (
    <React.Fragment>
      <Helmet titleTemplate={`%s | ${pageTitle}`} defaultTitle={`${pageTitle}`}>
        <link rel="icon" type="image/png" href={`${faviconUrl}`} />
        <style type='text/css'>
          {`
            .pf-c-page__header {
              --pf-global--BackgroundColor--100: ${headerColor} !important; 
              background-color: ${headerColor} !important;
            }
          `}
        </style>
      </Helmet>
      {children}
    </React.Fragment>
  );
};
