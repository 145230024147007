import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import ApplicationProfile from '@app/api/aclprofile/application-profile.actions';
import { useACLProfileContext } from '../../contexts/aclprofile.context';
import { PencilAltIcon } from '@patternfly/react-icons';
import { Spinner } from '@patternfly/react-core';
import { getPortRangeFromStartEnd, getAppRuleColumns, getRuleColumns } from './ACLProfileHelper';
import { InfoSection } from '@app/components/InfoSection';
import { IconHeading } from '@app/components/IconHeading';
import { sortable } from '@patternfly/react-table';
import { push } from 'connected-react-router';
import { createMatchSelector } from 'connected-react-router';
import ACLProfileIcon from '@app/bgimages/service-catalog-black.svg';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortBy } from '@app/lib/functions';
import { capitalizeFirstLetter } from '@app/utils/utils';
import { BullhornIcon, LockIcon, DomainIcon, PficonNetworkRangeIcon, SyncAltIcon, UserIcon, OptimizeIcon, WrenchIcon } from '@patternfly/react-icons';
import { PageSection, Title, Flex, FlexItem, Button, Card, CardBody, Modal, ModalVariant, Spinner, TextArea, ToggleGroup, ToggleGroupItem, Tabs, Tab, TabTitleText, TabTitleIcon } from "@patternfly/react-core";

enum TabIndex {
  INBOUND,
  OUTBOUND,
  APPLICATION,
  DNS
}

export const ACLProfileView: React.FunctionComponent = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, isLoading, isSuccess, isFailed } = useACLProfileContext();
  const emptyAclProfile = { id: "", name: "", description: "", inboundRules: [], outboundRules: [], dnsRuleUrls: [] };
  const [aclProfile, setAclProfile] = React.useState(emptyAclProfile);
  const [dataLoading, setDataLoading] = React.useState(true);
  const [activeTabIndex, setActiveTabIndex] = useQueryParam('tab', withDefault(NumberParam, TabIndex.INBOUND));
  const [inboundPage, setInboundPage] = React.useState(1);
  const [outboundPage, setOutboundPage] = React.useState(1);
  const [applicationPage, setApplicationPage] = React.useState(1);
  const [dnsRulePage, setDnsRulePage] = React.useState(1);
  const TitleMap = {
    [TabIndex.INBOUND]: 'Inbound Rules',
    [TabIndex.OUTBOUND]: 'Outbound Rules',
    [TabIndex.APPLICATION]: 'Application Rules',
    [TabIndex.DNS]: 'DNS Rules'
  };

  React.useEffect(() => {
    (async function() {
      loadACLProfile();
      (document.getElementById("primary-app-container") as HTMLDivElement).scroll(0, 0);
    })();
  }, [activeTabIndex]);

  const loadACLProfile = async () => {
    const applications = await ApplicationProfile.getApplications();
    const acl = await ACLProfile.get(id);

    if (acl === undefined) {
      return history.push("/notfound");
    }

    const inboundNumberedPriorities = acl.inbound_rules ? acl.inbound_rules.filter(r => r.priority !== "" && r.priority !== "*") : [];
    const inboundOtherPriorities = acl.inbound_rules ? acl.inbound_rules.filter(r => r.priority === "" || r.priority === "*") : [];
    const inboundRules = sortBy(inboundNumberedPriorities, 'priority');
    const outboundNumberedPriorities = acl.outbound_rules ? acl.outbound_rules.filter(r => r.priority !== "" && r.priority !== "*") : [];
    const outboundOtherPriorities = acl.outbound_rules ? acl.outbound_rules.filter(r => r.priority === "" || r.priority === "*") : [];
    const outboundRules = sortBy(outboundNumberedPriorities, 'priority');
    const applicationNumberedPriorities = acl.application_rules ? acl.application_rules.filter(r => r.priority !== "" && r.priority !== "*") : [];
    const applicationOtherPriorities = acl.application_rules ? acl.application_rules.filter(r => r.priority === "" || r.priority === "*") : [];
    const applicationRules = sortBy(applicationNumberedPriorities, 'priority');
    let appRules = applicationRules.concat(applicationOtherPriorities);

    for (const i = 0; i < appRules.length; i++) {
      let appRule = appRules[i];

      if (appRule.type === 'All Applications') {
        appRule.type = appRule.match;
        appRule.match = 'All';
      }
      else {
        const group = applications.find(app => app.name === appRule.match);
        appRule.type = group.category;
      }
    }
    
    let dnsRule = [];

    if (acl.dns_rules) {
      dnsRule = acl.dns_rules[0].parameters !== 'null' ? JSON.parse(acl.dns_rules[0].parameters).urls.join("\n") : [];
    }

    setDataLoading(false);
    setAclProfile({
      id: acl.id,
      name: acl.name,
      description: acl.description,
      inboundRules: inboundRules.concat(inboundOtherPriorities),
      outboundRules: outboundRules.concat(outboundOtherPriorities),
      applicationRules: appRules,
      dnsRuleUrls: dnsRule
    });
  }

  const ruleColumns = () => {
    return getRuleColumns().map(column => { return { title: column }});
  }

  const appRuleColumns = () => {
    return getAppRuleColumns().map(column => { return { title: column }});
  }

  const ruleFromItem = (item) => {
    const srcPort = getPortRangeFromStartEnd(item.source_port_start, item.source_port_end);
    const destPort = getPortRangeFromStartEnd(item.destination_port_start, item.destination_port_end);

    return {
      cells: [
        { title: item.priority },
        { title: item.type },
        { title: item.source_ip },
        { title: srcPort },
        { title: item.destination_ip },
        { title: destPort },
        { title: capitalizeFirstLetter(item.action) },
        { title: item.comments }
      ]
    }
  };

  const appRuleFromItem = (item) => {
    const action = capitalizeFirstLetter(item.action);
    return {
      cells: [
        { title: item.priority },
        { title: item.type }, 
        { title: item.match },
        { title: item.qos },
        { title: item.source_ip },
        { title: action === "Shape" ? item.download_cap : "" },
        { title: action === "Shape" ? item.upload_cap : "" },
        { title: action === "Accept" ? item.exit_via : "" },
        { title: action }
      ]
    }
  }

  const handleTabClick = (e, index) => {
    setActiveTabIndex(index);
  };

  const editAclProfile = () => {
    history.push(`/aclprofile/edit/${id}?tab=${activeTabIndex}`);
  };

  const getRules = (rulesList) => {
    const rules = [];

    if (aclProfile[rulesList]) {
      aclProfile[rulesList].map(item => {
        if (rulesList === "applicationRules") {
          rules.push(appRuleFromItem(item));
        }
        else {
          rules.push(ruleFromItem(item));
        }
      });
    }
    return rules;
  };

  const inboundRules = getRules("inboundRules");
  const outboundRules = getRules("outboundRules");
  const applicationRules = getRules("applicationRules");

  return (    
    <React.Fragment>
      <Helmet>
        <title>{aclProfile.name}</title>
      </Helmet>
      <PageSection>

        <Card isCompact>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <td width="12%" className="aclprofile-header">
                    <img src={ACLProfileIcon} width="60%" alt={aclProfile.name} />
                  </td>
                  <td width="88%" className="aclprofile-header aclprofile-desc">
                    {dataLoading ? 
                      <Spinner size="lg" />
                    : 
                      <div>
                        <Title headingLevel="h1" size="xl">{aclProfile.name}</Title><br />
                        <div className="textarea-input">
                          <p>{aclProfile.description}</p><br />
                        </div>
                        <ToggleGroup aria-label="ACL Profile options">
                          <ToggleGroupItem icon={<PencilAltIcon />} text="Edit" aria-label="Edit ACL Profile" buttonId="toolbar-edit" onClick={editAclProfile} />
                        </ToggleGroup>
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>

        <br />

        <Card isCompact>
          <CardBody>
            <Tabs activeKey={activeTabIndex} onSelect={handleTabClick} isBox>

              <Tab
                eventKey={TabIndex.INBOUND}
                title={
                  <React.Fragment>
                    <TabTitleIcon>
                      <PficonNetworkRangeIcon className="icon-medium" />
                    </TabTitleIcon>{' '}
                    <TabTitleText>{TitleMap[TabIndex.INBOUND]}</TabTitleText>
                  </React.Fragment>
                }
              >
                {dataLoading ? 
                  <Spinner size="lg" />
                : <div>
                  {inboundRules.length > 0 ?
                    <PatternflyTable
                      columns={ruleColumns}
                      data={inboundRules}
                      page={inboundPage}
                    />
                  : <div>No Inbound Rules configured!</div>}
                </div>}
              </Tab>

              <Tab
                eventKey={TabIndex.OUTBOUND}
                title={
                  <React.Fragment>
                    <TabTitleIcon>
                    <PficonNetworkRangeIcon className="icon-medium" />
                    </TabTitleIcon>{' '}
                    <TabTitleText>{TitleMap[TabIndex.OUTBOUND]}</TabTitleText>
                  </React.Fragment>
                }
              >
                {dataLoading ? 
                  <Spinner size="lg" />
                : <div>
                  {outboundRules.length > 0 ?
                    <PatternflyTable
                      columns={ruleColumns}
                      data={outboundRules}
                      page={outboundPage}
                    />
                  : <div>No Outbound Rules configured!</div>}
                </div>}
              </Tab>

              <Tab
                eventKey={TabIndex.APPLICATION}
                title={
                  <React.Fragment>
                    <TabTitleIcon>
                      <WrenchIcon />
                    </TabTitleIcon>{' '}
                    <TabTitleText>{TitleMap[TabIndex.APPLICATION]}</TabTitleText>
                  </React.Fragment>
                }
              >
                {dataLoading ? 
                  <Spinner size="lg" />
                : <div>
                  {applicationRules.length > 0 ?
                    <PatternflyTable
                      columns={appRuleColumns}
                      data={applicationRules}
                      page={applicationPage}
                    />
                  : <div>No Application Rules configured!</div>}
                </div>}
              </Tab>

              <Tab
                eventKey={TabIndex.DNS}
                title={
                  <React.Fragment>
                    <TabTitleIcon>
                      <WrenchIcon />
                    </TabTitleIcon>{' '}
                    <TabTitleText>{TitleMap[TabIndex.DNS]}</TabTitleText>
                  </React.Fragment>
                }
              >
                {dataLoading ? 
                  <Spinner size="lg" />
                : <div>
                  {aclProfile.dnsRuleUrls.length > 0 ?
                    <TextArea
                      rows="5"
                      id={"dns-urls"}
                      name={"dns-urls"}
                      autoComplete="new-password"
                      value={aclProfile.dnsRuleUrls}
                      aria-label="DNS URLs"
                    />
                  : <div>No DNS Rules configured!</div>}
                </div>}
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  );
}
