import { Action } from 'redux';
import UserSession from '../common/user-session';

export const createReducer = <State, ActionHandlers>(initialState: State, actionHandlers: ActionHandlers) => {
  return (state = initialState, action: Action): State =>
    actionHandlers[action.type] 
      ? actionHandlers[action.type](state, action) 
      : state;
};

export const normalizeError = (e: Error) => (e && e.message ? e.message : e);

export const isTimeToRefresh = (prevTime, diff) => {
  return  Date.now() - prevTime > diff;
}

export const tokenIsValid = (expires) => {
  const currentTime = new Date();
  const expireTime = new Date(expires as number);

  if (currentTime < expireTime) {
    return true;
  }

  return false;
}