import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import UserSession from '@app/common/user-session';
import License from '@app/api/license/license.actions';
import { DEFAULT_UUID } from '@app/lib/constants';
import { Spinner } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import { truncateString } from '@app/utils/utils';
import { sortBy, formatDate } from '@app/lib/functions';
import { PageSection, Title, Button, Modal, ModalVariant } from "@patternfly/react-core";
import { BullhornIcon } from '@patternfly/react-icons';
import '../../app.css';

export const LicenseList: React.FunctionComponent = ({ props }) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [licenses, setLicenses] = React.useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isErrorOpen, setIsErrorOpen] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [deleteError, setDeleteError] = React.useState("");

  React.useEffect(() => {
    (async function() {
      try {
        const partnerId = UserSession.getPartnerId();
        const productList = await License.getProducts(partnerId);
        const licenseList = await License.getAll(partnerId);
        let licenseRows = [];

        if (licenseList) {
          licenseRows = licenseList.map(item => {
            return licenseFromItem(licenseList, item, productList);
          });
        }

        setLicenses(licenseRows);
        setLoading(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const getColumns = () => {
    return [
      { title: 'ID' },
      { title: 'Type', transforms: [sortable] },
      { title: 'Created', transforms: [sortable] },
      { title: 'Activated' }, 
      { title: 'Assigned To', transforms: [sortable] },
      { title: 'Expires', transforms: [sortable, cellWidth(10)] },
    ];
  }

  const getColumnIndex = (columnName) => {
    const columns = getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  const handleErrorToggle = () => {
    setIsErrorOpen(!isErrorOpen);
  };

  const licenseFromItem = (licenses, item, products) => {
    const assignedTo = item.assigned_to !== DEFAULT_UUID ? <Link to={{ pathname: "/site/" + item.assigned_to }}>{item.assigned_to}</Link> : '';
    const product = products.find(product => item.type === product.id);

    return {
      cells: [
        { title: <Link to={{ pathname: "/license/" + item.id }}>{item.id}</Link> },
        { title: product.name },
        { title: formatDate(item.created, true) },
        { title: formatDate(item.activated, true) },
        { title: assignedTo },
        { title: formatDate(item.expires, true) }
      ]
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Licenses</title>
      </Helmet>
      <PageSection>
        <Title headingLevel="h1" size="xl">Licenses</Title><br />

        {loading ? 
          <Spinner size="lg" />
        : licenses.length === 0 ? <div>No Licenses found!</div> :
          <div>
            <PatternflyTable
              columns={getColumns}
              data={licenses}
              page={page}
            />
          </div>
        }

        <Modal
          title="Error"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isErrorOpen}
          onClose={handleErrorToggle}
          actions={[
            <Button key="cancel" variant="primary" onClick={handleErrorToggle}>OK</Button>
          ]}
        >
          {deleteError}
        </Modal>
      </PageSection>
    </React.Fragment>
  );
}
