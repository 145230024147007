import * as React from 'react';

export const SearchContext = React.createContext<{
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}>({
  searchText: '',
  setSearchText: () => {},
});

export const SearchProvider = ({ children }) => {
  const [searchText, setSearchText] = React.useState('');
  return <SearchContext.Provider value={{ searchText, setSearchText }}>{children}</SearchContext.Provider>;
};
