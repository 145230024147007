import React from 'react';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@patternfly/react-icons';

const throughputFromLinks = (links) => {
  var throughput = { primaryDown: 0, primaryUp: 0, standbyDown: 0, standbyUp: 0 }

  if (links && links.length > 0) {
    links.forEach(link => {
      if (!link.isStandby && link.isEnabled) {
        throughput.primaryDown += parseFloat(link.downloadThroughput)
        throughput.primaryUp += parseFloat(link.uploadThroughput)
      }
      if (link.isStandby && link.isEnabled) {
        throughput.standbyDown += parseFloat(link.downloadThroughput)
        throughput.standbyUp += parseFloat(link.uploadThroughput)
      }
    });
  }

  return (
    <table>
      <tbody>
        <tr className="info-section-row" key="throughput-1">
          <td>Primary Link(s):</td>
          <td>{throughput.primaryDown > 0 ? <div>&nbsp;&nbsp;&nbsp;<ArrowCircleDownIcon className="icon-small" />&nbsp;</div> : ''}</td>
          <td>{throughput.primaryDown > 0 ? throughput.primaryDown + ' Mbps' : ''}</td>
          <td>{throughput.primaryUp > 0 ? <div>&nbsp;&nbsp;&nbsp;<ArrowCircleUpIcon className="icon-small" />&nbsp;</div> : ''}</td>
          <td>{throughput.primaryUp > 0 ? throughput.primaryUp + ' Mbps' : ''}</td>
        </tr>
        <tr className="info-section-row" key="throughput-2">
          <td>Standby Link(s):</td>
          <td>{throughput.standbyDown > 0 ? <div>&nbsp;&nbsp;&nbsp;<ArrowCircleDownIcon className="icon-small" />&nbsp;</div> : 'N/A'}</td>
          <td>{throughput.standbyDown > 0 ? throughput.standbyDown + ' Mbps' : ''}</td>
          <td>{throughput.standbyUp > 0 ? <div>&nbsp;&nbsp;&nbsp;<ArrowCircleUpIcon className="icon-small" />&nbsp;</div> : ''}</td>
          <td>{throughput.standbyUp > 0 ? throughput.standbyUp + ' Mbps' : ''}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default throughputFromLinks;