import { all, fork } from 'redux-saga/effects';
import { authSaga } from '../common/auth/auth.saga';
import { sessionSaga } from '../common/session/session.saga';
import { configurationSaga } from '../common/configuration/configuration.saga';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(sessionSaga),
    fork(configurationSaga)
  ]);
};