import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { actionCreators } from '../common/configuration/configuration.reducer';
import { State } from '../store/root-reducer';
import { isUUID } from '@app/lib/validator';

const selectLicenseMatch = createMatchSelector<State, Match>({
  path: '/license/:mode/:id?',
});

export const useLicenseContext = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectLicenseMatch);
  const mode = match?.params.mode;
  const id = isUUID(mode) ? mode : match?.params.id;

  return { id, mode };
};
