const getAllGatewayIds = (wans) => {
  let wanGateways: string[] = [];
  for (var i = 0; i < wans.length; i++) {
    wanGateways.push(wans[i].activeServerId);
    if (wans[i].standbyServerId) {
      wanGateways.push(wans[i].standbyServerId);
    }
  }
  return wanGateways;
}

export default getAllGatewayIds;