import * as React from 'react';
import { MAX_LOGO_SIZE_MB, MAX_FAVICON_SIZE_MB, FAVICON_FILE_TYPES, LOGO_FILE_TYPES } from '@app/lib/constants';
import { textInputRequiredOnly } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { InfoCircleIcon } from '@patternfly/react-icons';
import { Popover, PageSection, Title, Card, CardBody, TextInput, Form } from '@patternfly/react-core';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '@app/common/auth/auth.reducer';
import { State } from '@app/store/root-reducer';
import { PartnerPreferences } from '@app/types';

const FileUpload = React.lazy(() => import('../../components/forms/pf/file-upload.component'));

import './Admin.css';

export const Preferences: React.FC = () => {
  const dispatch = useDispatch();
  const preferences = useSelector((state: State) => state.common.auth.partnerPreferences);

  return (
    <React.Suspense fallback={<React.Fragment />}>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...preferences }}
        onSubmit={(values) => {
          dispatch(actionCreators.preferencesUpdateRequest(values as PartnerPreferences));
        }}
      >
        {({ dirty, handleSubmit, values, setFieldValue }) => {
          return (
            <PageSection>
              <Title headingLevel="h1" size="xl">
                Branding
              </Title>

              <Card isCompact className="top-card">
                <CardBody>
                  <p>Update your branding preference:</p>
                  <br />

                  <Form>
                    <table>
                      <tbody>
                        <tr className="preferences-row">
                          <td className="preferences-label">
                            Page Title&nbsp;&nbsp;
                            <Popover
                              aria-label="Page Title"
                              footerContent=""
                              headerContent={<div>Page Title</div>}
                              bodyContent={<div>The title of the Page/Tab in the browser</div>}
                            >
                              <InfoCircleIcon className="simple-popover" />
                            </Popover>
                          </td>
                          <td>
                            <TextInput
                              isRequired
                              type="text"
                              id="pageTitle"
                              name="pageTitle"
                              validated={textInputRequiredOnly(values.pageTitle)}
                              className="text-input"
                              value={values.pageTitle as string}
                              onChange={(e) => setFieldValue('pageTitle', e)}
                            />
                          </td>
                        </tr>
                        <tr className="preferences-row">
                          <td>
                            Logo File&nbsp;&nbsp;
                            <Popover
                              aria-label="Logo Image Info"
                              footerContent=""
                              headerContent={<div>Logo Image</div>}
                              bodyContent={
                                <div>
                                  The main logo image. Large images can produce undesired effects. Use a 100px(width) by
                                  24px(height) image, or as close to these dimensions as possible
                                </div>
                              }
                            >
                              <InfoCircleIcon className="simple-popover" />
                            </Popover>
                          </td>
                          <td>
                            <FileUpload
                              id="logo"
                              name="logo"
                              label="logo"
                              file="logoFile"
                              filename="logoFilename"
                              helperTextInvalid={`Logo must be of file type ${LOGO_FILE_TYPES} and a max of 5 MB in size`}
                              acceptFileTypes={LOGO_FILE_TYPES}
                              maxFileSize={MAX_LOGO_SIZE_MB}
                              dispatch={dispatch}
                            />
                          </td>
                        </tr>
                        <tr className="preferences-row">
                          <td>
                            Favicon File&nbsp;&nbsp;
                            <Popover
                              aria-label="Favicon Image Info"
                              footerContent=""
                              headerContent={<div>Favicon Image</div>}
                              bodyContent={<div>Icon that will appear beside the webpage title in the browser tab</div>}
                            >
                              <InfoCircleIcon className="simple-popover" />
                            </Popover>
                          </td>
                          <td>
                            <FileUpload
                              id="favicon"
                              name="favicon"
                              label="favicon"
                              file="faviconFile"
                              filename="faviconFilename"
                              helperTextInvalid={`Favicon must be of type ${FAVICON_FILE_TYPES} and a max of 2 MB in size`}
                              acceptFileTypes={FAVICON_FILE_TYPES}
                              maxFileSize={MAX_FAVICON_SIZE_MB}
                            />
                          </td>
                        </tr>
                        <tr className="preferences-row">
                          <td>
                            Header Colour&nbsp;&nbsp;
                            <Popover
                              aria-label="Header Colour"
                              footerContent=""
                              headerContent={<div>Header Colour</div>}
                              bodyContent={<div>Changes the colour of the top webpage header</div>}
                            >
                              <InfoCircleIcon className="simple-popover" />
                            </Popover>
                          </td>
                          <td>
                            <Field type="color" id="headerColor" name="headerColor" className="color-picker" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Form>
                </CardBody>
              </Card>
              <br />

              <Saver submitButtonText="Save" submitButtonisDisabled={!dirty} submit={handleSubmit} showCancel={false} />
            </PageSection>
          );
        }}
      </Formik>
    </React.Suspense>
  );
};
