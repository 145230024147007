import * as React from 'react';
import Partner from '@app/api/partner/partner.actions';
import { Saver } from '@app/components/Saver';
import { PageSection, Title, Button, Card, CardTitle, CardBody, CardFooter, Checkbox, Chip, ChipGroup, TextInput, Form, FormGroup } from '@patternfly/react-core';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      carrier: "",  
      carriers: [],
      message: "",
      messageType: "",
      linkTypes: [
        { name: 'Fiber', checked: false },
        { name: 'Cable', checked: false },
        { name: 'LTE', checked: false },
        { name: 'Satellite', checked: false },
        { name: 'Line-of-Sight', checked: false },
        { name: 'DSL', checked: false },
        { name: 'xDSL', checked: false },
        { name: '3G', checked: false },
        { name: '4G', checked: false },
        { name: '5G', checked: false },
        { name: 'Other', checked: false }
      ],
    }
  }

  componentDidMount = async () => {
    this.loadCarriers();
    this.loadLinkTypes();
  }

  loadCarriers = async () => {
    try {
      let result = await Partner.getCarriers();
      const carriers = result.map(carrier => carrier.name);
      this.setState(() => ({
        carriers: carriers
      }));
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the carriers"
      }));
    }
  }

  loadLinkTypes = async () => {
    try {
      let result = await Partner.getLinkTypes();
      const linkTypeNames = new Set(result.map(linkType => linkType.name));
      const { linkTypes } = this.state;
      const allLinkTypes = linkTypes.map(linkType => {
        if (linkTypeNames.has(linkType.name)) {
          return { ...linkType, checked: true }
        }
        else {
          return linkType
        }
      });
      this.setState(() => ({
        linkTypes: allLinkTypes
      }));
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the link types"
      }));
    }
  }

  handleLinkTypeChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const { linkTypes } = this.state;
    const updatedLinkTypes = linkTypes.map(linkType => 
      linkType.name === name ? {...linkType, checked: value} : linkType
    );
    this.setState(() => ({ 
      linkTypes: updatedLinkTypes 
    }));
  };

  deleteCarrier = (carrier) => {
    const { carriers } = this.state;
    const index = carriers.indexOf(carrier);
    if (index !== -1) {
      carriers.splice(index, 1);
      this.setState(() => ({ 
        carriers: carriers 
      }));
    }
  }

  addCarrier = () => {
    const { carrier, carriers } = this.state;
    if (carrier.length > 0) {
      carriers.push(carrier);
      this.setState(() => ({
        carrier: "",  
        carriers: carriers 
      }));
    }
  }

  handleCarrier = (value) => {
    this.setState(() => ({ 
      carrier: value 
    }));
  }

  handleAddCarrier = (event) => {
    if (event.charCode == 13) {
      this.addCarrier();
    }
  }

  handleSubmit = async (event) => {
    try {
      const checkedLinkTypes = this.state.linkTypes.filter(linkType => linkType.checked === true).map(linkType => linkType.name);
      const result = await Partner.updateSettings(this.state.carriers, checkedLinkTypes);

      this.setState(() => ({
        message: "Settings updated successfully!"
      }));
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error updating the settings, or you are not permitted to perform this action"
      }));
    }
  }

  render() {
    const { linkTypes, carriers, carrier } = this.state;
    const linkTypesComponent = linkTypes.map((linkType, index) => (
      <Checkbox 
        label={linkType.name} 
        name={linkType.name} 
        aria-label={linkType.name} 
        id={`link-type-${index}`} 
        key={`link-type-${index}`} 
        isChecked={linkType.checked} 
        onChange={(checked, event) => this.handleLinkTypeChange(checked, event)}
      />
    ));
    const carrierComponent = carriers.map(carrier => (
      <Chip key={carrier} onClick={() => this.deleteCarrier(carrier)}>
        {carrier}
      </Chip>
    ));

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">Settings</Title>

        <Card isCompact className="top-card">
          <CardBody>
            Catalogue connectivity types and ISPs in your area.
          </CardBody>
        </Card>
        <br />

        <table width="100%">
          <tbody>
            <tr>
              <td width="35%" className="admin-cards">
                <Card isCompact className="admin-settings-card">
                  <CardTitle>
                    Link Types
                  </CardTitle>
                  <CardBody>
                    {linkTypesComponent}
                  </CardBody>
                </Card>
              </td>
              <td width="3%"></td>
              <td className="admin-cards">
                <Card isCompact className="admin-settings-card">
                  <CardTitle>
                    Carriers In Your Space
                  </CardTitle>
                  <CardBody>
                    <p>Add the ISPs that you will use to deploy last-mile connections. This list will populate a drop-down menu in the site configuration page. We use this information to help you understand your deployment environment and better report on performance.</p>
                    <br />

                    <table>
                      <tbody>
                        <tr>
                          <td width="100%">
                            <TextInput
                              type="text" id="carrier" name="carrier" autoComplete="new-password"
                              value={carrier} onChange={this.handleCarrier} onKeyPress={this.handleAddCarrier}
                            />
                          </td>
                          <td><Button variant="primary" className="button-confirm" onClick={this.addCarrier}>Add</Button></td>
                        </tr>
                      </tbody>
                    </table>

                    <ChipGroup categoryName="Carriers:" numChips="10">
                      {carriers.length > 0 ? carrierComponent : <div>No carriers specified</div>}
                    </ChipGroup>
                  </CardBody>
                </Card>
              </td>
            </tr>
          </tbody>
        </table>

        <Saver 
          submitButtonText="Save" 
          submit={this.handleSubmit} 
          showCancel={false}
          message={this.state.message}
          messageType={this.state.messageType}
        />
      </PageSection>
    );
  }
}

export default Settings;
