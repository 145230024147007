import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import License from '@app/api/license/license.actions';
import { formatDate } from '@app/lib/functions';
import { getResources } from './LicenseHelper';
import UserSession from '@app/common/user-session';
import { useLicenseContext } from '../../contexts/license.context';
import { PencilAltIcon } from '@patternfly/react-icons';
import LicenseIcon from '@app/bgimages/license-key-black.svg';
import { PageSection, Title, Flex, FlexItem, Button, Card, CardBody, Spinner, ToggleGroup, ToggleGroupItem } from "@patternfly/react-core";

export const LicenseView: React.FunctionComponent = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, mode } = useLicenseContext();
  const [license, setLicense] = React.useState({});
  const [dataLoading, setDataLoading] = React.useState(true);

  React.useEffect(() => {
    (async function() {
      loadLicense();
    })();
  }, []);

  const loadLicense = async () => {
    const partnerId = UserSession.getPartnerId();
    const licenseObj = await License.get(partnerId, id);
    const productList = await License.getProducts(partnerId);
    const productType = productList.find(product => product.id === licenseObj.type);
    let assignedTo = 'Unassigned';

    if (licenseObj === undefined) {
      return history.push("/notfound");
    }

    if (licenseObj.assigned_to !== '00000000-0000-0000-0000-000000000000') {
      const resourceList = await getResources();
      const assignedToResource = resourceList.find(resource => resource.id === licenseObj.assigned_to);
      
      if (assignedToResource) {
        assignedTo = <Link to={{ pathname: "/site/" + assignedToResource.id }}>{assignedToResource.address1}</Link>;
      }
    }
  
    setDataLoading(false);
    setLicense({
      id: id,
      type: productType.name,
      description: productType.description,
      assigned_to: assignedTo,
      created: formatDate(licenseObj.created, true),
      activated: licenseObj.activated === '' ? 'Not activated' : formatDate(licenseObj.activated, true),
      expires: licenseObj.expires === '' ? 'Expiration not set' : formatDate(licenseObj.expires, true)
    });
  }

  const editLicense = () => {
    history.push(`/license/edit/${id}`);
  };

  return (    
    <React.Fragment>
      <Helmet>
        <title>{license.id}</title>
      </Helmet>
      <PageSection>

        <Card isCompact>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <td width="12%">
                    <img src={LicenseIcon} width="60%" alt="License information" />
                  </td>
                  <td width="88%">
                    {dataLoading ? 
                      <Spinner size="lg" />
                    : 
                      <div>
                        <Title headingLevel="h1" size="xl">{license.type} License</Title><br />
                        <div className="textarea-input">
                          <p><b>License ID:</b> {license.id}</p><br />
                        </div>
                        <ToggleGroup aria-label="License options">
                          <ToggleGroupItem icon={<PencilAltIcon />} text="Edit" aria-label="Edit License" buttonId="toolbar-edit" onClick={editLicense} />
                        </ToggleGroup>
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>

        <br />

        <Card isCompact>
          <CardBody>
            <Title headingLevel="h1" size="xl">License Details</Title><br />
            {dataLoading ? 
              <Spinner size="lg" />
            : 
              <div>
                <b>License Description: </b>{license.description}<br />
                <b>Assigned To: </b>{license.assigned_to}<br /><br />
                <b>Created: </b>{license.created}<br />
                <b>Activated: </b>{license.activated}<br />
                <b>Expires: </b>{license.expires}<br />
              </div>
            }
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  );
}
