import {
  ALL_LINK_TYPES,
  DELETE_LINK_TYPE,
  CREATE_LINK_TYPE,
  ALL_CARRIERS,
  CREATE_CARRIER,
  DELETE_CARRIER
} from './gql.queries';
import UserSession from '@app/common/user-session';
import axios from 'axios';

import { apolloClient } from '../graphql/client';
import { GQLHelpers } from '../hasura/ts';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

const getPartnerId = () => {
  if (UserSession.getParam('isImpersonating')) {
    return UserSession.getPartnerId();
  }
  else {
    return store.getState().common.auth.session?.partnerId;
  }
};

export default {
  async getLinkTypes() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = getPartnerId();
      const { objects: LinkTypes } = await GQLHelpers.Fragments.LinkType.queryObjects({
        apolloClient,
        options: { variables: { where: { partnerId: { _eq: partnerId } } } },
      });
      return LinkTypes;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getCarriers() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = getPartnerId();
      const { objects: Carriers } = await GQLHelpers.Fragments.Carrier.queryObjects({
        apolloClient,
        options: { variables: { where: { partnerId: { _eq: partnerId } } } },
      });
      return Carriers;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updateSettings(carriers, linkTypes) {
    this.updateCarriers(carriers);
    this.updateLinkTypes(linkTypes);
  },

  async updateCarriers(carrierNames) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      const {
        data: { Carrier: carriers },
      } = await apolloClient.query({
        query: ALL_CARRIERS,
        variables: { partnerId },
      });
      const carrierIds = carriers.map((carrier) => carrier.id);
      const deletePromises = carrierIds.map((carrierId) => {
        return apolloClient.mutate({
          mutation: DELETE_CARRIER,
          variables: { id: carrierId },
        });
      });
      await Promise.all(deletePromises);
  
      carrierNames.forEach(async (carrierName) => {
        let {
          data: {
            insert_Carrier: {
              returning: [carrier],
            },
          },
        } = await apolloClient.mutate({
          mutation: CREATE_CARRIER,
          variables: { input: [{ name: carrierName, partnerId }] },
        });
      });
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updateLinkTypes(linkTypeNames) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = getPartnerId();
      const {
        data: { LinkType: linkTypes },
      } = await apolloClient.query({ query: ALL_LINK_TYPES, variables: { partnerId } });
      const linkTypeIds = linkTypes.map((linkType) => linkType.id);
      const deletePromises = linkTypeIds.map((linkTypeId) => {
        return apolloClient.mutate({
          mutation: DELETE_LINK_TYPE,
          variables: { id: linkTypeId },
        });
      });
      await Promise.all(deletePromises);
  
      linkTypeNames.forEach(async (linkTypeName) => {
        let {
          data: {
            insert_LinkType: {
              returning: [linkType],
            },
          },
        } = await apolloClient.mutate({
          mutation: CREATE_LINK_TYPE,
          variables: { input: [{ name: linkTypeName, partnerId }] },
        });
      });
    }
    else {
      cognitoUser.signOut();
    }
  }
};
