
export const netmaskOptions = () => {
  return [
    { value: '/8' },
    { value: '/9' },
    { value: '/10' },
    { value: '/11' },
    { value: '/12' },
    { value: '/13' },
    { value: '/14' },
    { value: '/15' },
    { value: '/16' },
    { value: '/17' },
    { value: '/18' },
    { value: '/19' },
    { value: '/20' },
    { value: '/21' },
    { value: '/22' },
    { value: '/23' },
    { value: '/24' },
    { value: '/25' },
    { value: '/26' },
    { value: '/27' },
    { value: '/28' },
    { value: '/29' },
    { value: '/30' },
    { value: '/31' },
    { value: '/32' }
  ];
}

export const trafficTypeOptions = () => {
  return [
    { value: 'HTTP' },                // 80
    { value: 'HTTPS' },               // 443
    { value: 'Custom TCP' },          // 0-65535
    { value: 'Custom UDP' },          // 0-65535
    { value: 'Custom ICMP - IPv4' },  // 0-65535
    { value: 'All TCP' },             // 0-65535
    { value: 'All UDP' },             // 0-65535
    { value: 'All ICMP - IPv4' },     // 0-65535
    { value: 'All ICMP - IPv6' },     // 0-65535
    { value: 'All Traffic' },         // 0-65535
    { value: 'SSH' },                 // 22
    { value: 'SMTP' },                // 25
    { value: 'DNS(UDP)' },            // 53
    { value: 'DNS(TCP)' }             // 53
  ];
}

export const ruleActionOptions = () => {
  return [
    { value: 'Accept' },
    { value: 'Drop' }
  ];
}

export const appRuleActionOptions = () => {
  return [
    { value: 'Accept' },
    { value: 'Drop' },
    { value: 'Shape' }
  ];
}

export const qosOptions = () => {
  return [
    { value: 'High' },
    { value: 'Medium' },
    { value: 'Low' }
  ];
}

export const protocolOptions = () => {
  return [
    { value: 'TCP' },
    { value: 'UDP' }
  ];
}

export const directionOptions = () => {
  return [
    { value: 'Upload' },
    { value: 'Download' },
    { value: 'Bidirectional' }
  ];
}

export const wifiChannelOptions = () => {
  // 2.4 Ghz channels (1-14)
  return Array.from({ length: 14 }, (x, i) => i + 1);
}

export const viewerSubmitPaths = () => {
  return [
    '/login',
    '/forgot-password',
    '/auth/login',
    '/auth/forgot-password',
    '/admin?tab=4'
  ]
}