import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Spinner } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { DownloadImage } from '@app/components/DownloadImage';
import { sortable, cellWidth } from '@patternfly/react-table';
import { PageSection, Title, Button, Modal, ModalVariant } from '@patternfly/react-core';
import { BullhornIcon } from '@patternfly/react-icons';
import { useControllerContext } from '@app/contexts/controller.context';
import { useDispatch, useSelector } from 'react-redux';
import { controllerSelectors } from '@app/common/configuration/configuration.selectors';
import { push } from 'connected-react-router';
import { useSearch } from '@app/lib/use-search';
import { Gateway } from '@app/types';
import { ErrorView } from '../error/error';
import { actionCreators } from '@app/common/configuration/configuration.reducer';

export const ControllerList: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isFailed } = useControllerContext();

  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, actionId: '' });

  const controllers = useSearch(
    useSelector(controllerSelectors.selectAll).filter((x) => x.name?.toLowerCase() !== 'serverless')
  );
  const controllerById = useSelector(controllerSelectors.selectEntities);

  const editController = React.useCallback(
    (_, __, data) => {
      const controllerId = data['controller-id']['title'];
      dispatch(push(`/controller/edit/${controllerId}`));
    },
    [dispatch]
  );

  const deleteController = React.useCallback(
    (_, __, data) => {
      const controllerId = data['controller-id']['title'];
      setConfirmOpen({
        isOpen: true,
        actionId: controllerId,
      });
    },
    [setConfirmOpen]
  );

  const deleteRow = React.useCallback(() => {
    dispatch(actionCreators.controllerDeleteRequest(confirmOpen.actionId));
    setConfirmOpen({ isOpen: false, actionId: '' });
  }, [dispatch, confirmOpen]);

  const getColumns = () => {
    return [
      { title: 'Name', transforms: [sortable] },
      { title: 'Location' },
      { title: 'Controller ID' },
      { title: 'Image' },
      { title: 'Actions', transforms: [cellWidth(10)] },
    ];
  };

  const controllerFromItem = (item: Gateway) => {
    return {
      cells: [
        { title: <Link to={{ pathname: '/controller/' + item.id }}>{item.name}</Link> },
        { title: item.region },
        { title: item.id },
        { title: <DownloadImage imageType="controller" imageId={item.id} /> },
      ],
    };
  };

  if (isFailed) {
    return <ErrorView title="Failed to load Controller" message="🤖 Failed to load Controller" />;
  }

  if (isLoading && !isSuccess) {
    return <Spinner size="lg" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Controllers</title>
      </Helmet>
      <PageSection>
        <Title headingLevel="h1" size="xl">
          Controllers
        </Title>
        <br />

        {controllers.length === 0 ? (
          <div>No Controllers found!</div>
        ) : (
          <div>
            <PatternflyTable
              columns={getColumns}
              editRow={editController}
              deleteRow={deleteController}
              data={controllers.map(controllerFromItem)}
              page={1}
            />
          </div>
        )}

        <Modal
          title="Confirm Action"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={confirmOpen.isOpen}
          onClose={() => setConfirmOpen({ isOpen: false, actionId: '' })}
          actions={[
            <Button key="confirm" variant="primary" onClick={deleteRow}>
              Yes
            </Button>,
            <Button key="cancel" variant="link" onClick={() => setConfirmOpen({ isOpen: false, actionId: '' })}>
              Cancel
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this controller?</p>
          <p>
            <b>{confirmOpen.actionId}</b>
          </p>
        </Modal>
      </PageSection>
    </React.Fragment>
  );
};
