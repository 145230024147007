import {
  CREATE_CLIENT_COMPANY,
  UPDATE_CLIENT_COMPANY,
  CREATE_QUICKLINK,
  UPDATE_QUICKLINK,
  DELETE_QUICKLINK,
} from './gql.queries';
import UserSession from '@app/common/user-session';
import { quickLinkFromObj } from '@app/lib/functions';

import { GQLHelpers } from '../hasura/ts';
import { isUUID } from '@app/lib/validator';
import { apolloClient } from '../graphql/client';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll(partnerId: string) {
    const { objects: clientCompanies } = await GQLHelpers.Fragments.ClientCompany.queryObjects({
      apolloClient,
      options: { variables: { where: { partnerId: { _eq: partnerId } } } },
    });
    return clientCompanies;
  },

  async create(params) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      const payload = {
        partnerId: partnerId,
        ...params,
        Quicklinks: { data: [...params.Quicklinks] },
      };
      const {
        data: {
          insert_ClientCompany: {
            returning: [clientCompany],
          },
        },
      } = await apolloClient.mutate({
        mutation: CREATE_CLIENT_COMPANY,
        variables: { input: [payload] },
      });
  
      return clientCompany;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(id, clientCompany) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { id, Quicklinks, ...changes } = clientCompany;

      await apolloClient.mutate({
        mutation: UPDATE_CLIENT_COMPANY,
        variables: { id: id, changes: changes },
      });
  
      let quickLinkPromises: any[] = [];
  
      for (var i = 0; i < clientCompany.Quicklinks.length; i++) {
        let quickLink = clientCompany.Quicklinks[i];
        quickLink.clientCompanyId = id;
  
        // Delete Quicklink
        if (quickLink?.crudState === 'delete') {
          quickLinkPromises.push(
            apolloClient.mutate({
              mutation: DELETE_QUICKLINK,
              variables: { id: quickLink.id },
            })
          );
        }
  
        // Update Quicklink
        else if (isUUID(quickLink?.id)) {
          quickLinkPromises.push(
            apolloClient.mutate({
              mutation: UPDATE_QUICKLINK,
              variables: { id: quickLink.id, changes: quickLink },
            })
          );
        }
  
        // Create Quicklink
        else if (quickLink.id.startsWith('quick-link-')) {
          const newQuicklink = quickLinkFromObj(quickLink);
          quickLinkPromises.push(
            apolloClient.mutate({
              mutation: CREATE_QUICKLINK,
              variables: { input: newQuicklink },
            })
          );
        }
      }
      await Promise.all(quickLinkPromises);
    }
    else {
      cognitoUser.signOut();
    }
  },
};
