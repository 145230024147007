import { State } from "@app/store/root-reducer";

export const selectTokenValid = (state: State) => {
  const session = state.common?.auth?.session;
  const currentTime = new Date();
  const expireTime = new Date(session?.expiration as number);

  if (currentTime < expireTime) {
    return true;
  }

  return false;
}