import { createReducer } from '@app/lib/utils';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { Server, Site, User, Wan, Tunnel, Gateway, ClientCompany, LinkType, Carrier } from '../../types';

export const controllerAdapter = createEntityAdapter<Gateway>();
export const siteAdapter = createEntityAdapter<Site>();
export const tunnelAdapter = createEntityAdapter<Tunnel>();
export const wanAdapter = createEntityAdapter<Wan>();
export const userAdapter = createEntityAdapter<User>();
export const clientCompanyAdapter = createEntityAdapter<ClientCompany>();
export const serverAdapter = createEntityAdapter<Server>();
export const linkTypeAdapter = createEntityAdapter<LinkType>();
export const carrierAdapter = createEntityAdapter<Carrier>();

type Pagination = {
  isFetching: boolean;
  isSuccess: boolean;
  isFailed: boolean;
  lastUpdateTime: number;
};

export type ConfigurationState = {
  controllers: EntityState<Gateway> & Pagination;
  sites: EntityState<Site> & Pagination;
  tunnels: EntityState<Tunnel> & Pagination;
  wans: EntityState<Wan> & Pagination;
  users: EntityState<User> & Pagination;
  clientCompany: EntityState<ClientCompany> & Pagination;
  servers: EntityState<Server> & Pagination;
  linkTypes: EntityState<LinkType> & Pagination;
  carriers: EntityState<Carrier> & Pagination;
};

const initialPagination = {
  isFetching: true,
  isRefreshing: false,
  isSuccess: false,
  isFailed: false,
  lastUpdateTime: 0,
};

export const initialState: ConfigurationState = {
  controllers: {
    ...controllerAdapter.getInitialState(),
    ...initialPagination,
  },
  sites: {
    ...siteAdapter.getInitialState(),
    ...initialPagination,
  },
  tunnels: {
    ...tunnelAdapter.getInitialState(),
    ...initialPagination,
  },
  wans: {
    ...wanAdapter.getInitialState(),
    ...initialPagination,
  },
  users: {
    ...userAdapter.getInitialState(),
    ...initialPagination,
  },
  clientCompany: {
    ...clientCompanyAdapter.getInitialState(),
    ...initialPagination,
  },
  servers: {
    ...serverAdapter.getInitialState(),
    ...initialPagination,
  },
  linkTypes: {
    ...linkTypeAdapter.getInitialState(),
    ...initialPagination,
  },
  carriers: {
    ...carrierAdapter.getInitialState(),
    ...initialPagination,
  },
};

export enum actionTypes {
  SITE_REQUEST = 'CONFIG/SITE_REQUEST',
  SITE_SUCCESS = 'CONFIG/SITE_SUCCESS',
  SITE_FAILURE = 'CONFIG/SITE_FAILURE',
  SITE_SOURCE_CHANGED = 'CONFIG/SITE_SOURCE_CHANGED',
  SITE_REMOVE_SEARCH_RESULT = 'CONFIG/SITE_REMOVE_SEARCH_RESULT',
  SITE_REFRESHING_PAGE = 'CONFIG/SITE_REFRESHING_PAGE',
  SITE_UPDATE_PAGINATION_PARAMS = 'CONFIG/SITE_UPDATE_PAGINATION_PARAMS',
  SITE_UPDATE_SEARCH_PARAMS = 'CONFIG/SITE_UPDATE_SEARCH_PARAMS',
  SITE_SET_CURRENT_PAGE = 'CONFIG/SITE_SET_CURRENT_PAGE',

  SITE_CREATE_REQUEST = 'CONFIG/SITE_CREATE_REQUEST',
  SITE_CREATE_SUCCESS = 'CONFIG/SITE_CREATE_SUCCESS',
  SITE_CREATE_FAILURE = 'CONFIG/SITE_CREATE_FAILURE',

  SITE_PATCH_REQUEST = 'CONFIG/SITE_PATCH_REQUEST',
  SITE_PATCH_SUCCESS = 'CONFIG/SITE_PATCH_SUCCESS',
  SITE_PATCH_FAILURE = 'CONFIG/SITE_PATCH_FAILURE',

  SITE_DELETE_REQUEST = 'CONFIG/SITE_DELETE_REQUEST',
  SITE_DELETE_SUCCESS = 'CONFIG/SITE_DELETE_SUCCESS',
  SITE_DELETE_FAILURE = 'CONFIG/SITE_DELETE_FAILURE',

  WAN_REQUEST = 'CONFIG/WAN_REQUEST',
  WAN_SUCCESS = 'CONFIG/WAN_SUCCESS',
  WAN_FAILURE = 'CONFIG/WAN_FAILURE',

  WAN_CREATE_REQUEST = 'CONFIG/WAN_CREATE_REQUEST',
  WAN_CREATE_SUCCESS = 'CONFIG/WAN_CREATE_SUCCESS',
  WAN_CREATE_FAILURE = 'CONFIG/WAN_CREATE_FAILURE',

  WAN_PATCH_REQUEST = 'CONFIG/WAN_PATCH_REQUEST',
  WAN_PATCH_SUCCESS = 'CONFIG/WAN_PATCH_SUCCESS',
  WAN_PATCH_FAILURE = 'CONFIG/WAN_PATCH_FAILURE',

  CARRIER_CREATE_REQUEST = 'CONFIG/CARRIER_CREATE_REQUEST',
  CARRIER_CREATE_SUCCESS = 'CONFIG/CARRIER_CREATE_SUCCESS',
  CARRIER_CREATE_FAILURE = 'CONFIG/CARRIER_CREATE_FAILURE',

  LINK_UPDATE_REQUEST = 'CONFIG/LINK_UPDATE_REQUEST',
  LINK_UPDATE_SUCCESS = 'CONFIG/LINK_UPDATE_SUCCESS',
  LINK_UPDATE_FAILURE = 'CONFIG/LINK_UPDATE_FAILURE',

  CLIENT_COMPANY_REQUEST = 'CONFIG/CLIENT_COMPANY_REQUEST',
  CLIENT_COMPANY_SUCCESS = 'CONFIG/CLIENT_COMPANY_SUCCESS',
  CLIENT_COMPANY_FAILURE = 'CONFIG/CLIENT_COMPANY_FAILURE',

  CLIENT_COMPANY_CREATE_REQUEST = 'CONFIG/CLIENT_COMPANY_CREATE_REQUEST',
  CLIENT_COMPANY_CREATE_SUCCESS = 'CONFIG/CLIENT_COMPANY_CREATE_SUCCESS',
  CLIENT_COMPANY_CREATE_FAILURE = 'CONFIG/CLIENT_COMPANY_CREATE_FAILURE',

  CLIENT_COMPANY_UPDATE_REQUEST = 'CONFIG/CLIENT_COMPANY_UPDATE_REQUEST',
  CLIENT_COMPANY_UPDATE_SUCCESS = 'CONFIG/CLIENT_COMPANY_UPDATE_SUCCESS',
  CLIENT_COMPANY_UPDATE_FAILURE = 'CONFIG/CLIENT_COMPANY_UPDATE_FAILURE',

  CONTROLLER_REQUEST = 'CONFIG/CONTROLLER_REQUEST',
  CONTROLLER_SUCCESS = 'CONFIG/CONTROLLER_SUCCESS',
  CONTROLLER_FAILURE = 'CONFIG/CONTROLLER_FAILURE',

  CONTROLLER_CREATE_REQUEST = 'CONFIG/CONTROLLER_CREATE_REQUEST',
  CONTROLLER_CREATE_SUCCESS = 'CONFIG/CONTROLLER_CREATE_SUCCESS',
  CONTROLLER_CREATE_FAILURE = 'CONFIG/CONTROLLER_CREATE_FAILURE',

  CONTROLLER_DELETE_REQUEST = 'CONFIG/CONTROLLER_DELETE_REQUEST',
  CONTROLLER_DELETE_SUCCESS = 'CONFIG/CONTROLLER_DELETE_SUCCESS',
  CONTROLLER_DELETE_FAILURE = 'CONFIG/CONTROLLER_DELETE_FAILURE',

  CONTROLLER_PATCH_REQUEST = 'CONFIG/CONTROLLER_PATCH_REQUEST',
  CONTROLLER_PATCH_SUCCESS = 'CONFIG/CONTROLLER_PATCH_SUCCESS',
  CONTROLLER_PATCH_FAILURE = 'CONFIG/CONTROLLER_PATCH_FAILURE',

  SERVER_REQUEST = 'CONFIG/SERVER_REQUEST',
  SERVER_SUCCESS = 'CONFIG/SERVER_SUCCESS',
  SERVER_FAILURE = 'CONFIG/SERVER_FAILURE',

  TUNNEL_REQUEST = 'CONFIG/TUNNEL_REQUEST',
  TUNNEL_SUCCESS = 'CONFIG/TUNNEL_SUCCESS',
  TUNNEL_FAILURE = 'CONFIG/TUNNEL_FAILURE',

  SERVER_DELETE_REQUEST = 'CONFIG/SERVER_DELETE_REQUEST',
  SERVER_DELETE_SUCCESS = 'CONFIG/SERVER_DELETE_SUCCESS',
  SERVER_DELETE_FAILURE = 'CONFIG/SERVER_DELETE_FAILURE',

  SERVER_REBOOT_REQUEST = 'CONFIG/SERVER_REBOOT_REQUEST',
  SERVER_REBOOT_SUCCESS = 'CONFIG/SERVER_REBOOT_SUCCESS',
  SERVER_REBOOT_FAILURE = 'CONFIG/SERVER_REBOOT_FAILURE',

  LINK_TYPE_REQUEST = 'CONFIG/LINK_TYPE_REQUEST',
  LINK_TYPE_SUCCESS = 'CONFIG/LINK_TYPE_SUCCESS',
  LINK_TYPE_FAILURE = 'CONFIG/LINK_TYPE_FAILURE',

  CARRIER_REQUEST = 'CONFIG/CARRIER_REQUEST',
  CARRIER_SUCCESS = 'CONFIG/CARRIER_SUCCESS',
  CARRIER_FAILURE = 'CONFIG/CARRIER_FAILURE',

  WAN_DELETE_REQUEST = 'CONFIG/WAN_DELETE_REQUEST',
  WAN_DELETE_SUCCESS = 'CONFIG/WAN_DELETE_SUCCESS',
  WAN_DELETE_FAILURE = 'CONFIG/WAN_DELETE_FAILURE',

  SITE_REBOOT_REQUEST = 'CONFIG/SITE_REBOOT_REQUEST',
  SITE_REBOOT_SUCCESS = 'CONFIG/SITE_REBOOT_SUCCESS',
  SITE_REBOOT_FAILURE = 'CONFIG/SITE_REBOOT_FAILURE',
}

export const actionCreators = {
  siteRequest: () => ({
    type: actionTypes.SITE_REQUEST,
  }),
  siteSuccess: (sites: Site[]) => ({
    type: actionTypes.SITE_SUCCESS,
    payload: { sites },
  }),
  siteFailure: (error) => ({
    type: actionTypes.SITE_FAILURE,
    payload: { error },
  }),
  siteCreateRequest: (formData: any) => ({
    type: actionTypes.SITE_CREATE_REQUEST,
    payload: { formData },
  }),
  sitePatchRequest: (formData: any) => ({
    type: actionTypes.SITE_PATCH_REQUEST,
    paylaod: { formData },
  }),
  sitePatchSuccess: () => ({
    type: actionTypes.SITE_PATCH_SUCCESS,
  }),
  sitePatchFailure: () => ({
    type: actionTypes.SITE_PATCH_FAILURE,
  }),
  siteDeleteRequest: (siteId: string) => ({
    type: actionTypes.SITE_DELETE_REQUEST,
    payload: { siteId },
  }),
  siteDeleteSuccess: (siteId: string) => ({
    type: actionTypes.SITE_DELETE_SUCCESS,
    payload: { siteId },
  }),
  siteDeleteFailure: (error: any) => ({
    type: actionTypes.SITE_DELETE_FAILURE,
    payload: { error },
  }),
  wanRequest: () => ({
    type: actionTypes.WAN_REQUEST,
  }),
  wanSuccess: (wans: Wan[]) => ({
    type: actionTypes.WAN_SUCCESS,
    payload: { wans },
  }),
  wanFailure: (error: any) => ({
    type: actionTypes.WAN_FAILURE,
    payload: { error },
  }),
  wanDeleteRequest: (wanId: string) => ({
    type: actionTypes.WAN_DELETE_REQUEST,
    payload: { wanId },
  }),
  wanDeleteSuccess: (wanId: string) => ({
    type: actionTypes.WAN_DELETE_SUCCESS,
    payload: { wanId },
  }),
  wanDeleteFailure: (error: any) => ({
    type: actionTypes.WAN_DELETE_FAILURE,
    payload: { error },
  }),
  controllerRequest: () => ({
    type: actionTypes.CONTROLLER_REQUEST,
  }),
  controllerSuccess: (gateways: Gateway[]) => ({
    type: actionTypes.CONTROLLER_SUCCESS,
    payload: { gateways },
  }),
  controllerFailure: (error: any) => ({
    type: actionTypes.CONTROLLER_FAILURE,
    payload: { error },
  }),
  controllerCreateRequest: (values) => ({
    type: actionTypes.CONTROLLER_CREATE_REQUEST,
    payload: { values },
  }),
  controllerCreateSuccess: (controller: Gateway) => ({
    type: actionTypes.CONTROLLER_CREATE_SUCCESS,
    payload: { controller },
  }),
  controllerCreateFailure: (error) => ({
    type: actionTypes.CONTROLLER_CREATE_FAILURE,
    payload: { error },
  }),
  controllerPatchRequest: (values) => ({
    type: actionTypes.CONTROLLER_PATCH_REQUEST,
    payload: { values },
  }),
  controllerPatchSuccess: () => ({
    type: actionTypes.CONTROLLER_PATCH_SUCCESS,
  }),
  controllerPatchFailure: (error: any) => ({
    type: actionTypes.CONTROLLER_PATCH_FAILURE,
    payload: { error },
  }),
  controllerDeleteRequest: (controllerId: string) => ({
    type: actionTypes.CONTROLLER_DELETE_REQUEST,
    payload: { controllerId },
  }),
  controllerDeleteSuccess: (controllerId: string) => ({
    type: actionTypes.CONTROLLER_DELETE_SUCCESS,
    payload: { controllerId },
  }),
  controllerDeleteFailure: (error: any) => ({
    type: actionTypes.CONTROLLER_DELETE_FAILURE,
    payload: { error },
  }),
  clientCompanyRequest: () => ({
    type: actionTypes.CLIENT_COMPANY_REQUEST,
  }),
  clientCompanySuccess: (clientCompanies: ClientCompany[]) => ({
    type: actionTypes.CLIENT_COMPANY_SUCCESS,
    payload: { clientCompanies },
  }),
  clientCompanyFailure: (error: any) => ({
    type: actionTypes.CLIENT_COMPANY_FAILURE,
    payload: { error },
  }),
  serverRequest: () => ({
    type: actionTypes.SERVER_REQUEST,
  }),
  serverSuccess: (servers: Server[]) => ({
    type: actionTypes.SERVER_SUCCESS,
    payload: { servers },
  }),
  serverFailure: (error: any) => ({
    type: actionTypes.SERVER_FAILURE,
    payload: { error },
  }),
  tunnelRequest: () => ({
    type: actionTypes.TUNNEL_REQUEST,
  }),
  tunnelSuccess: (tunnels: Tunnel[]) => ({
    type: actionTypes.TUNNEL_SUCCESS,
    payload: { tunnels },
  }),
  tunnelFailure: (error: any) => ({
    type: actionTypes.TUNNEL_FAILURE,
    payload: { error },
  }),
  serverDeleteRequest: (serverId: string) => ({
    type: actionTypes.SERVER_DELETE_REQUEST,
    payload: { serverId },
  }),
  serverDeleteSuccess: (serverId: string) => ({
    type: actionTypes.SERVER_DELETE_SUCCESS,
    payload: { serverId },
  }),
  serverDeleteFailure: (error: any) => ({
    type: actionTypes.SERVER_DELETE_FAILURE,
    payload: { error },
  }),
  linkTypeRequest: () => ({
    type: actionTypes.LINK_TYPE_REQUEST,
  }),
  linkTypeSuccess: (linkTypes: LinkType[]) => ({
    type: actionTypes.LINK_TYPE_SUCCESS,
    payload: { linkTypes },
  }),
  linkTypeFailure: (error: any) => ({
    type: actionTypes.LINK_TYPE_FAILURE,
    payload: { error },
  }),
  carrierRequest: () => ({
    type: actionTypes.CARRIER_REQUEST,
  }),
  carrierSuccess: (carriers: Carrier[]) => ({
    type: actionTypes.CARRIER_SUCCESS,
    payload: { carriers },
  }),
  carrierFailure: (error: any) => ({
    type: actionTypes.CARRIER_FAILURE,
    payload: { error },
  }),
  serverRebootRequest: (serverIds: string[]) => ({
    type: actionTypes.SERVER_REBOOT_REQUEST,
    payload: { serverIds },
  }),
  serverRebootSuccess: (serverIds: string[]) => ({
    type: actionTypes.SERVER_REBOOT_SUCCESS,
    payload: { serverIds },
  }),
  serverRebootFailure: (errors: any[]) => ({
    type: actionTypes.SERVER_REBOOT_FAILURE,
    payload: { errors },
  }),
  siteRebootRequest: (siteId: string | undefined) => ({
    type: actionTypes.SITE_REBOOT_REQUEST,
    payload: { siteId },
  }),
  siteRebootSuccess: () => ({
    type: actionTypes.SITE_REBOOT_SUCCESS,
  }), 
  siteRebootFailure: (error: any) => ({
    type: actionTypes.SITE_REBOOT_FAILURE,
    payload: { error },
  })
};

export const actionHandlers = {
  [actionTypes.SITE_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    sites: {
      ...state.sites,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.SITE_SUCCESS]: (
    state: ConfigurationState,
    { payload: { sites } }: ReturnType<typeof actionCreators.siteSuccess>
  ): ConfigurationState => ({
    ...state,
    sites: {
      ...siteAdapter.setAll(state.sites, sites),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.CONTROLLER_PATCH_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    controllers: {
      ...state.controllers,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.CONTROLLER_PATCH_SUCCESS]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    controllers: {
      ...state.controllers,
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.SITE_DELETE_SUCCESS]: (
    state: ConfigurationState,
    { payload: { siteId } }: ReturnType<typeof actionCreators.siteDeleteSuccess>
  ): ConfigurationState => ({
    ...state,
    sites: {
      ...siteAdapter.removeOne(state.sites, siteId),
    },
  }),
  [actionTypes.SITE_DELETE_FAILURE]: (
    state: ConfigurationState,
    { payload: { error } }: ReturnType<typeof actionCreators.siteDeleteFailure>
  ): ConfigurationState => ({
    ...state,
  }),
  [actionTypes.WAN_REQUEST]: (state: ConfigurationState) => ({
    ...state,
    wans: {
      ...state.wans,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),

  [actionTypes.WAN_SUCCESS]: (
    state: ConfigurationState,
    { payload: { wans } }: ReturnType<typeof actionCreators.wanSuccess>
  ): ConfigurationState => ({
    ...state,
    wans: {
      ...wanAdapter.setAll(state.wans, wans),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.WAN_FAILURE]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    wans: {
      ...wanAdapter.getInitialState(),
      isFetching: false,
      isSuccess: false,
      isFailed: true,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.CONTROLLER_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    controllers: {
      ...state.controllers,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.CONTROLLER_SUCCESS]: (
    state: ConfigurationState,
    { payload: { gateways } }: ReturnType<typeof actionCreators.controllerSuccess>
  ): ConfigurationState => ({
    ...state,
    controllers: {
      ...controllerAdapter.setAll(state.controllers, gateways),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.CONTROLLER_FAILURE]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    controllers: {
      ...controllerAdapter.getInitialState(),
      isFetching: false,
      isSuccess: false,
      isFailed: true,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.CLIENT_COMPANY_SUCCESS]: (
    state: ConfigurationState,
    { payload: { clientCompanies } }: ReturnType<typeof actionCreators.clientCompanySuccess>
  ): ConfigurationState => ({
    ...state,
    clientCompany: {
      ...clientCompanyAdapter.setAll(state.clientCompany, clientCompanies),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.SERVER_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    servers: {
      ...state.servers,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.SERVER_SUCCESS]: (
    state: ConfigurationState,
    { payload: { servers } }: ReturnType<typeof actionCreators.serverSuccess>
  ): ConfigurationState => ({
    ...state,
    servers: {
      ...serverAdapter.setAll(state.servers, servers),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.SERVER_FAILURE]: (
    state: ConfigurationState,
    { payload: { error } }: ReturnType<typeof actionCreators.serverFailure>
  ): ConfigurationState => ({
    ...state,
    servers: {
      ...serverAdapter.getInitialState(),
      isFetching: false,
      isSuccess: false,
      isFailed: true,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.TUNNEL_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    tunnels: {
      ...state.tunnels,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.TUNNEL_SUCCESS]: (
    state: ConfigurationState,
    { payload: { tunnels } }: ReturnType<typeof actionCreators.tunnelSuccess>
  ): ConfigurationState => ({
    ...state,
    tunnels: {
      ...tunnelAdapter.setAll(state.tunnels, tunnels),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.TUNNEL_FAILURE]: (
    state: ConfigurationState,
    { payload: { error } }: ReturnType<typeof actionCreators.tunnelFailure>
  ): ConfigurationState => ({
    ...state,
    tunnels: {
      ...tunnelAdapter.getInitialState(),
      isFetching: false,
      isSuccess: false,
      isFailed: true,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.LINK_TYPE_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    linkTypes: {
      ...state.linkTypes,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.LINK_TYPE_SUCCESS]: (
    state: ConfigurationState,
    { payload: { linkTypes } }: ReturnType<typeof actionCreators.linkTypeSuccess>
  ): ConfigurationState => ({
    ...state,
    linkTypes: {
      ...linkTypeAdapter.setAll(state.linkTypes, linkTypes),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.LINK_TYPE_FAILURE]: (
    state: ConfigurationState,
    { payload: { error } }: ReturnType<typeof actionCreators.linkTypeFailure>
  ): ConfigurationState => ({
    ...state,
    linkTypes: {
      ...linkTypeAdapter.getInitialState(),
      isFetching: false,
      isSuccess: false,
      isFailed: true,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.CARRIER_REQUEST]: (state: ConfigurationState): ConfigurationState => ({
    ...state,
    carriers: {
      ...state.carriers,
      isFetching: true,
      isSuccess: false,
      isFailed: false,
    },
  }),
  [actionTypes.CARRIER_SUCCESS]: (
    state: ConfigurationState,
    { payload: { carriers } }: ReturnType<typeof actionCreators.carrierSuccess>
  ): ConfigurationState => ({
    ...state,
    carriers: {
      ...carrierAdapter.setAll(state.carriers, carriers),
      isFetching: false,
      isSuccess: true,
      isFailed: false,
      lastUpdateTime: Date.now(),
    },
  }),
  [actionTypes.WAN_DELETE_SUCCESS]: (
    state: ConfigurationState,
    { payload: { wanId } }: ReturnType<typeof actionCreators.wanDeleteRequest>
  ): ConfigurationState => ({
    ...state,
    wans: {
      ...wanAdapter.removeOne(state.wans, wanId),
    },
  }),
  [actionTypes.CONTROLLER_CREATE_SUCCESS]: (
    state: ConfigurationState,
    { payload: { controller } }: ReturnType<typeof actionCreators.controllerCreateSuccess>
  ): ConfigurationState => ({
    ...state,
    controllers: {
      ...controllerAdapter.addOne(state.controllers, controller),
    },
  }),
  [actionTypes.CONTROLLER_DELETE_SUCCESS]: (
    state: ConfigurationState,
    { payload: { controllerId } }: ReturnType<typeof actionCreators.controllerDeleteSuccess>
  ): ConfigurationState => ({
    ...state,
    controllers: {
      ...controllerAdapter.removeOne(state.controllers, controllerId),
    },
  }),
};

export const configurationReducer = createReducer<ConfigurationState, typeof actionHandlers>(
  initialState,
  actionHandlers
);
