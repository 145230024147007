import dp from 'dot-prop-immutable';
import { createReducer, normalizeError } from '@app/lib/utils';

export enum NetworkStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  UNKNOWN = 'unknown',
}

export type SessionState = {
  networkStatus: NetworkStatus;
  error?: any;
  notifications: any[];
};

export const initialState: SessionState = {
  networkStatus: NetworkStatus.UNKNOWN,
  notifications: [],
};

export enum actionTypes {
  APP_INIT_RESET = 'SESSION/APP_INIT_RESET',
  FOCUS_CHANGED = 'SESSION/FOCUS_CHANGED',
  NETWORK_STATUS_CHANGED = 'SESSION/NETWORK_STATUS_CHANGED',
  PREFERENCES_LOAD_REQUEST = 'SESSION/PREFERENCES_LOAD_REQUEST',
  PREFERENCES_LOAD_SUCCESS = 'SESSION/PREFERENCES_LOAD_SUCCESS',
  PREFERENCES_LOAD_FAILURE = 'SESSION/PREFERENCES_LOAD_FAILURE',
  PREFERENCES_PATCH_REQUEST = 'SESSION/PREFERENCES_PATCH_REQUEST',
  PREFERENCES_PATCH_SUCCESS = 'SESSION/PREFERENCES_PATCH_SUCCESS',
  PREFERENCES_PATCH_FAILURE = 'SESSION/PREFERENCES_PATCH_FAILURE',
  NOTIFICATION_ENQUEUE = 'SESSION/NOTIFICATION_ENQUEUE',
  NOTIFICATION_CLOSE = 'SESSION/NOTIFICATION_CLOSE',
  NOTIFICATION_REMOVE = 'SESSION/NOTIFICATION_REMOVE',
}

export const actionCreators = {

  appInitializeReset: () => ({ type: actionTypes.APP_INIT_RESET }),

  focusChanged: () => ({ type: actionTypes.FOCUS_CHANGED }),

  networkStatusChanged: (event: NetworkStatus) => ({ type: actionTypes.NETWORK_STATUS_CHANGED, payload: { event } }),

  preferencesPatchRequest: () => ({
    type: actionTypes.PREFERENCES_PATCH_REQUEST,
  }),

  preferencesPatchSuccess: () => ({
    type: actionTypes.PREFERENCES_PATCH_SUCCESS,
  }),

  preferencesPatchFailure: () => ({
    type: actionTypes.PREFERENCES_PATCH_FAILURE,
  }),
  notificationEnqueue: (notification) => ({
    type: actionTypes.NOTIFICATION_ENQUEUE,
    payload: {
      notification,
      key: notification.options?.key || new Date().getTime() + Math.random(),
    },
  }),
  notificationRemove: (key) => ({
    type: actionTypes.NOTIFICATION_REMOVE,
    payload: { key },
  }),
  notificationClose: (key?: string) => ({
    type: actionTypes.NOTIFICATION_CLOSE,
    payload: { key, dismissAll: !key },
  }),
};

export const actionHandlers = {
  [actionTypes.APP_INIT_RESET]: () => ({
    ...initialState,
  }),

  [actionTypes.NETWORK_STATUS_CHANGED]: (
    state: SessionState,
    { payload: { event } }: ReturnType<typeof actionCreators.networkStatusChanged>
  ): SessionState => dp.set<SessionState, SessionState>(state, 'networkStatus', event),

  [actionTypes.NOTIFICATION_ENQUEUE]: (
    state: SessionState,
    { payload: { key, notification } }: ReturnType<typeof actionCreators.notificationEnqueue>
  ) => ({
    ...state,
    notifications: [
      ...state.notifications,
      {
        key,
        ...notification,
      },
    ],
  }),
  [actionTypes.NOTIFICATION_REMOVE]: (
    state: SessionState,
    { payload: { key } }: ReturnType<typeof actionCreators.notificationRemove>
  ) => ({
    ...state,
    notifications: [...state.notifications.filter((notification) => notification.key !== key)],
  }),
  [actionTypes.NOTIFICATION_CLOSE]: (
    state: SessionState,
    { payload: { key, dismissAll } }: ReturnType<typeof actionCreators.notificationClose>
  ) => ({
    ...state,
    notifications: state.notifications.map((notification) =>
      dismissAll || notification.key === key ? { ...notification, dismissed: true } : { ...notification }
    ),
  }),
};

export const sessionReducer = createReducer<SessionState, typeof actionHandlers>(initialState, actionHandlers);
