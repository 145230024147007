
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoRefreshToken,
  IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js';
import UserSession from '@app/common/user-session';

const poolId = process.env.REACT_APP_USER_POOL_ID!;
const clientId = process.env.REACT_APP_POOL_WEB_CLI_ID!;

const cognitoUserPool = new CognitoUserPool({ UserPoolId: poolId, ClientId: clientId });

export let cognitoUser: CognitoUser;

export const createCognitoUser = (username) => {
  cognitoUser = new CognitoUser({ Username: username, Pool: cognitoUserPool });
  return cognitoUser;
};

export const authenticateUser = (username: string, password: string) => {
  const authenticationData: IAuthenticationDetailsData = {
    Username: username,
    Password: password,
  };
  
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  cognitoUser = new CognitoUser({ Username: username, Pool: cognitoUserPool });

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
      newPasswordRequired: function (_, __) {
        UserSession.setParams({
          userName: username,
          tempPass: password,
        });
        reject({
          code: 'PasswordResetRequiredException',
          message: 'New Password Required',
          newPasswordRequired: true,
        });
      },
    });
  });
};

export const completeNewPassword = (username, newpassword, data = null) => {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newpassword, data, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export const changePassword = (username, code, password) => {
  const userData = {
    Username: username,
    Pool: cognitoUserPool,
  }

  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => resolve(true),
      onFailure: reject,
    });
  });

};

export const signOut = (): Promise<void> => {
  if (cognitoUser) {
    cognitoUser.signOut();
  }

  UserSession.clear();
  
  return Promise.resolve();
};

export const forgotPassword = (username) => {
  const userData = {
    Username: username,
    Pool: cognitoUserPool,
  };

  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    });
  });

};

export const refreshSession = (token) => {
  const refreshToken = new CognitoRefreshToken({ RefreshToken: token })

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.refreshSession(refreshToken, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}
