import * as React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { AppDispatch } from '@app/store';
import PartnerApi from '@app/api/partner-api/partner.actions';
import { Spacer } from '@app/components/Spacer';
import ApiGateway from '@app/api/api-gateway/api-gateway';
import { getCountryOptions, getRegionOptions } from '@app/common/world';
import { getParamFromQueryString } from '@app/lib/functions';
import { getNamesFromFullname } from './PartnerHelper';
import { textInputRequiredOnly, textInputValidate, isValidPhone, isValidEmail, isValidNumber, isLengthWithinRange } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { Flex, FlexItem, PageSection, Title, Card, CardBody, TextInput, Form, FormGroup, Select, SelectVariant } from '@patternfly/react-core';
import './Partner.css';

export const PartnerForm = connect()(class extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const id = this.props.computedMatch?.params?.id;
    const countries = getCountryOptions();

    this.state = {
      id: id,
      name: '',
      address_1: '',
      address_2: '',
      city: '',
      country: '',
      postal_code: '',
      state: '',
      contact_email: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_phone: '',
      contact_phone_ext: '',
      countries: countries,
      regions: [],
      isCountryOpen: false,
      isRegionOpen: false,
      error: '',
      message: '',
      messageType: '',
      isLoading:  false,
      mode: id ? "edit" : "new"
    }
  }

  componentDidMount = () => {
    try {
      this.loadPartner();
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the partner profile"
      }));
    }
  }

  loadPartner = async () => {
    const { mode } = this.state;

    if (mode === 'edit') {
      const partner = await PartnerApi.get(id);
      const names = getNamesFromFullname(partner.billing_contact);

      this.setState(() => ({
        name: partner.name,
        address_1: partner.billing_address1,
        address_2: partner.billing_address2,
        city: partner.billing_city,
        country: partner.billing_country,
        postal_code: partner.billing_postal_code,
        state: partner.billing_state,
        contact_email: partner.billing_email,
        contact_first_name: names.first_name,
        contact_last_name: names.last_name,
        contact_phone: partner.billing_phone,
        contact_phone_ext: partner.billing_ext,
        regions: partner.billing_country ? getRegionOptions(partner.billing_country) : [],
      }));  
    }  
  }

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  onCountryToggle = (isOpen) => {
    this.setState(() => ({
      isCountryOpen: isOpen,
    }));
  };

  onRegionToggle = (isOpen) => {
    this.setState(() => ({
      isRegionOpen: isOpen,
    }));
  };

  onCountrySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearCountry();
    } 
    else {
      const regions = getRegionOptions(selection);
      this.setState(() => ({
        country: selection,
        regions: regions,
        state: null,
        isCountryOpen: false,
      }));
    }
  };

  onRegionSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearRegion();
    } 
    else {
      this.setState(() => ({
        state: selection,
        isRegionOpen: false,
      }));
    }
  };

  clearCountry = () => {
    this.setState({
      country: null,
      isCountryOpen: false,
    });
  };

  clearRegion = () => {
    this.setState({
      state: null,
      isRegionOpen: false,
    });
  };

  handleCancel = () => {
    const { id } = this.state;

    if (id) {
      this.props.history.push(`/partners?id=${id}`);
    }
    else {
      this.props.history.push('/partners');
    }
  }

  partnerFromState = () => {
    const { name, address_1, address_2, city, country, state, postal_code, contact_email, contact_phone, contact_first_name, contact_last_name } = this.state;

    let partner = {
      name: name,
      address: {
        line1: address_1,
        line2: address_2,
        city: city,
        country: country,
        postal_code: postal_code,
        state: state
      },
      contact_info: {
        email: contact_email,
        first_name: contact_first_name,
        last_name: contact_last_name,
        phone: contact_phone
      }
    }

    return partner;
  }  

  updatePartner = async () => {
    const payload = this.partnerFromState();
    const { id } = this.state;
    let result;

    try {
      result = await axios.patch(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + `/partners/${id}`,
        payload, 
        { headers: ApiGateway.getHeaders() }
      );
      this.props.history.push(`/partners?id=${result.data.partner.id}`);
    }
    catch (error) {
      this.setState(() => ({
        message: error.response.data.error
      }));
    }
  }

  createPartner = async () => {
    const payload = this.partnerFromState();
    let result;

    try {
      result = await axios.post(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + '/partners',
        payload, 
        { headers: ApiGateway.getHeaders() }
      );
      this.props.history.push(`/partners?id=${result.data.id}`);
    }
    catch (error) {
      this.setState(() => ({
        message: error.response.data.error
      }));
    }
  }

  handleSubmit = async (event) => {
    const { mode } = this.state;

    if (mode === "edit") {
      this.updatePartner();
    }
    else if (mode === "new") {
      this.createPartner();
    }
  }

  render() {
    const { 
      name, address_1, address_2, city, country, postal_code, state, contact_email, contact_phone, contact_first_name, 
      contact_last_name, contact_phone_ext, countries, regions, isCountryOpen, isRegionOpen, mode
    } = this.state;
    const phoneIsValid = isValidPhone(contact_phone);
    const emailIsValid = isValidEmail(contact_email);
    const extIsValid = contact_phone_ext ? isValidNumber(contact_phone_ext) : true;
    const phoneErrorState = textInputValidate(contact_phone, true, phoneIsValid, "Invalid phone number");
    const emailErrorState = isLengthWithinRange(contact_email, 1, 255)
      ? textInputValidate(contact_email, true, emailIsValid, 'Invalid Email Address')
      : { validated: 'error', errorText: ' Max 255 characters allowed' };
    const extErrorState = textInputValidate(contact_phone_ext, true, extIsValid, "Invalid phone extension");
    const address1ErrorState = address_1 !== '' ? isLengthWithinRange(address_1, 1, 255)
        ? { validated: 'default', errorText: '' }
        : { validated: 'error', errorText: ' Max 255 characters allowed' }
      : { validated: 'error', errorText: ' Required field' };
    const address2ErrorState = address_2 !== '' ? isLengthWithinRange(address_2, 1, 255)
        ? { validated: 'default', errorText: '' }
        : { validated: 'error', errorText: ' Max 255 characters allowed' }
      : { validated: 'default', errorText: '' };
    const cityErrorState = city !== '' ? isLengthWithinRange(city, 1, 255)
        ? { validated: 'default', errorText: '' }
        : { validated: 'error', errorText: ' Max 255 characters allowed' }
      : { validated: 'default', errorText: '' };
    const postalCodeErrorState = postal_code !== '' ? isLengthWithinRange(postal_code, 1, 255)
        ? { validated: 'default', errorText: '' }
        : { validated: 'error', errorText: ' Max 255 characters allowed' }
      : { validated: 'default', errorText: '' };
    const siteLocationIsValid = address1ErrorState.validated !== 'error' && address2ErrorState.validated !== 'error' &&
      cityErrorState.validated !== 'error' && postalCodeErrorState.validated !== 'error';

    const formValid = name && contact_first_name && contact_last_name && contact_email && siteLocationIsValid && emailIsValid && 
      contact_phone && phoneIsValid;

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">{mode === "edit" ? "Edit" : "New"} Partner</Title>

        <Card isCompact className="top-card">
          <CardBody>
            <Flex>
              <FlexItem>
                <FormGroup label="Partner Name" isRequired fieldId="name"
                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(name)}>
                  <TextInput
                    isRequired type="text" id="name" name="name" validated={textInputRequiredOnly(name)}
                    value={name} onChange={(e) => this.handleChange("name", e)}
                    isDisabled={mode === "edit" ? true : false}
                  />
                </FormGroup>
              </FlexItem>
            </Flex>
          </CardBody>
        </Card>

        <br />

        <Flex>
          <FlexItem className="flex-item-partner-form">
            <Card isCompact>
              <CardBody>
                <Title headingLevel="h3" size="md">Primary Contact</Title><br />

                <FormGroup label="First Name" isRequired fieldId="contact_first_name"
                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(contact_first_name)}>
                  <TextInput
                    isRequired type="text" id="contact_first_name" name="contact_first_name" validated={textInputRequiredOnly(contact_first_name)}
                    value={contact_first_name} onChange={(e) => this.handleChange("contact_first_name", e)}
                  />
                </FormGroup>
                <FormGroup label="Last Name" isRequired fieldId="contact_last_name"
                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(contact_last_name)}>
                  <TextInput
                    isRequired type="text" id="contact_last_name" name="contact_last_name" validated={textInputRequiredOnly(contact_last_name)}
                    value={contact_last_name} onChange={(e) => this.handleChange("contact_last_name", e)}
                  />
                </FormGroup>
                <FormGroup label="Email Address" isRequired fieldId="contact_email"
                  helperTextInvalid={emailErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={emailErrorState.validated}>
                  <TextInput
                    isRequired type="text" id="contact_email" name="contact_email" validated={emailErrorState.validated} 
                    value={contact_email} onChange={(e) => this.handleChange("contact_email", e)}
                  />
                </FormGroup>
                <FormGroup label="Phone Number" isRequired fieldId="contact_phone"
                  helperTextInvalid={phoneErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={phoneErrorState.validated}>
                  <TextInput
                    isRequired type="phone" id="contact_phone" name="contact_phone" validated={phoneErrorState.validated} 
                    value={contact_phone} onChange={(e) => this.handleChange("contact_phone", e)}
                  />
                </FormGroup>
              </CardBody>
            </Card>

            <Spacer height="30" />

            <Saver
              submitButtonText="Save" 
              submitButtonisDisabled={!formValid}
              submit={this.handleSubmit}
              cancel={this.handleCancel}  
              showCancel={true}
              message={this.state.message}
              messageType={this.state.messageType}
            />
          </FlexItem>

          <FlexItem className="flex-item-partner-form">
            <Card isCompact>
              <CardBody>
                <Title headingLevel="h3" size="md">Partner Profile</Title><br />

                <FormGroup label="Address 1" isRequired fieldId="address_1"
                  helperTextInvalid={address1ErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={address1ErrorState.validated}>
                  <TextInput validated={address1ErrorState.validated}
                    isRequired type="text" id="address_1" name="address_1" placeholder=""
                    value={address_1} onChange={(e) => this.handleChange("address_1", e)}
                  />
                </FormGroup>
                <FormGroup label="Address 2" fieldId="address_2"
                  helperTextInvalid={address2ErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={address2ErrorState.validated}>
                  <TextInput validated={address2ErrorState.validated}
                    type="text" id="address_2" name="address_2" placeholder=""
                    value={address_2} onChange={(e) => this.handleChange("address_2", e)}
                  />
                </FormGroup>

                <table>
                  <tbody>
                    <tr>
                      <td className="postal-code-col">
                        <FormGroup label="Postal Code / Zip" fieldId="postal_code"
                          helperTextInvalid={postalCodeErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={postalCodeErrorState.validated}>
                          <TextInput type="text" id="postal_code" name="postal_code" validated={postalCodeErrorState.validated} 
                            value={postal_code} onChange={(e) => this.handleChange("postal_code", e)}
                          />
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup label="City" fieldId="city"
                          helperTextInvalid={cityErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={cityErrorState.validated}>
                          <TextInput type="text" id="city" name="city" validated={cityErrorState.validated}
                            value={city} onChange={(e) => this.handleChange("city", e)}
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <FormGroup label="Country" fieldId="country-toggle">
                  <Select 
                    maxHeight={300}
                    variant={SelectVariant.typeahead}
                    placeholderText="Select Country ..." aria-label="Select Country"
                    onToggle={this.onCountryToggle} onSelect={this.onCountrySelect}
                    selections={country} isOpen={isCountryOpen} isDisabled={false}
                  >
                    {countries}
                  </Select>
                </FormGroup>
                <FormGroup label="State / Province" fieldId="region-toggle">
                  <Select
                    maxHeight={300}
                    variant={SelectVariant.typeahead}
                    placeholderText="Select Region ..." aria-label="Select Region"
                    onToggle={this.onRegionToggle} onSelect={this.onRegionSelect}
                    selections={state} isOpen={isRegionOpen} isDisabled={false}
                  >
                    {regions}
                  </Select>
                </FormGroup>
              </CardBody>
            </Card>
          </FlexItem>
        </Flex>
      </PageSection>
    );
  }
})
