import gql from "graphql-tag";

export const ALL_CLIENT_COMPANIES = gql`
  query AllClientCompanies($partnerId: uuid) {
    ClientCompany(where: { partnerId: { _eq: $partnerId } }) {
      id
      name
      industry
      notes
      Quicklinks {
        id
        link
        description
      }
    }
  }
`;

export const ALL_CLIENT_COMPANY_IDS = gql`
  query AllClientCompanies($partnerId: uuid) {
    ClientCompany(where: { partnerId: { _eq: $partnerId } }) {
      id
    }
  }
`;

export const CREATE_CLIENT_COMPANY = gql`
  mutation CreateClientCompany($input: [ClientCompany_insert_input!]!) {
    insert_ClientCompany(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const GET_CLIENT_COMPANY = gql`
  query GetClientCompany($id: uuid) {
    ClientCompany(where: { id: { _eq: $id } }) {
      Quicklinks {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT_COMPANY = gql`
  mutation UpdateClientCompany($id: uuid, $changes: ClientCompany_set_input) {
    update_ClientCompany(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_QUICKLINK = gql`
  mutation UpdateQuicklink($id: uuid, $changes: Quicklink_set_input) {
    update_Quicklink(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CREATE_QUICKLINK = gql`
  mutation CreateQuicklink($input: [Quicklink_insert_input!]!) {
    insert_Quicklink(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_QUICKLINK = gql`
  mutation DeleteQuicklink($id: uuid) {
    delete_Quicklink(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;