import { GET_PUBLIC_IPS, CREATE_PUBLIC_IP } from './gql.queries';
import UserSession from '@app/common/user-session';
import { apolloClient } from '../graphql/client';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { data: { PublicIp } } = await apolloClient.query({ 
        query: GET_PUBLIC_IPS
      });
      return PublicIp;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { data: { insert_PublicIp: { returning: [publicIp] }}} = await apolloClient.mutate({
        mutation: CREATE_PUBLIC_IP,
        variables: { input: [payload] },
      });
      return publicIp;
    }
    else {
      cognitoUser.signOut();
    }
  }
}