import * as React from 'react';
import { PageHeader } from '@patternfly/react-core';
import { Logo } from './logo.component';
import { Toolbar } from './toolbar.component';

export const Header: React.FC = () => {
  const [isNavOpen, setNavOpen] = React.useState(true);

  const onNavToggle = React.useCallback(() => {
    setNavOpen(!isNavOpen);
  }, [isNavOpen, setNavOpen]);

  return (
    <PageHeader
      logo={<Logo />}
      headerTools={<Toolbar />}
      showNavToggle
      isNavOpen={isNavOpen}
      onNavToggle={onNavToggle}
    />
  )
}