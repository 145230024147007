import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import { PageSection, Title, Button, EmptyState, EmptyStateIcon, EmptyStateBody } from '@patternfly/react-core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const NotFound: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = React.useCallback(() => {
    dispatch(push('/dashboard'));
  }, [dispatch]);

  return (
    <PageSection data-testid="not-found">
      <Helmet>
        <title>
          404 Not Found
        </title>
      </Helmet>
      <EmptyState variant="full">
        <EmptyStateIcon icon={ExclamationTriangleIcon} />
        <Title headingLevel="h1" size="lg">
          404 Page not found
        </Title>
        <EmptyStateBody>
          We didn&apos;t find a page that matches the address you navigated to.
        </EmptyStateBody>
        <Button onClick={handleClick}>Go to Dashboard</Button>
      </EmptyState>
    </PageSection>
  )
};

export { NotFound };
