import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { actionCreators } from '../common/configuration/configuration.reducer';
import { State } from '../store/root-reducer';
import { isUUID } from '@app/lib/validator';

const selectWanMatch = createMatchSelector<State, Match>({
  path: '/wan/:mode/:id?',
});

export const useWanContext = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectWanMatch);

  const mode = match?.params.mode;

  const id = isUUID(mode) ? mode : match?.params?.id;

  React.useEffect(() => {
    dispatch(actionCreators.wanRequest());
    dispatch(actionCreators.siteRequest());
    dispatch(actionCreators.serverRequest());
    dispatch(actionCreators.controllerRequest());
  }, [dispatch]);

  const sites = useSelector((state: State) => state.configuration.sites);
  const controllers = useSelector((state: State) => state.configuration.controllers);
  const servers = useSelector((state: State) => state.configuration.servers);
  const wans = useSelector((state: State) => state.configuration.wans);

  const isLoading = sites.isFetching || controllers.isFetching || servers.isFetching || wans.isFetching;
  const isFailed = sites.isFailed || controllers.isFailed || servers.isFailed || wans.isFailed;
  const isSuccess = sites.isSuccess && controllers.isSuccess && servers.isSuccess && wans.isSuccess;

  return { id, mode, isLoading, isFailed, isSuccess };
};
