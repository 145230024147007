import * as React from 'react';
import UserSession from '@app/common/user-session';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import { Saver } from '@app/components/Saver';
import { PasswordPolicy } from '@app/components/PasswordPolicy';
import { Spacer } from '@app/components/Spacer';
import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import brandImg from '@app/assets/images/generic-login.jpg';
import { textInputValidate, textInputRequiredOnly, validatePassword } from '@app/lib/validator';
import { Button, Card, CardBody, Flex, FlexItem, Form, FormGroup, Title, TextInput } from '@patternfly/react-core';
import { AuthenticationDetails, CognitoUserPool,  CognitoUser } from "amazon-cognito-identity-js";
import '../../app.css';
import { withRouter } from 'react-router-dom';

class NewPasswordChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      newPasswordSet: false,
      message: "Your temporary password must be changed. Please set a new password"
    };
  }

  handleChange = (key, value) => {
    this.setState(() => ({ 
      [key]: value 
    }));
  };

  handleLoginClick = () => {
    this.props.history.push('/');
  }

  handleSubmit = async (event) => {
    const { newPassword, confirmPassword } = this.state;
    const passwordValidator = validatePassword(newPassword, confirmPassword);

    if (passwordValidator.isValid === true) {
      this.setState(() => ({ 
        isLoading: true
      }));
      await this.setPassword(newPassword);
    }
    else {
      this.setState(() => ({ 
        message: passwordValidator.error,
        isLoading: false
      }));
    }
  };

  setPassword = async (newPassword) => {
    const tempPass = UserSession.getParam("tempPass");
    const userName = UserSession.getParam("userName");
    const poolId = process.env.REACT_APP_USER_POOL_ID;
    const clientId = process.env.REACT_APP_POOL_WEB_CLI_ID;
    const pool = new CognitoUserPool({ UserPoolId: poolId, ClientId: clientId });
    const authDetails = new AuthenticationDetails({ Username: userName, Password: tempPass });
    const cognitoUser = new CognitoUser({ Username: userName, Pool: pool });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: async (result) => {},
      newPasswordRequired: async (userAttributes, requiredAttributes) => {
        let result = await cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
          onSuccess: (result) => {
            UserSession.clear();
            this.setState(() => ({ 
              message: "Your new password was set successfully! You can login now with your new password",
              newPasswordSet: true,
              isLoading: false
            }));
          },
          onFailure: (error) => {
            console.log(error);
            this.setState(() => ({ 
              message: "There was an error setting your new password",
              isLoading: false
            }));
          }
        });
      },
      onFailure: async (error) => {
        console.log(error)
        this.setState(() => ({ 
          showHelperText: true,
          isLoading: false
        }));
      },
    });
  }

  render() {
    const { oldPassword, newPassword, confirmPassword, newPasswordSet, message } = this.state;
    const passwordErrorState = newPassword ? textInputRequiredOnly(newPassword) : { validated: 'default', errorText: "" };
    const confirmPasswordErrorState = confirmPassword ? textInputValidate(confirmPassword, true, newPassword === confirmPassword, "Passwords must match") : { validated: 'default', errorText: "" };
    const formValid = newPassword && confirmPassword && (newPassword === confirmPassword);

    return (
      <div align="center" className="unauth-container" style={{ backgroundImage: 'url(' + brandImg + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <Flex className="unauth-flex-container" direction={{ default: 'column' }} alignSelf={{ default: 'alignSelfCenter' }}>
          <FlexItem>
            <img src={EthicaLogo} width="260px" alt="Orchestrator Logo" />
            <Spacer />
            <Title headingLevel="h1" size="xl" className="white-text">Create Password</Title><br />

            {!newPasswordSet ? 
              <div>
                <p className="white-text">{message}</p><br />
                <Card isCompact className="top-card">
                  <CardBody>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="60%">
                            <Form>
                              <FormGroup label="New Password" isRequired fieldId="newPassword"
                                helperTextInvalid={passwordErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={newPassword ? passwordErrorState.validated : 'default'}>
                                <TextInput className="text-input text-input-center"
                                  isRequired type="password" id="newPassword" name="newPassword" validated={newPassword ? passwordErrorState.validated : 'default'} 
                                  value={newPassword} onChange={(e) => this.handleChange("newPassword", e)}
                                  autoComplete='new-password'
                                />
                              </FormGroup>
                              <FormGroup label="Confirm Password" isRequired fieldId="confirmPassword"
                                helperTextInvalid={confirmPasswordErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={confirmPassword ? confirmPasswordErrorState.validated : 'default'}>
                                <TextInput className="text-input text-input-center"
                                  isRequired type="password" id="confirmPassword" name="confirmPassword" validated={confirmPassword ? confirmPasswordErrorState.validated : 'default'} 
                                  value={confirmPassword} onChange={(e) => this.handleChange("confirmPassword", e)}
                                  autoComplete='new-password'
                                />
                              </FormGroup>
                            </Form>
                          </td>
                          <td>
                            <PasswordPolicy />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                </Card><br />

                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Saver 
                          submitButtonText="Submit" 
                          submitButtonisDisabled={!formValid}
                          submit={this.handleSubmit} 
                          showCancel={false}
                          showConfirmationMessage={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            : 
              <div>
                <p className="white-text">{message}</p><br />
                <Button onClick={this.handleLoginClick}>Login Now</Button> 
              </div>
            }
          </FlexItem>
        </Flex>
      </div>
    );
  }
}

export default withRouter(NewPasswordChallenge);