import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import License from '@app/api/license/license.actions';
import SiteApi from '@app/api/site-api/site.actions';
import { DEFAULT_UUID } from '@app/lib/constants';
import { siteSelectors } from '@app/common/configuration/configuration.selectors';
import UserSession from '@app/common/user-session';
import { Saver } from '@app/components/Saver';
import { getResources } from './LicenseHelper';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { Flex, FlexItem, PageSection, Title, Card, CardBody, TextInput, Form, FormGroup, Select, SelectOption, SelectVariant, Spinner } from '@patternfly/react-core';

export const LicenseUpdate = connect()(class extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const id = this.props.computedMatch?.params?.id;

    this.state = {
      id: id,
      type: '',
      assigned_to: '',
      isTypeOpen: false,
      isAssignedToOpen: false,
      resourceList: [],
      error: '',
      message: '',
      messageType: '',
      dataLoading: true,
    }
  }

  componentDidMount = () => {
    try {
      this.loadLicense();
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the License information"
      }));
    }
  }

  loadLicense = async () => {
    const partnerId = UserSession.getPartnerId();
    const license = await License.get(partnerId, id);
    const licenseList = await License.getAll(partnerId);
    const productList = await License.getProducts(partnerId);
    const productType = productList.find(product => product.id === license.type);
    const resourceList = await getResources();
    const assignedLicenses = licenseList.filter(lic => { 
      if (lic.assigned_to !== DEFAULT_UUID && lic.assigned_to !== license.assigned_to) {
        return lic.assigned_to;
      }
    });
    const assignedSiteIds = assignedLicenses.map(site => site.assigned_to);
    const unassigned = resourceList.filter(site => {
      if (!assignedSiteIds.includes(site.id)) {
        return site;
      }  
    });

    const assignedToResource = resourceList.find(resource => resource.id === license.assigned_to);
    let assignedTo = 'Unassigned';

    if (assignedToResource) {
      assignedTo = assignedToResource.address1;
    }

    this.setState(() => ({
      id: license.id,
      type: productType.name,
      assigned_to: assignedTo,
      resourceList: unassigned,
      dataLoading: false
    })); 
  }

  onAssignedToToggle = (isOpen) => {
    this.setState(() => ({
      isAssignedToOpen: isOpen,
    }));
  };

  onAssignedToSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearAssignedTo();
    } 
    else {
      this.setState(() => ({
        assigned_to: selection,
        isAssignedToOpen: false,
      }));
    }
  };

  getResourceOptions = () => {
    // TODO: Refactor since resource types will use different prop names to display here
    return this.state.resourceList.map((resource) => {
      return <SelectOption key={resource.id} id={resource.id} value={resource.address1} />;
    });
  };

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  handleCancel = () => {
    const { id } = this.state;

    if (id) {
      this.props.history.push(`/license/${id}`);
    }
    else {
      this.props.history.push('/licenses');
    }
  }

  payloadFromState = () => {
    const { id } = this.state;
    const payload = { license_id: id };
    return payload;
  }  

  handleSubmit = async (event) => {
    const { id, assigned_to, resourceList } = this.state;

    try {
      const partnerId = UserSession.getPartnerId();
      const payload = this.payloadFromState();
      const site = resourceList.find(site => site.address1 === assigned_to);
      const result = await SiteApi.attachLicense(site.id, { license_id: id });
      this.props.history.push(`/license/${id}`);
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error updating the License"
      }));
    }
  }

  render() {
    const { id, type, assigned_to, isAssignedToOpen, message, messageType, dataLoading } = this.state;
    const formValid = type;

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">Update License</Title><br />

        <Card isCompact>
          <CardBody>
            <Flex>
              <FlexItem>
                {dataLoading ? 
                  <Spinner size="lg" />
                : 
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>License Type: &nbsp;</td>
                          <td>{type}</td>
                        </tr>
                        <tr>
                          <td>License ID: </td>
                          <td>{id}</td>
                        </tr>
                      </tbody>
                    </table><br />

                    <FormGroup label="Assign To" fieldId="assigned-to-toggle">
                      <Select 
                        maxHeight={300}
                        variant={SelectVariant.typeahead}
                        placeholderText="Select ..." aria-label="Assign To"
                        onToggle={this.onAssignedToToggle} onSelect={this.onAssignedToSelect}
                        selections={assigned_to} isOpen={isAssignedToOpen} isDisabled={false}
                      >
                        {this.getResourceOptions()}
                      </Select>
                    </FormGroup>
                  </div>
                }
              </FlexItem>
            </Flex>
          </CardBody>
        </Card>

        <br />

        <Saver 
          submitButtonText='Save' 
          submitButtonisDisabled={!formValid}
          submit={this.handleSubmit} 
          cancel={this.handleCancel} 
          showCancel={true}
          message={message}
          messageType={messageType}
        />
      </PageSection>
    );
  }
})
