import * as React from 'react';
import { AppDispatch } from '@app/store';
import UserMgmtApi from '@app/api/user-api/user.actions';
import { textInputValidate, isValidEmail } from '@app/lib/validator';
import { formatDate } from '@app/lib/functions';
import { capitalizeFirstLetter } from '@app/utils/utils';
import { Saver } from '@app/components/Saver';
import { BullhornIcon, ExclamationCircleIcon } from '@patternfly/react-icons';
import { Button, PageSection, Title, Card, CardBody, TextInput, FormGroup, Select, SelectOption, SelectVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import './Admin.css';
import { State } from '@app/store/root-reducer';
import { connect } from 'react-redux';

interface UsersState {
  firstName: string;
  lastName: string;
  email: string;
  message?: string;
  isLoading?: boolean;
  [key: string]: any;
}

class Users extends React.Component<AppDispatch & { partnerId: string, partnerName: string }, UsersState> {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      userEmail: "",
      users: [],
      roles: [],
      updateRoles: [],
      role: 'Viewer',
      updatedRole: '',
      updatedStatus: '',
      actionId: '',
      isRoleOpen: false,
      isUpdateRoleModalOpen: false,
      isUpdateRoleOpen: false,
      isUpdateStatusModalOpen: false
    }
  }

  componentDidMount = async () => {
    this.loadUsers();
  }

  loadUsers = async () => {
    try {
      const userList = await UserMgmtApi.getAll();
      const roles = this.getRoleOptions('invite');
      const updateRoles = this.getRoleOptions('update');

      this.setState(() => ({
        roles: roles,
        updateRoles: updateRoles,
        users: userList.map(this.userFromItem)
      }));
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the users"
      }));
    }
  }

  userFromItem = (item) => {
    const status = item.account_status;
    return {
      cells: [
        { title: item.first_name + ' ' + item.last_name },
        { title: status },
        { title: capitalizeFirstLetter(item.role) },
        { title: status?.toLowerCase() === 'pending' ? 'None' : formatDate(item.last_login) },
        { title: item.phone },
        { title: item.email },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' } }
      ],
    };
  };

  getColumns = () => {
    return [
      { title: 'Name', transforms: [sortable] },
      { title: 'Status' },
      { title: 'Role' },
      { title: 'Last Login' },
      { title: 'Phone' },
      { title: 'Email', transforms: [sortable] },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  }

  onRoleToggle = (isOpen) => {
    this.setState(() => ({
      isRoleOpen: isOpen,
    }));
  };

  onRoleSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      // 
    }
    else {
      this.setState(() => ({
        role: selection,
        isRoleOpen: false,
      }));
    }
  };

  onUpdateRoleToggle = (isOpen) => {
    this.setState(() => ({
      isUpdateRoleOpen: isOpen,
    }));
  };

  onUpdateRoleSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      //
    }
    else {
      this.setState(() => ({
        updatedRole: capitalizeFirstLetter(selection),
        isUpdateRoleOpen: false,
      }));
    }
  };

  showUpdateRoleModal = (event, rowId, rowData) => {
    const userId = rowData['id']['title'];
    const userRole = rowData['role']['title'];
    const userEmail = rowData['email']['title'];

    this.setState(() => ({
      isUpdateRoleModalOpen: true,
      updatedRole: capitalizeFirstLetter(userRole),
      userEmail: userEmail,
      actionId: userId
    }));
  }

  showUpdateStatusModal = (event, rowId, rowData) => {
    const userId = rowData['id']['title'];
    const userStatus = rowData['status']['title'];
    const userEmail = rowData['email']['title'];

    this.setState(() => ({
      isUpdateStatusModalOpen: true,
      userEmail: userEmail,
      updatedStatus: userStatus,
      actionId: userId
    }));
  }

  getColumnIndex = (columnName) => {
    const columns = this.getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  updateRole = async (event, rowId, rowData) => {
    const { actionId, updatedRole, users } = this.state;
    const payload = { change: { role: updatedRole.toLowerCase() } };

    try {
      const result = await UserMgmtApi.update(actionId, payload);
      const idIndex = this.getColumnIndex('ID');
      const roleIndex = this.getColumnIndex('Role');
      const updatedUsers = users.map(user => {
        if (user.cells[idIndex].title === actionId) {
          user.cells[roleIndex] = { title: capitalizeFirstLetter(updatedRole) };
          return user;
        }
        return user;
      });

      this.setState(() => ({
        isUpdateRoleModalOpen: false,
        users: [...updatedUsers]
      }));
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error updating the user role"
      }));
    }
  }

  updateStatus = async (event, rowId, rowData) => {
    const { actionId, users } = this.state;

    try {
      const result = await UserMgmtApi.updateAccountStatus(actionId);
      const idIndex = this.getColumnIndex('ID');
      const statusIndex = this.getColumnIndex('Status');
      const action = this.getStatusAction();
      const updatedUsers = users.map(user => {
        if (user.cells[idIndex].title === actionId) {
          user.cells[statusIndex] = { title: action === 'Enable' ? 'Active' : 'Disabled' };
          return user;
        }
        return user;
      });

      this.setState(() => ({
        isUpdateStatusModalOpen: false,
        users: [...updatedUsers]
      }));
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        isUpdateStatusModalOpen: false,
        message: "There was an error updating the user account status"
      }));
    }
  }

  handleUpdateRoleModalToggle = (isOpen) => {
    this.setState(() => ({
      isUpdateRoleModalOpen: !isOpen,
    }));
  };

  handleUpdateStatusModalToggle = (isOpen) => {
    this.setState(() => ({
      isUpdateStatusModalOpen: !isOpen,
    }));
  };

  getRoleOptions = (uniqueVal) => {
    const allRoles = ['Editor', 'Manager', 'Viewer'];
    const roleItems = [];
    allRoles.forEach(function (name, index) {
      roleItems.push(<SelectOption key={`role-${uniqueVal}-${index}`} value={name} />);
    });
    return roleItems;
  }

  handleChange = (name, value) => {
    this.setState(() => ({
      [name]: value
    }));
  }

  handleInviteUser = async (event) => {
    const { firstName, lastName, email, role } = this.state;
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      role: role
    }

    try {
    let result = await UserMgmtApi.inviteUser(payload);

      if (result?.data?.request_id) {
        const user = result.data.user;
      const fullName = user.first_name + " " + user.last_name;

      this.setState(() => ({
        message: fullName + " was invited successfully!",
        firstName: "",
        lastName: "",
        email: "",
        role: "Viewer",
        users: [
          ...this.state.users,
          this.userFromItem({
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            account_status: user.account_status,
            role: user.role,
            last_login: 'N/A',
            phone: user.phone,
            email: user.email,
          })
        ],
        isLoading: false
      }));
    }
    else {
      this.setState(() => ({
        message: result.data.error,
          isLoading: false
        }));
      }
    }
    catch(error) {
      this.setState(() => ({
        message: error.message,
        isLoading: false
      }));
    }
  }

  getStatusAction = () => {
    const { updatedStatus } = this.state;
    return updatedStatus === 'Active' ? 'Disable' : 'Enable';
  }

  render() {
    const { firstName, lastName, email, users, userEmail, role, roles, updateRoles, updatedRole, isRoleOpen, updatedStatus,
      isUpdateRoleModalOpen, isUpdateRoleOpen, isUpdateStatusModalOpen } = this.state;
    const emailIsValid = email ? isValidEmail(email) : false;
    const emailErrorState = email ? textInputValidate(email, true, emailIsValid, "Invalid email format") : { validated: 'default', errorText: "" };
    const statusAction = this.getStatusAction();
    const formValid = emailIsValid;

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">Invite User</Title>

        <Card isCompact className="top-card">
          <CardBody>
            <p>Invite users into your space!</p><br />

            <table className="invite-table">
              <tbody>
                <tr>
                  <td>
                    <FormGroup label="First Name" fieldId="firstName">
                      <TextInput
                        className="invite-field"
                        type="text" id="firstName" name="firstName" validated="default"
                        value={firstName} onChange={(e) => this.handleChange("firstName", e)}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup label="Last Name" fieldId="lastName">
                      <TextInput
                        className="invite-field"
                        type="text" id="lastName" name="lastName" validated="default"
                        value={lastName} onChange={(e) => this.handleChange("lastName", e)}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup label="Email" isRequired fieldId="email"
                      helperTextInvalid={emailErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={email ? emailErrorState.validated : 'default'}>
                      <TextInput
                        className="invite-field"
                        isRequired type="text" id="email" name="email" validated={email ? emailErrorState.validated : 'default'}
                        value={email} onChange={(e) => this.handleChange("email", e)}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup label="Role" isRequired fieldId="role">
                      <Select
                        maxHeight={300}
                        variant={SelectVariant.typeahead}
                        onToggle={this.onRoleToggle} onSelect={this.onRoleSelect}
                        selections={role} isOpen={isRoleOpen} isDisabled={false}
                      >
                        {roles}
                      </Select>
                    </FormGroup>
                  </td>
                  <td className="invite-user-button">
                    <Saver
                      submitButtonText="Invite User"
                      submitButtonisDisabled={!formValid}
                      submit={this.handleInviteUser}
                      showCancel={false}
                      confirmationMessage="Are you sure you want to invite this user to your Partner Space?"
                      message={this.state.message}
                      messageType={this.state.messageType}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
        <br />

        <Title headingLevel="h1" size="xl">Users in this Space</Title>
        <Card isCompact className="top-card">
          <CardBody>
            {users.length === 0 ? (
              <div>No Users found!</div>
            ) : (
              <div>
                <PatternflyTable
                  columns={this.getColumns}
                  updateRole={this.showUpdateRoleModal}
                  updateStatus={this.showUpdateStatusModal}
                  data={users}
                  page={1}
                />
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          title="Update Role"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isUpdateRoleModalOpen}
          onClose={this.handleUpdateRoleModalToggle}
          actions={[
            <Saver
              key="update-role"
              submitButtonText="Update"
              submitButtonisDisabled={false}
              submit={this.updateRole}
              showCancel={false}
              confirmationMessage={`Are you sure you want to update the role for ${userEmail}?`}
            />,
            <Button key="cancel-update" variant="link" onClick={this.handleUpdateRoleModalToggle}>Cancel</Button>
          ]}
        >
          <FormGroup label={"Select new a role for " + userEmail} fieldId="updatedRole">
            <Select
              className="role-select"
              variant={SelectVariant.single}
              onToggle={this.onUpdateRoleToggle} onSelect={this.onUpdateRoleSelect}
              selections={updatedRole} isOpen={isUpdateRoleOpen} isDisabled={false}
            >
              {updateRoles}
            </Select>
            <br />
          </FormGroup>
        </Modal>

        <Modal
          title="Update Account Status"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isUpdateStatusModalOpen}
          onClose={this.handleUpdateStatusModalToggle}
          actions={[
            <Saver
              key="update-status"
              submitButtonText={statusAction}
              submitButtonisDisabled={false}
              submit={this.updateStatus}
              showConfirmationMessage={false}
              showCancel={false}
            />,
            <Button key="cancel-update" variant="link" onClick={this.handleUpdateStatusModalToggle}>Cancel</Button>
          ]}
        >
          Are you sure you want to <strong>{statusAction}</strong> the account for {userEmail}?
        </Modal>
      </PageSection>
    );
  }
}

export default connect((state: State) => ({
  partnerId: state.common.auth.session?.partnerId,
  partnerName: state.common.auth.session?.partnerName,
}))(Users);
