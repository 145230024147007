import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { actionCreators } from '../common/configuration/configuration.reducer';
import { State } from '../store/root-reducer';
import { isUUID } from '@app/lib/validator';

const selectControllerMatch = createMatchSelector<State, Match>({
  path: '/controller/:mode/:id?',
});

export const useControllerContext = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectControllerMatch);

  const mode = match?.params.mode;

  const id = isUUID(mode) ? mode : match?.params.id;

  React.useEffect(() => {
    dispatch(actionCreators.siteRequest());
    dispatch(actionCreators.wanRequest());
    dispatch(actionCreators.tunnelRequest());
    dispatch(actionCreators.controllerRequest());
    dispatch(actionCreators.serverRequest());
  }, [dispatch]);

  const sites = useSelector((state: State) => state.configuration.sites);
  const wans = useSelector((state: State) => state.configuration.wans);
  const controllers = useSelector((state: State) => state.configuration.controllers);
  const tunnels = useSelector((state: State) => state.configuration.tunnels);

  const isLoading = sites.isFetching || wans.isFetching || controllers.isFetching || tunnels.isFetching;
  const isFailed = sites.isFailed || wans.isFailed || controllers.isFailed || tunnels.isFailed;
  const isSuccess = sites.isSuccess && wans.isSuccess && controllers.isSuccess && tunnels.isSuccess;

  return { id, mode, isLoading, isFailed, isSuccess };
};
