import ApiGateway from '../api-gateway/api-gateway';
import GatewayApi from '../gateway-api/gateway.actions';
import PublicIp from '../publicip/publicip.actions';
import { isUUID } from '@app/lib/validator';
import _map from 'lodash-es/map';
import { Timezones } from '@app/common/world';
import { GET_GATEWAY } from '../controller/gql.queries';
import { UPDATE_PUBLIC_IP, DELETE_PUBLIC_IP } from '@app/api/gateway/gql.queries';
import { CREATE_SERVER, UPDATE_SERVER } from '@app/api/gateway/gql.queries';
import { apolloClient } from '../graphql/client';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';


export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get('/controllers');
      return result.data.controllers;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async get(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/controllers/${id}`);
      return result.data.controller;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const timezones = Timezones();
      const timezoneObj = timezones.find((t) => payload.timezone.startsWith(t.label));
      const result = await ApiGateway.post('/controllers', { ...payload, timezone: timezoneObj!.name });
  
      if (payload?.gateways?.length) {
        for(let i=0; i<payload.gateways.length; i++) {
          const gateway = await GatewayApi.create({ 
            controller_id: result.data.controller.id,
            ip_4_address: payload.gateways[i].cells[0].props.value,
            ip_4_cidr: payload.gateways[i].cells[1].props.value,
            ip_4_router: payload.gateways[i].cells[2].props.value
          });
  
          const publicIp = await PublicIp.create({
            serverId: gateway.id,
            address: payload.gateways[i].cells[0].props.value,
            ip4Cidr: payload.gateways[i].cells[1].props.value,
            isV6: false
          });
        }
      }
  
      return result.data.controller;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const timezones = Timezones();
      const timezoneObj = timezones.find((t) => payload.timezone.startsWith(t.label));
      const result = await ApiGateway.patch(`/controllers/${id}`, { ...payload, timezone: timezoneObj!.name });
  
      const newGateways = payload.gateways.filter((item) => {
        const cell = item.cells[4].props;
        return cell.name === 'id' && parseInt(cell.value) < 0;
      });
  
      const existingGateways = payload.gateways.filter((item) => {
        const cell = item.cells[4].props;
        return cell.name === 'id' && isUUID(cell.value);
      });
  
      if (newGateways?.length) {
        for(let i=0; i<newGateways.length; i++) {
          const gateway = await GatewayApi.create({
            controller_id: id,
            ip_4_address: newGateways[i].cells[0].props.value,
            ip_4_cidr: newGateways[i].cells[1].props.value,
            ip_4_router: newGateways[i].cells[2].props.value
          });
  
          const publicIp = await PublicIp.create({
            serverId: gateway.id,
            address: newGateways[i].cells[0].props.value,
            ip4Cidr: newGateways[i].cells[1].props.value,
            isV6: false
          });
        }
      }
  
      // Update existing Server and PublicIp records
      let updatePromises = [];
  
      for (var i = 0; i < existingGateways.length; i++) {
        let gateway = existingGateways[i];
  
        const publicIpId = gateway.publicIpId;
        const ip4Address = gateway.cells[0].props.value;
        const ip4Cidr = gateway.cells[1].props.value;
        const ip4Router = gateway.cells[2].props.value;
        const serverId = gateway.cells[4].props.value;
  
        await GatewayApi.update(serverId, { 
          ip_4_address: ip4Address,
          ip_4_cidr: ip4Cidr,
          ip_4_router: ip4Router
        });
  
        if (publicIpId !== '') {
          updatePromises.push(apolloClient.mutate({
            mutation: UPDATE_PUBLIC_IP,
            variables: {
              id: publicIpId,
              changes: {
                address: ip4Address,
                ip4Cidr: ip4Cidr,
                isV6: false,
              },
            }})
          );
        }
      }
  
      await Promise.all(updatePromises);
  
      return result.data.controller;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async delete(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { data: { Gateway } } = await apolloClient.query({
        query: GET_GATEWAY,
        variables: { id },
      });
  
      // Delete Servers and PublicIps
      for (const server of Gateway[0].Servers) {
        let promises = _map(server.PublicIps, ({ id }) => {
          return apolloClient.mutate({ mutation: DELETE_PUBLIC_IP, variables: { id } });
        });
        await Promise.all(promises);
        await GatewayApi.delete(server.id);
      }
  
      const result = await ApiGateway.delete(`/controllers/${id}`);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  }
}
