import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Flex,
  FlexItem,
  Modal,
  ModalVariant,
  PageSection,
  Spinner,
  Title,
  ToggleGroup,
  ToggleGroupItem,
} from '@patternfly/react-core';
import { Spacer } from '@app/components/Spacer';
import { sortable } from '@patternfly/react-table';
import { useWanContext } from '../../contexts/wan.context';
import {
  selectControllerById,
  selectServerById,
  selectWanById,
  siteSelectors,
} from '../../common/configuration/configuration.selectors';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import { push } from 'connected-react-router';
import WanIcon from '@app/bgimages/wans-black.svg';
import {
  BullhornIcon,
  DomainIcon,
  LockIcon,
  PencilAltIcon,
  PficonNetworkRangeIcon,
  ServiceCatalogIcon,
  SyncAltIcon,
} from '@patternfly/react-icons';
import { IconHeading } from '@app/components/IconHeading';
import ControllerIcon from '@app/bgimages/controllers-black.svg';
import { InfoSection } from '@app/components/InfoSection';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { actionCreators } from '@app/common/configuration/configuration.reducer';
import { Link } from 'react-router-dom';
import { NotFound } from '../not-found/not-found';
import { ErrorView } from '../error/error';
import './Wan.css';
import '../../app.css';

export const WanView: React.FC = () => {
  const dispatch = useDispatch();
  const { id, isLoading, isFailed, isSuccess } = useWanContext();
  const [isRebootConfirmOpen, setRebootConfirmOpen] = React.useState<boolean>(false);
  const wan = useSelector(selectWanById(id));
  const sites = useSelector(siteSelectors.selectAll).filter((site) => site.wanId === id);
  const activeController = useSelector(selectControllerById(wan?.activeGatewayId));
  const standbyController = useSelector(selectControllerById(wan?.standbyGatewayId));
  const activeGateway = useSelector(selectServerById(wan?.activeServerId));
  const standbyGateway = useSelector(selectServerById(wan?.standbyServerId));
  const encryptedState = wan?.isEncrypted ? 'Enabled' : 'Disabled';
  const [aclProfiles, setAclProfiles] = React.useState([]);

  React.useEffect(() => {
    (async function () {
      const aclProfiles = await ACLProfile.getAssignedACLs(id);
      setAclProfiles(aclProfiles);
    })();
  }, []);

  const getSiteSubnetColumns = () => {
    return [
      { title: 'Address' },
      { title: 'LAN Subnet', transforms: [sortable] },
      { title: 'Accessible IPs' },
      { title: 'Addresses' },
    ];
  };

  const editWan = React.useCallback(() => {
    dispatch(push(`/wan/edit/${id}`));
  }, [id]);

  const getControllerInfo = React.useCallback(() => {
    return [
      { label: 'Active Controller', value: activeController?.name },
      { label: 'Standby Controller', value: standbyController?.name ?? 'N/A' },
    ];
  }, [activeController, standbyController]);

  const getGatewayInfo = React.useCallback(() => {
    return [
      { label: 'Active Tunnel Gateway', value: activeGateway?.ip4Address },
      { label: 'Standby Tunnel Gateway', value: standbyGateway?.ip4Address ?? 'N/A' },
    ];
  }, [activeGateway, standbyGateway]);

  const getAclProfileInfo = () => {
    if (aclProfiles?.length > 0) {
      return aclProfiles.map(profile => {
        let aclProfile = `/aclprofile/${profile.id}`;
        return { label: <Link to={aclProfile}>{profile.name}</Link>, value: profile.description }
      });
    }
  }

  const rebootGatewaysInWan = React.useCallback(() => {
    sites.forEach((site) => {
      const serverIds = [
        site.Tunnels?.[0]?.Server?.id,
        site.Tunnels?.[1]?.Server?.id,
        activeGateway?.id,
        standbyGateway?.id,
      ].filter((x) => x !== undefined);

      dispatch(actionCreators.serverRebootRequest(serverIds));
    });
    setRebootConfirmOpen(false);
  }, [dispatch, sites, setRebootConfirmOpen]);

  const getSiteSubnets = React.useCallback(() => {
    return sites.map((site) => {
      const lan = site.SiteLans?.[0];
      return {
        cells: [
          { title: <Link to={{ pathname: '/site/' + site.id }}>{site.address1}</Link> },
          { title: `${lan?.lanSegmentIp4}${lan?.lanCidrIp4}` },
          { title: site.ipAccessLevel || 'All' },
          { title: site.ipAccessLevel === 'Specific' ? site.accessibleIps : '' },
        ],
      };
    });
  }, [sites]);

  const aclProfileInfo = getAclProfileInfo();

  if (isLoading) {
    return <Spinner size="lg" />;
  }

  if (isFailed) {
    return <ErrorView title="Failed to load WAN" message="🤖 Failed to load WAN" />;
  }

  if (isSuccess && !wan) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{wan?.name}</title>
      </Helmet>
      <PageSection>
        <Card isCompact>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <td width="12%" className="wan-header">
                    <img src={WanIcon} width="60%" alt={wan?.name} />
                  </td>
                  <td width="88%" className="wan-header wan-desc">
                    <div>
                      <Title headingLevel="h1" size="xl">
                        {wan?.name}
                      </Title>
                      <br />
                      <div className="textarea-input">
                        <p>{wan?.description}</p>
                        <br />
                      </div>
                      <ToggleGroup aria-label="Site options">
                        <ToggleGroupItem
                          icon={<PencilAltIcon />}
                          text="Edit"
                          aria-label="Edit WAN"
                          buttonId="toolbar-edit"
                          onClick={editWan}
                        />
                        <ToggleGroupItem
                          icon={<SyncAltIcon />}
                          text="Reboot Gateways"
                          aria-label="Reboot Gateways"
                          buttonId="toolbar-reboot-gateway"
                          onClick={(e) => setRebootConfirmOpen(!isRebootConfirmOpen)}
                        />
                      </ToggleGroup>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>

        <br />
        <Title headingLevel="h3" size="md">
          WAN Configuration
        </Title>
        <br />


        <Flex>
          <FlexItem className="flex-item-wan">
            <Card isCompact className="wan-settings-row">
              <CardBody>
                <div>
                  <IconHeading
                    icon={<img src={ControllerIcon} width="24px" height="24px" alt="Controllers" />}
                    heading="Controllers"
                  />
                  <InfoSection keyValuePairs={getControllerInfo()} showHeading={false} />
                </div>
              </CardBody>
            </Card>
          </FlexItem>

          <FlexItem className="flex-item-wan">
            <Card isCompact className="wan-settings-row">
              <CardBody>
                <div>
                  <IconHeading icon={<DomainIcon className="icon-medium" />} heading="Tunnel Gateway" />
                  <InfoSection keyValuePairs={getGatewayInfo()} showHeading={false} />
                </div>
              </CardBody>
            </Card>
          </FlexItem>

          <FlexItem className="flex-item-wan">
            <Card isCompact className="wan-settings-row">
              <CardBody>
                <div>
                  <IconHeading
                    icon={<LockIcon className="icon-medium" />}
                    heading="Encryption"
                    subHeading={encryptedState}
                  />
                  <p>If enabled, all tunnel traffic will be encrypted at 128-bit using a 256-bit key</p>
                </div>
              </CardBody>
            </Card>
          </FlexItem>
        </Flex>

        <br />
        <Title headingLevel="h3" size="md">
          Security Settings
        </Title>
        <br />

        <Flex>
          <FlexItem className="acl-profile-card">
            <Card isCompact className="wan-settings-row">
              <CardBody>
                {isLoading ? (
                  <Spinner size="lg" />
                ) : (
                  <div>
                    <IconHeading
                      icon={<ServiceCatalogIcon className="icon-medium" />}
                      heading="ACL Profiles"
                      subHeading={aclProfileInfo?.length > 0 ? 'Enabled' : 'Disabled'}
                    />
                    {(aclProfileInfo?.length > 0) ? (
                      <div>
                        <InfoSection keyValuePairs={aclProfileInfo} showHeading={false} />
                      </div>
                    ) : (
                      <div>No ACL Profiles defined</div>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          </FlexItem>
        </Flex>

        <br />
        <Title headingLevel="h3" size="md">
          Sites
        </Title>
        <br />

        <Card isCompact>
          <CardBody>
            <div>
              <IconHeading icon={<PficonNetworkRangeIcon className="icon-medium" />} heading="Site Subnets" />
              {getSiteSubnets().length > 0 ? (
                <PatternflyTable columns={getSiteSubnetColumns} data={getSiteSubnets()} />
              ) : (
                <div>No Sites configured!</div>
              )}
            </div>
          </CardBody>
        </Card>

        <Modal
          title="Confirm Reboot"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isRebootConfirmOpen}
          onClose={() => setRebootConfirmOpen(!isRebootConfirmOpen)}
          actions={[
            <Button key="confirm" variant="primary" onClick={rebootGatewaysInWan}>
              Yes
            </Button>,
            <Button
              key="cancel"
              variant="link"
              onClick={(e) => {
                setRebootConfirmOpen(false);
              }}
            >
              Cancel
            </Button>,
          ]}
        >
          <p>Are you sure you want to reboot all Gateways?</p>
          <p>Please note that all Sites in this WAN will be affected by this action.</p>
        </Modal>
      </PageSection>
    </React.Fragment>
  );
};
