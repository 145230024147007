import * as React from 'react';
import { withRouter } from 'react-router-dom';
import Partner from '@app/api/partner/partner.actions';
import UserMgmtApi from '@app/api/user-api/user.actions';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import { Saver } from '@app/components/Saver';
import { PasswordPolicy } from '@app/components/PasswordPolicy';
import { Spacer } from '@app/components/Spacer';
// import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import brandImg from '@app/assets/images/generic-login.jpg';
import { textInputValidate, isValidEmail, textInputRequiredOnly, validatePassword } from '@app/lib/validator';
import { Button, Card, CardBody, Flex, FlexItem, Form, FormGroup, Title, TextInput } from '@patternfly/react-core';
import { userIsNotAuthenticated } from '@app/components/layout/auth.component';

import '../../app.css';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      message: "",
      messageType: "",
      newPassword: "",
      confirmPassword: "",
      confirmationCode: "",
      showLoginButton: false,
      showBackToLoginLink: true,
      showConfirmationCode: false
    };
  }

  handleChange = (key, value) => {
    this.setState(() => ({ 
      [key]: value 
    }));
  };

  handleLoginClick = () => {
    this.props.history.push('/');
  }

  handleEmailKeyPress = async (event) => {
    const { email } = this.state;

    if (email && isValidEmail(email) && event.charCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      await this.sendEmail();
    }
  };

  handleSubmitEmail = async (event) => {
    const { email } = this.state;

    if (email && isValidEmail(email)) {
      await this.sendEmail();
    }
    else {
      this.setState(() => ({ 
        message: "A valid email address is required"
      }));
    }
  };

  sendEmail = async () => {
    const { email } = this.state;
    const payload = { user_email: email };

    try {
      let result = await UserMgmtApi.deleteUser(payload);

      if (result?.status === 202) {
        this.setState(() => ({
          showConfirmationCode: true,
          isLoading: false
        }));
      }
      else {
        this.setState(() => ({
          message: 'There was an error sending the Forgot Password email',
          showConfirmationCode: false,
          isLoading: false
        }));
      }
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error sending your confirmation code",
        isLoading: false
      }));
    }
  }

  handleCodeKeyPress = async (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      await this.submitCode();
    }
  };

  handleSubmitCode = async (event) => {
    await this.submitCode();
  }

  submitCode = async (event) => {
    const { email, newPassword, confirmPassword, confirmationCode } = this.state;
    const payload = {
      user_email: email,
      new_password: newPassword,
      confirmation_code: confirmationCode
    }

    if (confirmationCode) {
      try {
        const passwordValidator = validatePassword(newPassword, confirmPassword);

        if (passwordValidator.isValid === true) {
          let result = await UserMgmtApi.submitCode(payload);

          if (result?.status === 202) {
            this.setState(() => ({
              message: "Your password was reset successfully!",
              showConfirmationCode: false,
              showBackToLoginLink: false,
              showLoginButton: true,
              isLoading: false
            }));
          }
          else {
            this.setState(() => ({
              message: "There was an error submitting your confirmation code",
              showConfirmationCode: false,
              showBackToLoginLink: false,
              showLoginButton: true,
              isLoading: false
            }));
          }
        }
        else {
          this.setState(() => ({
            message: passwordValidator.error,
            isLoading: false
          }));          
        }
      }
      catch (error) {
        console.log(error)
        this.setState(() => ({
          message: "The confirmation code you have entered is invalid",
          isLoading: false
        }));
      }
    }
    else {
      this.setState(() => ({ 
        message: "The confirmation code is required"
      }));
    }
  };

  render() {
    const { email, message, messageType, newPassword, confirmPassword, showLoginButton, showBackToLoginLink, showConfirmationCode, confirmationCode } = this.state;
    const emailIsValid = email ? isValidEmail(email) : true;
    const emailErrorState = email ? textInputValidate(email, true, emailIsValid, "Invalid email format") : { validated: 'default', errorText: "" };
    const passwordErrorState = newPassword ? textInputRequiredOnly(newPassword) : { validated: 'default', errorText: "" };
    const confirmPasswordErrorState = confirmPassword ? textInputValidate(confirmPassword, true, newPassword === confirmPassword, "Passwords must match") : { validated: 'default', errorText: "" };
    const emailFormValid = email && emailIsValid;
    const codeFormValid = newPassword && confirmPassword && (newPassword === confirmPassword);

    return (
      <div align="center" className="unauth-container" style={{ backgroundImage: 'url(' + brandImg + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <Flex className="unauth-flex-container" direction={{ default: 'column' }} alignSelf={{ default: 'alignSelfCenter' }}>
          <FlexItem>
            {/* <img src={EthicaLogo} width="260px" alt="Orchestrator Logo" /> */}
            <Spacer />
            <Title headingLevel="h1" size="xl" className="white-text">Forgot Password</Title><br />

            {showConfirmationCode ? 
              <div>
                <p className="white-text">Check your email for the confirmation code and enter it here</p>

                <Card isCompact className="top-card">
                  <CardBody>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="60%">
                            <Form>
                              <FormGroup label="Confirmation Code" isRequired fieldId="confirmationCode"
                                helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(confirmationCode)}>
                                <TextInput className="text-input-long text-input-center"
                                  isRequired type="text" id="confirmationCode" name="confirmationCode" validated={textInputRequiredOnly(confirmationCode)}
                                  value={confirmationCode} onChange={(e) => this.handleChange("confirmationCode", e)}
                                  autoComplete='new-password'
                                />
                              </FormGroup>
                              <FormGroup label="New Password" isRequired fieldId="newPassword"
                                helperTextInvalid={passwordErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={newPassword ? passwordErrorState.validated : 'default'}>
                                <TextInput className="text-input-long text-input-center"
                                  isRequired type="password" id="newPassword" name="newPassword" validated={newPassword ? passwordErrorState.validated : 'default'} 
                                  value={newPassword} onChange={(e) => this.handleChange("newPassword", e)} onKeyPress={this.handleCodeKeyPress}
                                  autoComplete='new-password'
                                />
                              </FormGroup>
                              <FormGroup label="Confirm Password" isRequired fieldId="confirmPassword"
                                helperTextInvalid={confirmPasswordErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={confirmPassword ? confirmPasswordErrorState.validated : 'default'}>
                                <TextInput className="text-input-long text-input-center"
                                  isRequired type="password" id="confirmPassword" name="confirmPassword" validated={confirmPassword ? confirmPasswordErrorState.validated : 'default'} 
                                  value={confirmPassword} onChange={(e) => this.handleChange("confirmPassword", e)} onKeyPress={this.handleCodeKeyPress}
                                  autoComplete='new-password'
                                />
                              </FormGroup>
                            </Form>
                          </td>
                          <td>
                            <PasswordPolicy />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
                
                <br />
                <p className="white-text">{message}</p>
              </div>
            : showLoginButton ? 
              <div>
                <p className="white-text">{message}</p><br />
                <Button onClick={this.handleLoginClick}>Login Now</Button> 
              </div>
            : 
              <div>
                <p className="white-text">Enter your email address and we will send you a confirmation code to verify your identity</p>

                <Card isCompact className="top-card">
                  <CardBody>
                    <Form>
                      <FormGroup label="" isRequired fieldId="email"
                        helperTextInvalid={emailErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={email ? emailErrorState.validated : 'default'}>
                        <TextInput className="text-input-center"
                          isRequired type="text" id="email" name="email" validated={email ? emailErrorState.validated : 'default'}
                          value={email} onChange={(e) => this.handleChange("email", e)} onKeyPress={this.handleEmailKeyPress}
                        />
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card><br />

                <p className="white-text">{message}</p>
              </div>
            }   
            
            {!showLoginButton ? 
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Saver 
                        submitButtonText="Submit" 
                        submitButtonisDisabled={!emailFormValid || (showConfirmationCode && !codeFormValid)}
                        submit={showConfirmationCode ? this.handleSubmitCode : this.handleSubmitEmail} 
                        showCancel={false}
                        showConfirmationMessage={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            : <></> }

            {showBackToLoginLink ? 
              <div>
                <br />
                <a href="/" target="_blank" style={{ color: '#fff' }}>Back to Login</a>
              </div>
            : <></>}
          </FlexItem>
        </Flex>
      </div>
    );
  }
}

export default withRouter(userIsNotAuthenticated(ForgotPassword));