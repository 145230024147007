import gql from "graphql-tag";

// SERVER = GATEWAY

export const ALL_SERVERS = gql`
  query AllServers($gatewayIds: [uuid!]) {
    Server(where: { gatewayId: { _in: $gatewayIds } }) {
      id
      ip4Address
      ip4Cidr
      ip4Router
      PublicIps {
        id
        address
        ip4Cidr
        serverId
      }
      Tunnels {
        id
      }
      gatewayId
      updated_at
    }
  }
`;

export const GET_SERVER = gql`
  query GetServer($id: uuid) {
    Server(where: { id: { _eq: $id } }) {
      id
      Tunnels {
        id
        Links {
          id
        }
      }
      PublicIps {
        id
      }
    }
  }
`;

export const CREATE_SERVER = gql`
  mutation CreateServer($input: [Server_insert_input!]!) {
    insert_Server(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SERVER = gql`
  mutation UpdateServer($id: uuid, $changes: Server_set_input) {
    update_Server(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_SERVER = gql`
  mutation DeleteServer($id: uuid) {
    delete_Server(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

// PublicIp has a one-to-one relationship with Server
export const CREATE_PUBLIC_IP = gql`
  mutation CreatePublicIp($input: [PublicIp_insert_input!]!) {
    insert_PublicIp(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PUBLIC_IP = gql`
  mutation UpdatePublicIp($id: uuid!, $changes: PublicIp_set_input!) {
    update_PublicIp_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
    }
  }
`;

export const DELETE_PUBLIC_IP = gql`
  mutation DeletePublicIp($id: uuid!) {
    delete_PublicIp_by_pk(id: $id) {
      id
    }
  }
`;

