import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get('/gateways');
      return result.data.gateways;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async get(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/gateways/${id}`);
      return result.data.gateway;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post('/gateways', payload);
      return result.data.gateway;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/gateways/${id}`, payload);
      return result.data.gateway;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async delete(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.delete(`/gateways/${id}`);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  }
}
