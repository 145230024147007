import * as React from 'react';
import '../app.css';

export const PasswordPolicy = () => {
  return (
    <div >
      <h2><strong>Password Rules</strong></h2>
      <ul className="password-policy">
        <li>Minimum of 8 characters</li>
        <li>At least one lowercase letter</li>
        <li>At least one uppercase letter</li>
        <li>At least one special character</li>
        <li>At least one numeric character</li>
      </ul>
    </div>
  );
}