import * as React from 'react';
import { AppDispatch } from '@app/store';
import UserApi from '@app/api/user-api/user.actions';
import { textInputRequiredOnly, textInputValidate, isValidPhone } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { PageSection, Title, Card, CardBody, TextInput, Form, FormGroup } from '@patternfly/react-core';
import './Admin.css';
import { connect } from 'react-redux';
import { State } from '@app/store/root-reducer';

interface ProfileState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  isLoading: boolean;
  messageType?: string; 
  responseMessage?: string;
}
class Profile extends React.Component<AppDispatch & { partnerId: string, userId: string }, ProfileState> {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      isLoading:  false,
    }
  }

  componentDidMount = () => {
    try {
      this.loadProfile();
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the user profile"
      }));
    }
  }

  loadProfile = async () => {
    const { userId } = this.props;
    const user = await UserApi.get(userId);

    this.setState(() => ({
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      email: user?.email || "",
      phone: user?.phone || ""
    }));    
  }

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  handleSubmit = async (event) => {
    try {
      const { userId } = this.props;
      const { firstName, lastName, phone } = this.state;
      const payload = {
        first_name: firstName,
        last_name: lastName,
        phone: phone
      }

      const result = await UserApi.update(userId, payload);

      if (result?.request_id) {
        this.setState(() => ({
          message: "User was updated successfully!"
        }));
      }
      else {
        this.setState(() => ({
          message: "There was an error updating the user"
        }));
      }
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error updating the user"
      }));
    }
  }

  render() {
    const { partnerId } = this.props;
    const { firstName, lastName, email, phone, isLoading, responseMessage } = this.state;
    const phoneIsValid = isValidPhone(phone);
    const phoneErrorState = textInputValidate(phone, true, phoneIsValid, "Invalid phone number");
    const formValid = firstName && lastName && phone && phoneIsValid;

    return (
      <PageSection>
        <div className="right"><strong>Partner ID:</strong>&nbsp; {partnerId}</div>
        <Title headingLevel="h1" size="xl">Profile</Title>

        <Card isCompact className="top-card">
          <CardBody>
            <Form isHorizontal>
              <FormGroup label="First Name" isRequired fieldId="firstName"
                helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(firstName)}>
                <TextInput
                  isRequired type="text" id="firstName" name="firstName" validated={textInputRequiredOnly(firstName)}
                  value={firstName} onChange={(e) => this.handleChange("firstName", e)}
                />
              </FormGroup>
              <FormGroup label="Last Name" isRequired fieldId="lastName"
                helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(lastName)}>
                <TextInput
                  isRequired type="text" id="lastName" name="lastName" validated={textInputRequiredOnly(lastName)}
                  value={lastName} onChange={(e) => this.handleChange("lastName", e)}
                />
              </FormGroup>
              <FormGroup label="Email" fieldId="email">
                <TextInput
                  isDisabled type="text" id="email" name="email" value={email} 
                />
              </FormGroup>
              <FormGroup label="Phone Number" isRequired fieldId="phone"
                helperTextInvalid={phoneErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={phoneErrorState.validated}>
                <TextInput
                  isRequired type="phone" id="phone" name="phone" validated={phoneErrorState.validated} 
                  value={phone} onChange={(e) => this.handleChange("phone", e)}
                />
              </FormGroup>
            </Form>
          </CardBody>
        </Card><br />
      </PageSection>
    );
  }
}

export default connect((state: State) => ({
  partnerId: state.common.auth.session?.partnerId,
  userId: state.common.auth.session?.userId,
}))(Profile);
