import React from "react";
import ReactDOM from "react-dom";
import { registerSelectors } from 'reselect-tools'
import App from '@app/index';

import * as configurationSelectors from './app/common/configuration/configuration.selectors';

registerSelectors(configurationSelectors);

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
