import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import License from '@app/api/license/license.actions';
import UserSession from '@app/common/user-session';
import ProductCard from '@app/components/ProductCard';
import Partner from '@app/api/partner-api/partner.actions';
import { isValidNumber } from '@app/lib/validator';
import { MAX_LICENSE_QUANTITY, DEFAULT_UUID } from '@app/lib/constants';
import { BullhornIcon, ExclamationCircleIcon } from '@patternfly/react-icons';
import { Button, Flex, FlexItem, PageSection, Title, Card, CardBody, Modal, ModalVariant, TextInput, Form, FormGroup, Radio, Select, SelectOption, SelectVariant, Spinner } from '@patternfly/react-core';

export const LicensePurchase = connect()(class extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      product: { name: '', description: '' },
      quantity: '1',
      intervals: {},
      interval: 'month',
      currency: '',
      isConfirmOpen: false,
      isPurchaseOpen: false,
      isPaymentMethodRequiredOpen: false,
      error: '',
      message: '',
      messageType: '',
      dataLoading: true,
      modalLoading: false
    }
  }

  componentDidMount = async () => {
    const partnerId = UserSession.getPartnerId();
    const products = await License.getProducts(partnerId);
    let productCurrency = 'usd';
    let intervals = {};

    products.map(product => {
      intervals[product.id] = 'month-' + product.id;
      productCurrency = product.prices[0].currency;

      if (product.prices.length === 1) {
        intervals[product.id] = product.prices[0].recurring?.interval + '-' + product.id;
      }
    });

    this.setState(() => ({
      products: products,
      intervals: {...intervals},
      currency: productCurrency,
      dataLoading: false
    })); 
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value 
    });
  }

  handleCancel = () => {
    this.props.history.push('/licenses');
  }

  productClick = (product) => {
    const { intervals } = this.state;

    this.setState(() => ({
      isPurchaseOpen: !this.state.isPurchaseOpen,
      product: {...product}
    }));
  }

  productConfirm = async () => {
    this.setState(() => ({
      modalLoading: true
    }));

    const partnerId = UserSession.getPartnerId();
    const paymentMethod = await Partner.getPaymentMethods(partnerId);
    const partner = await Partner.get(partnerId);
    const confirmPurchase = false;
    const paymentMethodRequired = true;

    if (paymentMethod?.length || partner.billing_method === 'invoice') {
      confirmPurchase = true;
      paymentMethodRequired = false;
    }

    this.setState(() => ({
      isPurchaseOpen: false,
      isConfirmOpen: confirmPurchase,
      isPaymentMethodRequiredOpen: paymentMethodRequired,
      modalLoading: false
    }));
  }

  paymentMethodRequired = () => {
    this.setState(() => ({
      isPaymentMethodRequiredOpen: false
    }));
  }

  payloadFromState = () => {
    const { product, quantity, interval } = this.state;
    const price = product.prices.find(price => price.recurring.interval === interval);

    return {
      product_id: product.id,
      price_id: price.id,
      quantity: parseInt(quantity)
    }
  }

  purchaseLicense = async () => {
    const { quantity } = this.state;

    this.setState(() => ({
      modalLoading: true
    }));
    
    try {
      const payload = this.payloadFromState();
      const partnerId = UserSession.getPartnerId();
      let result = await License.purchase(partnerId, payload);
      this.props.history.push('/licenses');
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        modalLoading: false,
        isConfirmOpen: false,
        message: 'There was an error purchasing the License(s)'
      }));
    }
  }

  handleConfirmToggle = () => {
    this.setState(() => ({
      isConfirmOpen: !this.state.isConfirmOpen
    }));
  };

  handlePurchaseToggle = () => {
    this.setState(() => ({
      isPurchaseOpen: !this.state.isPurchaseOpen
    }));
  };

  handlePaymentToggle = () => {
    this.setState(() => ({
      isPaymentOpen: !this.state.isPaymentOpen
    }));
  };

  handlePaymentMethodRequiredToggle = () => {
    this.setState(() => ({
      isPaymentMethodRequiredOpen: !this.state.isPaymentMethodRequiredOpen
    }));
  };

  handlePurchasedIntervalChange = (value, event) => {
    this.setState(() => ({
      interval: value
    }));
  }

  handleIntervalChange = (value, event) => {
    const { intervals } = this.state;
    const intervalParts = value.split('-');
    const selectedInterval = intervalParts[0];
    const productId = intervalParts[1];

    intervals[productId] = value;

    this.setState(() => ({
      intervals: { ...intervals },
      interval: selectedInterval
    }));
  }

  getProductCards = () => {
    const { intervals, currency } = this.state;

    return this.state.products.map((product) => {
      return (
        <FlexItem key={product.id} className='product-card-container'>
          <ProductCard 
            key={product.id}
            submit={(p) => this.productClick(product)}
            product={product}
            intervals={intervals}
            currency={currency}
            handleIntervalChange={this.handleIntervalChange}
          />
        </FlexItem>
      );
    });
  };

  render() {
    const { quantity, interval, intervals, assigned_to, products, product, isPurchaseOpen, isConfirmOpen, isPaymentMethodRequiredOpen, message, messageType, dataLoading, modalLoading } = this.state;
    const productsCards = this.getProductCards();
    const quantityValid = quantity && isValidNumber(quantity) && parseInt(quantity) >= 1 && parseInt(quantity) <= MAX_LICENSE_QUANTITY;
    const quantityTooHigh = quantity && parseInt(quantity) > MAX_LICENSE_QUANTITY;
    const quantityErrorState = quantityValid ? { validated: 'default', errorText: '' } : quantityTooHigh ? { validated: 'error', errorText: ' Quantity too high' }  : { validated: 'error', errorText: ' Must be a positive number' };

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">Purchase New License</Title><br />

        <Card isCompact>
          <CardBody>
            <Flex>
              {dataLoading ? <Spinner size="lg" /> : productsCards}
            </Flex>
          </CardBody>
        </Card>

        <Modal
          title="Payment Method Required"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isPaymentMethodRequiredOpen}
          onClose={this.handlePaymentMethodRequiredToggle}
          actions={[
            <Button key="confirm" variant="primary" onClick={this.paymentMethodRequired}>OK</Button>
          ]}
        >
          <p>A Payment Method must be setup before products can be purchased. </p><br />
          <p><Link to='/admin?tab=5'>Add Payment Method here</Link></p>
        </Modal>

        <Modal
          title="Confirm Purchase"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isConfirmOpen}
          onClose={this.handleConfirmToggle}
          actions={[
            <Button key="confirm" variant="primary" onClick={this.purchaseLicense}>Yes</Button>,
            <Button key="cancel" variant="link" onClick={this.handleConfirmToggle}>Cancel</Button>
          ]}
        >
          <p>Are you sure you want to purchase {quantity} {product.name} licenses?</p>

          {modalLoading ? <Spinner size="md" /> : <div></div>}
        </Modal>

        <Modal
          title="Purchase Licenses"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isPurchaseOpen}
          onClose={this.handlePurchaseToggle}
          actions={[
            <Button key="confirm" variant="primary" isDisabled={!quantityValid} onClick={this.productConfirm}>Purchase</Button>,
            <Button key="cancel" variant="link" onClick={this.handlePurchaseToggle}>Cancel</Button>
          ]}
        >
          <p>Please specify the quantity of "{product.name}" licenses to purchase, and desired payment interval:</p><br />

          <FormGroup label="Quantity" isRequired fieldId="quantity"
            helperTextInvalid={quantityErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={quantityErrorState.validated}>
            <TextInput
              isRequired type="text" id="quantity" name="quantity" validated={quantityErrorState.validated}
              value={quantity} onChange={(e) => this.handleChange("quantity", e)}
            />
          </FormGroup>
          <FormGroup label="Payment Interval" isRequired fieldId="interval">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Radio
                      className="form-input"
                      isChecked={interval.startsWith('month')}
                      name={'month-' + product.id}
                      id={'month-' + product.id}
                      isDisabled={false}
                      onChange={(e) => this.handlePurchasedIntervalChange('month', e)}
                      label="Monthly"
                      value={'month-' + product.id}
                    />
                  </td>
                  <td>
                    <Radio
                      className="form-input"
                      isChecked={interval.startsWith('year')}
                      name={'year-' + product.id}
                      id={'year-' + product.id}
                      isDisabled={false}
                      onChange={(e) => this.handlePurchasedIntervalChange('year', e)}
                      label="Yearly"
                      value={'year-' + product.id}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormGroup>

          {modalLoading ? <Spinner size="md" /> : <div></div>}
        </Modal>
      </PageSection>
    );
  }
})
