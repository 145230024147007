import gql from "graphql-tag";

export const ALL_SITES = gql`
  query AllSites($clientCompanyIds: [uuid!]) {
    Site(where: { clientCompanyId: { _in: $clientCompanyIds }}, order_by: { address1: asc }) {
      id
      osVersion
      address1
      address2
      postalCode
      country
      city
      state
      isServerless
      ipAccessLevel
      accessibleIps
      clientCompanyId
      wanId
      ClientCompany {
        id
        name
      }
      Tunnels(order_by: { isPrimary: desc }) {
        id
        isPrimary
        cidr
        downloadCap
        hasTrafficManagement
        interfaceName
        isCapEnabled
        isClearTextData
        isQOSEnabled
        lossTolerance
        mtu
        shouldReorderBuffer
        reorderBufferTime
        status
        timeout
        tunnelPassword
        tuntap
        uploadCap
        serverId
        autoMTU
        portRangeStart
        portRangeEnd
        Server {
          id
          gatewayId
          ip4Address
          ip4Cidr
          ip4Router
        }
        Links {
          id
          type
          carrierName
          isDHCP
          staticIp
          staticDnsIp
          staticGatewayIp
          cpeBindIp
          dataLimit
          dataLimitReset
          dataLimitUsed
          downloadThroughput
          downloadThroughputCap
          interfaceName
          isCapped
          isCapEnabled
          isEnabled
          isEncrypted
          isMetered
          isPassthrough
          isStandby
          name
          notes
          serverBindIp
          serverPort
          state
          uploadThroughput
          uploadThroughputCap
          modemAPN
          isPPPoE
          isWWAN
          pppoeUsername
          pppoePassword
        }
      }
      SiteLans {
        id
        lanSegmentIp4
        dhcpDefaultLease
        dhcpMaxLease
        dhcpRangeEnd
        dhcpRangeStart
        dnsServer2
        dnsServer1
        isDhcpRequired
        lanCidrIp4
        isDMZ
        ip4DMZ
        isAP
        interfaceName
        vlanId
        apMode
        apSSID
        apCountryCode
        apChannel
        apPassword
        ap5SSID
        ap5Channel
        ap5Password
      }
      SiteRoutes {
        id
        subnet
        routerIP
        routerInterface
        metric
        isActive
        isInbound
      }
      SiteContacts {
        id
        label
        lastName
        phone
        ext
        email
        emailAlert
        firstName
      }
      Wan {
        id
        name
      }
    }
  }
`;

export const CREATE_SITE = gql`
  mutation CreateSite($input: [Site_insert_input!]!) {
    insert_Site(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const GET_SITE = gql`
  query GGW($id: uuid) {
    Site(where: { id: { _eq: $id } }) {
      address1
      Tunnels {
        id
        serverId
        isPrimary
        portRangeStart
        portRangeEnd
        Links {
          id
          type
          carrierName
          isDHCP
          cpeBindIp
          dataLimit
          dataLimitReset
          dataLimitUsed
          downloadThroughput
          downloadThroughputCap
          interfaceName
          isCapped
          isCapEnabled
          isEnabled
          isEncrypted
          isMetered
          isPassthrough
          isStandby
          name
          notes
          serverBindIp
          serverPort
          state
          uploadThroughput
          uploadThroughputCap
          modemAPN
          isPPPoE
          isWWAN
          pppoeUsername
          pppoePassword
        }
      }
      SiteContacts {
        id
      }
      SiteLans {
        id
      }
      SiteRoutes {
        id
      }
    }
  }
`;

export const GET_SITES_COUNT_BY_WAN = gql`
  query GetSitesByWan($wanId: uuid) {
    Site(where: { wanId: { _eq: $wanId } }) {
      id
    }
  }
`;

export const GET_SITES_BY_WAN = gql`
  query GetSitesByWan($wanId: uuid) {
    Site(where: { wanId: { _eq: $wanId } }) {
      id
      osVersion
      address1
      address2
      postalCode
      country
      city
      state
      isServerless
      accessibleIps
      ipAccessLevel
      clientCompanyId
      ClientCompany {
        id
        name
      }
      Tunnels(order_by: { isPrimary: desc }) {
        id
        isPrimary
        cidr
        downloadCap
        hasTrafficManagement
        interfaceName
        isCapEnabled
        isClearTextData
        isQOSEnabled
        lossTolerance
        mtu
        shouldReorderBuffer
        reorderBufferTime
        status
        timeout
        tunnelPassword
        tuntap
        uploadCap
        serverId
        portRangeStart
        portRangeEnd
        Server {
          id
          gatewayId
          ip4Address
          ip4Cidr
          ip4Router
        }
        Links {
          id
          type
          carrierName
          isDHCP
          cpeBindIp
          dataLimit
          dataLimitReset
          dataLimitUsed
          downloadThroughput
          downloadThroughputCap
          interfaceName
          isCapped
          isCapEnabled
          isEnabled
          isEncrypted
          isMetered
          isPassthrough
          isStandby
          name
          notes
          serverBindIp
          serverPort
          state
          uploadThroughput
          uploadThroughputCap
          modemAPN
          isPPPoE
          pppoeUsername
          pppoePassword
        }
      }
      SiteLans {
        id
        lanSegmentIp4
        dhcpDefaultLease
        dhcpMaxLease
        dhcpRangeEnd
        dhcpRangeStart
        dnsServer2
        dnsServer1
        isDhcpRequired
        lanCidrIp4
        isDMZ
        ip4DMZ
        isAP
        vlanId
        apMode
        apSSID
        apCountryCode
        apChannel
        apPassword
        ap5SSID
        ap5Channel
        ap5Password
      }
      SiteRoutes {
        id
        subnet
        routerIP
        routerInterface
        metric
        isActive
        isInbound
      }
      SiteContacts {
        id
        label
        lastName
        phone
        ext
        email
        emailAlert
        firstName
      }
    }
  }
`;

export const UPDATE_SITE = gql`
  mutation UpdateSite($id: uuid, $changes: Site_set_input) {
    update_Site(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_SITE = gql`
  mutation DeleteSite($id: uuid) {
    delete_Site(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_SITE_LANS = gql`
  query GetSiteLans($siteId: uuid) {
    SiteLan(where: { siteId: { _eq: $siteId } }) {
      id
      lanSegmentIp4
      dhcpDefaultLease
      dhcpMaxLease
      dhcpRangeEnd
      dhcpRangeStart
      dnsServer2
      dnsServer1
      isDhcpRequired
      interfaceName
      lanCidrIp4
      isDMZ
      ip4DMZ
      isAP
      vlanId
      apMode
      apSSID
      apCountryCode
      apChannel
      apPassword
      ap5SSID
      ap5Channel
      ap5Password
    }
  }
`;

export const CREATE_SITE_LAN = gql`
  mutation CreateSiteLan($input: [SiteLan_insert_input!]!) {
    insert_SiteLan(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SITE_LAN = gql`
  mutation UpdateSiteLan($id: uuid, $changes: SiteLan_set_input) {
    update_SiteLan(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_SITE_LAN = gql`
  mutation DeleteSiteLan($id: uuid) {
    delete_SiteLan(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CREATE_SITE_ROUTE = gql`
  mutation CreateSiteRoute($input: [SiteRoute_insert_input!]!) {
    insert_SiteRoute(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SITE_ROUTE = gql`
  mutation UpdateSiteRoute($id: uuid, $changes: SiteRoute_set_input) {
    update_SiteRoute(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_SITE_ROUTE = gql`
  mutation DeleteSiteRoute($id: uuid) {
    delete_SiteRoute(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CREATE_SITE_CONTACT = gql`
  mutation CreateSiteContact($input: [SiteContact_insert_input!]!) {
    insert_SiteContact(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SITE_CONTACT = gql`
  mutation UpdateSiteContact($id: uuid, $changes: SiteContact_set_input) {
    update_SiteContact(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_SITE_CONTACT = gql`
  mutation DeleteSiteContact($id: uuid) {
    delete_SiteContact(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_TUNNEL = gql`
  mutation DeleteTunnel($id: uuid) {
    delete_Tunnel(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_LINK = gql`
  query GetLink($id: uuid) {
    Link(where: { id: { _eq: $id } }) {
      id
      type
      carrierName
      isDHCP
      cpeBindIp
      dataLimit
      dataLimitReset
      dataLimitUsed
      downloadThroughput
      downloadThroughputCap
      interfaceName
      isCapped
      isCapEnabled
      isEnabled
      isEncrypted
      isMetered
      isPassthrough
      isStandby
      name
      notes
      serverBindIp
      serverPort
      state
      uploadThroughput
      uploadThroughputCap
      modemAPN
      isPPPoE
      pppoeUsername
      pppoePassword
    }
  }
`;

export const CREATE_LINK = gql`
  mutation CreateLink($input: [Link_insert_input!]!) {
    insert_Link(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_LINK = gql`
  mutation UpdateLink($id: uuid, $changes: Link_set_input) {
    update_Link(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_LINK = gql`
  mutation DeleteLink($id: uuid) {
    delete_Link(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
