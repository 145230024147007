import * as React from 'react';
import { Helmet } from 'react-helmet';
import UserSession from '@app/common/user-session';
import { PageSection, Title, Card, CardBody } from '@patternfly/react-core';
import IframeResizer from 'iframe-resizer-react'

import './Dashboard.css';

class DashboardView extends React.Component {
  constructor(props) {
    super(props);

    const partnerId = UserSession.getPartnerId();

    this.state = {
      partnerId: partnerId,
      siteCountLoading: true,
      panelIds: {
        totalWans: 1,
        totalControllers: 2,
        totalSites: 3,
        industries: 7,
        carriers: 6,
        linkTypes: 8,
        siteStatus: 9,
        cpu: 10,
        bandwidth: 11
      }
    }
  }

  render() {
    const { partnerId, siteCountLoading, panelIds } = this.state;
    const grafanaLink = process.env.REACT_APP_GRAFANA_LINK.replace('{{partner_id}}', partnerId)

    return (
      <PageSection>
        <h1><strong>Site Status</strong></h1>
        <hr className="heading-rule" />
        <iframe 
          id="site-status-iframe"
          allowtransparency="true"
          frameBorder="0"
          title="Site Alerts"
          src={grafanaLink + panelIds.siteStatus} 
          width="100%"
          className="site-status iframe-auto-scroll"
        />

        <h1><strong>Controller Performance</strong></h1>
        <hr className="heading-rule" />
        <iframe 
          allowtransparency="true"
          frameBorder="0"
          title="CPU"
          src={grafanaLink + panelIds.cpu}
          width="50%"
          className="controller-performance iframe-auto-scroll"
        />
        <iframe 
          allowtransparency="true"
          frameBorder="0"
          title="Bandwidth"
          src={grafanaLink + panelIds.bandwidth}
          width="50%"
          className="controller-performance iframe-auto-scroll"
        />

        <h1><strong>Customer Configurations</strong></h1>
        <hr className="heading-rule" />
        <div className="pie-container">
          <iframe
            allowtransparency="true"
            frameBorder="0"
            title="Industries"
            src={grafanaLink + panelIds.industries}
            className="pie-graph iframe-auto-scroll"
          />
        </div>
        <div className="pie-container">
          <iframe
            allowtransparency="true"
            frameBorder="0"
            title="Carriers"
            src={grafanaLink + panelIds.carriers}
            className="pie-graph iframe-auto-scroll"
          />
        </div>
        <div className="pie-container">
          <iframe
            allowtransparency="true"
            frameBorder="0"
            title="Link Types"
            src={grafanaLink + panelIds.linkTypes}
            className="pie-graph iframe-auto-scroll"
          />
        </div>

        <h1><strong>Overview</strong></h1>
        <hr className="heading-rule" />
        <iframe
          allowtransparency="true"
          frameBorder="0"
          title="Total WANs"
          src={grafanaLink + panelIds.totalWans}
          width="16%"
        />
        <iframe
          allowtransparency="true"
          frameBorder="0"
          title="Total Controllers"
          src={grafanaLink + panelIds.totalControllers}
          width="16%"
        />
        <iframe
          allowtransparency="true"
          frameBorder="0"
          title="Total Sites"
          src={grafanaLink + panelIds.totalSites}
          width="16%"
        />

      </PageSection>
    );
  }
}

export { DashboardView };
