import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Spinner } from '@patternfly/react-core';
import { sortable, cellWidth } from '@patternfly/react-table';
import { PageSection, Title, Button, Modal, ModalVariant } from '@patternfly/react-core';
import { BullhornIcon } from '@patternfly/react-icons';
import { PatternflyTable } from '../../components/PatternflyTable';
import { ErrorView } from '../error/error';
import { useWanContext } from '../../contexts/wan.context';
import { useSearch } from '../../lib/use-search';
import {
  controllerSelectors,
  serverSelectors,
  siteSelectors,
  wanSelectors,
} from '../../common/configuration/configuration.selectors';
import { actionCreators } from '../../common/configuration/configuration.reducer';
import { Wan } from '../../types';

export const WanList: React.FC = () => {
  const dispatch = useDispatch();
  const { isFailed, isLoading, isSuccess } = useWanContext();

  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, actionId: '' });

  const wans = useSearch(useSelector(wanSelectors.selectAll));
  const sites = useSelector(siteSelectors.selectAll);
  const serverById = useSelector(serverSelectors.selectEntities);
  const controllerById = useSelector(controllerSelectors.selectEntities);

  const getColumns = () => {
    return [
      { title: 'Name', transforms: [sortable] },
      { title: 'Sites' },
      { title: 'Controller' },
      { title: 'Public IP', transforms: [sortable] },
      { title: 'WAN ID' },
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  };

  const wanFromItem = React.useCallback(
    (wan: Wan) => {
      const wanSites = sites.filter(({ wanId }) => wanId === wan.id);
      const wanController = controllerById[wan.activeGatewayId];
      const wanGateway = serverById[wan.activeServerId];
      return {
        cells: [
          { title: <Link to={{ pathname: `/wan/${wan.id}` }}>{wan.name}</Link> },
          { title: wanSites.length },
          { title: wanController?.name },
          { title: wanGateway?.ip4Address },
          { title: wan.id },
        ],
      };
    },
    [sites, serverById, controllerById]
  );

  const editWan = React.useCallback(
    (_, __, data) => {
      const wanId = data['wan-id']['title'];
      dispatch(push(`/wan/edit/${wanId}`));
    },
    [dispatch]
  );

  const deleteWan = React.useCallback(
    (_, __, data) => {
      const wanId = data['wan-id']['title'];
      setConfirmOpen({ isOpen: !confirmOpen.isOpen, actionId: wanId });
    },
    [confirmOpen, setConfirmOpen]
  );

  const deleteRow = React.useCallback(() => {
    dispatch(actionCreators.wanDeleteRequest(confirmOpen.actionId));
    setConfirmOpen({ isOpen: false, actionId: '' });
  }, [dispatch, confirmOpen, setConfirmOpen]);

  if (isFailed) {
    return <ErrorView title="Failed to load WAN" message="🤖 Failed to load WAN" />;
  }

  if (isLoading && !isSuccess) {
    return <Spinner size="lg" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>WANs</title>
      </Helmet>
      <PageSection>
        <Title headingLevel="h1" size="xl">
          WANs
        </Title>
        <br />

        {(wans?.length ?? 0) === 0 ? (
          <div>No WANs found!</div>
        ) : (
          <div>
            <PatternflyTable
              columns={getColumns}
              editRow={editWan}
              deleteRow={deleteWan}
              data={wans.map(wanFromItem)}
              page={1}
            />
          </div>
        )}
        <Modal
          title="Confirm Action"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={confirmOpen.isOpen}
          onClose={() => setConfirmOpen({ isOpen: false, actionId: '' })}
          actions={[
            <Button key="confirm" variant="primary" onClick={deleteRow}>
              Yes
            </Button>,
            <Button
              key="cancel"
              variant="link"
              onClick={() => {
                setConfirmOpen({ isOpen: false, actionId: '' });
              }}
            >
              Cancel
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this WAN?</p>
          <p>
            <b>{confirmOpen.actionId}</b>
          </p>
        </Modal>
      </PageSection>
    </React.Fragment>
  );
};
