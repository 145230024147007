import { call, fork, put, retry, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import ClientCompany from '@app/api/client-company/client-company.actions';

import { actionCreators, actionTypes } from '../auth/auth.reducer';
import { actionCreators as sessionActionCreators } from '../session/session.reducer';
import * as cognito from '../../lib/cognito';
import UserSession from '@app/common/user-session';
import PartnerApi from '@app/api/partner-api/partner.actions';
import { selectPartnerId, selectPartnerPreferences } from '../session/session.selectors';
import { API_RETRY_ATTEMPTS, API_RETRY_INTERVAL } from '@app/lib/constants';
import { State } from '@app/store/root-reducer';

import { persistor } from '@app/store';

const claimsKey = process.env.REACT_APP_CLAIMS_KEY!;

function* watchLoginRequest() {
  for (; ;) {
    try {
      const {
        payload: { username, password },
      } = yield take(actionTypes.SIGNIN_REQUEST);

      const cognitoSession = yield call(cognito.authenticateUser, username, password);

      const idToken = cognitoSession.getIdToken();
      const token = idToken.getJwtToken();
      const claims = JSON.parse(idToken.payload[claimsKey]);
      const expiration = new Date(0).setUTCSeconds(idToken.payload.exp);
      const partnerId = claims['x-hasura-partner-id'];
      const partnerName = claims['x-hasura-partner-name'];

      UserSession.setParams({
        userName: username,
        token: token,
        isImpersonating: false,
        accessToken: cognitoSession.getAccessToken().getJwtToken(),
        refreshToken: cognitoSession.getRefreshToken().getToken(),
        role: claims['x-hasura-default-role'],
        userId: claims['x-hasura-user-id'],
        cognitoId: cognitoSession.getAccessToken().payload.sub,
        partnerName: partnerName,
        partnerId: partnerId,
        expiration: expiration,
      });

      const clientCompanies = yield call(ClientCompany.getAll, partnerId);

      UserSession.setParam(
        'clientCompanyIds',
        clientCompanies.map((c) => c.id)
      );

      yield put(actionCreators.signInSuccess(cognitoSession));
    } catch (error) {
      console.log(error)
      if ((error as any).code === 'PasswordResetRequiredException') {
        yield put(push('/auth/new-password'));
      }

      yield put(actionCreators.signInFailure(error));
    }
  }
}

function* watchLogoutRequest() {
  for (; ;) {
    try {
      yield take(actionTypes.LOGOUT_REQUEST);

      yield call(cognito.signOut);
      yield call(UserSession.clear);
      yield call(persistor.purge);

      yield put(actionCreators.logoutSuccess());
      yield put(push('/auth/login'));
    } catch (error) {
      yield put(actionCreators.logoutFailure(error));
    }
  }
}

function* watchPreferencesUpdateRequest() {
  for (; ;) {
    const {
      payload: { partnerPreferences },
    } = yield take(actionTypes.PREFERENCES_UPDATE_REQUEST);

    try {
      const partnerId = yield select(selectPartnerId);

      yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, PartnerApi.updatePreferences, partnerId, {
        logo: partnerPreferences.logoFile,
        favicon: partnerPreferences.faviconFile,
        pageTitle: partnerPreferences.pageTitle,
        headerColor: partnerPreferences.headerColor
      });

      yield put(actionCreators.preferencesUpdateSuccess(partnerPreferences));

      // Reload preferences
      const preferences = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, PartnerApi.getPreferences, partnerId);

      yield put(actionCreators.loadPreferencesSuccess({
        partnerId: preferences.partner_id,
        logoFilename: preferences.logo_filename,
        faviconFilename: preferences.favicon_filename,
        headerColor: preferences.header_color,
        pageTitle: preferences.page_title,
        logoFileUrl: preferences.logo_filename_url,
        faviconFileUrl: preferences.favicon_filename_url
      }));

      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Preferences Updated',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (error) {
      yield put(actionCreators.preferencesUpdateFailure(error));
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Preferences Update Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
  }
}

function* watchTokenRefreshRequest() {
  for (; ;) {
    yield take(actionTypes.TOKEN_REFRESH_REQUEST);
    try {
      const refreshToken = yield select((state: State) => state.common.auth.session?.refreshToken);
      const session = yield call(cognito.refreshSession, refreshToken);

      const idToken = session.getIdToken();
      const token = idToken.getJwtToken();
      const claims = JSON.parse(idToken.payload[claimsKey]);
      const expiration = new Date(0).setUTCSeconds(idToken.payload.exp);
      const partnerId = claims['x-hasura-partner-id'];
      const partnerName = claims['x-hasura-partner-name'];
      const userName = UserSession.getParam('userName');

      UserSession.setParams({
        userName: userName,
        token: token,
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
        role: claims['x-hasura-default-role'],
        userId: claims['x-hasura-user-id'],
        cognitoId: session.getAccessToken().payload.sub,
        partnerName: partnerName,
        partnerId: partnerId,
        expiration: expiration,
      });

      // Reload preferences
      const preferences = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, PartnerApi.getPreferences, partnerId);

      if (preferences?.partner_id) {
        yield put(actionCreators.loadPreferencesSuccess({
          partnerId: preferences.partner_id,
          logoFilename: preferences.logo_filename,
          faviconFilename: preferences.favicon_filename,
          headerColor: preferences.header_color,
          pageTitle: preferences.page_title,
          logoFileUrl: preferences.logo_filename_url,
          faviconFileUrl: preferences.favicon_filename_url
        }));

        UserSession.setParam('partnerId', preferences.partner_id);
        UserSession.setParam('logoFileUrl', preferences.logo_filename_url);
        UserSession.setParam('faviconFileUrl', preferences.favicon_filename_url);
        UserSession.setParam('pageTitle', preferences.page_title);
        UserSession.setParam('headerColor', preferences.header_color);
        UserSession.setParam('logoFilename', preferences.logo_filename);
        UserSession.setParam('faviconFilename', preferences.favicon_filename);
      }

      yield put(actionCreators.tokenRefreshSuccess(session))
    } catch (error) {
      console.log('Error refreshing session');
      console.log(error);
      yield put(actionCreators.tokenRefreshFailure(error));
      yield put(actionCreators.logoutRequest());
    }
  }
}

export function* authSaga() {
  yield fork(watchLoginRequest);
  yield fork(watchLogoutRequest);
  yield fork(watchPreferencesUpdateRequest);
  yield fork(watchTokenRefreshRequest);
}
