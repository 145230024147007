import * as React from 'react';
import { Link } from 'react-router-dom';
import './InfoSection.css';

interface InfoSectionProps {
  renderRows?: (prefix: string, obj: any) => React.ReactNode;
  showHeading?: boolean;
  headerRowClass?: string;
  keyPrefix?: string;
  keyValuePairs: any;
  heading?: string;
  icon?: React.ReactNode;
}

const getTableRows = (keyPrefix, keyValuePairs) => {
  return keyValuePairs.map((obj, index) => {
    const valueClass =
      obj.value === 'Enabled' || obj.value === 'No'
        ? 'enabled'
        : obj.value === 'Disabled' || obj.value === 'Yes'
        ? 'disabled'
        : '';
        
    // need better solution as this doesn't support i18n
    if (obj.label === 'WAN Name') {
      return (
        <tr className="info-section-row" key={keyPrefix + '-' + (index + 2)}>
          <td className="info-section-key">{obj.label}</td>
          <td className={'info-section-value ' + valueClass}>
            <strong><Link to={`/wan/${obj.id}`}>{obj.value}</Link></strong>
          </td>
        </tr>
      );
    }

    return (
      <tr className="info-section-row" key={keyPrefix + '-' + (index + 2)}>
        <td className="info-section-key">{obj.label}</td>
        <td className={'info-section-value ' + valueClass}>
          <strong>{obj.value}</strong>
        </td>
      </tr>
    );
  });
};

export const InfoSection: React.FC<InfoSectionProps> = ({
  headerRowClass = 'info-section-header-row',
  keyValuePairs,
  renderRows,
  showHeading,
  heading,
  icon,
}) => {
  const headerClass = showHeading ? headerRowClass : 'info-section-header-row-no-border';
  const keyPrefix = heading ? heading.replace(' ', '-').toLowerCase() : 'info-section';
  const tableRows =
    typeof renderRows === 'function' ? renderRows(keyPrefix, keyValuePairs) : getTableRows(keyPrefix, keyValuePairs);
  return (
    <div>
      <table className="info-section">
        <tbody>
          {showHeading ? (
            <tr className={headerClass} key={keyPrefix + '-1'}>
              <td width="100%" colSpan={2}>
                {icon} &nbsp;&nbsp;<strong>{heading}</strong>
              </td>
            </tr>
          ) : (
            <tr>
              <td></td>
            </tr>
          )}
          {tableRows}
        </tbody>
      </table>
    </div>
  );
};