import { ALL_CLIENT_COMPANIES, ALL_CLIENT_COMPANY_IDS } from '@app/api/client-company/gql.queries';
import { fork, put, take, select, retry, call, delay } from 'redux-saga/effects';
import { selectPartnerId } from '../session/session.selectors';
import { actionCreators, actionTypes } from './configuration.reducer';
import { actionCreators as sessionActionCreators } from '../session/session.reducer';
import { ALL_WANS, DELETE_WAN } from '@app/api/wan/gql.queries';
import { API_RETRY_ATTEMPTS, API_RETRY_INTERVAL } from '@app/lib/constants';
import _map from 'lodash-es/map';
import {
  controllerSelectors,
  selectControllerById,
  selectControllerServers,
  siteSelectors,
  wanSelectors,
} from './configuration.selectors';
import { push } from 'connected-react-router';

import ClientCompany from '../../api/client-company/client-company.actions';
import ControllerApi from '../../api/controller-api/controller.actions';
import Site from '../../api/site/site.actions';
import Server from '../../api/gateway/gateway.actions';
import Wan from '../../api/wan/wan.actions';
import Partner from '../../api/partner/partner.actions';
import Tunnel from '../../api/tunnel/tunnel.actions';

import { apolloClient } from '../../api/graphql/client';
import { GQLHelpers } from '@app/api/hasura/ts';

function* watchSiteRequest() {
  for (;;) {
    yield take(actionTypes.SITE_REQUEST);
    try {
      const partnerId = yield select(selectPartnerId);

      const sites = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Site.getAll, partnerId);

      yield put(actionCreators.siteSuccess(sites));
    } catch (error) {
      yield put(actionCreators.siteFailure(error));
    }
  }
}

function* watchWanRequest() {
  for (;;) {
    yield take(actionTypes.WAN_REQUEST);
    try {
      const partnerId = yield select(selectPartnerId);

      const wans = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Wan.getAll, partnerId);

      yield put(actionCreators.wanSuccess(wans));
    } catch (error) {
      yield put(actionCreators.wanFailure(error));
    }
  }
}

function* watchControllerRequest() {
  for (;;) {
    yield take(actionTypes.CONTROLLER_REQUEST);
    try {
      const gateways = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, ControllerApi.getAll);

      yield put(actionCreators.controllerSuccess(gateways));
    } catch (error) {
      yield put(actionCreators.controllerFailure(error));
    }
  }
}

function* watchTunnelRequest() {
  for (;;) {
    yield take(actionTypes.TUNNEL_REQUEST);
    try {
      const tunnels = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Tunnel.getAll);

      yield put(actionCreators.tunnelSuccess(tunnels));
    } catch (error) {
      yield put(actionCreators.tunnelFailure(error));
    }
  }
}

function* watchControllerDeleteRequest() {
  for (;;) {
    const {
      payload: { controllerId },
    } = yield take(actionTypes.CONTROLLER_DELETE_REQUEST);

    const servers = yield select(selectControllerServers(controllerId));
    const wans = (yield select(wanSelectors.selectAll)).filter(
      (wan) => wan?.activeGatewayId === controllerId || wan?.standbyGatewayId === controllerId
    );

    if (wans.length > 0) {
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: `This Controller is currently configured to the WAN named: '${wans[0].name}'`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    } else if (servers['assigned'].length > 0) {
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'This Controller has gateways that are currently active.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    } else {
      try {
        yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, ControllerApi.delete, controllerId);
        yield put(actionCreators.controllerDeleteSuccess(controllerId));
        yield put(
          sessionActionCreators.notificationEnqueue({
            message: 'Deleted Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      } 
      catch (error) {
        yield put(actionCreators.controllerDeleteFailure(error));
        yield put(
          sessionActionCreators.notificationEnqueue({
            message: 'Delete Failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          })
        );
      }
    }
  }
}

function* watchControllerCreateRequest() {
  for (;;) {
    const {
      payload: { values },
    } = yield take(actionTypes.CONTROLLER_CREATE_REQUEST);
    try {
      const controller = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Controller.create, values);
      yield put(actionCreators.controllerCreateSuccess(controller));
      yield put(push(`/controller/${controller.id}`));
      yield delay(1000);
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Controller Created',
          options: {
            key: new Date().getTime() + Math.random(),
          },
        })
      );
    } catch (error) {
      yield put(actionCreators.controllerCreateFailure(error));
    }
  }
}

function* watchControllerPatchRequest() {
  for (;;) {
    const {
      payload: { values },
    } = yield take(actionTypes.CONTROLLER_PATCH_REQUEST);
    try {
      yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Controller.update, values, 4);
      yield put(actionCreators.controllerPatchSuccess());
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Controller Updated',
          options: {
            key: new Date().getTime() + Math.random(),
          },
        })
      );
    } catch (error) {
      yield put(actionCreators.controllerPatchFailure(error));
    }
  }
}

function* watchClientCompanyRequest() {
  for (;;) {
    yield take(actionTypes.CLIENT_COMPANY_REQUEST);
    try {
      const partnerId = yield select(selectPartnerId);

      const clientCompanies = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, ClientCompany.getAll, partnerId);

      yield put(actionCreators.clientCompanySuccess(clientCompanies));
    } catch (error) {
      yield put(actionCreators.clientCompanyFailure(error));
    }
  }
}

function* watchServerRequest() {
  for (;;) {
    yield take(actionTypes.SERVER_REQUEST);
    try {
      const partnerId = yield select(selectPartnerId);

      const servers = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Server.getAll, partnerId)

      yield put(actionCreators.serverSuccess(servers));
    } catch (error) {
      yield put(actionCreators.serverFailure(error));
    }
  }
}

function* watchLinkTypeRequest() {
  for (;;) {
    yield take(actionTypes.LINK_TYPE_REQUEST);
    try {
      const partnerId = yield select(selectPartnerId);

      const linkTypes = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Partner.getLinkTypes, partnerId);

      yield put(actionCreators.linkTypeSuccess(linkTypes));
    } catch (error) {
      yield put(actionCreators.linkTypeFailure(error));
    }
  }
}

function* watchCarrierRequest() {
  for (;;) {
    yield take(actionTypes.CARRIER_REQUEST);
    try {
      const partnerId = yield select(selectPartnerId);

      const carriers = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Partner.getCarriers, partnerId);

      yield put(actionCreators.carrierSuccess(carriers));
    } catch (error) {
      yield put(actionCreators.carrierFailure(error));
    }
  }
}

function* watchWanDeleteRequest() {
  for (;;) {
    const {
      payload: { wanId },
    } = yield take(actionTypes.WAN_DELETE_REQUEST);
    const sites = yield select(siteSelectors.selectAll);

    if (sites.filter((site) => site.wanId === wanId).length > 0) {
      // TODO: create expandable toaster notification to contain large messages.
      // const message = 'This WAN has Sites allocated to it. These Sites must be deallocated from the WAN before it can be deleted.';
      const message = 'Delete Failed: Sites Allocated';
      yield put(actionCreators.wanDeleteFailure(message));
      yield put(
        sessionActionCreators.notificationEnqueue({
          message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    } else {
      try {
        yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, apolloClient.mutate, {
          mutation: DELETE_WAN,
          variables: { id: wanId },
        });
        yield put(actionCreators.wanDeleteSuccess(wanId));
        yield put(
          sessionActionCreators.notificationEnqueue({
            message: 'Deleted Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      } catch (error) {
        yield put(actionCreators.wanDeleteFailure(error));
      }
    }
  }
}

function* watchSiteDeleteRequest() {
  for (;;) {
    const {
      payload: { siteId },
    } = yield take(actionTypes.SITE_DELETE_REQUEST);
    try {
      yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Site.delete, siteId);
      yield put(actionCreators.siteDeleteSuccess(siteId));
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Deleted Successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (error) {
      yield put(actionCreators.siteDeleteFailure(error));
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Delete Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
  }
}

function* watchServerRebootRequest() {
  for (;;) {
    const {
      payload: { serverIds },
    } = yield take(actionTypes.SERVER_REBOOT_REQUEST);

    try {
      yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Server.reboot, serverIds);
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Gateway was flagged for reboot successfully!',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
      yield put(actionCreators.serverRebootSuccess(serverIds));
    } catch (error) {
      yield put(actionCreators.serverFailure(error));
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'There was an error flagging the Gateway for reboot',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
  }
}

function* watchSiteRebootRequest() {
  for (;;) {
    const {
      payload: { siteId },
    } = yield take(actionTypes.SITE_REBOOT_REQUEST);

    try {
      yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, Site.reboot, siteId);
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Site was flagged for reboot successfully!',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (error) {
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'There was an error flagging the Site for reboot',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
  }
}

function* watchServerDeleteRequest() {
  for (;;) {
    const {
      payload: { serverId },
    } = yield take(actionTypes.SERVER_DELETE_REQUEST);

    try {
      yield put(
        sessionActionCreators.notificationEnqueue({
          message: 'Deleted Successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (error) {
      yield put(actionCreators.serverDeleteFailure(error));
      if ((error as any)?.message === 'SERVER_ASSIGNED') {
        yield put(
          sessionActionCreators.notificationEnqueue({
            message: 'Server Assigned: Unable to Delete',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          })
        );
      } else {
        yield put(
          sessionActionCreators.notificationEnqueue({
            message: 'There was an error deleting Site',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          })
        );
      }
    }
  }
}

export function* configurationSaga() {
  yield fork(watchSiteRequest);
  yield fork(watchWanRequest);
  yield fork(watchControllerRequest);
  yield fork(watchControllerPatchRequest);
  yield fork(watchClientCompanyRequest);
  yield fork(watchServerRequest);
  yield fork(watchLinkTypeRequest);
  yield fork(watchCarrierRequest);
  yield fork(watchWanDeleteRequest);
  yield fork(watchControllerCreateRequest);
  yield fork(watchControllerDeleteRequest);
  yield fork(watchSiteDeleteRequest);
  yield fork(watchSiteRebootRequest);
  yield fork(watchServerRebootRequest);
  yield fork(watchServerDeleteRequest);
  yield fork(watchTunnelRequest);
}
