// @ts-nocheck
import * as React from 'react';

class Spacer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: this.props.height || 8
    }
  }

  render() {
    const { height } = this.state;

    return (
      <table><tbody><tr height={`${height}px;`}><td></td></tr></tbody></table>
    );
  }
}

export { Spacer };