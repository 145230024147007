import gql from "graphql-tag";

export const ALL_WANS = gql`
  query AllWans($partnerId: uuid) {
    Wan(where: { partnerId: { _eq: $partnerId } }) {
      id
      name
      description
      isEncrypted
      activeGatewayId
      standbyGatewayId
      activeServerId
      standbyServerId
      partnerId
      created_at
      updated_at
    }
  }
`;

export const GET_WAN = gql`
  query Wan($id: uuid) {
    Wan(where: {id: {_eq: $id} }) {
      id
      name
      description
      isEncrypted
      activeGatewayId
      standbyGatewayId
      activeServerId
      standbyServerId
      partnerId
      created_at
      updated_at
    }
  }
`;

export const GET_WANS_BY_GATEWAY = gql`
  query Wan($gatewayId: uuid) {
    Wan(where: {_or: [
      {activeGatewayId: {_eq: $gatewayId}},
      {standbyGatewayId: {_eq: $gatewayId}}
    ]}) {
      id
      name
    }
  }
`;

export const CREATE_WAN = gql`
  mutation CreateWan($input: [Wan_insert_input!]!) {
    insert_Wan(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_WAN = gql`
  mutation DeleteWan($id: uuid) {
    delete_Wan(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_WAN = gql`
  mutation UpdateWan($id: uuid, $changes: Wan_set_input) {
    update_Wan(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_WAN_SITES = gql`
  query GetWanLinkPorts($wanId: uuid) {
    Site (where: {wanId: {_eq: $wanId}}) {
      id
      Tunnels {
        portRangeStart
        portRangeEnd
        Links {
          id
          serverPort
        }
      }
    }
  }
`;