
export const shouldUbondRestart = (originalData, primaryLans, link, primaryTunnel) => {
  const originalLinks = originalData.links;
  const originalLans = originalData.lans;
  const originalActiveTunnel = originalData.activeTunnel;

  return lanSubnetChanged(originalLans, primaryLans) || wifiChanged(originalLans, primaryLans) || linkChanged(originalLinks, link) || tunnelChanged(originalActiveTunnel, primaryTunnel)
}

export const tunnelChanged = (originalTunnel, newTunnel) => {
  var tunnelChanged = false;

  // If Encryption changed
  if (originalTunnel.isClearTextData !== newTunnel.isClearTextData) {
    tunnelChanged = true;
  }

  // If Loss Tolerance enabled
  if (originalTunnel.lossTolerance === "25" && newTunnel.lossTolerance !== "25") {
    tunnelChanged = true;
  }

  // If Reorder Buffer changed
  if (originalTunnel.reorderBufferTime !== newTunnel.reorderBufferTime) {
    tunnelChanged = true;
  }

  // If MTU changed
  if (originalTunnel.mtu !== newTunnel.mtu) {
    tunnelChanged = true;
  }

  return tunnelChanged;
}

export const linkChanged = (originalLinks, link) => {
  var originalLink = originalLinks.filter(l => l.id === link.id)[0];
  var linkChanged = false;

  // If Link Rate Limiting was enabled, disabled or changed
  if (originalLink.isCapEnabled !== link.isCapEnabled || (originalLink.isCapEnabled && link.isCapEnabled && 
      (link.uploadThroughput !== originalLink.uploadThroughput || link.downloadThroughput !== originalLink.downloadThroughput))) {
    linkChanged = true;
  }

  return linkChanged;
}

export const wifiChanged = (originalLans, newLans) => {
  var wifiChanged = false;

  for (var i = 0; i < originalLans.length; i++) {
    const originalLan = originalLans[i];
    const newLan = newLans.find((lan) => lan.id === originalLan.id);

    if (newLan) {
      if (originalLan.isAP !== newLan.isAP || originalLan.apMode !== newLan.apMode ||
        originalLan.apChannel !== newLan.apChannel || originalLan.ap5Channel !== newLan.ap5Channel ||
        originalLan.apSSID !== newLan.apSSID || originalLan.ap5SSID !== newLan.ap5SSID ||
        originalLan.apPassword !== newLan.apPassword || originalLan.ap5Password !== newLan.ap5Password
      ) {
        wifiChanged = true;
      }
    }
  }

  return wifiChanged;
}

export const lanSubnetChanged = (originalLans, newLans) => {
  var lanSubnetChanged = false;

  for (var i = 0; i < originalLans.length; i++) {
    const originalLan = originalLans[i];
    const newLan = newLans.find((lan) => lan.id === originalLan.id);

    if (newLan) {
      if (originalLan.lanSegmentIp4 !== newLan.lanSegmentIp4 || originalLan.lanCidrIp4 !== newLan.lanCidrIp4) {
        lanSubnetChanged = true;
      }
    }
  }
  
  return lanSubnetChanged;
}

