import gql from "graphql-tag";

export const PARTNERS_QUERY = gql`
  query AllPartners {
    Partner {
      id
      Users {
        id
        email
        emailAlert
      }
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation UpdatePartner($id: uuid, $changes: Partner_set_input) {
    update_Partner(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_PARTNER = gql`
  query PartnerById($id: uuid) {
    Partner(where: { id: { _eq: $id } }) {
      id
      name
      billingContact
      billingEmail
      billingPhone
      billingExt
      billingAddress
      billingAddress1
      billingAddress2
      billingCity
      billingState
      billingCountry
      billingPostalCode
      primaryLanguage
    }
  }
`;

export const ALL_LINK_TYPES = gql`
  query AllLinkTypes($partnerId: uuid) {
    LinkType(where: { partnerId: { _eq: $partnerId } }) {
      id
      name
      isActive
    }
  }
`;

export const CREATE_LINK_TYPE = gql`
  mutation CreateLinkType($input: [LinkType_insert_input!]!) {
    insert_LinkType(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_LINK_TYPE = gql`
  mutation DeleteLinkType($id: uuid) {
    delete_LinkType(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ALL_CARRIERS = gql`
  query AllCarriers($partnerId: uuid) {
    Carrier(where: { partnerId: { _eq: $partnerId } }) {
      id
      name
    }
  }
`;

export const CREATE_CARRIER = gql`
  mutation CreateCarrier($input: [Carrier_insert_input!]!) {
    insert_Carrier(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_CARRIER = gql`
  mutation DeleteCarrier($id: uuid) {
    delete_Carrier(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
