import axios from 'axios';
import headers from './api-gateway-headers';
import UserSession from '@app/common/user-session';

export default {
  async get(path) {
    let response = await axios.get(
      process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
      { headers: this.getHeaders() }
    );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    else {
      throw new Error(error.response)
    }
  },

  async patch(path, payload) {
    let response = await axios.patch(
      process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
      payload, 
      { headers: this.getHeaders() }
    );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    else {
      throw new Error(error.response)
    }
  },

  async post(path, payload) {
    let response = await axios.post(
      process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
      payload, 
      { headers: this.getHeaders() }
    );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    else {
      throw new Error(error.response)
    }
  },

  async put(path, payload) {
    let response = await axios.put(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        payload,
        { headers: this.getHeaders() }
      );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    else {
      throw new Error(error.response)
    }
  },

  async delete(path, payload) {
    let response = await axios.delete(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        { headers: this.getHeaders(), data: payload }
      );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    else {
      throw new Error(error.response)
    }
  },

  getHeaders() {
    let requestHeaders = headers.getApiKeyHeaders();

    if (UserSession.getToken()) {
      requestHeaders = headers.getAuthHeaders();
    }

    return requestHeaders;
  },
}
