import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async attachLicense(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/sites/${id}/licenses`, payload);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getRouting(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/sites/${id}/routing`);
      return result?.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async addRouting(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/sites/${id}/routing`, payload);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async addSiteToWan(siteId, wanId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/sites/${siteId}/wans`, { wan_id: wanId });
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async removeSiteFromWan(siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.delete(`/sites/${siteId}/wans`);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async createRegistrations(siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/sites/${siteId}/registrations`);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  }
}
