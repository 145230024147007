import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get('/users');
      return result.data.users;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async get(userId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/users/${userId}`);
      return result.data.user;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(userId, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/users/${userId}`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updateAccountStatus(userId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/users/${userId}/status`);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async resetPassword(userId, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/users/${userId}/password`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async inviteUser(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post('/users', payload);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async deleteUser(payload) {
    const result = await ApiGateway.delete('/users/password', payload);
    return result;
  },

  async submitCode(payload) {
    const result = await ApiGateway.patch('/users/password', payload);
    return result;
  }
}
