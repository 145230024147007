import { GET_WAN, CREATE_WAN, DELETE_WAN, UPDATE_WAN, GET_WAN_SITES, GET_WANS_BY_GATEWAY } from './gql.queries';
import { UPDATE_SITE, DELETE_LINK } from '@app/api/site/gql.queries';
import { UPDATE_TUNNEL, CREATE_TUNNEL, DELETE_TUNNEL } from '@app/api/tunnel/gql.queries';
import Site from '@app/api/site/site.actions';
// Use Gateway alias since there is a Server var already present
import { default as Gateway } from '@app/api/gateway/gateway.actions';
import UserSession from '@app/common/user-session';

import { apolloClient } from '../graphql/client';
import { GQLHelpers } from '../hasura/ts';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      const { objects: Wans } = await GQLHelpers.Fragments.Wan.queryObjects({
        apolloClient,
        options: { variables: { where: { partnerId: { _eq: partnerId } } } },
      });
      return Wans;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getWansByController(gatewayId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { objects: Wans } = await GQLHelpers.Fragments.Wan.queryObjects({
        apolloClient,
        options: {
          variables: {
            where: { _or: [{ activeGatewayId: { _eq: gatewayId } }, { standbyGatewayId: { _eq: gatewayId } }] },
          },
        },
      });
      return Wans;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getWanSites(wanId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { data: sites } = await apolloClient.query({
        query: GET_WAN_SITES,
        variables: { wanId: wanId },
      });
      return sites;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async get(wanId: string) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { wan } = await GQLHelpers.Fragments.Wan.queryById({ apolloClient, wanId });
      return wan;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async delete(wanId: string) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      await GQLHelpers.Models.Wan.removeById({ apolloClient, wanId });
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(params) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      params.partnerId = partnerId;
      const { siteSubnets, ...wanParams } = params;
  
      const {
        data: {
          insert_Wan: {
            returning: [wan],
          },
        },
      } = await apolloClient.mutate({
        mutation: CREATE_WAN,
        variables: { input: [wanParams] },
      });
  
      return wan;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(id, changes, sites) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const { siteSubnets, ...wanChanges } = changes;

      let activeTunnelServerChanged = false;
      let standbyTunnelServerChanged = false;
      let encryptionChanged = false;
      let ipAccessLevelChanged = false;
      let accessibleIpsChanged = false;
      let siteLevelAccessChanged = false;
      let addingFailover = false;
      let removingFailover = false;
  
      await GQLHelpers.Fragments.Wan.updateById({ apolloClient, wanId: id, set: wanChanges });
  
      let sitePromises = changes.siteSubnets.map(async (siteSubnet, index) => {
        // TODO: This layer should not need to know about the structure of site.cells[x].props.value
        const siteId = siteSubnet.cells[4].props.value;
        const ipAccessLevel = siteSubnet.cells[2].props.value;
        const accessibleIps = siteSubnet.cells[3].props.value;
        const origSite = sites.find((site) => site.id === siteId);
  
        let tunnelAddressPairSeed = -1;
        let updatedTunnel;
  
        addingFailover = origSite.Tunnels[1] === undefined && wanChanges.standbyServerId ? true : false;
  
        removingFailover = origSite.Tunnels[1] !== undefined && !wanChanges.standbyServerId ? true : false;
  
        activeTunnelServerChanged = origSite.Tunnels[0].serverId !== wanChanges.activeServerId;
  
        standbyTunnelServerChanged =
          origSite.Tunnels[1] && wanChanges.standbyServerId
            ? origSite.Tunnels[1].serverId !== wanChanges.standbyServerId
            : false;
  
        encryptionChanged = origSite.Tunnels[0].isClearTextData === wanChanges.isEncrypted;
        ipAccessLevelChanged = origSite.ipAccessLevel !== ipAccessLevel;
        accessibleIpsChanged = origSite.accessibleIps !== accessibleIps;
  
        if (ipAccessLevelChanged || accessibleIpsChanged) {
          siteLevelAccessChanged = true;
        }
  
        // Update Tunnels
        for (var i = 0; i < origSite.Tunnels.length; i++) {
          let tunnel = origSite.Tunnels[i];
  
          const { Server, ...tempTunnel } = tunnel;
  
          tempTunnel.Links = { data: tempTunnel.Links };
          updatedTunnel = await Site.updateTunnelPortAndSeed(tempTunnel);
          tunnelAddressPairSeed = updatedTunnel.tunnelAddressPairSeed;
  
          if (
            activeTunnelServerChanged ||
            standbyTunnelServerChanged ||
            encryptionChanged ||
            ipAccessLevelChanged ||
            accessibleIpsChanged ||
            siteLevelAccessChanged
          ) {
            if (!addingFailover && !removingFailover) {
              await Site.updatePortsForWan(id);
            }
  
            let tunnelChanges = {
              serverId: tunnel?.serverId,
              siteId: siteId,
              isClearTextData: !wanChanges.isEncrypted,
              tunnelAddressPairSeed: tunnelAddressPairSeed + index,
            };
  
            if (tunnel.isPrimary || !removingFailover) {
              await apolloClient.mutate({
                mutation: UPDATE_TUNNEL,
                variables: { id: tunnel.id, changes: tunnelChanges },
              });
            }
  
            await Gateway.reboot(tunnel?.serverId); // Reboot the Gateway
          }
        }
  
        if (addingFailover) {
          const { id, Links, Server, ...newTunnel } = origSite.Tunnels[0];
          newTunnel.isPrimary = false;
          newTunnel.serverId = wanChanges.standbyServerId;
          newTunnel.siteId = siteId;
          newTunnel.Links = { data: origSite.Tunnels[0].Links.map(({ id, tunnelId, ...link }) => link) };
          let updatedTunnel = await Site.updateTunnelPortAndSeed(newTunnel);
          updatedTunnel.tunnelAddressPairSeed += index;
  
          await apolloClient.mutate({
            mutation: CREATE_TUNNEL,
            variables: { input: updatedTunnel },
          });
        } 
        
        else if (removingFailover) {
          await GQLHelpers.Models.Link.removeObjects({ apolloClient, options: { variables: { where: { tunnelId: { _eq: origSite.Tunnels[1].id }}}}})
          await GQLHelpers.Models.Tunnel.removeById({ apolloClient, tunnelId: origSite.Tunnels[1].id });
        }
  
        // Update Site
        return apolloClient.mutate({
          mutation: UPDATE_SITE,
          variables: {
            id: siteId,
            changes: {
              ipAccessLevel: ipAccessLevel,
              accessibleIps: accessibleIps,
            },
          },
        });
      });
  
      await Promise.all(sitePromises);
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getWanPorts(wanId, portRangeStart, portRangeEnd) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      let serverPorts: number[] = [];
      const sites = await this.getWanSites(wanId);
  
      for (var h = 0; h < sites.Site.length; h++) {
        let site = sites.Site[h];
  
        for (var i = 0; i < site.Tunnels.length; i++) {
          let tunnel = site.Tunnels[i];
  
          for (var j = 0; j < tunnel.Links.length; j++) {
            let link = tunnel.Links[j];
  
            if (link.serverPort >= portRangeStart && link.serverPort <= portRangeEnd) {
              serverPorts.push(link.serverPort);
            }
          }
        }
      }
  
      // Return ports unique and sorted
      return serverPorts.filter((v, i, a) => a.indexOf(v) === i).sort();
    }
    else {
      cognitoUser.signOut();
    }
  },
};
