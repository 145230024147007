import * as React from 'react';
import { Nav, NavExpandable, NavItem, NavList, PageSidebar } from '@patternfly/react-core';
import { routes, IAppRoute, IAppRouteGroup } from '@app/routes';
import { NavLink, useHistory } from 'react-router-dom';
import { sideBarLinkStyle, exitPartnerLinkStyle } from '../../common/sidebar-styles';
import UserSession from '@app/common/user-session';
import AdminIcon from '@app/bgimages/admin.svg';
import DashboardIcon from '@app/bgimages/dashboard.svg';
import HelpIcon from '@app/bgimages/help.svg';
import SupportIcon from '@app/bgimages/support.svg';
import LicenseIcon from '@app/bgimages/license-key.svg';
import ControllerIcon from '@app/bgimages/controllers.svg';
import SiteIcon from '@app/bgimages/sites.svg';
import WanIcon from '@app/bgimages/wans.svg';
import ACLProfileIcon from '@app/bgimages/service-catalog.svg';
import PartnerIcon from '@app/bgimages/users.svg';
import ExitPartner from '@app/bgimages/exit-partner.svg';

const helpLink = process.env.REACT_APP_HELP_LINK;

export const Sidebar: React.FC = () => {
  const history = useHistory();
  const isImpersonating = UserSession.getParam('isImpersonating');
  const navIcons = {
    dashboard: (<img src={DashboardIcon} width="24px" height="24px" alt="Ethica Dashboard" />),
    partners: (<img src={PartnerIcon} width="25px" height="25px" alt="Ethica Partners" />),
    licenses: (<img src={LicenseIcon} width="25px" height="25px" alt="Ethica Licensing" />),
    admin: (<img src={AdminIcon} width="24px" height="24px" alt="Ethica Admin" />),
    help: (<img src={HelpIcon} width="24px" height="24px" alt="Ethica Help" />),
    support: (<img src={SupportIcon} width="24px" height="24px" alt="Ethica Support" />),
    controllers: (<img src={ControllerIcon} width="24px" height="24px" alt="Ethica Controllers" />),
    sites: (<img src={SiteIcon} width="24px" height="24px" alt="Ethica Sites" />),
    wans: (<img src={WanIcon} width="24px" height="24px" alt="Ethica WANs" />),
    ["acl profiles"]: (<img src={ACLProfileIcon} width="24px" height="24px" alt="Ethica ACL Profiles" />),
    exitPartner: (<img src={ExitPartner} width="22px" height="22px" alt="Exit Partner" />)
  }

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`}>
      <NavLink exact to={route.path} activeClassName="pf-m-current">
        {navIcons[route.label.toLowerCase()]}
        <span style={route.style}>
          <strong>{route.label}</strong>
        </span>
      </NavLink>
    </NavItem>
  );

  const exitPartner = () => {
    UserSession.setParam('isImpersonating', false);
    window.location.href = '/partners';
  };

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <NavExpandable
      key={`${group.label}-${groupIndex}`}
      id={`${group.label}-${groupIndex}`}
      title={group.label}
      isActive={group.routes.some((route) => route.path === location.pathname)}
    >
      {group.routes.map((route, idx) => route.label && renderNavItem(route, idx))}
    </NavExpandable>
  );
  const Navigation = (
    <Nav id="nav-primary-simple" theme="dark">
      <NavList id="nav-list-simple">
        {routes.map((route, i) => 
          route.label && (!route.routes ? renderNavItem(route, i) : renderNavGroup(route, i))
        )}
        <NavItem key="help-5" id="help-5">
          <a href={helpLink} target="_blank">
            {navIcons["help"]}
            <span style={sideBarLinkStyle}>
              <strong>Documentation</strong>
            </span>
          </a>
        </NavItem>
        <NavItem key="support-6" id="support-6">
          <a href="mailto:help@ethica.partners?Subject=Ethica Support Request">
            {navIcons["support"]}
            <span style={sideBarLinkStyle}>
              <strong>Support</strong>
            </span>
          </a>
        </NavItem>
        {isImpersonating ? 
          <NavItem key="exit-7" id="exit-7">
              {navIcons["exitPartner"]}
              <span onClick={() => exitPartner()} style={exitPartnerLinkStyle}>
                <strong>Exit Partner</strong>
              </span>
          </NavItem>
        : <></>}
      </NavList>
    </Nav>
  );
  return (
    <PageSidebar
      theme="dark"
      nav={Navigation}
      isNavOpen={true}
    />
  )
}