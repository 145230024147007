import UserSession from '@app/common/user-session';

var Headers = (function() {
  var getAuthHeaders = function() {
    const token = UserSession.getToken();
    return {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };

  var getApiKeyHeaders = function() {
    return {
      'Accept': 'application/json',
      'Authorization': process.env.REACT_APP_USER_MGMT_API_KEY
    }
  };

  return {
    getAuthHeaders: getAuthHeaders,
    getApiKeyHeaders: getApiKeyHeaders
  }

})();

export default Headers;