import * as React from 'react';
import { AppDispatch } from '@app/store';
import { State } from '@app/store/root-reducer';
import UserSession from '@app/common/user-session';
import { AngleLeftIcon, TachometerAltIcon } from '@patternfly/react-icons';
import { PageSection, Title, ToggleGroupItem, ToggleGroup } from "@patternfly/react-core";
import { connect } from 'react-redux';

interface SitePerformanceState {
  id: string;
  partnerId: string;
}

export const SitePerformance = connect((state: State) => ({
  partnerId: UserSession.getParam('isImpersonating') ? UserSession.getPartnerId() : state.common.auth.session?.partnerId
}))(class extends React.Component<AppDispatch & { computedMatch: any, partnerId: string, history: any }, SitePerformanceState> {
  constructor(props) {
    super(props);

    const id = this.props.computedMatch.params.id;
    const partnerId = this.props.partnerId;

    this.state = {
      id: id,
      partnerId: partnerId
    }
  }

  launchGrafanaDashboard = () => {
    const { id, partnerId } = this.state;
    const nocLink = process.env.REACT_APP_NOC_LINK.replace('{{site_id}}', id)

    window.open(nocLink, '_blank');
  }

  backToSiteDetails = () => {
    this.props.history.push("/site/" + this.state.id);
  }

  render() {
    const { id, partnerId } = this.state;

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">Site Performance</Title><br />

        <ToggleGroup aria-label="Site performance">
          <ToggleGroupItem icon={<AngleLeftIcon />} text="Back to Site Details" aria-label="Site Details" buttonId="toolbar-back" onClick={this.backToSiteDetails} />
          <ToggleGroupItem icon={<TachometerAltIcon />} text="Launch Grafana Dashboard" aria-label="Launch Dashboard" buttonId="toolbar-dashboard" onClick={this.launchGrafanaDashboard} />
        </ToggleGroup>

        <iframe src={"https://" + partnerId + ".mon.ops.ethica.partners/d-solo/site_dash/site-dashboard?orgId=1&refresh=5s&var-instance=" + id + "&theme=light&panelId=5"} width="25%" height="100" frameBorder="0"></iframe>
        <iframe src={"https://" + partnerId + ".mon.ops.ethica.partners/d-solo/site_dash/site-dashboard?orgId=1&refresh=5s&var-instance=" + id + "&theme=light&panelId=37"} width="35%" height="100" frameBorder="0"></iframe>
        <iframe src={"https://" + partnerId + ".mon.ops.ethica.partners/d-solo/site_dash/site-dashboard?orgId=1&refresh=5s&var-instance=" + id + "&theme=light&panelId=2"} width="100%" height="300" frameBorder="0"></iframe>
        <iframe src={"https://" + partnerId + ".mon.ops.ethica.partners/d-solo/site_dash/site-dashboard?orgId=1&refresh=5s&var-instance=" + id + "&theme=light&panelId=3"} width="100%" height="300" frameBorder="0"></iframe>
        <iframe src={"https://" + partnerId + ".mon.ops.ethica.partners/d-solo/site_dash/site-dashboard?orgId=1&refresh=5s&var-instance=" + id + "&theme=light&panelId=27"} width="100%" height="300" frameBorder="0"></iframe>

      </PageSection>
    );
  }
})