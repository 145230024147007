import React from 'react';
import { IconHeading } from '@app/components/IconHeading';
import { ExclamationCircleIcon, MapMarkerIcon } from '@patternfly/react-icons';
import { FormGroup, TextInput, Select, SelectVariant } from "@patternfly/react-core";
import { FormGroupSpacer } from '../../../components/forms/pf/form-group-spacer.component';

const SiteInfo = ({ 
  site, address1ErrorState, address2ErrorState, cityErrorState, postalCodeErrorState, onRegionSelect, 
  onRegionToggle, isRegionOpen, onCountrySelect, onCountryToggle, isCountryOpen, handleChange, countries, regions
}) => {
  return (
    <div>
      <IconHeading icon={<MapMarkerIcon className="icon-medium" />} heading="Site Location" />

      <FormGroup label="Site Address" isRequired fieldId="address1"
        helperTextInvalid={address1ErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={address1ErrorState.validated}>
        <TextInput className="form-input" validated={address1ErrorState.validated}
          isRequired type="text" id="address1" name="address1" placeholder=""
          value={site.address1} onChange={(e) => handleChange("siteInfo", "address1", e)}
        />
      </FormGroup>

      <FormGroupSpacer>
        <FormGroup label="Address Line 2" fieldId="address2"
          helperTextInvalid={address2ErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={address2ErrorState.validated}>
          <TextInput className="form-input" validated={address2ErrorState.validated}
            type="text" id="address2" name="address2" placeholder=""
            value={site.address2} onChange={(e) => handleChange("siteInfo", "address2", e)}
          />
        </FormGroup>
      </FormGroupSpacer>

      <table>
        <tbody> 
          <tr>
            <td width="50%">
              <FormGroupSpacer>
                <FormGroup label="City" fieldId="city"
                  helperTextInvalid={cityErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={cityErrorState.validated}>
                  <TextInput className="form-input"
                    type="text" id="city" name="city" placeholder="" validated={cityErrorState.validated}
                    value={site.city} onChange={(e) => handleChange("siteInfo", "city", e)}
                  />
                </FormGroup>
              </FormGroupSpacer>
            </td>
            <td>
              <FormGroupSpacer>
                <FormGroup label="Country" fieldId="country-toggle">
                  <Select 
                    maxHeight={300}
                    variant={SelectVariant.typeahead}
                    placeholderText="Select Country ..." aria-label="Select Country"
                    onToggle={onCountryToggle} onSelect={onCountrySelect}
                    selections={site.country} isOpen={isCountryOpen} isDisabled={false}
                  >
                    {countries}
                  </Select>
                </FormGroup>
              </FormGroupSpacer>
            </td>
          </tr>

          <tr>
            <td>
              <FormGroupSpacer>
                <FormGroup label="State / Province" fieldId="region-toggle">
                  <Select
                    maxHeight={300}
                    variant={SelectVariant.typeahead}
                    placeholderText="Select Region ..." aria-label="Select Region"
                    onToggle={onRegionToggle} onSelect={onRegionSelect}
                    selections={site.state} isOpen={isRegionOpen} isDisabled={false}
                  >
                    {regions}
                  </Select>
                </FormGroup>
              </FormGroupSpacer>
            </td>
            <td>
              <FormGroupSpacer>
                <FormGroup label="Postal Code / Zip" fieldId="postalCode"
                  helperTextInvalid={postalCodeErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={postalCodeErrorState.validated}>
                  <TextInput className="form-input"
                    type="text" id="postalCode" name="postalCode" placeholder="" validated={postalCodeErrorState.validated}
                    value={site.postalCode} onChange={(e) => handleChange("siteInfo", "postalCode", e)}
                  />
                </FormGroup>
              </FormGroupSpacer>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  );
}

export default SiteInfo;