import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import { PageSection, Title, Button, EmptyState, EmptyStateIcon, EmptyStateBody } from '@patternfly/react-core';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';

interface ErrorViewProps {
  title: string;
  message: string;
}

export const ErrorView: React.FC<ErrorViewProps> = ({ title, message }) => {
  const dispatch = useDispatch();

  const handleClick = React.useCallback(() => {
    dispatch(goBack());
  }, [dispatch])

  return (
    <PageSection data-testid="error-view">
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <EmptyState variant="full">
        <EmptyStateIcon icon={ExclamationTriangleIcon} />
        <Title headingLevel="h1" size="lg">
          {title}
        </Title>
        <EmptyStateBody>
          {message}
        </EmptyStateBody>
        <Button onClick={handleClick}>Go Back</Button>
      </EmptyState>
    </PageSection>
  );
};