import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import PartnerApi from '@app/api/partner-api/partner.actions';
import { Spacer } from '@app/components/Spacer';
import UserSession from '@app/common/user-session';
import ClientCompany from '@app/api/client-company/client-company.actions';
import { getNamesFromFullname } from './PartnerHelper';
import { getParamFromQueryString, formatDate, reverseSortBy } from '@app/lib/functions';
import { Card, CardBody, Flex, FlexItem, Spinner, TreeView, ToggleGroup, ToggleGroupItem, PageSection, Title, Button, Modal, ModalVariant, Spinner } from '@patternfly/react-core';
import { BullhornIcon, PencilAltIcon, ShareSquareIcon } from '@patternfly/react-icons';
import './Partner.css';
import '../../app.css';

export const PartnerList: React.FunctionComponent = ({ props }) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [partners, setPartners] = React.useState([]);
  const [activeItems, setActiveItems] = React.useState({});
  const [activePartner, setActivePartner] = React.useState({});
  const [enteringPartner, setEnteringPartner] = React.useState(false);
  const partnerName = UserSession.getParam('partnerName');
  const id = getParamFromQueryString('id');

  React.useEffect(() => {
    (async function() {
      try {
        const partners = await PartnerApi.getAll(true);
        const partnerId = UserSession.getPartnerId();
        const sortedPartners = reverseSortBy(partners, 'updated');
        const partnerPromises = [];

        if (sortedPartners) {
          partnerPromises = sortedPartners.map(item => {
            return partnerFromItem(item);
          });
        }

        const partnerRows = await Promise.all(partnerPromises);

        setPartners(partnerRows);
        setLoading(false);

        // If no partner id is passed, just expand the first partner 
        if (!id && partnerRows?.length) {
          partnerRows[0].defaultExpanded = true;
          setActivePartner(partnerRows[0]);
          setActiveItems([partnerRows[0]]);
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const enterPartnerSpace = async (e) => {
    e.preventDefault();
    const flattened = flattenPartners(partners);
    const currentPartnerId = UserSession.getPartnerId();
    const partnerChain = getPartnerAuthChain(flattened, activePartner.id, currentPartnerId, activePartner);

    setEnteringPartner(true);

    // If the active selected partner is nested, add it to the end of the partner chain
    if (currentPartnerId !== activePartner.parent_id) {
      partnerChain.push({ id: activePartner.id, parent_id: activePartner.parent_id, name: activePartner.name });
    }

    for (let i = 0; i < partnerChain.length; i++) {
      const partner = partnerChain[i];
      const assumedRole = await PartnerApi.getAssumedRole(partner.id);
      const preferences = await PartnerApi.getPreferences(partner.id);

      UserSession.setParam('token', assumedRole.assumed_token);
      UserSession.setParam('partnerId', partner.id);
      UserSession.setParam('partnerName', partner.name);

      // Override partner preferences
      UserSession.setParam('logoFileUrl', preferences?.logo_filename_url || false);
      UserSession.setParam('faviconFileUrl', preferences?.favicon_filename_url || false);
      UserSession.setParam('pageTitle', preferences?.page_title || false);
      UserSession.setParam('headerColor', preferences?.header_color || false);
      UserSession.setParam('logoFilename', preferences?.logo_filename || false);
      UserSession.setParam('faviconFilename', preferences?.favicon_filename || false);

      const clientCompanies = await ClientCompany.getAll(partner.id);
      UserSession.setParam('clientCompanyIds', clientCompanies.map((c) => c.id));
    }

    // When exiting the partner space from components/layout/sidebar.component.tsx, this will 
    // get set to false and app will be reloaded, and original session attributes from redux 
    // store will get loaded
    UserSession.setParam('isImpersonating', true);

    window.location.href = '/dashboard';
  }

  const getPartnerAuthChain = (flattened, partnerId, currentPartnerId, activePartner) => {
    const partner = flattened.find(p => p.id === partnerId);
    let partnerChain = [];

    if (currentPartnerId === partner.parent_id) {
      partnerChain = partnerChain.concat({ id: partnerId, parent_id: partner.parent_id, name: partner.name });
    }
    else {
      partnerChain = partnerChain.concat(getPartnerAuthChain(flattened, partner.parent_id, currentPartnerId));
    }

    return partnerChain;
  }

  const flattenPartners = (partners) => {
    var result = [];

    partners.forEach(function (partner) {
      result.push(partner);

      if (Array.isArray(partner.children)) {
        result = result.concat(flattenPartners(partner.children));
      }
    });

    return result;
  }

  const editPartner = () => {
    history.push(`/partner/edit/${activePartner.id}`);
  }

  const partnerFromItem = async (item) => {
    const subPartnerCount = item.partners ? item.partners.length : 0;
    const names = getNamesFromFullname(item.billing_contact);
    const partner = {
      name: item.name,
      id: item.id,
      key: item.id,
      parent_id: item.parent_id,
      created: item.created,
      updated: item.updated,
      acknowledgedTerms: item.acknowledgedTerms,
      contactFirstName: names.first_name,
      contactLastName: names.last_name,
      contactEmail: item.billing_email,
      contactPhone: item.billing_phone,
      controllers: item.controllers,
      sites: item.sites
    }

    // If id is passed via query string, make that the active node in the TreeView
    if (id === item.id) {
      partner.defaultExpanded = true;
      setActiveItems([partner]);
      setActivePartner(partner);
    }

    // Only show the badge containing the sub partner count if > 0
    if (subPartnerCount > 0) {
      const badgeText = subPartnerCount === 1 ? `${subPartnerCount} partner` : `${subPartnerCount} partners`;
      partner.hasBadge = true;
      partner.customBadgeContent = badgeText;
      item.partners = item.partners.map(item => {
        return partnerFromItem(item);
      });
      partner.children = await Promise.all(item.partners);
    }
    else {
      partner.hasBadge = false;
    }

    return partner;
  }

  const onSelect = async (evt, treeViewItem) => {
    if (treeViewItem) {
      setActiveItems([treeViewItem]);
      setActivePartner(treeViewItem);
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Partners</title>
      </Helmet>
      <PageSection>
        <Title headingLevel="h1" size="xl">Partners</Title><br />

        {loading ? 
          <Spinner size="lg" />
        : partners.length === 0 ? <div>No Partners found!</div> :
          <Flex>
            <FlexItem className="flex-item-partner-treeview">
              <Card isCompact className="partner-row">
                <CardBody>
                  <Title headingLevel="h3" size="md">{partnerName}</Title>

                  <div className="treeview-container">
                    <TreeView className="treeview-compact"
                      hasGuides={true}
                      data={partners} 
                      activeItems={activeItems} 
                      onSelect={onSelect}
                      hasBadges
                    />
                  </div>
                </CardBody>
              </Card>
            </FlexItem>

            <FlexItem className="flex-item-partner-detail">
              <Card isCompact className="partner-row">
                <CardBody>
                  <Title headingLevel="h3" size="md">Partner Details</Title>
                  <Spacer height="12" />

                  <div className="partner-detail">
                    <table>
                      <tbody>
                        <tr>
                          <td className="partner-detail-col"><strong>Name:</strong></td>
                          <td>{activePartner.name}</td>
                        </tr>
                        <tr>
                          <td><strong>Partner ID:</strong></td>
                          <td>{activePartner.id}</td>
                        </tr>
                        <tr>
                          <td><strong>Created:</strong></td>
                          <td>{formatDate(activePartner.created)}</td>
                        </tr>
                        <tr>
                          <td><strong>Last updated:</strong></td>
                          <td>{formatDate(activePartner.updated)}</td>
                        </tr>
                        {/* <tr>
                          <td><strong>Accepted Terms:</strong></td>
                          <td>{formatDate(activePartner.acknowledgedTerms)}</td>
                        </tr> */}
                        <tr>
                          <td><strong>Controllers:</strong></td>
                          <td>{activePartner.controllers}</td>
                        </tr>
                        <tr>
                          <td><strong>Sites:</strong></td>
                          <td>{activePartner.sites}</td>
                        </tr>
                      </tbody>   
                    </table>

                    <Spacer height="15" />
                    <Title headingLevel="h3" size="md">Primary Contact</Title>
                    <Spacer height="12" />

                    <table>
                      <tbody>
                        <tr>
                          <td className="partner-detail-col"><strong>Name:</strong></td>
                          <td>{activePartner.contactFirstName} {activePartner.contactLastName}</td>
                        </tr>
                        <tr>
                          <td><strong>Phone:</strong></td>
                          <td>{activePartner.contactPhone}</td>
                        </tr>
                        <tr>
                          <td><strong>Email:</strong></td>
                          <td>{activePartner.contactEmail}</td>
                        </tr>
                      </tbody>   
                    </table>

                    <Spacer height="30" />

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <ToggleGroup aria-label="Partner options">
                              <ToggleGroupItem
                                icon={<PencilAltIcon />}
                                text="Edit"
                                aria-label="Edit Partner"
                                buttonId="toolbar-edit-partner"
                                onClick={editPartner}
                              />
                              <ToggleGroupItem
                                icon={<ShareSquareIcon />}
                                text="Enter Partner Space"
                                aria-label="Enter Partner Space"
                                buttonId="toolbar-enter-partner"
                                isDisabled={enteringPartner}
                                onClick={enterPartnerSpace}
                              />
                            </ToggleGroup>
                          </td>
                          <td>
                            {enteringPartner ? <Spinner size="lg" /> : <></>}
                          </td>
                        </tr>
                      </tbody>  
                    </table>
                  </div>
                </CardBody>
              </Card>
            </FlexItem>
          </Flex>
        }
      </PageSection>
    </React.Fragment>
  );
}
