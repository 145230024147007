import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { createReducer, normalizeError } from '@app/lib/utils';

import { PartnerPreferences } from '../../types';

const claimsKey = process.env.REACT_APP_CLAIMS_KEY!;

export type AuthState = {
  isLoading?: boolean;
  session?: {
    token?: string;
    accessToken?: string;
    refreshToken?: string;
    role?: string;
    userId?: string;
    cognitoId?: string;
    partnerId?: string;
    partnerName?: string;
    expiration?: number;
  };
  partnerPreferences?: Partial<PartnerPreferences>;
  error?: any;
};

export const initialState: AuthState = {
  isLoading: false,
};

export enum actionTypes {
  SIGNIN_REQUEST = 'AUTH/SIGNIN_REQUEST',
  SIGNIN_SUCCESS = 'AUTH/SIGNIN_SUCCESS',
  SIGNIN_FAILURE = 'AUTH/SIGNIN_FAILURE',
  LOGOUT_REQUEST = 'AUTH/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'AUTH/LOGOUT_FAILURE',
  LOAD_PREFERENCES_REQUEST = 'AUTH/LOAD_PREFERENCES_REQUEST',
  LOAD_PREFERENCES_SUCCESS = 'AUTH/LOAD_PREFERENCES_SUCCESS',
  LOAD_PREFERENCES_FAILURE = 'AUTH/LOAD_PREFERENCES_FAILURE',
  TOKEN_REFRESH_REQUEST = 'AUTH/TOKEN_REFRESH_REQUEST',
  TOKEN_REFRESH_SUCCESS = 'AUTH/TOKEN_REFRESH_SUCCESS',
  TOKEN_REFRESH_FAILURE = 'AUTH/TOKEN_REFRESH_FAILURE',
  PREFERENCES_UPDATE_REQUEST = 'AUTH/PREFERENCES_UPDATE_REQUEST',
  PREFERENCES_UPDATE_SUCCESS = 'AUTH/PREFERENCES_UPDATE_SUCCESS',
  PREFERENCES_UPDATE_FAILURE = 'AUTH/PREFERENCES_UPDATE_FAILURE',
}

export const actionCreators = {
  signInRequest: (username: string, password: string) => ({
    type: actionTypes.SIGNIN_REQUEST,
    payload: {
      username,
      password,
    },
  }),
  signInSuccess: (session: CognitoUserSession) => ({
    type: actionTypes.SIGNIN_SUCCESS,
    payload: { session },
  }),
  signInFailure: (error: any) => ({
    type: actionTypes.SIGNIN_FAILURE,
    payload: { error },
  }),
  loadPreferencesRequest: () => ({
    type: actionTypes.LOAD_PREFERENCES_REQUEST,
  }),
  loadPreferencesSuccess: (partnerPreferences: PartnerPreferences) => ({
    type: actionTypes.LOAD_PREFERENCES_SUCCESS,
    payload: { partnerPreferences },
  }),
  loadPreferencesFailure: (error: Error) => ({
    type: actionTypes.LOAD_PREFERENCES_FAILURE,
    payload: { error },
  }),
  logoutRequest: () => ({
    type: actionTypes.LOGOUT_REQUEST,
  }),
  logoutSuccess: () => ({
    type: actionTypes.LOGOUT_SUCCESS,
  }),
  logoutFailure: (error: any) => ({
    type: actionTypes.LOGOUT_FAILURE,
    payload: { error },
  }),
  tokenRefreshRequest: () => ({
    type: actionTypes.TOKEN_REFRESH_REQUEST,
  }),
  tokenRefreshSuccess: (session: CognitoUserSession) => ({
    type: actionTypes.TOKEN_REFRESH_SUCCESS,
    payload: { session },
  }),
  tokenRefreshFailure: (error: any) => ({
    type: actionTypes.TOKEN_REFRESH_FAILURE,
    payload: { error },
  }),
  preferencesUpdateRequest: (partnerPreferences: PartnerPreferences) => ({
    type: actionTypes.PREFERENCES_UPDATE_REQUEST,
    payload: { partnerPreferences },
  }),
  preferencesUpdateSuccess: (partnerPreferences: PartnerPreferences) => ({
    type: actionTypes.PREFERENCES_UPDATE_SUCCESS,
    payload: { partnerPreferences },
  }),
  preferencesUpdateFailure: (error: any) => ({
    type: actionTypes.PREFERENCES_UPDATE_FAILURE,
    payload: { error },
  }),
};

export const actionHandlers = {
  [actionTypes.SIGNIN_REQUEST]: (
    state: AuthState,
    { payload: { username } }: ReturnType<typeof actionCreators.signInRequest>
  ): AuthState => ({
    ...state,
    isLoading: true,
    session: {
      username,
    },
  }),
  
  [actionTypes.SIGNIN_SUCCESS]: (
    state: AuthState,
    { payload: { session } }: ReturnType<typeof actionCreators.signInSuccess>
  ): AuthState => {
    const idToken = session.getIdToken();
    const token = idToken.getJwtToken();
    const claims = JSON.parse(idToken.payload[claimsKey]);
    const expiration = new Date(0).setUTCSeconds(idToken.payload.exp);
    const partnerId = claims['x-hasura-partner-id'];
    const partnerName = claims['x-hasura-partner-name'];
    
    return {
      ...state,
      isLoading: false,
      session: {
        token: token,
        isImpersonating: false,
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
        role: claims['x-hasura-default-role'],
        userId: claims['x-hasura-user-id'],
        cognitoId: session.getAccessToken().payload.sub,
        partnerName: partnerName,
        partnerId: partnerId,
        expiration: expiration,
      },
    };
  },

  [actionTypes.SIGNIN_FAILURE]: (
    state: AuthState,
    { payload: { error } }: ReturnType<typeof actionCreators.signInFailure>
  ): AuthState => ({
    ...initialState,
    helperText: '',
    error: "Invalid username or password" // normalizeError(error),
  }),

  [actionTypes.TOKEN_REFRESH_FAILURE]: (
    state: AuthState,
    { payload: { error } }: ReturnType<typeof actionCreators.tokenRefreshFailure>
  ): AuthState => ({
    ...initialState,
    session: {}
  }),

  [actionTypes.LOAD_PREFERENCES_SUCCESS]: (
    state: AuthState,
    { payload: { partnerPreferences } }: ReturnType<typeof actionCreators.loadPreferencesSuccess>
  ): AuthState => ({
    ...state,
    isLoading: false,
    partnerPreferences,
  }),

  [actionTypes.LOAD_PREFERENCES_FAILURE]: (
    state: AuthState,
    { payload: { error } }: ReturnType<typeof actionCreators.loadPreferencesFailure>
  ): AuthState => ({
    ...initialState,
    error,
  }),

  [actionTypes.LOGOUT_SUCCESS]: () => ({
    ...initialState,
  }),

  [actionTypes.TOKEN_REFRESH_SUCCESS]: (
    state: AuthState,
    action: ReturnType<typeof actionCreators.tokenRefreshSuccess>
  ): AuthState => actionHandlers[actionTypes.SIGNIN_SUCCESS](state, action),

  [actionTypes.PREFERENCES_UPDATE_REQUEST]: (
    state: AuthState,
  ): AuthState => ({
    ...state,
  }),

  [actionTypes.PREFERENCES_UPDATE_SUCCESS]: (
    state: AuthState,
    { payload: { partnerPreferences } }: ReturnType<typeof actionCreators.preferencesUpdateSuccess>
  ): AuthState => ({
    ...state,
    partnerPreferences,
  }),
};

export const authReducer = createReducer<AuthState, typeof actionHandlers>(initialState, actionHandlers);
