import * as React from 'react';
import { SearchInput } from '@patternfly/react-core';
import { SearchContext } from '../../contexts/search.context';

export const Search: React.FC = () => {
  const { searchText, setSearchText } = React.useContext(SearchContext);

  const onSearchChange = React.useCallback((value) => {
    setSearchText(value);
  }, [setSearchText]) 

  return (
    <div className="search-input-container">
      <SearchInput
        className="search-input"
        placeholder='Search ...'
        value={searchText}
        onChange={onSearchChange}
        onClear={() => onSearchChange('')}
      />
    </div>
  );
};