export function accessibleRouteChangeHandler() {
  return window.setTimeout(() => {
    const mainContainer = document.getElementById('primary-app-container');
    
    if (mainContainer) {
      mainContainer.focus();
    }
  }, 50);
}

export const getCardClass = (section, enabled) => {
  return enabled ? `${section}-form-row card-enabled` : `${section}-form-row card-disabled`;
};

export const getInversedCardClass = (section, enabled) => {
  return enabled ? `${section}-form-row card-disabled` : `${section}-form-row card-enabled`;
};

export const buildStoreName = storePrefix => {
  return `${storePrefix}_store`;
}

export const buildUniqueKey = (obj) => {
  if (!obj) {
      console.error('input has to be defined!');
  }

  let result = '';
  Object.entries(obj).sort().forEach(
      ([key, value]) => {
          // @ts-ignore
          result = result.concat(key, '_', value, '_');
      }
  );
  return result;
}

export const convertTotalItemIndexToPageNum = (elementsPerPage) => (totalIndex) => {
  return Math.floor(totalIndex / elementsPerPage) + 1;
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + " ...";
  } 
  else {
    return str;
  }
}

export const trimString = (str) => {
  if (str) {
    return str.trim();
  }
  else {
    return "";
  }
}
