import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { actionCreators } from '../common/configuration/configuration.reducer';
import { State } from '../store/root-reducer';
import { isUUID } from '@app/lib/validator';

const selectACLProfileMatch = createMatchSelector<State, Match>({
  path: '/aclprofile/:mode/:id?',
});

export const useACLProfileContext = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectACLProfileMatch);
  const mode = match?.params.mode;
  const id = isUUID(mode) ? mode : match?.params.id;

  React.useEffect(() => {
    dispatch(actionCreators.siteRequest());
    dispatch(actionCreators.wanRequest());
  }, [dispatch]);

  const sites = useSelector((state: State) => state.configuration.sites);
  const wans = useSelector((state: State) => state.configuration.wans);

  const isLoading = sites.isFetching || wans.isFetching;
  const isFailed = sites.isFailed || wans.isFailed;
  const isSuccess = sites.isSuccess && wans.isSuccess;

  return { id, mode, isLoading, isFailed, isSuccess };
};
