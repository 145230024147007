import * as React from 'react';
import ClientCompany from '@app/api/client-company/client-company.actions';
import { textInputRequiredOnly, isValidUrl, isLengthWithinRange } from '@app/lib/validator';
import { getIndustryOptions } from '@app/common/world';
import { IconHeading } from '@app/components/IconHeading';
import { Saver } from '@app/components/Saver';
import SiteIcon from '@app/bgimages/sites-black.svg';
import { PageSection, Title, Card, CardBody, FormGroup, TextInput, Select, SelectVariant, TextArea } from "@patternfly/react-core";
import { CatalogIcon, ExclamationCircleIcon, TrashIcon, PlusIcon } from '@patternfly/react-icons';
import { FormGroupSpacer } from '../../components/forms/pf/form-group-spacer.component';
import './Company.css';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      industry: "",
      notes: "",
      quickLinks: [], 
      industries: getIndustryOptions(),
      isIndustryOpen: false,
      newQuickLinkIndex: 0
    }
  }

  onToggle = (isOpen) => {
    this.setState(() => ({ 
      isIndustryOpen: isOpen
    }));
  }

  onSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearIndustry();
    }
    else {
      this.setState(() => ({ 
        industry: selection,
        isIndustryOpen: false 
      }));
    }
  }

  clearIndustry = () => {
    this.setState({
      industry: null,
      isIndustryOpen: false
    });
  };

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  handleQuickLinkChange = (prop, name, value) => {
    const { quickLinks } = this.state;
    const changedLink = quickLinks.filter(quickLink => { return prop + '-' + quickLink.id === name });
    const index = quickLinks.map(quickLink => { return quickLink.id }).indexOf(changedLink[0].id);
    changedLink[0][prop] = value;
    quickLinks[index] = changedLink[0];

    this.setState(() => ({ 
      quickLinks: quickLinks 
    }));
  }

  addQuickLink = () => {
    const { quickLinks, newQuickLinkIndex } = this.state;
    const newIndex = newQuickLinkIndex + 1;
    const emptyQuickLink = {
      id: "quick-link-" + newIndex,
      link: "",
      description: "",
      state: "create"
    }
    this.setState(() => ({
      quickLinks: [emptyQuickLink, ...this.state.quickLinks],
      newQuickLinkIndex: newIndex
    }));
  }

  deleteQuickLink = (event, id) => {
    let { quickLinks } = this.state;

    quickLinks = quickLinks.filter(quickLink => { return quickLink.id !== id });

    this.setState(() => ({
      quickLinks: quickLinks
    }));
  }

  getQuickLinks = (quickLinks) => {
    if (quickLinks === undefined) {
      return "No Quick Links defined";
    }

    const allQuickLinks = quickLinks.filter(quickLink => {
      return quickLink.state !== 'delete'
    });

    return allQuickLinks.map((quickLink, index) => {
      const quickLinkId = "link-" + quickLink.id;
      const quickDescId = "description-" + quickLink.id;
      const linkValidated = quickLink.link ? 'default' : 'error';
      const descValidated = 'default';
      var linkErrorText = " Required field";
      var descErrorText = " Required field";

      if (quickLink.link && !isValidUrl(quickLink.link)) {
        linkErrorText = " Invalid link format";
        linkValidated = 'error';
      }
      if (quickLink.link && !isLengthWithinRange(quickLink.link, 1, 255)) {
        linkErrorText = " Max 255 characters allowed";
        linkValidated = 'error';
      }
      if (quickLink.description && !isLengthWithinRange(quickLink.description, 1, 255)) {
        descErrorText = " Max 255 characters allowed";
        descValidated = 'error';
      }

      return (
        <div className="quick-link" key={quickLink.id}>
          <FormGroup label="Quick Link:" isRequired fieldId={quickLinkId} validated={linkValidated}
            helperTextInvalid={linkErrorText} helperTextInvalidIcon={<ExclamationCircleIcon />}>
            <TextInput className="form-input"
              isRequired type="text" id={quickLinkId} name={quickLinkId} validated={linkValidated}
              value={quickLink.link} onChange={(e) => this.handleQuickLinkChange("link", quickLinkId, e)}
            />
          </FormGroup>

          <FormGroupSpacer>
            <FormGroup label="Description:" fieldId={quickDescId} validated={descValidated}
              helperTextInvalid={descErrorText} helperTextInvalidIcon={<ExclamationCircleIcon />}>
              <TextInput className="form-input"
                type="text" id={quickDescId} name={quickDescId} validated={descValidated}
                value={quickLink.description} onChange={(e) => this.handleQuickLinkChange("description", quickDescId, e)}
              />
            </FormGroup>
          </FormGroupSpacer>

          <div onClick={(e) => this.deleteQuickLink(e, quickLink.id)}><TrashIcon className="pointer" /></div>
        </div>
      )
    });
  }

  handleCancel = () => {
    this.props.history.push('/dashboard')
  }

  handleSubmit = async (event) => {
    let clientCompanies = await ClientCompany.getAll();
    let clientCompany = clientCompanies.find(c => c.name === this.state.name);

    if (clientCompany) {
      this.setState(() => ({
        message: "A Company with the name " + this.state.name+ " already exists. Please try another name"
      })); 
      return;
    }  

    try {
      const { name, industry, notes, quickLinks } = this.state;
      const links = quickLinks.map(({ id, state, ...rest }) => rest);
      const payload = {
        name: name,
        industry: industry,
        notes: notes,
        Quicklinks: links, 
      }
      let result = await ClientCompany.create(payload);
      this.setState(() => ({ 
        message: "Company was created successfully!",
        formValid: true
      }));
    }
    catch (error) {
      this.setState(() => ({ 
        message: "There was an error creating the company",
        formValid: true
      }));
    }
  }

  render() {
    const { name, industry, industries, notes, quickLinks, isIndustryOpen, validity } = this.state;
    const quickLinksComponent = this.getQuickLinks(quickLinks);
    const invalidQuickLinks = quickLinks.filter(q => { 
      return q.link === "" || !isValidUrl(q.link) || !isLengthWithinRange(q.link, 1, 255) || (q.description && !isLengthWithinRange(q.description, 1, 255))
    });
    const nameErrorState = isLengthWithinRange(name, 1, 255) ? { validated: 'default', errorText: "" } : { validated: 'error', errorText: " Max 255 characters allowed" };
    const notesErrorState = notes !== "" ? isLengthWithinRange(notes, 1, 255) ? { validated: 'default', errorText: "" } : { validated: 'error', errorText: " Max 255 characters allowed" } : { validated: 'default', errorText: "" };
    const formValid = name && industry && invalidQuickLinks.length === 0 && notesErrorState.validated !== 'error';

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">New Company</Title><br />

        <Card isCompact className="controller-edit-panel">
          <CardBody>
            <IconHeading icon={<img src={SiteIcon} width="24px" height="24px" alt="Company Profile" />} heading="Company Profile" /><br />

            <div className="company-input">
              <FormGroup label="Company Name:" isRequired fieldId="name"
                helperTextInvalid={nameErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={nameErrorState.validated}>
                <TextInput
                  isRequired type="text" id="name" name="name" autoComplete="new-password" validated={nameErrorState.validated}
                  value={name} onChange={(e) => this.handleChange("name", e)}
                />
              </FormGroup>

              <FormGroup label="Industry" isRequired fieldId="industry-toggle"
                helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(industry)}>
                <Select
                  maxHeight={300}
                  variant={SelectVariant.single} placeholderText="Select ..." validated={textInputRequiredOnly(industry)}
                  onToggle={(e) => this.onToggle(e, "isIndustryOpen")}
                  onSelect={(event, selection, isPlaceholder) => this.onSelect(event, selection, isPlaceholder, "industry", "isIndustryOpen")}
                  selections={industry} isOpen={isIndustryOpen} isDisabled={false}
                >
                  {industries}
                </Select>
              </FormGroup>
            </div>

            <br />

            <IconHeading icon={<CatalogIcon className="icon-medium" />} heading="Company Notes" />
            <div className="pointer" onClick={this.addQuickLink}><PlusIcon /> &nbsp;New Quick Link</div>
            {quickLinksComponent}<br />

            <br />

            <strong>Notes:</strong><br />
            <FormGroup label="" fieldId="notes"
              helperTextInvalid={notesErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={notesErrorState.validated}>
              <TextArea 
                rows="3" id="notes" name="notes" autoComplete="new-password"
                value={notes} onChange={(e) => this.handleChange("notes", e)} 
                aria-label="Company Notes" 
              />
            </FormGroup>
          </CardBody>
        </Card>

        <Saver 
          submitButtonText="Save"
          submitButtonisDisabled={!formValid}
          submit={this.handleSubmit} 
          cancel={this.handleCancel}
          showCancel={true}
          message={this.state.message}
          messageType={this.state.messageType}
        />
      </PageSection>
    );
  }
}

export { CompanyForm };
