import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { actionCreators } from '../common/session/session.reducer';
import { selectNotifications } from '@app/common/session/session.selectors';

let displayed: string[] = [];

export const useNotifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, notificationKey) => {
          if (options.onClose) {
            options.onClose(event, reason, notificationKey);
          }
        },
        onExited: (_, notificationKey) => {
          dispatch(actionCreators.notificationRemove(notificationKey));
          removeDisplayed(notificationKey);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, displayed]);
};
