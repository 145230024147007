import getAllGatewayIds from '@app/pages/wan/WanHelper';

const setControllerServers = (controller, sites, wans) => {
  var controllerSites = [];
  var assignedServers = [];
  var availableServers = [];
  let wanGateways = getAllGatewayIds(wans);

  controller.Servers.forEach((server) => {
    if (server.Tunnels.length > 0) {
      assignedServers.push(server);

      let foundSite;

      server.Tunnels.forEach((tunnel) => {
        foundSite = sites.find((site) => site.Tunnels.find((siteTunnel) => tunnel.id === siteTunnel.id));
        controllerSites.push({ ...foundSite, server, name: foundSite.ClientCompany.name });
      });
    } else if (wanGateways.includes(server.id)) {
      assignedServers.push(server);
    } else {
      availableServers.push(server);
    }
  });

  return {
    sites: controllerSites,
    assigned: assignedServers,
    available: availableServers,
  };
};

export default setControllerServers;