
export const MAX_LINKS_ALLOWED = 16;
export const DEFAULT_MIN_SERVER_PORT = 7000; 
export const DEFAULT_MAX_SERVER_PORT = 7999;
export const CHECK_TOKEN_EXPIRATION_FREQ = 60000;
export const MAX_LOGO_SIZE_MB = 1024 * 1024 * 5;
export const MAX_FAVICON_SIZE_MB = 1024 * 1024 * 2;
export const FAVICON_FILE_TYPES = '.ico, .png, .jpeg, .jpg, .gif';
export const LOGO_FILE_TYPES = '.png, .jpeg, .jpg, .gif';
export const API_RETRY_ATTEMPTS = 4;
export const API_RETRY_INTERVAL = 3.5 * 1000;
export const MAX_ACL_PORT = 65535;
export const MAX_LICENSE_QUANTITY = 100000;
export const DEFAULT_UUID = '00000000-0000-0000-0000-000000000000';
export const SPEED_TEST_POLL_FREQ = 30000;