import * as React from 'react';
import ControllerApi from '@app/api/controller-api/controller.actions';
import GatewayApi from '@app/api/gateway-api/gateway.actions';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import Server from '@app/api/gateway/gateway.actions';
import Site from '@app/api/site/site.actions';
import PublicIp from '@app/api/publicip/publicip.actions';
import Wan from '@app/api/wan/wan.actions';
import setControllerServers from '@app/pages/controller/ControllerHelper';
import { textInputRequiredOnly, isValidIP, isValidPrefix, isLengthWithinRange, isUUID } from '@app/lib/validator';
import { Timezones, getCountryOptions, getRegionOptions, getTimezoneOptions, getOffsetString } from '@app/common/world';
import { IconHeading } from '@app/components/IconHeading';
import { Saver } from '@app/components/Saver';
import ControllerIcon from '@app/bgimages/controllers-black.svg';
import { ExclamationCircleIcon, TrashIcon, PlusIcon, BullhornIcon, TopologyIcon, PficonNetworkRangeIcon } from '@patternfly/react-icons';
import { PageSection, Title, Checkbox, Button, Card, CardBody, Flex, FlexItem, FormGroup, Modal, ModalVariant, TextInput, Select, SelectVariant, TextArea, Tabs, Tab, TabTitleText, TabTitleIcon } from "@patternfly/react-core";
import { Table, TableHeader, TableBody, TableVariant, cancelCellEdits, validateCellEdits, applyCellEdits, EditableTextCell } from '@patternfly/react-table';
import { FormGroupSpacer } from '../../components/forms/pf/form-group-spacer.component';

import './Controller.css';

import { connect } from 'react-redux';
import { actionCreators } from '@app/common/session/session.reducer';

enum TabIndex {
  OSPF,
  BGP
}

const TitleMap = {
  [TabIndex.OSPF]: 'OSPF',
  [TabIndex.BGP]: 'BGP'
};

export const ControllerForm = connect()(class extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const id = this.props.computedMatch?.params?.id;
    const countries = getCountryOptions();
    const timezones = getTimezoneOptions();

    this.state = {
      id: id,
      name: "",
      description: "",
      country: "",
      region: "", 
      timezone: "",
      ip_address: "",
      routingEnabled: false,
      routingConfig: {
        ospf_enabled: false,
        ospf_configuration: "",
        bgp_enabled: false,
        bgp_configuration: ""
      },
      activeTabIndex: TabIndex.OSPF,
      isCountryOpen: false,
      isRegionOpen: false,
      isTimezoneOpen: false,
      isDeleteServerConfirmOpen: false,
      serverId: "",
      newServerTempId: -1,
      countries: countries,
      regions: [],
      timezones: timezones,
      servers: [],
      bgp_configuration_rows: 10,
      ospf_configuration_rows: 10,
      message: "",
      messageType: "",
      editRowMode: false,
      mode: id ? "edit" : "new"
    }
  }

  componentDidMount = async () => {
    if (this.state.mode === "edit") {
      try {
        await this.loadController();
      } 
      catch (error) {
        console.log(error)
        this.setState(() => ({
          message: "There was an error loading the controller"
        }));
      }
    }
  }

  loadController = async () => {
    const sites = await Site.getAll();
    const wans = await Wan.getAll();
    const controller = await ControllerApi.get(this.state.id);
    const gateways = await GatewayApi.getAll();
    const tunnels = await Tunnel.getAll();
    const publicIps = await PublicIp.getAll();

    if (controller === undefined) {
      this.props.history.push("/notfound");
    }
    
    controller.Servers = gateways.filter(gateway => gateway.controller_id === this.state.id);

    for (let i=0; i<controller.Servers.length; i++) {
      controller.Servers[i] = { 
        ...controller.Servers[i], 
        Tunnels: tunnels.filter(tunnel => tunnel.serverId === controller.Servers[i].id),
        PublicIps: publicIps.filter(publicIp => publicIp.serverId === controller.Servers[i].id)
      };
    }

    const servers = setControllerServers(controller, sites, wans);
    const assignedServers = servers['assigned'].map(item => { return this.getRow(item, false); });
    const availableServers = servers['available'].map(item => { return this.getRow(item, false); });
    const regions = getRegionOptions(controller.country) || [];
    const timezones = Timezones();
    const timezoneObj = timezones.find(t => t.name === controller.timezone);
    const timezone = getOffsetString(timezoneObj); //TODO: Ugly, refactor this
    const ospfConfig = "";
    const bgpConfig = "";
    const ospf = controller.routing_configuration?.ospf_configuration;
    const bgp = controller.routing_configuration?.bgp_configuration;
    
    if (ospf) {
      ospfConfig = ospf.join('\n');
    }
    if (bgp) {
      bgpConfig = bgp.join('\n');
    }  

    this.setState(() => ({
      name: controller.name,
      description: controller.description,
      country: controller.country, 
      region: controller.region, 
      regions: regions,
      timezone: timezone,
      ip_address: controller.ip_address || '',
      // routerIp: controller.router_ip,
      // isVps: controller.is_vps,
      // vpsProvider: controller.vps_provider,
      // monitorEndpoint: controller.monitor_endpoint,
      ospf_configuration_rows: ospf?.length > 10 ? ospf.length : 10,
      bgp_configuration_rows: bgp?.length > 10 ? bgp.length : 10,
      routingEnabled: controller.dynamic_routing_enabled,
      routingConfig: {
        ospf_enabled: controller.routing_configuration?.ospf_enabled,
        ospf_configuration: ospfConfig,
        bgp_enabled: controller.routing_configuration?.bgp_enabled,
        bgp_configuration: bgpConfig,
      },
      servers: [...assignedServers, ...availableServers]
    }));
  }

  getRow = (server, isEditable) => {
    var cells: any[] = [];

    cells.push(this.getCell(server, "ip_4_address"));
    cells.push(this.getCell(server, "ip_4_cidr"));
    cells.push(this.getCell(server, "ip_4_router"));
    cells.push(this.getCell(server, "delete"));
    cells.push(this.getCell(server, "id"));

    const row = {
      rowEditValidationRules: [{
        name: 'required',
        validator: value => value.trim() !== '',
        errorText: 'Field required'
      },{
        name: 'invalidIp',
        validator: value => isValidIP(value.trim()) !== false,
        errorText: 'Invalid IP Address'
      },{
        name: 'invalidMask',
        validator: value => isValidPrefix(value.trim()) !== false,
        errorText: 'Must be between /8 and /32'
      }],
      cells: cells,
      publicIpId: (server.PublicIps && server.PublicIps.length > 0) ? server.PublicIps[0].id : ""
    }

    row.isEditable = isEditable;
 
    return row;
  } 

  getCell = (server, columnName) => {
    if (columnName === 'delete') {
      return { 
        title: (
          <div className="trash-icon" onClick={(e) => this.deleteConfirmation(e, server.id)}>
            <TrashIcon />
          </div>
        ),
        props: {
          value: "-1",
          name: columnName
        }
      };
    }
    
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableTextCell
          value={value}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          handleTextInputChange={this.handleTextInputChange}
          inputAriaLabel={columnName}
        />
      ),
      props: {
        value: server[columnName],
        name: columnName
      }
    }

    return cell;
  }

  handleTabClick = (e, index) => {
    this.setState(() => ({
      activeTabIndex: index
    }));
  };

  addServer = () => {
    const { servers, newServerTempId } = this.state;
    var emptyRow = { ip_4_address: "", ip_4_cidr: "/24", ip_4_router: "", delete: "", id: newServerTempId.toString() };

    servers.push(this.getRow(emptyRow, true));

    this.setState(() => ({
      newServerTempId: newServerTempId - 1,
      editRowMode: true,
      servers: servers
    }));
  }

  deleteConfirmation = (event, id) => {
    this.setState(() => ({
      isDeleteServerConfirmOpen: true,
      serverId: id
    }));
  }

  deleteServer = async () => {
    const { serverId, servers } = this.state;
    const serverIdIndex = this.getServerColumns().length;
    const updated = servers.filter(c => c.cells[serverIdIndex].props.value !== serverId);

    // If server being deleted actually exists in the DB. Servers added, but not persisted will have "-1" id
    if (isUUID(serverId)) {
      try {
        await Server.delete(serverId);
        this.setState(() => ({
          message: "Gateway was deleted successfully!",
          servers: updated, 
        }));
      } catch (error) {
        if ((error as any).message === 'SERVER_ASSIGNED') {
          this.props.dispatch(actionCreators.notificationEnqueue({
            message: 'Server Assigned: Unable to Delete',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          }));
        }
      }
    }
    else {
      this.setState(() => ({
        message: "Gateway was deleted successfully!",
        servers: updated, 
      }));
    }

    this.setState(() => ({
      isDeleteServerConfirmOpen: false,
      serverId: "",
      error: "",
      editRowMode: false
    }));
  }

  onCountryToggle = (isOpen) => {
    this.setState(() => ({ 
      isCountryOpen: isOpen
    }));
  }

  onRegionToggle = (isOpen) => {
    this.setState(() => ({ 
      isRegionOpen: isOpen
    }));
  }

  onTimezoneToggle = (isOpen) => {
    this.setState(() => ({ 
      isTimezoneOpen: isOpen
    }));
  }

  onCountrySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearCountry();
    }
    else {
      const regions = getRegionOptions(selection);
      this.setState(() => ({ 
        country: selection,
        regions: regions,
        region: null,
        isCountryOpen: false 
      }));
    }
  }

  onRegionSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearRegion();
    }
    else {
      this.setState(() => ({ 
        region: selection,
        isRegionOpen: false 
      }));
    }
  }

  onTimezoneSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearTimezone();
    }
    else {
      this.setState(() => ({ 
        timezone: selection,
        isTimezoneOpen: false 
      }));
    }
  }

  clearCountry = () => {
    this.setState({
      country: null,
      isCountryOpen: false
    });
  };

  clearRegion = () => {
    this.setState({
      region: null,
      isRegionOpen: false
    });
  };

  clearTimezone = () => {
    this.setState({
      timezone: null,
      isTimezoneOpen: false
    });
  };

  handleTextInputChange = (newValue, evt, rowIndex, cellIndex) => {
    let newRows = Array.from(this.state.servers);
    newRows[rowIndex].cells[cellIndex].props.editableValue = newValue;
    this.setState(() => ({
      servers: newRows
    }));
  }

  updateEditableRows = async (evt, type, isEditable, rowIndex, validationErrors) => {
    let newRows = Array.from(this.state.servers);
    var invalidIps = [];
    var invalidMasks = [];

    if (validationErrors.invalidIp && validationErrors.invalidIp.includes("ip_4_address")) {
      invalidIps.push("ip_4_address")
    }    
    if (validationErrors.invalidIp && validationErrors.invalidIp.includes("ip_4_router")) {
      invalidIps.push("ip_4_router")
    }
    if (validationErrors.invalidMask && validationErrors.invalidMask.includes("ip_4_cidr")) {
      invalidMasks.push("ip_4_cidr")
    }

    if (invalidIps.length > 0) {
      validationErrors.invalidIp = invalidIps;
    }
    else {
      delete validationErrors.invalidIp
    }

    if (invalidMasks.length > 0) {
      validationErrors.invalidMask = invalidMasks;
    }
    else {
      delete validationErrors.invalidMask
    }

    if (validationErrors && Object.keys(validationErrors).length) {
      newRows[rowIndex] = validateCellEdits(newRows[rowIndex], type, validationErrors);
      this.setState({ servers: newRows });
      return;
    }
    
    if (type === 'edit' && Object.keys(validationErrors).length === 0) {
      this.setState({ editRowMode: true });
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);
      return;
    }

    if (type === 'cancel') {
      newRows[rowIndex] = cancelCellEdits(newRows[rowIndex]);
      this.setState({ servers: newRows, editRowMode: false });
      return;
    }
    
    if (type === 'save') {
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);

      // Check for duplicate IPs
      let currentRowIp = newRows[rowIndex].cells[0].props.value;
      let newServerIps = newRows.filter(s => s.cells[0].props.value === currentRowIp);

      if (newServerIps.length > 1) {
        this.setState({ editRowMode: true, error: currentRowIp + " already exists. Please edit and try another" });
      }
      else {
        this.setState({ servers: newRows, editRowMode: false, error: "" });
      }
    }
  }

  getServerColumns = () => {
    return ['IP Address', 'Subnet', 'Controller IP', ''];
  }

  handleConfirmToggle = () => {
    this.setState(({ isDeleteServerConfirmOpen }) => ({
      isDeleteServerConfirmOpen: !isDeleteServerConfirmOpen
    }));
  };

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  handleObjectChange = (key, name, value) => {
    const obj = this.state[key];
    const rowsKey = name + '_rows';
    const configRows = obj[name].split('\n');
    const numRows = configRows.length > 10 ? configRows.length : 10;
    obj[name] = value;
    
    this.setState(() => ({
      [key]: obj,
      [rowsKey]: numRows
    }));
  };

  handleConfigPaste = (key, event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('Text');
    const configProp = key.replace('_rows', '');
    const configRows = pastedData.split('\n');
    const numRows = configRows?.length > 10 ? configRows?.length : 10;
    const config = this.state.routingConfig;
    config[configProp] = pastedData;

    this.setState(() => ({
      routingConfig: { ...config },
      [key]: numRows
    }));
  };

  handleRoutingCheckboxChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(prevState => ({
      routingConfig: {
        ...prevState.routingConfig,
        [name]: value
      },
    }));
  };

  handleCancel = () => {
    if (this.state.id) {
      this.props.history.push('/controller/' + this.state.id)
    }
    else {
      this.props.history.push('/controllers')
    }
  }

  handleSubmit = async (event) => {
    if (this.state.mode === "edit") {
      this.updateController();
    }
    else if (this.state.mode === "new") {
      this.createController();
    }
  }

  getPayloadFromState = () => {
    const routingConfig = this.state.routingConfig;

    return {
      name: this.state.name,
      description: this.state.description,
      country: this.state.country,
      region: this.state.region, 
      timezone: this.state.timezone,
      gateways: this.state.servers,
      ip_address: this.state.ip_address,
      dynamic_routing_enabled: routingConfig.ospf_enabled || routingConfig.bgp_enabled,
      routing_configuration: {
        ospf_enabled: routingConfig.ospf_enabled,
        ospf_configuration: routingConfig.ospf_configuration.split('\n') || [],
        bgp_enabled: routingConfig.bgp_enabled,
        bgp_configuration: routingConfig.bgp_configuration.split('\n') || []
      }
    }
  }

  createController = async () => {
    const { name, servers } = this.state;

    if (name.toLowerCase() === "serverless") {
      this.setState(() => ({
        message: "The name " + name + " is reserved. Please try another name"
      })); 
      return;
    }
    
    const controllers = await ControllerApi.getAll();
    const controller = controllers.find(c => c.name === name);

    if (controller) {
      this.setState(() => ({
        message: "A controller with the name " + name + " already exists. Please try another name"
      })); 
      return;
    }

    try {
      const payload = this.getPayloadFromState();
      let result = await ControllerApi.create(payload);
      this.props.history.push('/controller/' + result.id)
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error creating the controller"
      }));
    }
  }

  updateController = async () => {
    const { name, servers } = this.state;

    if (name.toLowerCase() === "serverless") {
      this.setState(() => ({
        message: "The name " + name + " is reserved. Please try another name"
      })); 
      return;
    }

    let controllers = await ControllerApi.getAll();
    let otherControllers = controllers?.filter(c => c.id !== this.state.id);
    let controller = otherControllers?.find(c => c.name === name);

    if (controller) {
      this.setState(() => ({
        message: "A controller with the name " + name + " already exists. Please try another name"
      })); 
      return;
    }

    try {
      const payload = this.getPayloadFromState();
      let result = await ControllerApi.update(this.state.id, payload);
      this.props.history.push('/controller/' + this.state.id)
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error updating the controller"
      }));
    }
  }

  render() {
    const { 
      name, description, country, region, timezone, ip_address, routingConfig, routingEnabled, isCountryOpen, isRegionOpen, isTimezoneOpen, serverId,
      bgp_configuration_rows, ospf_configuration_rows, activeTabIndex, isDeleteServerConfirmOpen, countries, regions, timezones, servers, editRowMode, mode
    } = this.state;

    const bgpErrorState = routingConfig?.bgp_enabled && routingConfig?.bgp_configuration === "" ? { validated: 'error', errorText: " BGP Configuration must be provided" } : { validated: 'default', errorText: "" };
    const ospfErrorState = routingConfig?.ospf_enabled && routingConfig?.ospf_configuration === "" ? { validated: 'error', errorText: " OSPF Configuration must be provided" } : { validated: 'default', errorText: "" };
    const descriptionErrorState = isLengthWithinRange(description, 1, 255) ? { validated: 'default', errorText: "" } : { validated: 'error', errorText: " Max 255 characters allowed" };  
    const ipAddressErrorState = ip_address === '' || (ip_address !== '' && isValidIP(ip_address)) ? { errorText: '', validated: 'default' } : { errorText: 'Invalid IP Address', validated: 'error' };

    const formInvalid = !name || !country || !region || !timezone || editRowMode || descriptionErrorState.validated === 'error' ||
      bgpErrorState.validated === 'error' || ospfErrorState.validated === 'error' || ipAddressErrorState.validated === 'error';

    return (
      <PageSection>
        <Title headingLevel="h1" size="xl">{this.state.mode === "edit" ? "Edit" : "New"} Controller</Title><br />

        <Flex>
          <FlexItem className="flex-item-half">
            <Card isCompact className="controller-edit-panel">
              <CardBody>
                <IconHeading icon={<img src={ControllerIcon} width="24px" height="24px" alt="Controller" />} heading="Controller" />

                <FormGroup label="Controller Name:" isRequired fieldId="name"
                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={mode === "new" ? textInputRequiredOnly(name) : 'default'}>
                  <TextInput
                    isRequired type="text" id="name" name="name" autoComplete="new-password"
                    value={name} onChange={(e) => this.handleChange("name", e)} validated={mode === "new" ? textInputRequiredOnly(name) : 'default'}
                  />
                </FormGroup>

                <FormGroup label="IP Address:" fieldId="ip_address"
                  helperTextInvalid={ipAddressErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={ipAddressErrorState.validated}>
                  <TextInput
                    isRequired type="text" id="ip_address" name="ip_address" autoComplete="new-password"
                    value={ip_address} onChange={(e) => this.handleChange("ip_address", e)} validated={ipAddressErrorState.validated}
                  />
                </FormGroup>

                <FormGroupSpacer>
                  <FormGroup label="Description:" fieldId="description" isRequired helperTextInvalid={' Required field'} 
                    helperTextInvalidIcon={<ExclamationCircleIcon />} validated={descriptionErrorState.validated}>
                    <TextArea 
                      rows="3" id="description" name="description" autoComplete="new-password"
                      validated={description !== "" ? descriptionErrorState.validated : 'default'}
                      value={description} onChange={(e) => this.handleChange("description", e)} 
                      aria-label="Controller description" 
                    />
                  </FormGroup>
                </FormGroupSpacer>

              </CardBody>
            </Card>
          </FlexItem>

          <FlexItem className="flex-item-half">
            <Card isCompact className="controller-edit-panel">
              <CardBody>
                <IconHeading icon={<img src={ControllerIcon} width="24px" height="24px" alt="Location" />} heading="Location" />

                <FormGroup label="Country:" isRequired fieldId="country-toggle"
                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={mode === "new" ? textInputRequiredOnly(country) : 'default'}>
                  <Select 
                    maxHeight={300}
                    validated={mode === "new" ? textInputRequiredOnly(country) : 'default'} variant={SelectVariant.typeahead}
                    placeholderText="Select Country ..." aria-label="Select Country"
                    onToggle={this.onCountryToggle} onSelect={this.onCountrySelect}
                    selections={country} isOpen={isCountryOpen} isDisabled={false}
                  >
                    {this.state.countries}
                  </Select>
                </FormGroup>

                <FormGroupSpacer>
                  <FormGroup label="Region:" isRequired fieldId="region-toggle"
                    helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={mode === "new" ? textInputRequiredOnly(region) : 'default'}>
                    <Select
                      maxHeight={300}
                      validated={mode === "new" ? textInputRequiredOnly(region) : 'default'} variant={SelectVariant.typeahead}
                      placeholderText="Select Region ..." aria-label="Select Region"
                      onToggle={this.onRegionToggle} onSelect={this.onRegionSelect}
                      selections={region} isOpen={isRegionOpen} isDisabled={false}
                    >
                      {this.state.regions}
                    </Select>
                  </FormGroup>
                </FormGroupSpacer>

                <FormGroupSpacer>
                  <FormGroup label="Timezone:" isRequired fieldId="timezone-toggle"
                    helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={mode === "new" ? textInputRequiredOnly(timezone) : 'default'}>
                    <Select
                      maxHeight={300}
                      validated={mode === "new" ? textInputRequiredOnly(timezone) : 'default'} variant={SelectVariant.typeahead}
                      placeholderText="Select Timezone ..." aria-label="Select Timezone"
                      onToggle={this.onTimezoneToggle} onSelect={this.onTimezoneSelect}
                      selections={timezone} isOpen={isTimezoneOpen} isDisabled={false} 
                    >
                      {this.state.timezones}
                    </Select>
                  </FormGroup>
                </FormGroupSpacer>

              </CardBody>
            </Card>
          </FlexItem>
        </Flex>

        <br />

        <Flex>
          <FlexItem className="flex-item-full">
            <Card isCompact>
              <CardBody>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td><IconHeading icon={<img src={ControllerIcon} width="24px" height="24px" alt="Gateways" />} heading="Gateways" /></td>
                      <td className="right">
                        <div className="pointer" onClick={this.addServer}><PlusIcon /> &nbsp;Add Gateway</div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Table
                  onRowEdit={this.updateEditableRows}
                  aria-label="Edit Gateways"
                  variant={TableVariant.compact}
                  cells={this.getServerColumns()}
                  rows={servers}
                >
                  <TableHeader />
                  <TableBody />
                </Table>
                <br />
                <span className="error">{this.state.error}</span>
              </CardBody>
            </Card>
          </FlexItem>
        </Flex>

        <br />

        <Flex>
          <FlexItem className="flex-item-full">
            <Card isCompact>
              <CardBody>
                <IconHeading icon={<TopologyIcon />} heading="Dynamic Routing" />

                <Tabs activeKey={activeTabIndex} onSelect={this.handleTabClick} isBox>
                  <Tab
                    eventKey={TabIndex.OSPF}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <PficonNetworkRangeIcon className="icon-medium" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.OSPF]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    <br />
                    <Checkbox
                      label="OSPF Enabled"
                      aria-label="OSPF Enabled"
                      name="ospf_enabled"
                      id="ospf-enabled"
                      key="ospf-enabled"
                      onChange={this.handleRoutingCheckboxChange}
                      isChecked={routingConfig?.ospf_enabled}
                    />
                    <br />
                    <FormGroup label="" isRequired fieldId="ospf-config-toggle"
                      helperTextInvalid={ospfErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={ospfErrorState.validated}>
                      <TextArea 
                        rows={ospf_configuration_rows} id="ospf_configuration" name="ospf_configuration" autoComplete="new-password"  isDisabled={!routingConfig?.ospf_enabled}
                        value={routingConfig?.ospf_configuration} 
                        onChange={(e) => this.handleObjectChange('routingConfig', 'ospf_configuration', e)}
                        aria-label="OSPF Configuration" className="code-config"
                      />
                    </FormGroup>
                  </Tab>
                  <Tab
                    eventKey={TabIndex.BGP}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <PficonNetworkRangeIcon className="icon-medium" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.BGP]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    <br />
                    <Checkbox
                      label="BGP Enabled"
                      aria-label="BGP Enabled"
                      name="bgp_enabled"
                      id="bgp-enabled"
                      key="bgp-enabled"
                      onChange={this.handleRoutingCheckboxChange}
                      isChecked={routingConfig?.bgp_enabled}
                    />
                    <br />
                    <FormGroup label="" isRequired fieldId="bgp-config-toggle"
                      helperTextInvalid={bgpErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={bgpErrorState.validated}>
                      <TextArea 
                        rows={bgp_configuration_rows} id="bgp_configuration" name="bgp_configuration" autoComplete="new-password" isDisabled={!routingConfig?.bgp_enabled}
                        value={routingConfig?.bgp_configuration} 
                        onChange={(e) => this.handleObjectChange('routingConfig', 'bgp_configuration', e)} 
                        aria-label="BGP Configuration" className="code-config"
                      />
                    </FormGroup>
                  </Tab>
                </Tabs>
              </CardBody>
            </Card>

            <Modal
              title="Confirm Action"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={isDeleteServerConfirmOpen}
              onClose={this.handleConfirmToggle}
              actions={[
                <Button key="confirm" variant="primary" onClick={this.deleteServer}>Yes</Button>,
                <Button key="cancel" variant="link" onClick={this.handleConfirmToggle}>Cancel</Button>
              ]}
            >
              <p>Are you sure you want to delete this gateway?</p>
              {isUUID(serverId) ? <p><b>{serverId}</b></p> : <></> }
            </Modal>

            <Saver 
              submitButtonText="Save" 
              submitButtonisDisabled={formInvalid}
              submit={this.handleSubmit} 
              cancel={this.handleCancel} 
              showCancel={true}
              message={this.state.message}
              messageType={this.state.messageType}
            />
          </FlexItem>
        </Flex>
        
      </PageSection>
    );
  }
});