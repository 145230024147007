import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/lib/persistReducer';

import { authReducer } from '../common/auth/auth.reducer';
import { sessionReducer } from '../common/session/session.reducer';
import { configurationReducer } from '../common/configuration/configuration.reducer';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['isLoading', 'error'],
  timeout: null as unknown as number,
};

export const createRootReducer = (history: History) => {
  const commonReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer) as typeof authReducer,
    session: sessionReducer,
  });

  return combineReducers({
    common: commonReducer,
    configuration: configurationReducer,
    router: connectRouter(history),
  });
};

export type State = ReturnType<ReturnType<typeof createRootReducer>> & { router: RouterState };
