import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { DashboardView } from '@app/pages/dashboard/DashboardView';
import { AdminView } from '@app/pages/admin/AdminView';
import { CompanyForm } from '@app/pages/client-company/CompanyForm';
import { ControllerList } from '@app/pages/controller/controller.list';
import { ControllerView } from '@app/pages/controller/controller.view';
import { ControllerForm } from '@app/pages/controller/ControllerForm';
import { WanList } from '@app/pages/wan/wan.list';
import { WanView } from '@app/pages/wan/wan.view';
import { WanForm } from '@app/pages/wan/WanForm';
import { SiteList } from '@app/pages/site/site.list';
import { SiteView } from '@app/pages/site/site.view';
import { SiteForm } from '@app/pages/site/SiteForm';
import { SitePerformance } from '@app/pages/site/SitePerformance';
import { ACLProfileList } from '@app/pages/aclprofile/ACLProfileList';
import { ACLProfileForm } from '@app/pages/aclprofile/ACLProfileForm';
import { ACLProfileView } from '@app/pages/aclprofile/ACLProfileView';
import { LicenseList } from '@app/pages/license/LicenseList';
import { LicenseUpdate } from '@app/pages/license/LicenseUpdate';
import { LicensePurchase } from '@app/pages/license/LicensePurchase';
import { LicenseView } from '@app/pages/license/LicenseView';
import { PartnerList } from '@app/pages/partner/PartnerList';
import { PartnerForm } from '@app/pages/partner/PartnerForm';
import { NotFound } from '@app/pages/not-found/not-found';
import { LastLocationProvider } from 'react-router-last-location';
import { sideBarLinkStyle } from '@app/common/sidebar-styles';

export interface IAppRoute {
  label?: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact?: boolean;
  path: string;
  title: string;
  isAsync?: boolean;
  routes?: undefined;
}

export interface IAppRouteGroup {
  label: string;
  routes: IAppRoute[];
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;

const routes: AppRouteConfig[] = [
  {
    component: DashboardView,
    exact: true,
    label: 'Dashboard',
    path: '/dashboard',
    title: 'Dashboard',
    style: sideBarLinkStyle
  },
  {
    component: PartnerList,
    exact: true,
    label: 'Partners',
    path: '/partners',
    title: 'Partners',
    style: sideBarLinkStyle
  },
  {
    component: PartnerForm,
    exact: true,
    path: '/partner/new',
    title: 'New Partner'
  },
  {
    component: PartnerForm,
    exact: true,
    path: '/partner/edit/:id',
    title: 'Edit Partner'
  },
  {
    component: CompanyForm,
    exact: true,
    path: '/company/new',
    title: 'New Company'
  },
  {
    component: ControllerList,
    exact: true,
    label: 'Controllers',
    path: '/controllers',
    title: 'Controllers',
    style: sideBarLinkStyle
  },
  {
    component: ControllerForm,
    exact: true,
    path: '/controller/new',
    title: 'New Controller'
  },
  {
    component: ControllerForm,
    exact: true,
    path: '/controller/edit/:id',
    title: 'Edit Controller'
  },
  {
    component: ControllerView,
    exact: true,
    path: '/controller/:id',
    title: 'View Controller'
  },
  {
    component: SiteList,
    exact: true,
    label: 'Sites',
    path: '/sites',
    title: 'Sites',
    style: sideBarLinkStyle
  },
  {
    component: SiteForm,
    exact: true,
    path: '/site/new',
    title: 'New Site'
  },
  {
    component: SiteForm,
    exact: true,
    path: '/site/edit/:id',
    title: 'Edit Site'
  },
  {
    component: SiteView,
    exact: true,
    path: '/site/:id',
    title: 'View Site'
  },
  {
    component: SitePerformance,
    exact: true,
    path: '/site/performance/:id',
    title: 'Site Performance'
  },
  {
    component: WanList,
    exact: true,
    label: 'WANs',
    path: '/wans',
    title: 'WANs',
    style: sideBarLinkStyle
  },
  {
    component: WanForm,
    exact: true,
    path: '/wan/new',
    title: 'New WAN'
  },
  {
    component: WanForm,
    exact: true,
    path: '/wan/edit/:id',
    title: 'Edit WAN'
  },
  {
    component: WanView,
    exact: true,
    path: '/wan/:id',
    title: 'View WAN'
  },
  {
    component: ACLProfileList,
    exact: true,
    label: 'ACL Profiles',
    path: '/aclprofiles',
    title: 'ACL Profiles',
    style: sideBarLinkStyle
  },
  {
    component: ACLProfileForm,
    exact: true,
    path: '/aclprofile/new',
    title: 'New ACL Profile'
  },
  {
    component: ACLProfileForm,
    exact: true,
    path: '/aclprofile/edit/:id',
    title: 'Edit ACL Profile'
  },
  {
    component: ACLProfileView,
    exact: true,
    path: '/aclprofile/:id',
    title: 'View ACL Profile'
  },
  {
    component: LicenseList,
    exact: true,
    label: 'Licenses',
    path: '/licenses',
    title: 'Licenses',
    style: sideBarLinkStyle
  },
  {
    component: LicensePurchase,
    exact: true,
    path: '/license/new',
    title: 'Purchase License'
  },
  {
    component: LicenseUpdate,
    exact: true,
    path: '/license/edit/:id',
    title: 'Edit License'
  },
  {
    component: LicenseView,
    exact: true,
    path: '/license/:id',
    title: 'View License'
  },
  {
    component: AdminView,
    exact: true,
    label: 'Admin',
    path: '/admin',
    title: 'Admin',
    style: sideBarLinkStyle
  }
];

const RouteWithTitleUpdates = ({ component: Component, title, ...rest }: IAppRoute) => {
  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />;
  }

  return <Route render={routeWithTitle} />;
};

const PageNotFound = ({ title }: { title: string }) => {
  return <Route component={NotFound} />;
};

const flattenedRoutes: IAppRoute[] = routes.reduce(
  (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])],
  [] as IAppRoute[]
);

const AppRoutes = (): React.ReactElement => (
  <LastLocationProvider>
    <Switch>
      {flattenedRoutes.map(({ path, exact, component, title, isAsync }, idx) => (
        <RouteWithTitleUpdates
          path={path}
          exact={exact}
          component={component}
          key={idx}
          title={title}
        />
      ))}
      <PageNotFound title="404 Page Not Found" />
    </Switch>
  </LastLocationProvider>
);

export { AppRoutes, routes };
