import * as React from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import throughputFromLinks from './SiteHelper';
import { IconHeading } from '@app/components/IconHeading';
import { InfoSection } from '@app/components/InfoSection';
import { Spacer } from '@app/components/Spacer';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { protocolOptions, directionOptions } from '@app/common/constants';
import { capitalizeFirstLetter } from '@app/utils/utils';
import { sortable, cellWidth, textRight } from '@patternfly/react-table';
import { Accordion, AccordionContent, AccordionItem, AccordionToggle, FormGroup, Spinner, Select, SelectOption, SelectVariant, Tabs, Tab, TabTitleIcon, TabTitleText, TextInput } from '@patternfly/react-core';
import { sortBy, reverseSortBy } from '@app/lib/functions';
import Site from '@app/api/site/site.actions';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import SiteApi from '@app/api/site-api/site.actions';
import SpeedTest from '@app/api/speed-test/speed-test.actions';
import ControllerApi from '@app/api/controller-api/controller.actions';
import License from '@app/api/license/license.actions';
import SiteIcon from '@app/bgimages/sites-black.svg';
import WanIcon from '@app/bgimages/wans-black.svg';
import ACLProfileIcon from '@app/bgimages/service-catalog.svg';
import { getLbAlgoNameFromId } from './TunnelHelper';
import {
  AutomationIcon,
  BullhornIcon,
  ConnectedIcon,
  EnterpriseIcon,
  NetworkIcon,
  CatalogIcon,
  ClusterIcon,
  MapMarkerIcon,
  ExternalLinkAltIcon,
  ExclamationCircleIcon,
  SyncAltIcon,
  InfrastructureIcon,
  OutlinedClockIcon,
  TopologyIcon,
  DomainIcon,
  ServiceCatalogIcon,
  UserIcon,
  WrenchIcon,
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  PencilAltIcon,
  PficonNetworkRangeIcon
} from '@patternfly/react-icons';
import {
  PageSection,
  Title,
  Button,
  Card,
  CardBody,
  Flex,
  FlexItem,
  Modal,
  ModalVariant,
  ToggleGroup,
  ToggleGroupItem,
} from '@patternfly/react-core';
import { networkCidrBlock, formatDate } from '@app/lib/functions';
import { isValidIP, isValidNumber } from '@app/lib/validator';
import { SPEED_TEST_POLL_FREQ } from '@app/lib/constants';
import '../../components/InfoSection.css';
import './Site.css';
import { useSiteContext } from '@app/contexts/site.context';
import {
  selectClientCompanyById,
  selectControllerById,
  selectServerById,
  selectSiteById,
  selectWanById,
} from '@app/common/configuration/configuration.selectors';
import { push } from 'connected-react-router';
import { selectPartnerId } from '@app/common/session/session.selectors';
import UserSession from '@app/common/user-session';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import { NotFound } from '../not-found/not-found';
import { ErrorView } from '../error/error';
import { actionCreators } from '@app/common/configuration/configuration.reducer';
import '../../app.css';
import './Site.css';

enum TabIndex {
  // OSPF,
  BGP
}

export const SiteView: React.FC = () => {
  const dispatch = useDispatch();
  const { id, isLoading, isFailed, isSuccess } = useSiteContext();

  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, rebootType: '' });

  const partnerId = useSelector(selectPartnerId);
  const site = useSelector(selectSiteById(id));
  const wan = useSelector(selectWanById(site?.wanId));
  const primaryTunnel = site?.Tunnels.find((tunnel) => tunnel.isPrimary === true) ?? site?.Tunnels?.[0];
  const server = useSelector(selectServerById(primaryTunnel?.Server.id));
  const gateway = useSelector(selectControllerById(primaryTunnel?.Server.gatewayId));

  const siteContact = site?.SiteContacts[0];
  const routedIpState = (site?.SiteRoutes?.length ?? 0) > 0 ? 'Enabled' : 'Disabled';
  const wanState = site?.wanId ? 'Enabled' : 'Disabled';

  const secondaryTunnel = site?.Tunnels.find((tunnel) => tunnel.isPrimary === false);
  const standbyServer = useSelector(selectServerById(secondaryTunnel?.Server.id));
  const standbyGateway = useSelector(selectControllerById(secondaryTunnel?.Server.gatewayId));
  const [aclProfiles, setAclProfiles] = React.useState([]);
  const [activeController, setActiveController] = React.useState(undefined);
  const [standbyController, setStandbyController] = React.useState(undefined);
  const [siteLans, setSiteLans] = React.useState([]);
  const [licenseId, setLicenseId] = React.useState('');
  const [licenseLoading, setLicenseLoading] = React.useState(true);
  const [isSpeedTestModalOpen, setIsSpeedTestModalOpen] = React.useState(false);
  const [isSpeedTestResultsModalOpen, setIsSpeedTestResultsModalOpen] = React.useState(false);
  const [isSpeedTestDropdownOpen, setIsSpeedTestDropdownOpen] = React.useState(false);
  const [isCancelSpeedTestConfirmOpen, setIsCancelSpeedTestConfirmOpen] = React.useState(false);
  const [speedTestInterface, setSpeedTestInterface] = React.useState('Tunnel');
  const [completedSpeedTestInterface, setCompletedSpeedTestInterface] = React.useState('');
  const [speedTests, setSpeedTests] = React.useState([]);
  const [runningTestId, setRunningTestId] = React.useState('');
  const [speedTestIsRunning, setSpeedTestIsRunning] = React.useState(false);
  const [speedTestResultStatus, setSpeedTestResultStatus] = React.useState('');
  const [speedTestInputsDisabled, setSpeedTestInputsDisabled] = React.useState(false);
  const [speedTestResults, setSpeedTestResults] = React.useState({ download: 0, upload: 0, jitter: 0, loss: 0, latency: 0 });
  const [samples, setSamples] = React.useState([]);
  const [download, setDownload] = React.useState(0);
  const [upload, setUpload] = React.useState(0);
  const [protocol, setProtocol] = React.useState('TCP');
  const [direction, setDirection] = React.useState('Bidirectional');
  const [duration, setDuration] = React.useState('10');
  const [occurences, setOccurences] = React.useState('1');
  const [cancelButtonText, setCancelButtonText] = React.useState('Cancel');
  // const [period, setPeriod] = React.useState('');
  const [isProtocolDropdownOpen, setIsProtocolDropdownOpen] = React.useState(false);
  const [isDirectionDropdownOpen, setIsDirectionDropdownOpen] = React.useState(false);
  const [advancedExpanded, setAdvancedExpanded] = React.useState('');
  const [routingConfig, setRoutingConfig] = React.useState('');
  const [activeTabIndex, setActiveTabIndex] = React.useState(TabIndex.BGP);
  const [isSpeedTestsLoading, setIsSpeedTestsLoading] = React.useState(true);
  const nocLink = process.env.REACT_APP_NOC_LINK.replace('{{site_id}}', id)

  const TitleMap = {
    // [TabIndex.OSPF]: 'OSPF',
    [TabIndex.BGP]: 'BGP'
  };
  React.useEffect(() => {
    (async function () {
      const licenseList = await License.getAll(partnerId);
      const siteLicenseId = licenseList.filter(site => site.assigned_to === id);
      const siteRouting = await SiteApi.getRouting(id);
      const sites = await Site.getAll();
      const lans = await Site.getSiteLansBySiteId(id);
      const allSpeedTests = await SpeedTest.getAll(id);
      const theSite = sites.find((site) => site.id === id);
      const tunnels = await Tunnel.getBySiteId(id);
      const activeTunnel = tunnels?.filter(tunnel => tunnel.isActive === true);
      const standbyTunnel = tunnels?.filter(tunnel => tunnel.isActive === false);
      let active, standby;

      if (siteRouting.routing_configuration) {
        setRoutingConfig(siteRouting.routing_configuration)
      }
      if (activeTunnel.length) {
        active = await ControllerApi.get(activeTunnel[0].Server?.gatewayId);
        active.Server = { ...activeTunnel[0].Server };
      }
      if (standbyTunnel.length) {
        standby = await ControllerApi.get(standbyTunnel[0].Server?.gatewayId);
        standby.Server = { ...standbyTunnel[0].Server };
      }

      let acls = await ACLProfile.getAssignedACLs(id);

      if (theSite.wanId) {
        const wanAcls = await ACLProfile.getAssignedACLs(theSite.wanId);
        acls = acls.concat(wanAcls);
      }

      if (siteLicenseId.length) {
        setLicenseId(siteLicenseId[0].id);
      }
      else {
        setLicenseId('Unlicensed');
      }

      const sortedSpeedTests = reverseSortBy(allSpeedTests.tests, 'test_date')
      setSpeedTests(sortedSpeedTests);
      setActiveController(active);
      setStandbyController(standby);
      setLicenseLoading(false);
      setIsSpeedTestsLoading(false);
      setAclProfiles(acls);
      setSiteLans(lans);
    })();
  }, []);

  const getTunnelInfo = React.useCallback(() => {
    return [
      { label: 'Loss Threshold', value: primaryTunnel?.lossTolerance + '%' },
      { label: 'Encryption', value: primaryTunnel?.isClearTextData ? 'Disabled' : 'Enabled' },
      { label: 'MTU Size', value: primaryTunnel?.autoMTU ? 'Auto' : 'Custom ' + primaryTunnel?.mtu },
      { label: 'Reorder Buffer Time', value: primaryTunnel?.reorderBufferTime + ' ms' },
      { label: 'Link Port Range', value: primaryTunnel?.portRangeStart + '-' + primaryTunnel?.portRangeEnd },
      { label: 'Load Balancing Algorithm', value: getLbAlgoNameFromId(primaryTunnel?.lbAlgo) },
      { label: 'Flowlet', value: primaryTunnel?.flowlet ? 'Enabled' : 'Disabled' }
    ];
  }, [primaryTunnel]);

  const getBusinessContinuityInfo = React.useCallback(() => {
    const linkConfig = site?.Tunnels?.[0]?.Links.find((link) => link.isPassthrough);
    const interfaceName = linkConfig === undefined ? 'N/A' : `eth${linkConfig.interfaceName}`;
    return [{ label: 'Interface Port', value: interfaceName }];
  }, [site]);

  const getLanInfo = (lan) => {
    const subnetAddress = `${networkCidrBlock(`${lan?.lanSegmentIp4}${lan?.lanCidrIp4}`)}${lan?.lanCidrIp4}`;

    return [
      { label: 'LAN Subnet', value: subnetAddress },
      { label: 'LAN Gateway IP', value: lan?.lanSegmentIp4 },
      { label: 'Primary DNS', value: lan?.dnsServer1 },
      { label: 'Secondary DNS', value: lan?.dnsServer2 },
      { label: 'DMZ Forwarding IP', value: lan?.isDMZ ? lan?.ip4DMZ : 'Disabled' },
      { label: 'VLAN', value: lan?.vlanId }
    ];
  };

  const getWifiInfo = (lan) => {
    const mode = lan?.apMode === 'mode_5' ? {
      speed: '5',
      channel: lan?.ap5Channel,
      ssid: lan?.ap5SSID,
      password: lan?.ap5Password,
    } : {
      speed: '2.4',
      channel: lan?.apChannel,
      ssid: lan?.apSSID,
      password: lan?.apPassword,
    };

    return [
      { label: mode.speed + ' GHz SSID', value: mode.ssid },
      { label: mode.speed + ' GHz Password', value: mode.password },
      { label: mode.speed + ' GHz Channel', value: mode.channel },
    ];
  };

  const getDhcpInfo = (lan) => {
    return [
      { label: 'IP Range Start', value: lan?.dhcpRangeStart },
      { label: 'IP Range End', value: lan?.dhcpRangeEnd },
      { label: 'Default Lease Time (s)', value: lan?.dhcpDefaultLease },
      { label: 'Max Lease Time (s)', value: lan?.dhcpMaxLease },
    ];
  };

  const getRoutedIpInfo = React.useCallback(() => {
    return site?.SiteRoutes.map((route) => {
      return { label: route.subnet, value: route.routerIP };
    });
  }, [site]);

  const getCompanyInfo = React.useCallback(() => {
    return [
      { label: 'Name', value: site?.ClientCompany?.name },
      { label: 'Industry', value: site?.ClientCompany?.industry },
    ];
  }, [site]);

  const getWanInfo = React.useCallback(() => {
    const accessibleIps = site?.ipAccessLevel === 'All' ? 'All' : site?.accessibleIps;
    return [
      { label: 'WAN Name', value: wan?.name, id: site?.Wan?.id },
      { label: 'Accessible IPs', value: wan?.name ? accessibleIps : '' },
    ];
  }, [site, wan]);

  const getQuickLinks = React.useCallback(() => {
    if (site?.ClientCompany?.Quicklinks === undefined) {
      return '';
    }
    return site?.ClientCompany?.Quicklinks.map((quickLink, index) => {
      return (
        <div className="quick-link" key={quickLink.id}>
          Quick Link: <a href={quickLink.link as string} target="_blank">{quickLink.link}</a>
          <br />
          Description: <strong>{quickLink.description}</strong>
        </div>
      );
    });
  }, [site]);

  const getContactInfo = React.useCallback(() => {
    return [
      { label: 'First Name', value: siteContact?.firstName },
      { label: 'Last Name', value: siteContact?.lastName },
      { label: 'Email', value: siteContact?.email },
      { label: 'Phone', value: siteContact?.phone },
      { label: 'Ext.', value: siteContact?.ext },
      { label: 'Receives Alerts', value: siteContact?.emailAlert ? 'Yes' : 'No' },
    ];
  }, [siteContact]);

  const getLocationInfo = React.useCallback(() => {
    return [
      { label: 'Site Address', value: site?.address1 },
      { label: 'Address line 2', value: site?.address2 },
      { label: 'City', value: site?.city },
      { label: 'Country', value: site?.country },
      { label: 'State/Province', value: site?.state },
      { label: 'Postal Code', value: site?.postalCode },
    ];
  }, [site]);

  const getAclProfileInfo = () => {
    if (aclProfiles?.length > 0) {
      return aclProfiles.map(acl => {
        let aclProfile = `/aclprofile/${acl.id}`;
        return { label: <Link to={aclProfile}>{acl.name}</Link>, value: acl.description }
      });
    }
  };

  const getControllerInfo = () => {
    return [
      { label: 'Tunnel', value: site?.isServerless ? 'Disabled' : 'Enabled' },
      { label: 'Active Controller', value: site?.isServerless ? 'N/A' : activeController?.name },
      { label: 'Active Gateway', value: site?.isServerless ? 'N/A' : activeController?.Server.ip4Address ? activeController.Server.ip4Address : 'N/A' },
      { label: 'Standby Controller', value: standbyController ? standbyController.name : 'N/A' },
      { label: 'Standby Gateway', value: standbyController?.Server.ip4Address ? standbyController.Server.ip4Address : 'N/A' },
    ];
  };

  const handleTabClick = (e, index) => {
    setActiveTabIndex(index);
  };

  const editSite = React.useCallback(
    (event) => {
      dispatch(push(`/site/edit/${id}`));
    },
    [dispatch, id]
  );

  const openSpeedTestModal = async (e, testId) => {
    setIsSpeedTestModalOpen(true);

    if (testId) {
      const speedTest = await SpeedTest.getById(id, testId);
      const params = speedTest.test.params;

      setProtocol(params.protocol.toUpperCase());
      setDirection(capitalizeFirstLetter(params.direction));
      setDuration(params.duration);
      setOccurences(params.recurrence.repeat);
      // setPeriod(params.recurrence.period);
      setSpeedTestInterface(speedTest.test.device.name);
      setSpeedTestInputsDisabled(true);
      setRunningTestId(testId);
      setSpeedTestIsRunning(true);
      pollSpeedTest(testId);
    }
    else {
      initSpeedTest(true);
    }
  };

  const initSpeedTest = (setDefaults) => {
    setRunningTestId('');
    setSpeedTestInputsDisabled(false);
    setSpeedTestIsRunning(false);

    if (setDefaults) {
      setSpeedTestResultStatus('');
      setSpeedTestResults({ download: 0, upload: 0, jitter: 0, loss: 0, latency: 0 });
      setProtocol('TCP');
      setDirection('Bidirectional');
      setDuration('10');
      setOccurences('1');
      // setPeriod('');
      setSpeedTestInterface('Tunnel');
      setAdvancedExpanded('')
    }
  }

  const handleSpeedTestResultsToggle = () => {
    setIsSpeedTestResultsModalOpen(false);
  };

  const handleCancelSpeedTestConfirmToggle = () => {
    setIsCancelSpeedTestConfirmOpen(false);
  };

  const runSpeedTest = async () => {
    const payload = {
      device_name: speedTestInterface,
      protocol: protocol.toLowerCase(),
      direction: direction.toLowerCase(),
      duration: parseInt(duration),
      recurrence: {
        repeat: parseInt(occurences),
        // period: period
      }
    };

    try {
    const result = await SpeedTest.start(id, payload);
      const testId = result?.data.test_id;

    if (testId) {
      setRunningTestId(testId);
      setSpeedTestIsRunning(true);
      pollSpeedTest(testId);
      setSpeedTestResultStatus('');
      setSpeedTestResults({ download: 0, upload: 0, jitter: 0, loss: 0, latency: 0 });
    }
    else {
        setSpeedTestResultStatus('StartFailed');
        setSpeedTestIsRunning(false);
      }
    }
    catch(error) {
      console.log(error)
      setSpeedTestResultStatus('StartFailed');
      setSpeedTestIsRunning(false);
    }
  };

  const pollSpeedTest = async (testId) => {
    let poll = await SpeedTest.getById(id, testId);
    let testStatus = capitalizeFirstLetter(poll.test.status);
    await new Promise(resolve => setTimeout(resolve, 1000));

    while (testStatus === 'Pending' || testStatus === 'Running') {
      await new Promise(resolve => setTimeout(resolve, 5000)); // SPEED_TEST_POLL_FREQ
      poll = await SpeedTest.getById(id, testId);
      testStatus = capitalizeFirstLetter(poll.test.status);

      if (poll.test.samples) {
        const peakDownload = getPeak(poll.test.samples, 'rx_rate');
        const peakUpload = getPeak(poll.test.samples, 'tx_rate');
        const peakJitter = getPeak(poll.test.samples, 'jitter');
        const peakLatency = getPeak(poll.test.samples, 'latency');
        const peakLoss = getPeak(poll.test.samples, 'loss');
  
        setSpeedTestResults({
          download: peakDownload.rx_rate,
          upload: peakUpload.tx_rate,
          latency: peakLatency.latency,
          jitter: peakJitter.jitter,
          loss: peakLoss.loss
        })
      }
    }

    setSpeedTestResultStatus(testStatus);

    if (testStatus === 'Completed' || testStatus === 'Failed' || testStatus === 'Cancelled') {
      setCancelButtonText('Close');
      setCompletedSpeedTestInterface(speedTestInterface);
      initSpeedTest(false);
    }
  }

  const getPeak = (samples, prop) => {
    const last = samples.length - 1;
    return sortBy(samples, prop)[last];
  }

  const cancelSpeedTest = async () => {
    if (runningTestId !== '') {
      await SpeedTest.cancel(id, runningTestId);
    }

    setProtocol('');
    setDirection('');
    setDuration('10');
    setOccurences('1');
    // setPeriod('');
    setSpeedTestInterface('');
    setIsCancelSpeedTestConfirmOpen(false);
    setIsSpeedTestModalOpen(false);
  };

  const cancelSpeedTestConfirm = async (isOpen) => {
    if (speedTestIsRunning) {
      setIsCancelSpeedTestConfirmOpen(isOpen);
    }
    else {
      setIsSpeedTestModalOpen(false);
    }
  };

  const onSelectInterfaceToggle = (isOpen) => {
    setIsSpeedTestDropdownOpen(isOpen)
  };

  const onSelectProtocolToggle = (isOpen) => {
    setIsProtocolDropdownOpen(isOpen)
  };

  const onSelectDirectionToggle = (isOpen) => {
    setIsDirectionDropdownOpen(isOpen)
  };

  const onInterfaceSelect = (event, selection, isPlaceholder) => {
    setSpeedTestInterface(selection);
    setIsSpeedTestDropdownOpen(false);
  };

  const onProtocolSelect = (event, selection, isPlaceholder) => {
    setProtocol(selection);
    setIsProtocolDropdownOpen(false);
  };

  const onDirectionSelect = (event, selection, isPlaceholder) => {
    setDirection(selection);
    setIsDirectionDropdownOpen(false);
  };

  const handleDurationChange = (value) => {
    setDuration(value);
  };

  const handleOccurencesChange = (value) => {
    setOccurences(value);
  };

  // const handlePeriodChange = (value) => {
  //   setPeriod(value);
  // };

  const getDirectionOptions = () => {
    const dirOptions = directionOptions();
    return dirOptions.map((dir) => {
      return <SelectOption key={dir.value} id={dir.value} value={dir.value} />;
    });
  }

  const getProtocolOptions = () => {
    const protoOptions = protocolOptions();
    return protoOptions.map((proto) => {
      return <SelectOption key={proto.value} id={proto.value} value={proto.value} />;
    });
  }

  const getLinkInterfaceOptions = () => {
    const interfaces = [];
    site.Tunnels[0]?.Links?.map((link) => {
      interfaces.push(<SelectOption key={link.id} id={link.id} value={'eth' + link.interfaceName} />);
    });
    interfaces.push(<SelectOption key="tunnel" id="tunnel" value="Tunnel" />);
    return interfaces;
  };

  const getTestColumns = () => {
    return [
      { title: 'Run Date', transforms: [sortable] },
      { title: 'Download', props: { className: 'text-right' } },
      { title: 'Upload', props: { className: 'text-right' } },
      { title: 'Device', transforms: [sortable] },
      { title: 'Status', transforms: [sortable] },
      //{ title: 'LB Algorithm' },
      { title: 'Direction' },
      { title: 'Details' },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
    ];
  }

  const showResults = async (e, testId) => {
    const results = await SpeedTest.getById(id, testId);
    const params = results.test.params;

    if (results.test.samples) {
      setDownload(getPeak(results.test.samples, 'rx_rate').rx_rate);
      setUpload(getPeak(results.test.samples, 'tx_rate').tx_rate);
    }

    setProtocol(params.protocol.toUpperCase());
    setDirection(capitalizeFirstLetter(params.direction));
    setDuration(params.duration);
    setOccurences(params.recurrence.repeat);
    // setPeriod(params.recurrence.period);
    setSpeedTestInterface(results.test.device.name);
    setSamples(results.test.samples)
    setIsSpeedTestResultsModalOpen(true);
  }

  const getSampleColumns = (isResults) => {
    return [
      { title: isResults ? 'Timestamp' : 'Run Date' },
      { title: 'Latency', props: { className: 'text-right' } },
      { title: 'Jitter', props: { className: 'text-right' } },
      { title: 'Loss', props: { className: 'text-right' } },
      { title: 'Download Rate (Mbps)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'Bytes Downloaded (MB)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'Upload Rate (Mbps)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'Bytes Uploaded (MB)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
    ];
  }

  const sampleFromItem = (item, isResults) => {
    return {
      cells: [
        { title: isResults ? Date.parse(item.test_time) : formatDate(item.test_time) },
        { title: item.latency },
        { title: item.jitter },
        { title: item.loss },
        { title: getMbps(item.rx_rate) },
        { title: getMB(item.rx_bytes) },
        { title: getMbps(item.tx_rate) },
        { title: getMB(item.tx_bytes) },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
      ]
    }
  }

  const getMbps = (bitsPerSecond) => {
    if (bitsPerSecond === 0) {
      return bitsPerSecond;
    }
    const mbps = bitsPerSecond / 1000000;
    return mbps.toFixed(1);
  }

  const getMB = (bytesPerSecond) => {
    if (bytesPerSecond === 0) {
      return bytesPerSecond;
    }
    const mb = bytesPerSecond / 1000000;
    return mb.toFixed(1);
  }

  const speedTestFromItem = (item) => {
    const device = item.device?.name?.toLowerCase();
    const isTunnelTest = device?.startsWith('ubond') || device === 'tunnel';
    const testStatus = capitalizeFirstLetter(item.status);

    if (!item.samples) {
      return { 
        cells: [
          { title: sortableDate(item.test_date) },
          { title: '' },
          { title: '' },
          { title: isTunnelTest ? 'Tunnel' : device },
          { title: testStatus },
          // { title: 'N/A' },
          { title: capitalizeFirstLetter(item.params.direction) },
          { title: <div key={`results-${item.test_id}`} className='pointer link' onClick={(e) => showResults(e, item.test_id)}>Show</div>},
          { title: item.test_id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
        ] 
      };
    }

    const sample = item.samples[0];
    const last = item.samples.length - 1;
    const peakDownload = sortBy(item.samples, 'rx_rate')[last];
    const peakUpload = sortBy(item.samples, 'tx_rate')[last];

    return {
      cells: [
        { title: sortableDate(item.test_date) },
        { title: getMbps(peakDownload.rx_rate) },
        { title: getMbps(peakUpload.tx_rate) },
        { title: isTunnelTest ? 'Tunnel' : device },
        { title: testStatus === 'Running' ? <div key={`speed-test-${item.test_id}`} className='pointer link' onClick={(e) => openSpeedTestModal(e, item.test_id)}>Running</div> : testStatus },
        // { title: isTunnelTest ? sample?.algo : 'N/A' },
        { title: capitalizeFirstLetter(item.params.direction) },
        { title: <div key={`results-${item.test_id}`} className='pointer link' onClick={(e) => showResults(e, item.test_id)}>Show</div>},
        { title: item.test_id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
      ]
    }
  }

  const sortableDate = (date) => {
    if (!date) {
      return '';
    }
    return date.replaceAll('T', ' ').split('.')[0];
  }

  const onAccordionToggle = (id) => {
    if (id === advancedExpanded) {
      setAdvancedExpanded('');
    } 
    else {
      setAdvancedExpanded(id);
    }
  };

  const viewSitePerformance = React.useCallback(() => {
    window.open(
      nocLink,
      '_blank'
    );
  }, [partnerId, id]);

  const rebootGateway = React.useCallback(() => {
    dispatch(actionCreators.serverRebootRequest(server?.id));
    setConfirmOpen({ isOpen: false, rebootType: '' });
  }, [dispatch, server]);

  const rebootSite = React.useCallback(() => {
    dispatch(actionCreators.siteRebootRequest(id));
    setConfirmOpen({ isOpen: false, rebootType: '' });
  }, [dispatch, site]);

  const getLans = () => {
    return siteLans.length === 0 ? [] : siteLans?.map((lan, index) => {
      const dhcpState = lan?.isDhcpRequired ? 'Enabled' : 'Disabled';
      const wifiState = lan?.isAP ? 'Enabled' : 'Disabled';

      return (
        <Card isCompact key={`lan-${index}`}>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <td className="inner-flex-item-site">
                    <IconHeading icon={<NetworkIcon className="icon-medium" />} heading={lan.interfaceName} />
                    <InfoSection keyValuePairs={getLanInfo(lan)} showHeading={false} />
                  </td>
                  <td className="inner-flex-item-site">
                    <IconHeading
                      icon={<ClusterIcon className="icon-medium" />}
                      heading="DHCP"
                      subHeading={dhcpState}
                    />
                    <InfoSection keyValuePairs={getDhcpInfo(lan)} showHeading={false} />
                  </td>
                  <td className="inner-flex-item-site">
                    <IconHeading
                      icon={<ConnectedIcon className="icon-medium" />}
                      heading="WiFi Settings"
                      subHeading={wifiState}
                    />
                    <InfoSection keyValuePairs={getWifiInfo(lan)} showHeading={false} />
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      );
    })
  };

  const getLinks = React.useCallback(
    (isStandby) => {
      const links = site?.Tunnels?.[0]?.Links.filter((link) => link.isStandby === isStandby);
      const linkClass = isStandby ? 'standby-link' : '';
      let dataResetDate = '';

      return links?.map((link, index) => {
        if (link.dataLimitReset) {
          let dateParts = link.dataLimitReset.split('T');
          let dateStr = dateParts[0];
          let timeStr = dateParts[1].split('+')[0];
          let timeParts = timeStr.split(':');
          timeStr = timeParts[0] + ':' + timeParts[1];
          dataResetDate = dateStr + ' ' + timeStr;
        }

        const dnsAddresses = link.staticDnsIp?.split(',') || [];
        const dataLimitGb = (link.dataLimit / 1000 / 1000).toFixed(2);

        const linkData = [
          { label: 'Link State', value: link.isEnabled ? 'Enabled' : 'Disabled' },
          { label: 'Interface', value: 'eth' + link.interfaceName },
          { label: 'Address Type', value: link.isDHCP ? 'Dynamic' : 'Static' },
          // @ts-ignore
          ...(!link.isDHCP && [{ label: 'Address', value: link.staticIp }]),
          // @ts-ignore
          ...(!link.isDHCP && link?.staticGatewayIp?.length > 0 && [{ label: 'Gateway', value: link.staticGatewayIp }]),
          // @ts-ignore
          ...(!link.isDHCP && isValidIP(dnsAddresses[0]) && [{ label: 'DNS - Primary', value: dnsAddresses[0] }]),
          // @ts-ignore
          ...(!link.isDHCP && isValidIP(dnsAddresses[1]) && [{ label: 'DNS - Secondary', value: dnsAddresses[1] }]),
          { label: 'Link Type', value: link?.type },
          { label: 'Carrier', value: link.carrierName },
          {
            label: 'Data Limit Exceeded',
            value: link.dataLimit > 0 ? (link.dataLimitUsed >= link.dataLimit ? 'Yes' : 'No') : 'N/A',
          },
          { label: 'Data Limit Reset Date', value: dataResetDate },
          {
            label: 'Data Usage',
            value:
              link.dataLimit > 0 ? link.dataLimitUsed + ' KB of ' + dataLimitGb + ' GB' : link.dataLimitUsed + ' KB',
          },
        ];
        return (
          <Card className={linkClass} isCompact key={`primary-link-${index}`}>
            <CardBody>
              <table>
                <tbody>
                  <tr>
                    <td className="inner-flex-item-site">
                      <table>
                        <tbody>
                          <tr>
                            <td width="50px">{/* <OutlinedClockIcon className="icon-large" /> */}</td>
                            <td>
                              <br />
                              Configured Throughput
                              <br />
                              <table className="link-speed-table">
                                <tbody>
                                  <tr>
                                    <td width="110px">
                                      <strong>{link.downloadThroughput} Mbps</strong>
                                      <br />
                                      <ArrowCircleDownIcon className="icon-small" /> Download
                                    </td>
                                    <td>
                                      <strong>{link.uploadThroughput} Mbps</strong>
                                      <br />
                                      <ArrowCircleUpIcon className="icon-small" /> Upload
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="inner-flex-item-site">
                      <InfoSection keyValuePairs={linkData} showHeading={false} />
                    </td>
                    <td className="inner-flex-item-site ">
                      <div className="textarea-input">
                        <strong>Notes:</strong>
                        <br />
                        <hr />
                        {link.notes}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        );
      });
    },
    [site]
  );

  if (isLoading) {
    return <Spinner data-testid="spinner" size="lg" />;
  }

  if (isFailed) {
    return <ErrorView title="Failed to load Site" message="🤖 Failed to load Site" />;
  }

  if (isSuccess && !site) {
    return <NotFound />;
  }

  const aclProfileInfo = getAclProfileInfo();
  const controllerInfo = getControllerInfo();
  const linkInterfaces = getLinkInterfaceOptions();
  const protocols = getProtocolOptions();
  const directions = getDirectionOptions();

  const durationIsValid = isValidNumber(duration);
  const durationErrorState = durationIsValid ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Must be a positive number' };
  const occurencesIsValid = isValidNumber(occurences);
  const occurencesErrorState = occurencesIsValid ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Must be a positive number' };
  // const periodErrorState = period !== '' ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Must be a valid CRON string' };
  const interfaceErrorState = speedTestInterface !== '' ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: '' };
  const protocolErrorState = protocol !== '' ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: '' };
  const directionErrorState = direction !== '' ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: '' };

  const speedTestParamsInvalid = durationErrorState.validated === 'error' || occurencesErrorState.validated === 'error' || 
    // periodErrorState.validated === 'error' || 
    interfaceErrorState.validated === 'error' || protocolErrorState.validated === 'error' ||
    directionErrorState.validated === 'error'; 

  // const ospfConfig = [];
  const bgpConfig = [];
  // const ospf = routingConfig?.ospf_configuration;
  const bgp = routingConfig?.bgp_configuration;

  // if (ospf) {
  //   for (let i=0; i<ospf.length; i++) {
  //     ospfConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{ospf[i]}<br /></div>);
  //   }
  // }
  if (bgp) {
    for (let i=0; i<bgp.length; i++) {
      bgpConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{bgp[i]}<br /></div>);
    }
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{site?.address1}</title>
      </Helmet>
      <PageSection>
        <Flex direction={{ default: 'column' }} style={{ display: 'block' }}>
          <FlexItem>
            <Card isCompact>
              <CardBody>
                <table>
                  <tbody>
                    <tr>
                      <td width="8%" className="site-header">
                        <img src={SiteIcon} width="60%" />
                      </td>
                      <td width="66%" className="site-header site-desc">
                        {isLoading ? (
                          <Spinner size="lg" />
                        ) : (
                          <div>
                            <Title headingLevel="h1" size="xl">
                              {site?.address1}
                            </Title>
                            <br />
                            <div>
                              <strong>Site ID: </strong>&nbsp;{id}<br />
                              <strong>Client Company: </strong>&nbsp;{site?.ClientCompany?.name}<br />
                              <strong>Client Company ID: </strong>&nbsp;{site?.ClientCompany?.id}<br />
                              <strong>License ID: </strong>&nbsp;{licenseLoading ? <Spinner size="sm" /> : licenseId}
                            </div>
                            <br />
                            <ToggleGroup aria-label="Site options">
                              <ToggleGroupItem
                                icon={<PencilAltIcon />}
                                text="Edit"
                                aria-label="Edit Site"
                                buttonId="toolbar-edit"
                                onClick={editSite}
                              />
                              <ToggleGroupItem
                                icon={<SyncAltIcon />}
                                text="Reboot Site"
                                aria-label="Reboot Site"
                                buttonId="toolbar-reboot-site"
                                onClick={(e) => setConfirmOpen({ rebootType: 'Site', isOpen: true })}
                              />
                              {
                                (site?.isServerless || wan) ? <React.Fragment /> :
                                  <ToggleGroupItem
                                    data-testid='reboot-gateway-button'
                                    icon={<SyncAltIcon />}
                                    text="Reboot Gateway"
                                    aria-label="Reboot Gateway"
                                    buttonId="toolbar-reboot-gateway"
                                    onClick={(e) => setConfirmOpen({ rebootType: 'Tunnel Gateway', isOpen: true })}
                                  />
                              }
                              <ToggleGroupItem
                                icon={<ExternalLinkAltIcon />}
                                text="Performance"
                                aria-label="View Site Performance"
                                buttonId="toolbar-performance"
                                onClick={viewSitePerformance}
                              />
                              <ToggleGroupItem
                                icon={<OutlinedClockIcon />}
                                text="Speed Test"
                                aria-label="Speed Test"
                                buttonId="toolbar-speed-test"
                                onClick={openSpeedTestModal}
                              />
                            </ToggleGroup>
                            <br />
                          </div>
                        )}
                      </td>
                      <td width="26%" className="site-header site-info">
                        {isLoading ? (
                          <Spinner size="lg" />
                        ) : (
                          <div>
                            <IconHeading
                              icon={<img src={WanIcon} width="24px" height="24px" alt="Wide Area Networking" />}
                              heading="Wide Area Network"
                              subHeading={wanState}
                            />
                            <InfoSection keyValuePairs={getWanInfo()} showHeading={false} />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </FlexItem>

          <Title headingLevel="h3" size="md">
            LAN Interfaces
          </Title>
          {isLoading ? (
            <Spinner size="lg" />
          ) : (
            <React.Fragment>
              {(getLans()?.length ?? 0) > 0 ? (
                getLans()
              ) : (
                <div>
                  There are no LANs configured
                  <br />
                </div>
              )}
            </React.Fragment>
          )}

          <Title headingLevel="h3" size="md">
            Routing
          </Title>
          <Flex>
            <FlexItem className="flex-item-site">
              <Card isCompact className="routing-settings-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading
                        icon={<TopologyIcon className="icon-medium" />}
                        heading="Routed IP Blocks"
                        subHeading={routedIpState}
                      />
                      {(getRoutedIpInfo()?.length ?? 0) > 0 ? (
                        <div>
                          <Spacer />
                          <div className="right">
                            <strong>Router IP</strong>
                          </div>
                          <div>
                            <strong>Public Subnet</strong>
                          </div>
                          <Spacer />
                          <InfoSection keyValuePairs={getRoutedIpInfo()} showHeading={false} />
                        </div>
                      ) : (
                        <div>No routes defined</div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>

            <FlexItem className="flex-item-two-thirds">
              <Card isCompact className="routing-settings-row">
                <CardBody>
                  <IconHeading icon={<TopologyIcon className="icon-medium" />} heading="Advanced" />

                  {/* {!routingConfig.bgp_configuration ? (
                    <div>BGP Routing disabled</div>
                  ) : ( */}
                  <Tabs activeKey={activeTabIndex} onSelect={handleTabClick} isBox>
                    {/* <Tab
                      eventKey={TabIndex.OSPF}
                      title={
                        <React.Fragment>
                          <TabTitleIcon>
                            <PficonNetworkRangeIcon className="icon-medium" />
                          </TabTitleIcon>{' '}
                          <TabTitleText>{TitleMap[TabIndex.OSPF]}</TabTitleText>
                        </React.Fragment>
                      }
                    >
                      {site?.routingConfig?.ospf_enabled ?
                        <div className="code-config"><br />{ospfConfig}</div>
                      : <div><br />OSPF disabled</div> }
                    </Tab> */}
                    <Tab
                      eventKey={TabIndex.BGP}
                      title={
                        <React.Fragment>
                          <TabTitleIcon>
                            <PficonNetworkRangeIcon className="icon-medium" />
                          </TabTitleIcon>{' '}
                          <TabTitleText>{TitleMap[TabIndex.BGP]}</TabTitleText>
                        </React.Fragment>
                      }
                    >
                      {routingConfig.bgp_configuration ?
                        <div className="code-config"><br />{bgpConfig}</div>
                      : <div><br />BGP disabled</div>}
                    </Tab>
                  </Tabs>
                  {/* )}; */}
                </CardBody>
              </Card>
            </FlexItem>
          </Flex>

          <Title headingLevel="h3" size="md">
            Tunnel Settings
          </Title>

          <Flex>
            <FlexItem className="flex-item-site">
              <Card isCompact className="tunnel-settings-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<InfrastructureIcon className="icon-medium" />} heading="Controllers" />
                      <InfoSection keyValuePairs={controllerInfo} showHeading={false} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>

            <FlexItem className="flex-item-site">
              <Card isCompact className="tunnel-settings-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<DomainIcon className="icon-medium" />} heading="Tunnel Settings" />
                      <InfoSection keyValuePairs={getTunnelInfo()} showHeading={false} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>

            <FlexItem className="flex-item-site">
              <Card isCompact className="tunnel-settings-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<WrenchIcon className="icon-medium" />} heading="Expected Throughput" />
                      {(getLinks(false)?.length ?? 0) > 0 || (getLinks(true)?.length ?? 0) > 0 ? (
                        throughputFromLinks(site?.Tunnels[0].Links)
                      ) : (
                        <div>There are no active links configured!</div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>
          </Flex>

          <Title headingLevel="h3" size="md">
            Security Settings
          </Title>

          <Flex>
            <FlexItem className="acl-profile-card">
              <Card isCompact className="lan-settings-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading
                        icon={<ServiceCatalogIcon className="icon-medium" />}
                        heading="ACL Profiles"
                        subHeading={aclProfileInfo?.length > 0 ? 'Enabled' : 'Disabled'}
                      />
                      {(aclProfileInfo?.length > 0) ? (
                        <div>
                          <InfoSection keyValuePairs={aclProfileInfo} showHeading={false} />
                        </div>
                      ) : (
                        <div>No ACL Profiles assigned!</div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>
          </Flex>

          <Title headingLevel="h3" size="md">
            Primary Links
          </Title>
          {(getLinks(false)?.length ?? 0) > 0 ? (
            getLinks(false)
          ) : (
            <div>
              There are no Primary links configured
              <br />
            </div>
          )}

          <Title headingLevel="h3" size="md">
            Standby Links
          </Title>
          {(getLinks(true)?.length ?? 0) > 0 ? (
            getLinks(true)
          ) : (
            <div>
              There are no Standby links configured
              <br />
              <br />
            </div>
          )}

          <Flex direction={{ default: 'column' }}>
            <FlexItem>
              <Card isCompact>
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<AutomationIcon className="icon-medium" />} heading="Business Continuity" />
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>A last-resort pass-through interface if tunnel fails</td>
                            <td className="site-business-continuity right">
                              <InfoSection keyValuePairs={getBusinessContinuityInfo()} showHeading={false} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>
            <FlexItem>
              <Card isCompact>
                <CardBody>
                  <div>
                    <IconHeading icon={<OutlinedClockIcon className="icon-medium" />} heading="Speed Tests" />

                    {isSpeedTestsLoading ? <Spinner size="md" /> : speedTests.length === 0 ? <div>No Speed Tests found!</div> :
                      <div>
                        <PatternflyTable
                          columns={getTestColumns}
                          perPage={6}
                          data={speedTests.map(speedTestFromItem)}
                          page={1}
                        />
                      </div>
                    } 
                  </div>
                </CardBody>
              </Card>
            </FlexItem>
          </Flex>

          <Title headingLevel="h3" size="md">
            Contact Information
          </Title>

          <Flex>
            <FlexItem spacer={{ default: 'spacerMd' }} className="flex-item-site">
              <Card isCompact className="company-contact-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<EnterpriseIcon className="icon-medium" />} heading="Company Profile" />
                      <InfoSection keyValuePairs={getCompanyInfo()} showHeading={false} />
                      <br />
                      <IconHeading icon={<CatalogIcon className="icon-medium" />} heading="Company Notes" />
                      {getQuickLinks()}
                      <br />
                      {site?.ClientCompany?.notes && site?.ClientCompany.notes.length > 0 ? (
                        <div className="textarea-input">
                          <strong>Notes:</strong> <br />
                          {site?.ClientCompany.notes}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>

            <FlexItem className="flex-item-site">
              <Card isCompact className="company-contact-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<MapMarkerIcon className="icon-medium" />} heading="Site Location" />
                      <InfoSection keyValuePairs={getLocationInfo()} showHeading={false} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>

            <FlexItem className="flex-item-site">
              <Card isCompact className="company-contact-row">
                <CardBody>
                  {isLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <IconHeading icon={<UserIcon className="icon-medium" />} heading="Contact Information" />
                      <InfoSection keyValuePairs={getContactInfo()} showHeading={false} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </FlexItem>
          </Flex>
        </Flex>

        <Modal
          title="Confirm Reboot"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={confirmOpen.isOpen}
          onClose={() => setConfirmOpen({ isOpen: false, rebootType: '' })}
          actions={[
            <Button
              key="confirm"
              variant="primary"
              onClick={confirmOpen.rebootType === 'Site' ? rebootSite : rebootGateway}
            >
              Yes
            </Button>,
            <Button key="cancel" variant="link" onClick={() => setConfirmOpen({ isOpen: false, rebootType: '' })}>
              Cancel
            </Button>,
          ]}
        >
          Are you sure you want to reboot this {confirmOpen.rebootType}?
        </Modal>

        <Modal
          title="Run Speed Test"
          titleIconVariant={OutlinedClockIcon}
          variant={ModalVariant.medium}
          isOpen={isSpeedTestModalOpen}
          onClose={cancelSpeedTestConfirm}
          actions={[
            <Button key="confirm" variant="primary" isDisabled={speedTestParamsInvalid || speedTestIsRunning} onClick={runSpeedTest}>Start</Button>,
            <Button key="cancel" variant="link" onClick={cancelSpeedTestConfirm}>{cancelButtonText}</Button>
          ]}
        >
          <p>Start a speed test with the default settings, or expand below for advanced options</p><br />

          <Accordion asDefinitionList>
            <AccordionItem>
              <AccordionToggle
                onClick={() => { onAccordionToggle('advanced-options') }}
                isExpanded={advancedExpanded === 'advanced-options'}
                id="advanced-options"
              >
                Advanced options
              </AccordionToggle>
              <AccordionContent id="advanced-expand" isHidden={advancedExpanded !== 'advanced-options'}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <FormGroup
                          label="Number of Runs" isRequired 
                          helperTextInvalid=" Required Field" 
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          validated={occurencesErrorState.validated}
                        >
                          <TextInput
                            type="text" id="occurences" name="occurences"
                            validated={occurencesErrorState.validated}
                            value={occurences}
                            onChange={handleOccurencesChange}
                          />
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup
                          label="Duration(sec)" isRequired 
                          helperTextInvalid=" Required Field" 
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          validated={durationErrorState.validated}
                        >
                          <TextInput
                            type="text" id="duration" name="duration"
                            validated={durationErrorState.validated}
                            value={duration}
                            onChange={handleDurationChange}
                          />
                        </FormGroup>
                      </td>
                      <td>
                        {/* <FormGroup
                          label="Period(CRON)" isRequired 
                          helperTextInvalid=" Required Field" 
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          // validated={periodErrorState.validated}
                        >
                          <TextInput
                            type="text" id="period" name="period"
                            // validated={periodErrorState.validated}
                            placeholder="* * * * *"
                            value={period}
                            onChange={handlePeriodChange}
                          />
                        </FormGroup> */}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormGroup
                          label="Interface" isRequired
                          helperTextInvalid=""
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          validated={interfaceErrorState.validated}
                        >
                          <Select
                            className="speed-test-select"
                            maxHeight={300}
                            variant={SelectVariant.single}
                            validated={interfaceErrorState.validated}
                            onToggle={onSelectInterfaceToggle}
                            onSelect={onInterfaceSelect} placeholderText="Select ..."
                            isOpen={isSpeedTestDropdownOpen}
                            isDisabled={speedTestInputsDisabled}
                            selections={speedTestInterface}
                          >
                            {linkInterfaces}
                          </Select>
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup
                          label="Protocol" isRequired
                          helperTextInvalid=""
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          validated={protocolErrorState.validated}
                        >
                          <Select
                            className="speed-test-select"
                            maxHeight={300}
                            variant={SelectVariant.single}
                            validated={protocolErrorState.validated}
                            onToggle={onSelectProtocolToggle}
                            onSelect={onProtocolSelect} placeholderText="Select ..."
                            isOpen={isProtocolDropdownOpen}
                            selections={protocol}
                          >
                            {protocols}
                          </Select>
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup
                          label="Direction" isRequired
                          helperTextInvalid=""
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          validated={directionErrorState.validated}
                        >
                          <Select
                            className="speed-test-select"
                            maxHeight={300}
                            variant={SelectVariant.single}
                            validated={directionErrorState.validated}
                            onToggle={onSelectDirectionToggle}
                            onSelect={onDirectionSelect} placeholderText="Select ..."
                            isOpen={isDirectionDropdownOpen}
                            selections={direction}
                          >
                            {directions}
                          </Select>
                        </FormGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          {speedTestIsRunning ? 
            <div><br /><Spinner size='md' /> &nbsp;Speed Test is running. Please wait ...<br /></div>
          : <></>}

          {speedTestResultStatus === 'Completed' ? 
            <div><br />
              Speed Test on <strong>{completedSpeedTestInterface}</strong> completed successfully!<br /><br />
            </div>
          : <></> }

          {speedTestResultStatus === 'Cancelled' ? 
            <div><br />Speed Test cancelled successfully</div>
          : <></> }

          {speedTestResultStatus === 'Failed' ? 
            <div><br />Speed Test failed to complete</div>
          : <></> }

          {speedTestResultStatus === 'StartFailed' ? 
            <div><br />The Speed Test failed to start due to an error, or there may already be one scheduled or running. Please try again later</div>
          : <></> }

          {(speedTestResults?.download > 0 || speedTestResults?.upload > 0) ?
            <div align="center">
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div align="center">
                        <strong>Download</strong><br />
                        <span className="peak">{getMbps(speedTestResults.download)} Mbps</span>
                      </div>
                    </td>
                    <td width="50"></td>
                    <td>
                      <div align="center">
                        <strong>Upload</strong><br />
                        <span className="peak">{getMbps(speedTestResults.upload)} Mbps</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <Spacer />
              <hr className="hr-results" />
              <Spacer />

              <table>
                <tbody>
                  <tr>
                    <td width="175"><strong>Latency:</strong> {speedTestResults.latency} ms</td>
                    <td width="175"><strong>Jitter:</strong> {speedTestResults.jitter} ms</td>
                    <td width="175"><strong>Loss:</strong> {speedTestResults.loss}%</td>
                  </tr>  
                </tbody>  
              </table>
            </div>
          : <></> }

          <br />
        </Modal>

        <Modal
          title="Speed Test Results"
          titleIconVariant={OutlinedClockIcon}
          variant={ModalVariant.large}
          isOpen={isSpeedTestResultsModalOpen}
          onClose={handleSpeedTestResultsToggle}
          actions={[
            <Button key="cancel" variant="link" onClick={handleSpeedTestResultsToggle}>Close</Button>
          ]}
        >
          <table>
            <tbody>
              <tr>
                <td width="150"><strong>Peak Download:</strong></td>
                <td width="110">{getMbps(download)} Mbps</td>
                <td width="90"><strong>Device:</strong></td>
                <td width="100">{speedTestInterface}</td>
                <td width="100"><strong>Duration:</strong></td>
                <td width="80">{duration}</td>
              </tr>
              <tr>
                <td width="120"><strong>Peak Upload:</strong></td>
                <td width="110">{getMbps(upload)} Mbps</td>
                <td width="90"><strong>Protocol:</strong></td>
                <td width="100">{protocol}</td>
                <td width="90"><strong>Direction:</strong></td>
                <td width="145">{direction}</td>
              </tr>
              <tr>
                <td width="145"><strong>Number of Runs:</strong></td>
                <td width="80">{occurences}</td>
              </tr>
            </tbody>
          </table>
          <br />

          {samples?.length ? 
            <div>
              <strong>Samples:</strong>

              <PatternflyTable
                columns={() => getSampleColumns(true)}
                data={samples.map((sample) => sampleFromItem(sample, true))}
                page={1}
              />
            </div>
          : 
            <div>No samples to display!</div>
          }
        </Modal>

        <Modal
          title="Confirm Action"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isCancelSpeedTestConfirmOpen}
          onClose={handleCancelSpeedTestConfirmToggle}
          actions={[
            <Button key="confirm" variant="primary" onClick={cancelSpeedTest}>Yes</Button>,
            <Button key="cancel" variant="link" onClick={handleCancelSpeedTestConfirmToggle}>Cancel</Button>
          ]}
        >
          <p>Are you sure you want to cancel this speed test?</p>
        </Modal>
      </PageSection>
    </React.Fragment>
  );
};
