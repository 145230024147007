import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { sortable } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { useControllerContext } from '../../contexts/controller.context';
import { selectControllerById, wanSelectors } from '../../common/configuration/configuration.selectors';
import setControllerServers from './ControllerHelper';
import ControllerApi from '@app/api/controller-api/controller.actions';
import Site from '@app/api/site/site.actions';
import Wan from '@app/api/wan/wan.actions';
import GatewayApi from '@app/api/gateway-api/gateway.actions';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import { Card, CardBody, PageSection, Spinner, Title, ToggleGroup, ToggleGroupItem, Tabs, Tab, TabTitleIcon, TabTitleText } from '@patternfly/react-core';
import ControllerIcon from '@app/bgimages/controllers-black.svg';
import SiteIcon from '@app/bgimages/sites-black.svg';
import { IconHeading } from '@app/components/IconHeading';
import { InfoSection } from '@app/components/InfoSection';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { PencilAltIcon, RegionsIcon, TopologyIcon, PficonNetworkRangeIcon } from '@patternfly/react-icons';
import { push } from 'connected-react-router';
import { NotFound } from '../not-found/not-found';
import { ErrorView } from '../error/error';
import '../../app.css';

enum TabIndex {
  OSPF,
  BGP
}

export const ControllerView: React.FC = () => {
  const dispatch = useDispatch();
  const { id, isLoading, isSuccess, isFailed } = useControllerContext();
  const [activeTabIndex, setActiveTabIndex] = React.useState(TabIndex.OSPF);
  const [controller, setController] = React.useState({});
  const [sites, setSites] = React.useState([]);
  const [assigned, setAssigned] = React.useState([]);
  const [available, setAvailable] = React.useState([]);
  const [lxdBridgeIp, setLxdBridgeIp] = React.useState('N/A');
  const [netmask, setNetmask] = React.useState('N/A');
  const wanById = useSelector(wanSelectors.selectEntities);
  const TitleMap = {
    [TabIndex.OSPF]: 'OSPF',
    [TabIndex.BGP]: 'BGP'
  };

  React.useEffect(() => {
    (async function() {
      loadController();
      (document.getElementById("primary-app-container") as HTMLDivElement).scroll(0, 0);
    })();
  }, []);

  const loadController = async () => {
    const controller = await ControllerApi.get(id);
    const gateways = await GatewayApi.getAll();
    const sites = await Site.getAll();
    const wans = await Wan.getAll();
    const tunnels = await Tunnel.getAll();

    if (controller === undefined) {
      return history.push("/notfound");
    }

    controller.Servers = gateways.filter(gateway => gateway.controller_id === id);

    for (let i=0; i<controller.Servers.length; i++) {
      controller.Servers[i] = { 
        ...controller.Servers[i],
        Tunnels: tunnels.filter(tunnel => tunnel.serverId === controller.Servers[i].id) 
      };
    }

    const controllerServers = setControllerServers(controller, sites, wans);
    const lxdBridgeIpAddr = controllerServers.assigned?.[0]?.ip_4_router ?? 'N/A';
    const controllerNetmask = controllerServers.assigned?.[0]?.ip_4_cidr ?? 'N/A';

    setLxdBridgeIp(lxdBridgeIpAddr);
    setNetmask(controllerNetmask);
    setSites(controllerServers.sites);
    setAssigned(controllerServers.assigned);
    setAvailable(controllerServers.available);
    setController({
      name: controller.name,
      description: controller.description,
      country: controller.country, 
      region: controller.region, 
      timezone: controller.timezone,
      ip_address: controller.ip_address,
      routingEnabled: controller.dynamic_routing_enabled,
      routingConfig: controller.routing_configuration
    });
  }

  const handleTabClick = (e, index) => {
    setActiveTabIndex(index);
  };

  const getSiteColumns = () => {
    return [
      { title: 'Address', transforms: [sortable] },
      { title: 'WAN' },
      { title: 'Location' },
      { title: 'Company' },
      { title: 'Tunnel Gateway', transforms: [sortable] },
    ];
  };

  const getServerColumns = () => {
    return [{ title: 'IPv4 Address', transforms: [sortable] }, { title: 'ID' }];
  };

  const siteFromItem = React.useCallback((item) => {
    const wan = wanById[item.wanId];
    const wanLink = item.wanId ? <Link to={{ pathname: `/wan/${item.wanId}` }}>{wan?.name}</Link> : '';
    return {
      cells: [
        { title: <Link to={{ pathname: `/site/${item.id}` }}>{item.address1}</Link> },
        { title: wanLink },
        item.city,
        item.ClientCompany.name,
        item.server.ip_4_address,
      ],
    };
  }, []);

  const serverFromItem = (item) => {
    return {
      cells: [item.ip_4_address + item.ip_4_cidr, item.id],
    };
  };

  const getLocationInfo = React.useCallback(() => {
    return [
      { label: 'Country', value: controller?.country },
      { label: 'Region', value: controller?.region },
      { label: 'Timezone', value: controller?.timezone },
    ];
  }, [controller]);

  const getControllerInfo = React.useCallback(() => {
    const controllerIp = controller && controller.ip_address && controller.ip_address !== '' ? controller?.ip_address : 'N/A';
    
    return [
      { label: 'IP Address', value: controllerIp },
      // { label: "Controller Type", value: "Bridged/Routed" },
      { label: 'LXD Bridge IP', value: lxdBridgeIp },
      { label: 'Netmask', value: netmask },
    ];
  }, [controller, lxdBridgeIp]);

  const editController = React.useCallback(() => {
    dispatch(push(`/controller/edit/${id}`));
  }, [dispatch, id]);

  if (isLoading) {
    return <Spinner data-testid='spinner' size="lg" />;
  }

  if (isFailed) {
    return <ErrorView title="Failed to load Controller" message="🤖 Failed to load Controller" />;
  }

  if (isSuccess && !controller) {
    return <NotFound />
  }

  const ospfConfig = [];
  const bgpConfig = [];
  const ospf = controller?.routingConfig?.ospf_configuration;
  const bgp = controller?.routingConfig?.bgp_configuration;

  if (ospf) {
    for (let i=0; i<ospf.length; i++) {
      ospfConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{ospf[i]}<br /></div>);
    }
  }
  if (bgp) {
    for (let i=0; i<bgp.length; i++) {
      bgpConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{bgp[i]}<br /></div>);
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{controller?.name}</title>
      </Helmet>
      <PageSection>
        <Card isCompact>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <td width="15%" className="controller-header">
                    <img src={ControllerIcon} width="60%" alt={controller?.name as string} />
                  </td>
                  <td width="50%" className="controller-header controller-desc">
                    <div>
                      <Title headingLevel="h1" size="xl">
                        {controller?.name}
                      </Title>
                      <br />
                      <p>
                        <strong>Controller ID: </strong>{id}<br /><br />
                      </p>
                      <div className="textarea-input">
                        <p>{controller?.description}</p>
                        <br />
                      </div>
                      <InfoSection keyValuePairs={getControllerInfo()} showHeading={false} />
                      <br />

                      <ToggleGroup aria-label="Site options">
                        <ToggleGroupItem
                          icon={<PencilAltIcon />}
                          text="Edit"
                          aria-label="Edit Controller"
                          buttonId="toolbar-edit"
                          onClick={editController}
                        />
                      </ToggleGroup>
                    </div>
                  </td>
                  <td width="5%" className="controller-header controller-desc"></td>
                  <td width="30%" className="controller-header controller-info">
                    <InfoSection heading="Location" icon={<RegionsIcon />} keyValuePairs={getLocationInfo()} />
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>

        <br />

        <IconHeading icon={<img src={SiteIcon} width="24px" height="24px" alt="Site List" />} heading="Site List" />
        <Card isCompact>
          <CardBody>
            {sites.length === 0 ? (
              <div>There are no Sites configured</div>
            ) : (
              <div>
                <PatternflyTable columns={getSiteColumns} data={sites.map(siteFromItem)} page={1} />
              </div>
            )}
          </CardBody>
        </Card>

        <br />

        <IconHeading
          icon={<img src={SiteIcon} width="24px" height="24px" alt="Assigned Tunnel Gateways" />}
          heading="Assigned Tunnel Gateways"
        />
        <Card isCompact>
          <CardBody>
            {assigned.length === 0 ? (
              <div>There are no Assigned Tunnel Gateways</div>
            ) : (
              <div>
                <PatternflyTable columns={getServerColumns} data={assigned.map(serverFromItem)} page={1} />
              </div>
            )}
          </CardBody>
        </Card>

        <br />

        <IconHeading
          icon={<img src={SiteIcon} width="24px" height="24px" alt="Unused Tunnel Gateways" />}
          heading="Unused Tunnel Gateways"
        />
        <Card isCompact>
          <CardBody>
            {available.length === 0 ? (
              <div>There are no Unused Tunnel Gateways</div>
            ) : (
              <div>
                <PatternflyTable columns={getServerColumns} data={available.map(serverFromItem)} page={1} />
              </div>
            )}
          </CardBody>
        </Card>

        <br />

        <IconHeading icon={<TopologyIcon />} heading="Dynamic Routing" />
        <Card isCompact>
          <CardBody>
            {!controller?.routingEnabled ? (
              <div>Dynamic Routing disabled</div>
            ) : (
              <div>
                <Tabs activeKey={activeTabIndex} onSelect={handleTabClick} isBox>
                  <Tab
                    eventKey={TabIndex.OSPF}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <PficonNetworkRangeIcon className="icon-medium" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.OSPF]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    {controller?.routingConfig?.ospf_enabled ?
                      <div className="code-config"><br />{ospfConfig}</div>
                    : <div><br />OSPF disabled</div> }
                  </Tab>
                  <Tab
                    eventKey={TabIndex.BGP}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <PficonNetworkRangeIcon className="icon-medium" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.BGP]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    {controller?.routingConfig?.bgp_enabled ?
                      <div className="code-config"><br />{bgpConfig}</div>
                    : <div><br />BGP disabled</div>}
                  </Tab>
                </Tabs>
              </div>
            )}
          </CardBody>
        </Card>

      </PageSection>
    </React.Fragment>
  );
};
