import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, DefaultOptions } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from '@app/store';
import UserSession from '@app/common/user-session';

const cache = new InMemoryCache({
  addTypename: false,
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
});

// const errorLink = onError(({ graphQLErrors, operation, forward }) => {
//   if (graphQLErrors) {
//     for (let i = 0; i < graphQLErrors.length; i += 1) {
//       const graphQLError = graphQLErrors[i];
//       const refreshToken = store.getState().common.auth.session?.refreshToken;

//       if (graphQLError.extensions?.code === 'invalid-jwt' && refreshToken) {
//         return fromPromise(
//           cognito
//             .refreshSession(refreshToken)
//             .then((session) => {
//               store.dispatch(actionCreators.tokenRefreshSuccess(session));
//               const headers = {
//                 ...operation.getContext().headers,
//                 authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
//               };

//               operation.setContext({
//                 headers,
//               });

//               return forward(operation);
//             })
//             .catch((error) => {
//               store.dispatch(actionCreators.tokenRefreshFailure(error));
//               return error;
//             })
//         );
//       }

//       return fromPromise(Promise.resolve(() => { throw graphQLError }))

//     }
//   }

//   return forward(operation);
// });

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const authLink = setContext((_, { headers }) => {
  const token = UserSession.getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions,
  link: ApolloLink.from([authLink, httpLink]),
});