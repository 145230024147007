import gql from "graphql-tag";

export const ALL_GATEWAYS = gql`
  query AllGateways($partnerId: uuid) {
    Gateway(where: { partnerId: { _eq: $partnerId } }) {
      id
      name
      description
      region
      country
      timezone
      state
      isVps
      vpsProvider
      Servers {
        id
        ip4Address
        ip4Cidr
        ip4Router
        name
        osVersion
        gatewayId
        PublicIps {
          id
          address
          ip4Cidr
          isV6
          serverId
        }
        Tunnels {
          id
        }
      }
    }
  }
`;

export const ALL_GATEWAY_IDS_FOR_PARTNER = gql`
  query AllGatewayIdsForPartner($partnerId: uuid) {
    Gateway(where: { partnerId: { _eq: $partnerId } }) {
      id
    }
  }
`;

export const CREATE_GATEWAY = gql`
  mutation CreateGateway($input: [Gateway_insert_input!]!) {
    insert_Gateway(objects: $input) {
      returning {
        id
        name
        description
        region
        country
        timezone
        state
        isVps
        vpsProvider
        Servers {
          id
          ip4Address
          ip4Cidr
          ip4Router
          name
          osVersion
          gatewayId
          PublicIps {
            id
            address
            ip4Cidr
            isV6
            serverId
          }
          Tunnels {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_GATEWAY = gql`
  mutation UpdateGateway($id: uuid, $changes: Gateway_set_input) {
    update_Gateway(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

// I'm just using this when making updates
export const GET_GATEWAY = gql`
  query GGW($id: uuid) {
    Gateway(where: { id: { _eq: $id } }) {
      Servers {
        id
        ip4Address
        ip4Cidr
        ip4Router
        PublicIps {
          id
        }
      }
    }
  }
`;
export const DELETE_GATEWAY = gql`
  mutation DeleteGateway($id: uuid) {
    delete_Gateway(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;