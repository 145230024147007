import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { actionCreators } from '../common/configuration/configuration.reducer';
import { State } from '../store/root-reducer';
import { isUUID } from '../lib/validator';

const selectSiteMatch = createMatchSelector<State, Match>({
  path: '/site/:mode/:id?',
});

export const useSiteContext = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectSiteMatch);

  const mode = match?.params.mode;

  const id = isUUID(mode) ? mode : match?.params?.id;

  React.useEffect(() => {
    dispatch(actionCreators.siteRequest());
    dispatch(actionCreators.clientCompanyRequest());
    dispatch(actionCreators.serverRequest());
    dispatch(actionCreators.controllerRequest());
    dispatch(actionCreators.wanRequest());
    dispatch(actionCreators.linkTypeRequest());
    dispatch(actionCreators.carrierRequest());
  }, [dispatch]);

  const sites = useSelector((state: State) => state.configuration.sites);
  const controllers = useSelector((state: State) => state.configuration.controllers);
  const clientCompanies = useSelector((state: State) => state.configuration.clientCompany);
  const servers = useSelector((state: State) => state.configuration.servers);
  const wans = useSelector((state: State) => state.configuration.wans);
  const linkTypes = useSelector((state: State) => state.configuration.linkTypes);
  const carriers = useSelector((state: State) => state.configuration.carriers);

  const isLoading =
    sites.isFetching ||
    clientCompanies.isFetching ||
    controllers.isFetching ||
    servers.isFetching ||
    wans.isFetching ||
    linkTypes.isFetching ||
    carriers.isFetching;

  const isFailed =
    sites.isFailed ||
    clientCompanies.isFailed ||
    controllers.isFailed ||
    servers.isFailed ||
    wans.isFailed ||
    linkTypes.isFailed ||
    carriers.isFailed;

  const isSuccess =
    sites.isSuccess &&
    clientCompanies.isSuccess &&
    controllers.isSuccess &&
    servers.isSuccess &&
    wans.isSuccess &&
    linkTypes.isSuccess &&
    carriers.isSuccess;

  return { id, mode, isLoading, isFailed, isSuccess };
};
