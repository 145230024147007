import * as React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import { withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#EA3753',
  },
}))(LinearProgress);

export const SplashScreen = () => (
  <div style={{ flex: 1, backgroundColor: '#000', height: '100vh' }}>
    <Container style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Box style={{ justifyContent: 'center' }}>
          <img src={EthicaLogo} style={{ width: '100%' }} />
          <BorderLinearProgress variant="indeterminate" />
      </Box>
    </Container>
  </div>
);

export const withSplashScreen = (WrappedComponent) => (props) => {
  // const applicationInitialized = useSelector(selectAppInit);
  // const userAuthenticated = useSelector((state: any) => state.common.auth.session?.accessToken !== undefined)
  // if (userAuthenticated && !applicationInitialized) return <SplashScreen />;
  return <WrappedComponent {...props} />;
};