import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import '@patternfly/react-core/dist/styles/base.css';
import Login from '@app/pages/auth/Login';
import ForgotPassword from '@app/pages/auth/ForgotPassword';
import NewPasswordChallenge from '@app/pages/auth/NewPasswordChallenge';
import { AppLayout } from '@app/components/layout/layout.component';
import { AppRoutes } from '@app/routes';
import CssBaseline from '@mui/material/CssBaseline';

import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';

import { Html } from './components/layout/html.component';
import { SearchProvider } from './contexts/search.context';

import { history, persistor, store } from './store';

import '@app/app.css';

const App: React.FunctionComponent = () => (
  <React.Fragment>
    <CssBaseline />
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <ConnectedRouter history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <SnackbarProvider dense anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Html>
                  <Switch>
                    <Route exact path="/forgot-password">
                      <Redirect to="/auth/forgot-password" />
                    </Route>
                    <Route exact path="/auth/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route exact path="/new-password">
                      <Redirect to="/auth/new-password" />
                    </Route>
                    <Route exact path="/auth/new-password">
                      <NewPasswordChallenge />
                    </Route>
                    <Route exact path="/login">
                      <Redirect to="/auth/login" />
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route exact path="/auth/login">
                      <Login />
                    </Route>
                    <SearchProvider>
                      <AppLayout>
                        <AppRoutes />
                      </AppLayout>
                    </SearchProvider>
                  </Switch>
                </Html>
              </SnackbarProvider>
            </QueryParamProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
  </React.Fragment>
);

export default App;
