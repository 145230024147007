import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  PageHeaderTools,
  ToolbarGroup,
  ToolbarItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  Toolbar as PFToolbar,
} from '@patternfly/react-core';
import { CogIcon, PlusIcon } from '@patternfly/react-icons';
import { Search } from './search.component';
import { actionCreators } from '../../../app/common/auth/auth.reducer';

export const Toolbar: React.FC = () => {
  const dispatch = useDispatch();
  const [createDropdownOpen, setCreateDropdownOpen] = React.useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);

  const handleLogout = React.useCallback(() => {
    dispatch(actionCreators.logoutRequest());
  }, [dispatch]);

  return (
    <PageHeaderTools>
      <PFToolbar>
        <ToolbarGroup>
          <ToolbarItem className="toolbar-item">
            <Search />
          </ToolbarItem>
          <ToolbarItem id="create-dropdown" className="toolbar-item">
            <Dropdown
              position="right"
              isPlain
              onSelect={() => setCreateDropdownOpen(!createDropdownOpen)}
              isOpen={createDropdownOpen}
              toggle={
                <DropdownToggle onToggle={setCreateDropdownOpen}>
                  <PlusIcon />
                </DropdownToggle>
              }
              dropdownItems={[
                <DropdownItem key="link-aclprofile-new" component={<Link to="/aclprofile/new">ACL Profile</Link>}></DropdownItem>,
                <DropdownItem key="link-company-new" component={<Link to="/company/new">Company</Link>}></DropdownItem>,
                <DropdownItem key="link-controller-new" component={<Link to="/controller/new">Controller</Link>}></DropdownItem>,
                <DropdownItem key="link-license-new" component={<Link to="/license/new">License</Link>}></DropdownItem>,
                <DropdownItem key="link-partner-new" component={<Link to="/partner/new">Partner</Link>}></DropdownItem>,
                <DropdownItem key="link-site-new" component={<Link to="/site/new">Site</Link>}></DropdownItem>,
                <DropdownItem key="link-wan-new" component={<Link to="/wan/new">WAN</Link>}></DropdownItem>
              ]}
            />
          </ToolbarItem>
          <ToolbarItem id="profile-dropdown" className="toolbar-item">
            <Dropdown
              position="right"
              isPlain
              onSelect={() => setUserDropdownOpen(!userDropdownOpen)}
              isOpen={userDropdownOpen}
              toggle={
                <DropdownToggle onToggle={setUserDropdownOpen}>
                  <CogIcon />
                </DropdownToggle>
              }
              dropdownItems={[
                <DropdownItem key="routerlink-1" component={<Link to="/admin">My Account</Link>}></DropdownItem>,
                <DropdownItem key="routerlink-2" onClick={handleLogout}>
                  Logout
                </DropdownItem>,
              ]}
            />
          </ToolbarItem>
        </ToolbarGroup>
      </PFToolbar>
    </PageHeaderTools>
  );
};
