import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { State } from '@app/store/root-reducer';
import { selectTokenValid } from '@app/common/auth/auth.selectors';

const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
  authenticatedSelector: (state: State) => selectTokenValid(state),
  wrapperDisplayName: 'UserIsAuthenticated',
};

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  redirectPath: '/auth/login'
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (_, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/dashboard',
  allowRedirectBack: false,
  authenticatedSelector: (state: State) => !selectTokenValid(state),
  wrapperDisplayName: 'UserIsNotAuthenticated'
})