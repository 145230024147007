import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";

import { 
  LoginFooterItem, 
  LoginForm, 
  LoginMainFooterBandItem, 
  LoginPage, 
  LoginMainHeader, 
  ListItem 
} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import brandImg from '@app/assets/images/generic-login.jpg';
import { Spinner } from '@patternfly/react-core';

// import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import { userIsNotAuthenticated } from '@app/components/layout/auth.component';
import { actionCreators } from '@app/common/auth/auth.reducer';
import { State } from '@app/store/root-reducer';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      isValidUsername: false,
      isValidPassword: false,
      showHelperText: false,
      helperText: "",
      isLoading: false
    };
  }

  handleUsernameChange = (value) => {
    this.setState(() => ({ 
      userName: value 
    }));
  };

  handlePasswordChange = (password) => {
    this.setState(() => ({ 
      password
    }));
  };

  onLoginButtonClick = (event) => {
    event.preventDefault();
    const { userName, password } = this.state;

    if (userName && password) {
      this.setState(() => ({ 
        helperText: "Invalid login credentials",
        showHelperText: false,
        isLoading: true
      }));
      this.login(userName, password);
    }
    else {
      this.setState(() => ({ 
        isValidUsername: !!userName,
        isValidPassword: !!password,
        helperText: "Username and password are required",
        showHelperText: true,
        isLoading: false
      }));
    }
  };

  login = async (userName, password) => {
    this.setState({
      showHelperText: true,
      helperText: '',
    });
    this.props.dispatch(actionCreators.signInRequest(userName, password));
  }

  render() {
    const registrationLink = process.env.REACT_APP_PORTAL_URL + "/registration/";
    const termsLink = process.env.REACT_APP_APP_TERMS_LINK;
    const helpLink = process.env.REACT_APP_HELP_LINK;
    const privacyLink = process.env.REACT_APP_PRIVACY_POLICY_LINK;
    const forgotPasswordLink = "/forgot-password";
    const { helperText } = this.state;
    const { error, isLoading } = this.props;

    const message = error || helperText;

    const helperText = (
      <React.Fragment>
        {message}
      </React.Fragment>
    );

    const signUpForAccountMessage = (
      <LoginMainFooterBandItem>
        Need an account? <a href={registrationLink} target="_blank">Sign up here!</a>
      </LoginMainFooterBandItem>
    );

    const forgotCredentials = (
      <LoginMainFooterBandItem>
        <a href={forgotPasswordLink} target="_blank">Forgot your password?</a>
      </LoginMainFooterBandItem>
    );

    // const loginHeader = (
    //   <LoginMainHeader>
    //     <img src={EthicaLogo} alt="Ethica Logo" />
    //   </LoginMainHeader>
    // );

    const listItem = (
      <React.Fragment>
        <ListItem>
          <LoginFooterItem href={termsLink}>Terms of Use </LoginFooterItem>
        </ListItem>
        <ListItem>
          <LoginFooterItem href={helpLink}>Help</LoginFooterItem>
        </ListItem>
        <ListItem>
          <LoginFooterItem href={privacyLink}>Privacy Policy</LoginFooterItem>
        </ListItem>
      </React.Fragment>
    );

    const loginForm = (
      <LoginForm
        showHelperText={this.state.showHelperText}
        helperText={helperText}
        usernameLabel="Username"
        usernameValue={this.state.userName}
        onChangeUsername={(e) => this.handleUsernameChange(e)}
        isValidUsername={this.state.isValidUsername}
        passwordLabel="Password"
        passwordValue={this.state.password}
        onChangePassword={(e) => this.handlePasswordChange(e)}
        isValidPassword={this.state.isValidPassword}
        onLoginButtonClick={(e) => this.onLoginButtonClick(e)}
      />
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <LoginPage
          footerListVariants="inline"
          // brandImgSrc={EthicaLogo}
          // brandImgAlt="Ethica Channel Enablement"
          backgroundImgSrc={brandImg}
          backgroundImgAlt="Ethica Channel Enablement"
          footerListItems={listItem}
          textContent="Welcome to Orchestrator!"
          loginTitle="Log in to your account"
          loginSubtitle="Please use your Orchestrator credentials"
          signUpForAccountMessage={signUpForAccountMessage}
          forgotCredentials={forgotCredentials}
        >
          {loginForm}

          {isLoading ? <div align="center"><Spinner size="lg" /></div> : <div></div>}
        </LoginPage>
      </React.Fragment>
    );
  }
}

export default connect((state: State) => ({ 
  isLoading: state.common.auth?.isLoading,
  error: state.common.auth?.error,
}))(withRouter(userIsNotAuthenticated(Login)));